import { RUNSHEET } from './actionTypes'

export const addRunsheet = (info) => {
    return {
        type: RUNSHEET,
        payload: info
    }

}

