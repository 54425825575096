import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Spin } from "antd";
import { Form } from "react-bootstrap";

import { LoadingOutlined } from "@ant-design/icons";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";

const antIcon = <LoadingOutlined style={{ fontSize: 115 }} spin />;

const CourierDetails = ({
  loading,
  show,
  data,
  setClose,
}) => {
  const close = () => {
    setClose(false);
  };

 
  const cssClasses = ["Modal CourierdetailsCard", show ? "ModalOpen" : "ModalClosed"];

  return (
    <>
      <div className={cssClasses.join(" ")}>
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            <div className="sectionPadding">
              <p>
                <i onClick={close} className="fas fa-times" />
              </p>
              <h3>Courier ID #{data.id}</h3>
            </div>
            <Row className="mt-3">
                <Col sm="12" md="12">
                    <div className="position-relative text-area-form">

                        <label className="bold-label">Full Name*</label>
                        <Form.Control
                            defaultValue={data.courier.user.name}
                            disabled
                            type="text"
                        />

                    </div>
                </Col>
                <Col sm="12" md="12">
                    <label className="bold-label">Phone Number*</label>
                    <Form.Control
                        defaultValue={data.courier.user.phone}
                        disabled
                        type="text"
                    />

                </Col>
                <Col sm="12" md="12">
                    <label className="bold-label">Phone Number</label>
                    <Form.Control
                        defaultValue={data.courier.user.phone_ult ? data.courier.user.phone_ult : "No alternate phone number"}
                        disabled
                        type="text"
                    />

                </Col>
                <Col sm="12" md="12">
                    <label className="bold-label">Covered Zone</label>
                    <Form.Control
                        defaultValue={data.id}
                        disabled
                        type="text"
                    />

                </Col>
                <Col sm="12" md="12">
                    <label className="bold-label">Type of Vehicles</label>
                    <Form.Control
                        defaultValue={data.id}
                        disabled
                        type="text"
                    />

                </Col>
            </Row>
           
           
          </>
        )}
      </div>
    </>
  );
};

export default ErrorHandler(CourierDetails, axiosInstance);
