import React, { useState, useEffect, useRef, useMemo } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { branchesSellers } from "../../layout/tableColumns/wallets/sellers";
import { CircleRightMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxBranch } from "../../../global-state/actions/branchAction";
import { getSeller } from "../../../global-state/actions/getSelectedAction";
import { useTranslation } from "react-i18next";
import TablePro from "../../tables/TablePro";

function SellersWallets(props) {
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sellerDispatch = useDispatch();
    const branch = useSelector((state) => state.branch.branch);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const columns = useMemo(
        () => [
            {
                dataIndex: "seller_id",
                title: t("sellerID"),
                render: (_, row) => <>#{row.seller_id}</>,
            },
            {
                dataIndex: "seller_name",
                title: t("sellername"),
            },
            {
                dataIndex: "store",
                title: t("storename"),
            },
            {
                dataIndex: "debit",
                title: t("debit"),
                render: (_, row) => (
                    <p className="debit">
                        {cur} {row.debit}
                    </p>
                ),
            },
            {
                dataIndex: "credit",
                title: t("credit"),
                width: 180,
                render: (_, row) => (
                    <p className="credit">
                        {cur} {row.credit}
                    </p>
                ),
            },
            {
                dataIndex: "total_amount",
                title: t("totalAmount"),
                width: 220,
                // valueGetter: (row) => row.row?.id,
                render: (_, row) => (
                    <p
                        className={
                            parseInt(row.debit ?? 0) -
                                parseInt(row.credit ?? 0) >
                            0
                                ? "debit"
                                : parseInt(row.debit ?? 0) -
                                      parseInt(row.credit ?? 0) <
                                  0
                                ? "credit"
                                : "amount"
                        }
                    >
                        {cur}{" "}
                        {parseInt(row.debit ?? 0) - parseInt(row.credit ?? 0)}
                    </p>
                ),
            },
            {
                dataIndex: "more",
                title: "more",
                width: 220,
                // valueGetter: (row) => row.row?.id,
                render: (_, row) => (
                    <Link
                        onClick={() => {
                            dispatch(reduxBranch(row.seller_name));
                            sellerDispatch(getSeller(row));
                        }}
                        to={`/sellers_orders/${row.seller_id}`}
                        className="sellers-wallets"
                    >
                        <Icon source={CircleRightMajor} color="base" />
                    </Link>
                ),
            },
        ],
        []
    );

    const getSellers = async () => {
        let result = await axiosInstance
            .get(`/OrderChainWallet/`, {
                params: {
                    search: search?.length > 0 ? search : undefined,
                    page,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            console.log({ result: result.data });
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    useEffect(() => {
        getSellers();
    }, [search, page]);
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <TablePro
                title={
                    <h3 style={{ marginBottom: "0px", fontSize: "20px" }}>
                        {t("sideMenu.sellers")}
                    </h3>
                }
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
                onSearch={setSearch}
                dataSource={data}
                columns={columns}
            />
        </>
    );
}

export default ErrorHandler(SellersWallets, axiosInstance);
