import React, {useEffect, useState} from "react";
import styles from "./StoreReportsPage.module.css";
import CommonReportsPage from "../CommonReportsPage";
import {
    branchReportsTypes,
    storeReportsTypes,
} from "../../../../api/ReportsRepository";
import axiosInstance from "../../../../api/axios";
import {useTranslation} from "react-i18next";

export default function StoreReportsPage({}) {
    const [selectedType, setSelectedType] = useState();
    const [sellers, setSellers] = useState([]);
    const {t} = useTranslation();
    const fields = [
        {
            multiple: selectedType?.title !== "financial",
            name: "seller",
            label: t("reports.store_name"),
            type: "select",
            search: true,
            placeholder: t("reports.store_name_ph"),
            rules: [{required: true, message: t("reports.store_name_req")}],
            options: sellers.map((it) => (
                <option key={it.id} value={it.id}>
                    {it.store_name}
                </option>
            )),
        },
        {
            name: "type",
            label: t("reports.report_type"),
            type: "select",
            placeholder: t("reports.report_type_ph"),
            rules: [{required: true, message: t("reports.report_type_req")}],
            onChange: (v) => setSelectedType(storeReportsTypes[v]),
            options: Object.keys(storeReportsTypes).map((key) => (
                <option key={key}>
                    {t(`reports.store_list.${storeReportsTypes[key].title}`)}
                </option>
            )),
        },
    ];

    async function fetchSellers() {
        const res = await axiosInstance
            .get("/Seller/?page_size=1000")
            .catch((err) => console.log({err}));
        if (res?.data) {
            setSellers(res.data?.data ?? []);
        }
    }

    useEffect(() => {
        fetchSellers();
    }, []);
    return (
        <CommonReportsPage
            formFields={fields}
            selectedType={selectedType}
            section={t("reports.store_reports")}
        />
    );
}
