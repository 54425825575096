import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import StatusCard from "../../layout/StatusCard";
import { CircleRightMajor, PaymentsMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxBranch } from "../../../global-state/actions/branchAction";
import { getSeller } from "../../../global-state/actions/getSelectedAction";
import { addRunsheetOrders } from "../../../global-state/actions/rsOrdersAction";
import { useTranslation } from "react-i18next";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../tables/TablePro";
import { selectedState } from "../../../global-state/actions/stateAction";

function PaidRequests(props) {
    const { Dragger } = Upload;
    const options = {
        name: "file",
        multiple: false,
        beforeUpload: (file) => {
            setFile(file);
            console.log(file);
            return false;
        },
    };

    const dispatch = useDispatch();
    const sellerDispatch = useDispatch();
    const dispatchOrders = useDispatch();
    const { t } = useTranslation();
    const [file, setFile] = useState("");

    const [selected, setSelected] = useState("Sent");
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [payment, setPayment] = useState("");
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const prevSelectionModel = useRef(selectionModel);
    const [statesNumbers, setStatesNumbers] = useState({ 1: 0, 2: 0, 3: 0 });
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const hide = () => {
        setShow(false);
    };

    const hideSuccess = () => {
        setShowSuccess(false);
        setCurrentState(currentState);
        getSummary();
    };
    const [columns, setColumns] = useState([
        {
            field: "payment_id",
            title: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row?.id,
            render: (_, row) => <p className="paragraphMargin">#{row.id}</p>,
        },
        {
            field: "id",
            title: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
            ),
        },
        {
            field: "name",
            title: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            title: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
            ),
        },
        {
            field: "total_amount",
            title: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            title: `${t("createDate")}`,
            width: 190,
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            title: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <Link
                    onClick={() => {
                        console.log(row);
                        dispatch(reduxBranch(row.seller.user.name));
                        sellerDispatch(getSeller(row.seller));
                        dispatchOrders(addRunsheetOrders(row.orders));
                    }}
                    to={`/view_orders`}
                    className="sellers-wallets"
                >
                    <Icon source={CircleRightMajor} color="base" />
                </Link>
            ),
        },
        {
            field: "Pay",
            title: `${t("pay")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <div
                    onClick={() => {
                        setShow(true);
                        setPayment(row);
                    }}
                    to={`/sellers_orders/${row.id}`}
                    className="sellers-wallets"
                >
                    <Icon source={PaymentsMajor} color="base" />
                </div>
            ),
        },
    ]);

    const [columns1, setColumns1] = useState([
        {
            field: "payment_id",
            title: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row.id,
            render: (_, row) => <p className="paragraphMargin">#{row.id}</p>,
        },
        {
            field: "id",
            title: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
            ),
        },
        {
            field: "name",
            title: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            title: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
            ),
        },

        {
            field: "total_amount",
            title: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            title: `${t("createDate")}`,
            width: 190,
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            title: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <Link
                    onClick={() => {
                        console.log({ rowOrd: row.orders });
                        dispatch(reduxBranch(row.seller.user.name));
                        sellerDispatch(getSeller(row.seller));
                        dispatchOrders(addRunsheetOrders(row.orders));
                    }}
                    to={`/view_orders`}
                    className="sellers-wallets"
                >
                    <Icon source={CircleRightMajor} color="base" />
                </Link>
            ),
        },
    ]);
    const lang = useLanguage();

    const [currentState, setCurrentState] = useState({
        id: "2",
        type: `${lang == "ar" ? "المستلمة" : "Recieved"}`,
        number: "10",
        styleClass: "statusCard",
        background: "#E0F3E8",
        style: { borderLeft: `solid 20px #BFFFDA` },
    });
    const cards = [
        {
            id: "2",
            type: `${lang == "ar" ? "المستلمة" : "Recieved"}`,
            number: statesNumbers["2"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #BFFFDA` },
        },

        {
            id: "3",
            type: `${lang == "ar" ? "المدفوع" : "Paid"}`,
            number: statesNumbers["3"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #148060` },
        },
    ];

    const confirmPayment = async () => {
        let formData = new FormData();
        formData.append("payment_proof", file);
        formData.append("verdict", "True");
        let result = await axiosInstance.post(
            `/AcceptPaymentRequest/${payment.id}`,
            formData
        );
        if (result) {
            setShow(false);
            setShowSuccess(true);
        }
    };

    const getSummary = async () => {
        let result = await axiosInstance.get(`/PaymentSummary/?sender=${1}`);

        if (result) {
            console.log(result.data);
            setStatesNumbers(result.data);
        }
    };
    const fetchState = async () => {
        const state = currentState;
        let result;

        result = await axiosInstance.get(
            `/FilterPaymentRequest/?state=${state.id}&sender=${1}`,
            {
                params: {
                    state: state.id,
                    sender: 1,
                    page: page,
                },
            }
        );
        if (result) {
            setData(result.data.data);
            console.log(result.data);
            setCount(result.data.count);
        }
        setSelected(state.type);
    };

    useEffect(() => {
        getSummary();
    }, []);
    console.log({ currentState });

    useEffect(() => {
        fetchState();
    }, [page, currentState]);

    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.requestsToPay")}
                    </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="cardsMargin">
                {cards.map((card, index) => {
                    return (
                        <Col
                            sm="12"
                            md="4"
                            onClick={() => setCurrentState(card)}
                        >
                            <StatusCard
                                key={card.id}
                                selectedCard={selected}
                                type={card.type}
                                number={card.number}
                                styleClass={"statusCard"}
                                background={card.background}
                                style={card.style}
                            />
                        </Col>
                    );
                })}
            </Row>

            <TablePro
                title={<h6>{t("sentRequest")}</h6>}
                dataSource={data}
                columns={currentState.id == "3" ? columns1 : columns}
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
            />
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("invoiceAttached")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="totalAmount">
                        {t("addManualAttachment")}
                    </label>
                    <Dragger {...options}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t("attachmentText")}</p>
                    </Dragger>
                </Modal.Body>
                {currentState.id === "4" ? null : (
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("cancel")}
                        </button>
                        <button
                            disabled={file === ""}
                            className="confirm"
                            onClick={confirmPayment}
                        >
                            {t("confirm")}
                        </button>
                    </Modal.Footer>
                )}
            </Modal>
            <SuccessfulRequest
                show={showSuccess}
                hide={hideSuccess}
                title={t("settleTicket")}
                body={t("settleTicketBody")}
            />
        </>
    );
}

export default ErrorHandler(PaidRequests, axiosInstance);
