import React, { useEffect, useState } from "react";
import styles from "./OrderChainDetails/OrderChainDetails.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { NavLink, useParams } from "react-router-dom";
import OrderChainHeader from "./OrderChainDetails/OrderChainHeader";
import axiosInstance from "../../api/axios";
import { Icon } from "@shopify/polaris";
import { AppstoreAddOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { CiSquareQuestion } from "react-icons/ci";
import { GoChecklist } from "react-icons/go";
import { MdOutlineAssistWalker, MdOutlineDoneAll } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { LiaShippingFastSolid } from "react-icons/lia";
import { TbBrandDeliveroo } from "react-icons/tb";

function Status({ status, iconNode, currentStatus }) {
    return (
        <div className="d-flex flex-column justify-content-start align-items-center gap-2">
            <div
                className={`d-flex justify-content-center align-items-center rounded-circle ${
                    status.number <= currentStatus.number
                        ? "bg-success"
                        : "bg-secondary"
                } p-2 shadow-sm`}
                style={{
                    width: "150px",
                    height: "150px",
                }}
            >
                {iconNode}
            </div>
            <p className="m-0">{status.title}</p>
        </div>
    );
}

function OrderChainPersonDetails({ details }) {
    const { t } = useTranslation();

    return (
        <ul>
            {Object.entries(details).map(([key, value]) => (
                <li>
                    <strong>{t(key)}: </strong>
                    <span>{value}</span>
                </li>
            ))}
        </ul>
    );
}

export default function OrderUnAuth() {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const statuses = [
        {
            number: 1,
            title: t("Pickup Requested"),
            icon: (
                <CiSquareQuestion
                    style={{ fontSize: "64px", color: "white" }}
                />
            ),
        },
        {
            number: 4,
            title: t("Pickup Confirmed"),
            icon: <GoChecklist style={{ fontSize: "64px", color: "white" }} />,
        },
        {
            number: 5,
            title: t("Courier Received"),
            icon: (
                <MdOutlineAssistWalker
                    style={{ fontSize: "64px", color: "white" }}
                />
            ),
        },
        {
            number: 6,
            title: t("Placed in Warehouse"),
            icon: (
                <IoStorefrontOutline
                    style={{ fontSize: "64px", color: "white" }}
                />
            ),
        },
        {
            number: 7,
            title: t("In Delivery"),
            icon: (
                <LiaShippingFastSolid
                    style={{ fontSize: "64px", color: "white" }}
                />
            ),
        },
        {
            number: 8,
            title: t("Delivered"),
            icon: (
                <MdOutlineDoneAll
                    style={{ fontSize: "64px", color: "white" }}
                />
            ),
        },
    ];

    const { waybill } = useParams();

    async function fetchOrder() {
        const res = await axiosInstance
            .get(`/track-order/${waybill}/`)
            .catch((err) => {
                console.log("Err in fetchOrder", err.response);
                setError(err.response.data);
            });
        console.log("res", res);
        if (res?.data) {
            setData({
                ...res.data,
                order: {
                    id: res.data.id,
                    paymentMethod: res.data.payment_method,
                    type: res.data.type,
                },
                seller: {
                    // name: res.data.seller.user.name,
                    store: res.data.seller.store_name,
                    phone: res.data.seller.user.phone_alt ? res.data.seller.user.phone_alt : res.data.seller.user.phone,
                    pickupAddress: res.data.seller_address.address,
                },
                customer: {
                    name: res.data.client.name,
                    phone: res.data.client.phone,
                    address: res.data.client_address.address,
                },
            });
        }
    }

    useEffect(() => {
        fetchOrder();
    }, []);
    return (
        <Container className={styles.container}>
            {error && (
                <div
                    className="d-flex flex-column gap-3 justify-content-center align-items-center"
                    style={{
                        height: "90vh",
                    }}
                >
                    <h1 className="text-danger">Error</h1>
                    <div className="alert alert-danger">
                        {error ?? "Internal server error"}
                    </div>
                </div>
            )}
            {data.id && (
                <div className="py-5" style={{ minHeight: "90vh" }}>
                    <OrderChainHeader order={data.order} />
                    <div className="d-flex pb-4 justify-content-start align-items-start gap-1 flex-column">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <p className="m-0">
                                {t("Order Number")}: {data.waybill}
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <p className="m-0">
                                {t("Order Status")}: {data.order_status.title}
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <p className="m-0">{data.status_note}</p>
                        </div>
                    </div>

                    <div>
                        <Collapse
                            bordered={false}
                            expandIconPosition={"end"}
                            className={styles.collapse}
                            style={{ background: "transparent" }}
                        >
                            <Collapse.Panel
                                header={t("sellerInfo")}
                                key="1"
                                className={styles.panel}
                                style={{ borderRadius: "7px" }}
                            >
                                <OrderChainPersonDetails
                                    details={data.seller ?? {}}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={t("customerInfo")}
                                key="1"
                                className={styles.panel}
                                style={{ borderRadius: "7px" }}
                            >
                                <OrderChainPersonDetails
                                    details={data.customer ?? {}}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel
                                style={{ display: "none" }}
                                header=""
                                key="2"
                            />
                        </Collapse>
                    </div>
                    <div className="py-5 mt-5 d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center gap-3">
                            {statuses.map((status, ind) => (
                                <>
                                    <Status
                                        status={status}
                                        iconNode={status.icon}
                                        currentStatus={data.order_status}
                                    />
                                    {ind < statuses.length - 1 && (
                                        <div
                                            className={`rounded ${
                                                status.number <
                                                data.order_status.number
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            } p-2 shadow-sm`}
                                            style={{
                                                width: "100px",
                                                height: "10px",
                                            }}
                                        ></div>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
}
