import React, { useState, useEffect } from 'react'
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { Row, Col, Modal, Card, Button } from "react-bootstrap";
function Billing() {
    const [plans, setPlans] = useState([])
    const getPlans = async () => {
        let result = await axiosInstance.get("/PlanConsumption/").catch(err => console.log(err))

        if (result) {
            console.log(result)
            setPlans(result.data)
        }
    }

    useEffect(() => {
        getPlans()
    }, [])
    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">Settings</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">Billing</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">Billing</h2>
                </Col>
            </Row>
            <Card className="text-center">
                <Card.Header>My Plans</Card.Header>
                <Card.Body>
                    {plans.map(plan => {
                        console.log(plan)
                        return (
                            <div className="plan" key={plan.id}>

                                {/* <Card.Title>Special title treatment</Card.Title> */}
                                <Card.Text>
                                    {plan.plan.name}
                                </Card.Text>
                            </div>
                        )
                    })}
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
                <Card.Footer className="text-muted">
                </Card.Footer>
            </Card>

        </div>
    )
}


export default ErrorHandler(Billing, axiosInstance)

