import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import TablePro from "../tables/TablePro";
import axiosInstance from "../../api/axios";
import { useOrdersColumns } from "./tableColumns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SuccessfulRequest, UnSuccessfulRequest } from "../modals/confirmationModal";
import { set } from "react-hook-form";
import { Button, Spin, Select } from "antd";
import OrderDetails from "./OrderDetails";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function EditInternal() {
    const { t } = useTranslation();
    const history = useHistory();
    const {id, oper} = useParams();
    const [runsheet, setRunsheet] = useState({});
    const [loadingRunsheet, setLoadingRunsheet] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const columns = useOrdersColumns();
    const [selectedRows, setSelectedRows] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [orderModalData, setOrderModalData] = useState(null);
    const [orderModalLoading, setOrderModalLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [citySearchTerm, setCitySearchTerm] = useState("");
    const [modalData, setModalData] = useState({
        title: "",
        body: "",
        success: false,
    });
    const [modaltype, setModalType] = useState("success");


    const handlingError = (err, title) => {
        setModalType("error");
        if (err.response.status === 400) {
            console.log("ERRIR", err.response.data);
            setModalData({
                title: t(title),
                body: err.response.data instanceof Object ? 
                 t(Object.entries(err.response.data).map(([key, value]) => `${key}: ${key === "orders" ? `${Object.values(value).map(v => v instanceof Array ? v[0] : v).join("\n")}` : value instanceof Array ? value[0] : value}`).join("\n"))
                 : t(err.response.data),
                success: false,
            });
        } else {
            setModalData({
                title: t(title),
                body: t("server_error"),
                success: false,
            });
        }
        setShowModal(true);
    }

    const fetchOrderDetails = async (id, page) => {
        const result = await axiosInstance.get(`/Order/${id}`, {
            params: {
                page: page ?? 1,
                page_size: 4,
            },
        })
        .catch((err) => console.log(err));
        if (result) {
            setOrderModalData(result.data);
        }
    };

    const onModalPagination = async (page) => {
        setOrderModalLoading(true);
        await fetchOrderDetails(modalData.id, page);
        setOrderModalLoading(false);
    };

    const getRunsheet = async () => {
        setLoadingRunsheet(true);
        const result = await axiosInstance.get(`/InternalRunsheet/${id}`)
        .catch(err => handlingError(err, "internalRunsheet"));
        if (result) {
            setRunsheet(result.data);
            if (oper === "remove") {
                setOrders(result.data.orders);
                setCount(result.data.orders.length);
                setPageSize(result.data.orders.length);
            }
        }
        setLoadingRunsheet(false);
    };

    const getOrders = async () => {
        setLoadingOrders(true);
        const result = await axiosInstance.get(`/OrdersForInternalRunsheet/`, {
            params: {
                current_branch: runsheet.branch?.id,
                page_size: pageSize,
                page,
                state:"1",
                client_address__city__in: selectedCities.join(","),
                search: searchTerm,
            },
        })
        .catch(err => handlingError(err, "orders"));
        if (result) {
            setOrders(result.data.data);
            setCount(result.data.count);
        }
        setLoadingOrders(false);
    }

    const getCity = async () => {
        let result = await axiosInstance.get(`/FilterCity/`, {
            params: {
                page_size: 20,
                search: citySearchTerm,
            },
        });
        if (result) {
            setCities(result.data.data);
        }
    };

    useEffect(() => {
        getCity();
    }, [citySearchTerm]);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1);
    };

    const handleSearch = (searchTerm) => {
        if (oper === "add") {
            setSearchTerm(searchTerm);
            setPage(1);
        } else if (searchTerm && searchTerm.length > 0) {
            setOrders(runsheet.orders.filter((o) => o.id.toString().includes(searchTerm) || o.seller.store_name.includes(searchTerm)));
            setCount(runsheet.orders.filter((o) => o.id.toString().includes(searchTerm) || o.seller.store_name.includes(searchTerm)).length);
            setPageSize(runsheet.orders.filter((o) => o.id.toString().includes(searchTerm) || o.seller.store_name.includes(searchTerm)).length);
        } else {
            setOrders(runsheet.orders);
            setCount(runsheet.orders.length);
            setPageSize(runsheet.orders.length);
        }
    };

    const handleSelect = (rows) => {
        setSelectedRows(rows);
    };

    const handleOper = async () => {
        setLoading(true);
        let url
        if (oper === "add") {
            url = `/AddToInternalRunsheet/${id}`;
        } else if (oper === "remove") {
            url = `/RemoveFromInternalRunsheet/${id}`;
        }
        const result = await axiosInstance.put(url, {orders: selectedRows})
        .catch(err => handlingError(err, `${oper[0].toUpperCase() + oper.slice(1)} ${oper === "add" ? "to" : "from"} internal runsheet`));
        if (result) {
            setModalType("success");
            setModalData({
                title: t(`${oper[0].toUpperCase() + oper.slice(1)} ${oper === "add" ? "to" : "from"} internal runsheet`),
                body: t(`Orders ${oper === "add" ? "added" : "removed"} Successfully`),
                success: true,
            });
            setShowModal(true);
            getRunsheet();
        }
        setLoading(false);
    }

    const handleHide = (nav) => {
        console.log(nav, "NAV");
        if (nav) {
            history.push("/" + nav)
        } else {
            setShowModal(false);
            setModalData({
                title: "",
                body: "",
                success: false,
            });
        }
    }

    useEffect(() => {
        getRunsheet();
    }, [id]);

    useEffect(() => {
        if (oper === "add") {
            getOrders();
        }
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else if (oper === "add") {
            getOrders();
        }
    }, [searchTerm, pageSize, runsheet.branch, selectedCities]);

    useEffect(() => {
        if (oper === "remove" && selectedCities.length > 0) {
            console.log(runsheet.orders, selectedCities, "FROM USE EFFECT");
            setOrders(runsheet.orders.filter((o) => selectedCities.includes(+o.client_address.city)));
        } else if (oper === "remove" && selectedCities.length === 0) {
            setOrders(runsheet.orders);
        }
    } , [selectedCities]);

    return (
        <Container>
            <SuccessfulRequest
                show={modaltype === "success" && showModal}
                hide={() => handleHide(`edit_runsheet_priorities/${id}/int`)}
                {...modalData}
            />
            <UnSuccessfulRequest
                show={modaltype === "error" && showModal}
                hide={handleHide}
                {...modalData}
            />

            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.internalRunSheet")}</span>

                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t(oper === "add" ? "Add to Internal Runsheet" : "Remove from Internal Runsheet")}
                    </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t(oper === "add" ? "Add to Internal Runsheet" : "Remove from Internal Runsheet")}</h2>
                </Col>
            </Row>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3 position-relative">
                <div className="w-100">
                <TablePro
                    onSearch={handleSearch}
                    columns={columns.map((it, i) => {
                                    if (i === columns.length - 1) {
                                        return  {
                                            title: t("status"),
                                            width: 270,
                                            dataIndex: "runsheet_type",
                                            render: (_, row) =>  (
                                                        <div
                                                            className={"HRS" + " btn btn-outline-primary mx-2"}
                                                        >
                                                            {"HRS"}
                                                        </div>
                                                    )
                                                
                                        }
                                    } else {
                                        return it;
                                    }
                    })}
                    selection={"checkbox"}
                    selectedRows={selectedRows}
                    setSelectedRows={handleSelect}
                    dataSource={orders}
                    loading={loadingOrders || loadingRunsheet}
                    onRowClick={(i, row) => fetchOrderDetails(row.id)}
                    pagination={{
                        size: "medium",
                        current: page,
                        onChange: handlePageChange,
                        total: count,
                    }}
                    headerExtra={
                        <Select
                            mode="multiple"
                            allowClear
                            value={selectedCities}
                            onChange={(v) => {
                                setSelectedCities(v);
                                setSearchTerm("");
                            }}
                            placeholder={t("Filter by city")}
                            style={{ width: "200px" }}
                            onSearch={(v) => setCitySearchTerm(v)}
                            onClear={() => {
                                setSelectedCities([]);
                                setCitySearchTerm("");
                            }}
                            filterOption={false}
                            showArrow
                            showSearch
                            options={cities.map((it) => ({
                                value: it.id,
                                label: it.name,
                            }))}
                        />
                    }
                />
                </div>

                {
                    orderModalData && (
                        <OrderDetails
                            data={orderModalData}
                            loading={orderModalLoading}
                            onPagination={onModalPagination}
                            show={orderModalData}
                            setClose={() => setOrderModalData(null)}
                            current={orderModalData.tickets.page ?? 1}
                            tickets={orderModalData.tickets}
                            ticketsCount={orderModalData.tickets.count}
                        />
                    )
                }

                <div className="d-flex w-100 justify-content-end">
                    <Button
                        loading={loading}
                        disabled={selectedRows.length < 1}
                        onClick={handleOper}
                        className="confirm"
                    >
                        {t(oper === "add" ? "Add to Internal Runsheet" : "Remove from Internal Runsheet")}
                    </Button>
                </div>

            </div>

        </Container>

    );

}

export default ErrorHandler(EditInternal, axiosInstance);