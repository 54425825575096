import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import useLanguage from "../../../hooks/useLanguage";
import {Form, Input, Spin} from "antd";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../../api/axios";
import {SuccessfulRequest} from "../../modals/confirmationModal";

export default function CourierChangePassword({show, courierId, onHide}) {
    const lang = useLanguage();
    const {t} = useTranslation();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    async function submit(data) {
        setLoading(true)
        const res = await axiosInstance
            .post(`ChangeCourierPassword/${courierId}`, data)
            .catch((err) => {
                if (err.response.status < 500) {
                    setErrors(err.response.data.new_password?.map(it => it[0] === "[" ? it.slice(2, it.length - 2) : it) ?? [])
                }
            });
        if (res?.status >= 200 && res?.status < 210) {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 1300);

            setTimeout(() => onHide?.(), 1000)

        }
        setLoading(false);
    }

    return (
        <>
            <SuccessfulRequest
                show={success}
                title={
                    lang == "ar" ? "تحديث معلومات المستخدم" : "Update User Info"
                }
                body={
                    lang == "ar"
                        ? "تم تحديث المعلومات بنجاح"
                        : "This user has been updated successfully"
                }
            />
            <Modal
                dir={lang == "ar" ? "rtl" : "ltr"}
                show={show && courierId && !success}
                onHide={onHide}
            >
                <div style={{padding: "20px"}}>
                    <h3>{t("changeCourierPassword")}</h3>
                    <Form layout={"vertical"} onFinish={submit}>
                        <Form.Item
                            label={t("newPassword")}
                            name={"new_password"}
                            rules={[
                                {
                                    required: true,
                                    message: t("required"),
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <div style={{marginBottom: "16px"}}>
                            {
                                errors.map(err => <p className="error" key={err}>{err}</p>)
                            }
                        </div>
                        <button type={"submit"} disabled={loading} className={"confirm"}>
                            {loading ? <div style={{height: "20px"}}><Spin/></div> : t("confirm")}
                        </button>
                    </Form>
                </div>
            </Modal>
        </>
    );
}
