import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addRunsheetOrders } from "../../global-state/actions/rsOrdersAction";
import { useTranslation } from "react-i18next";

function ViewRunsheetOrders(props) {
  const dispatchOrders = useDispatch();
  const { t } = useTranslation();
  const orders = useSelector((state) => state.rsOrders.orders);

  const history = useHistory();
  const branch = useSelector((state) => state.branch.branch);
  const cur = useSelector(state => state.auth.user.user.organization.country.currency)
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("1");
  const [seller, setSeller] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [changed, setChanged] = useState(false);
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: `${t("orderID")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin tableID">#{row.row?.id}</p>
      )
    },
    {
      field: "sfee",
      headerName: `${t("shipmentFee")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{row.row?.delivery_fees}</p>
      )
    },
    // {
    //   field: "rto",
    //   headerName: `${t("returnedFee")}`,
    //   width: 220,
    //   // valueGetter: (row) => row.row?.id,
    //   renderCell: (row) => (
    //     <p className="paragraphMargin debit">EGP {row.row?.return_fees}</p>
    //   )
    // },
    {
      field: "value",
      headerName: `${t("orderValue")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{cur} {row.row?.sub_total}</p>
      )
    },
    {
      field: "method",
      headerName: `${t("paymentMethod")}`,
      width: 240,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.payment_method}</p>
      )
    }
  ]);
  const prevSelectionModel = useRef(selectionModel);
  const prevOrders = useRef(orders);

 

 
  useEffect(() => {
    setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
    console.log("selection model ", selectionModel);
  }, [page, data]);

 
  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.runsheets")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.archived")}</span>
         
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
        </Col>
      </Row>
     
      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("orders")}</h6>
        </div>

        <div className="requestTable">
          <DataGrid
            rows={orders}
            columns={columns}
            rowCount={count}
            pagination
            
          />
        </div>
      </div>
    </>
  );
}

export default ErrorHandler(ViewRunsheetOrders, axiosInstance);
