import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import img from "../../images/signup.svg";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  SuccessfulRequest,
  UnSuccessfulRequest,
} from "../modals/confirmationModal";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function compare( a, b ) {
  if ( a.name.common < b.name.common ){
    return -1;
  }
  if ( a.name.common > b.name.common ){
    return 1;
  }
  return 0;
}
function RegisterationAr() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [domain, setDomain] = useState("");
  const [country, setCountry] = useState({})
  const [countries2, setCountries2] = useState([])
  const [showFailed, setShowFailed] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("الاسم مطلوب"),
    email: Yup.string().required("البريد الإلكترونى مطلوب "),
    phone: Yup.number()
      .typeError("رقم الهاتف مطلوب")
      .required("رقم الهاتف مطلوب"),
    company_name: Yup.string().required("اسم الشركة مطلوب"),
    app_id: Yup.string().required("اسم النطاق مطلوب"),
    password: Yup.string()
      .required("كلمة المرور مطلوبة")
      .min(6, "كلمة المرور يجب ان تكون مكونة من 6 احرف علي الاقل"),
    confirmPassword: Yup.string()
      .required("تأكيد كلمة المرور مطلوبة")
      .oneOf([Yup.ref("password")], "كلمتى المرور غير متطابقتين"),
    terms: Yup.boolean().oneOf([true], "يجب ان توافق علي الشروط و الإحكام"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const changeCountry = e => {
    console.log(country)
    let c = countries2.find(country => country.cca2 === e.target.value);
    setCountry({country:c.name.common, currency:Object.keys(c.currencies)[0], iso:c.cca2})
  }

  useEffect(()=>{
    const getCountries = async () => {
      let result = await (await axios.get("https://restcountries.com/v3.1/all")).data
      result.sort(compare)
      setCountries2(result)
      console.log(result)
    }
    getCountries()
  }, [])
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const validate = async (data) => {
    setErrorArray([]);

    setLoading(true);
    let result = await axios
      .post(`https://main.ghenastore.com/client_create?lang=ar`, {...data, ...country}, {headers:{"Accept-Language": "ar"}})
      .catch((err) => {
        let errArray = [];

        for (const value of Object.values(err.response.data)) {
          console.log(value);
          let errText = value[0];
          errArray.push(errText);
          console.log(errArray);
        }
        setErrorArray(errArray);

        console.log(Object.values(err.response.data));
        setShowFailed(true);
        setLoading(false);
      });

    if (result) {
      console.log(result);
      setDomain(data.app_id);
      gtag_report_conversion()
      setShow(true);
      setLoading(false);
    }

    console.log(data);
  };

  const hide = (created) => {
    // setShow(false);
    setShowFailed(false);
    // testing
    localStorage.setItem("lang" , "ar")
    if (created) window.location.href = `https://${domain}.vialines.net/login`;

    // production
    // if (created) window.location.href = `https://${domain}.treecode.io/login/ar`;
  };


  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  function gtag_report_conversion(url) {
    // var callback = function () {
    //   if (typeof url != "undefined") {
    //     window.location = url;
    //   }
    // };
    gtag("js", new Date());
    gtag("config", "AW-587206414");
    gtag("event", "conversion", {
      send_to: "AW-587206414/H5QZCIjV4pADEI6egJgC",
      // event_callback: callback,
    });
    return false;
  }

  

  useEffect(()=>{
    localStorage.setItem("lang" , "ar")
  },[])
  return (
    <div dir="rtl" className="form-background">
      <div className="register-card">
        <form className="text-center" onSubmit={handleSubmit(validate)}>
          <Row>
            <Col sm="6" md="6">
              <p className="form-title">تسجيل الإشتراك</p>
              <div className="field-wrapper">
                <i class="fas fa-lg fa-user"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("name")}
                    placeholder="الاسم"
                    type="text"
                    isInvalid={!!errors.name}
                    //   isValid={!errors.name}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>

              <div className="field-wrapper">
                <i class="fas fa-envelope"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("email")}
                    placeholder="البريد الإلكترونى"
                    type="email"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.email?.message}</p> */}
              <div className="field-wrapper">
                <i class="fas fa-phone"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("phone")}
                    placeholder="رقم الهاتف"
                    className="number-input"
                    type="number"
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.phone?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.phone?.message}</p> */}
              <div className="field-wrapper">
                <i class="fas fa-building"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("company_name")}
                    placeholder="اسم الشركة"
                    type="text"
                    isInvalid={!!errors.company_name}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.company_name?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.company_name?.message}</p> */}
              <div className="field-wrapper">
                <i class="fas fa-building"></i>
                <Form.Select onChange={changeCountry} defaultValue="0" aria-label="Default select example">
                  <option disabled value="0">Choose Your country</option>
                  {countries2.map(country => {
                    return (
                      <option value={country.cca2}>{country.name.common}</option>
                    )
                  })}
                </Form.Select>
              </div>
              <div className="field-wrapper">
                <i class="fas fa-link"></i>
                <InputGroup hasValidation>
                  <InputGroup.Text id="basic-addon2">
                    treecode.io.
                  </InputGroup.Text>
                  <Form.Control
                    {...register("app_id")}
                    placeholder="اسم المستخدم"
                    type="text"
                    isInvalid={!!errors.app_id}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.app_id?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.app_id?.message}</p> */}
              <div className="field-wrapper">
                <i class="fas fa-lock"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("password")}
                    placeholder="كلمة المرور"
                    type="password"
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.password?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.password?.message}</p> */}
              <div className="field-wrapper">
                <i class="fas fa-key"></i>
                <InputGroup hasValidation>
                  <Form.Control
                    {...register("confirmPassword")}
                    placeholder="تكرار كلمة السر"
                    type="password"
                    isInvalid={!!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.confirmPassword?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              {/* <p className="error">{errors.confirmPassword?.message}</p> */}

              <div className="text-center field-wrapper">
                <input {...register("terms")} id="terms" type="checkbox" />
                <label className="me-2" for="terms">
                  انا موافق علي كل{" "}
                </label>
                {/* testing */}
                <a href="https://test.tree-code.com/ar/terms">
                  الشروط و الأحكام
                </a>
                {/* production */}
                {/* <a href="https://tree-code.com/ar/terms"> الشروط و الأحكام </a> */}
              </div>
              <p className="error">{errors.terms?.message}</p>

              <button disabled={loading} className="confirm mt-4" type="submit">
                تسجيل
              </button>
            </Col>
            <Col sm="6" md="6" className="order-first order-md-last">
              <img src={img} alt="Sign up photo" />
            </Col>
          </Row>
        </form>
      </div>
      <SuccessfulRequest
        show={show}
        title="تجهيز شركتك"
        body={
          <p>
            تم إنشاء كلمة المرور و النطاق بنجاح
          </p>
        }
        hide={() => hide(true)}
      />
      <UnSuccessfulRequest
        show={showFailed}
        title="تجهيز شركتك"
        body={errorArray[0]}
        hide={() => hide(false)}
      />
      {/* <CSSTransitionGroup  transitionName="overlay"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}> */}
      {loading ? (
        <div className="overlayModal">
          <Spin className="registration-spinner" indicator={antIcon} />
          <p className="title fs-2">تجهيز منظمتك...</p>
          <p className="fs-5">من فضلك لا تغلق هذه الصفحةو انتظر حتي خمس دقائق</p>
        </div>
      ) : null}
      {/* </CSSTransitionGroup> */}
    </div>
  );
}

export default RegisterationAr;
