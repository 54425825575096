import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select, Form as AntForm } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addAccount } from "../../../../../global-state/actions/addAccountAction";
import {
    AccountConfirmationModal,
    CreationConfirmationModal,
    PaymentAlert,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SHOW_ACCOUNT_CREATION_ERROR } from "../../../../../global-state/actions/actionTypes";
import useLanguage from "../../../../../hooks/useLanguage";
import FieldsList from "../../../../../components/FieldsList";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import SelectAreas from "../../../../../components/select-search/AreasSelect";

export default function SellerPersonalInfo({ value, handleChange, onBack }) {
    const history = useHistory();

    const account = useSelector((state) => state.account);
    const errors = useSelector((state) => state.account.errors) ?? "";
    const dispatch = useDispatch();
    const [workModels, setWorkModeals] = useState([]);
    const [next, SetNext] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [workModelAreas, setWorkModelAreas] = useState([]);
    const [cities, setCities] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [ID, setID] = useState("");
    const [addressErr, setAddressErr] = useState("");
    const [password, setPassword] = useState("");
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [addresses, setAddresses] = useState([
        {
            address: "",
            branch: "",
            //   city: "",
            area: "",
            new_work_model: "",
        },
    ]);
    const { t } = useTranslation();
    console.log({ account });
    const getErrors = (e) => {
        console.log(e);
        const flattenObject = (obj, prefix = "") =>
            Object.keys(obj).reduce((acc, k) => {
                const pre = prefix.length ? prefix + "." : "";
                if (typeof obj[k] === "object" && !Array.isArray(obj[k]))
                    Object.assign(acc, flattenObject(obj[k], pre + k));
                else acc[k] = obj[k];
                return acc;
            }, {});

        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: flattenObject(e),
        });
    };
    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        console.log({ hideA: account });
        setShowConfirm(false);
        history.push(`/user_details/seller/${ID}`);
    };

    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...addresses];
        list[index][name] = value;
        const addressesList = [...selectedAddress];
        addressesList[index] = e.target.value;
        setSelectedAddress(addressesList);
        setAddresses(list);
    };

    const handleSelectedBranch = (e, i) => {
        const list = [...addresses];
        list[i].branch = e;
        const copy = [...selectedBranch];
        copy[i] = e;
        setSelectedBranch(copy);
        setAddresses(list);
    };

    const handleSelectedArea = (e, i) => {
        const list = [...addresses];
        list[i].area = e;
        const copy = [...selectedArea];
        copy[i] = e;
        setSelectedArea(copy);
        setAddresses(list);
    };

    useEffect(() => {
        console.log(selectedBranch);
    }, [selectedBranch]);
    const addAnotherAddress = () => {
        const addressList = [...addresses];
        setAddresses([
            ...addressList,
            {
                address: "",
                branch: "",
                // city: "",
                area: "",
                new_work_model: "",
            },
        ]);
    };
    const getAreasByWorkModel = async () => {
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    page_size: 10000,
                },
            })
            .catch((err) => console.log(err));

        return result.data.data;
    };
    const validate = (data) => {
        console.log(`Form Data = ${data}`);
        // console.log(formState.isValid)
        if (
            value === 1 &&
            (selectedArea.length === 0 ||
                selectedAddress.length === 0 ||
                selectedBranch.length === 0)
        ) {
            setAddressErr(t("addressErr"));
        } else {
            setAddressErr("");
            console.log(addresses);
            data.seller_addresses = addresses;

            if (value === 1) {
                dispatch(addAccount(data));
                dispatch({ type: SHOW_ACCOUNT_CREATION_ERROR, payload: "" });
                handleChange(undefined, value + 1);
            } else showModal();
        }

        // handleChange(null, value + 1)
    };

    const getWorkModels = async (selectedBranch) => {
        const result = await axiosInstance
            .get(`/NewWorkModel/?branch=${selectedBranch}&page_size=10000`)
            .catch((err) => console.log(err));

        return result.data.data;
    };

    //   useEffect(() => {
    //     // setWorkModeals([]);
    //     getWorkModels();
    //     getAreas();
    //     // return () => setWorkModeals([]);
    //   }, [selectedBranch]);
    const getBranches = async (e, i) => {
        const result = await axiosInstance
            .get("/Branch/?page_size=100000")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            SetNext(result.data.next);
        }
    };

    useEffect(() => {
        getBranches();
    }, []);

    const deleteAddress = (e, i) => {
        const list = [...addresses];
        const branches = [...selectedBranch];
        const zones = [...selectedZone];
        const areas = [...selectedArea];

        branches.splice(i, 1);
        zones.splice(i, 1);
        areas.splice(i, 1);
        list.splice(i, 1);

        setSelectedBranch(branches);
        setSelectedZone(zones);
        setSelectedArea(areas);
        setAddresses(list);
    };

    const lang = useLanguage();
    return (
        <>
            <ModifiedForm
                submitFunction={validate}
                defaultValues={account ?? undefined}
            >
                <fieldset disabled={value === 2}>
                    <Row>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="name"
                                    className="formTextField text-area"
                                    title={t("fullname")}
                                    disabled={false}
                                    name="name"
                                    validation={{ required: true }}
                                    shouldRegister
                                />

                                <TextField
                                    id="phone"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    shouldRegister
                                    type="text"
                                    numeric={true}
                                    name="phone"
                                    backendErrors={
                                        errors !== ""
                                            ? errors.phone?.length > 0
                                                ? errors.phone[0]
                                                : null
                                            : null
                                    }
                                    disabled={false}
                                    validation={{
                                        required: true,
                                        pattern: /^\+[1-9]\d{10,14}$/,
                                    }}
                                />
                                <TextField
                                    id="phone_alt"
                                    className="formTextField text-area"
                                    title={t("altPhone")}
                                    type="text"
                                    numeric={true}
                                    shouldRegister
                                    name="phone_alt"
                                    disabled={false}
                                    validation={{
                                        pattern: /^\+[1-9]\d{10,14}$/,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="store_name"
                                    className="formTextField text-area"
                                    title={t("storename")}
                                    shouldRegister
                                    validation={{ required: true }}
                                    name="store_name"
                                    disabled={false}
                                />
                                <TextField
                                    id="email"
                                    className="formTextField text-area"
                                    title={t("email")}
                                    shouldRegister
                                    backendErrors={
                                        errors !== ""
                                            ? errors.email?.length > 0
                                                ? errors?.email[0]
                                                : null
                                            : null
                                    }
                                    validation={{ required: true }}
                                    name="email"
                                    disabled={false}
                                />
                                <p className="info-text">
                                    {t("sellerInfoTxt")}
                                </p>
                            </div>
                        </Col>
                    </Row>

                    {value !== 2 &&
                        addresses.map((address, i) => {
                            return (
                                <div
                                    style={{
                                        marginTop: "20px",
                                    }}
                                    key={i}
                                    className="address-container"
                                >
                                    {value !== 2 && (
                                        <Row>
                                            <Col
                                                className="btnPosition"
                                                sm="12"
                                            >
                                                <i
                                                    onClick={(e) =>
                                                        deleteAddress(e, i)
                                                    }
                                                    disabled={
                                                        addresses.length === 1
                                                    }
                                                    className="fas fa-times px-2"
                                                >
                                                    {" "}
                                                </i>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col sm="12" md="6">
                                            <div className="text-area-form">
                                                <label>{t("branch")}</label>
                                                <Select
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onChange={(v) =>
                                                        handleSelectedBranch(
                                                            v,
                                                            i
                                                        )
                                                    }
                                                >
                                                    {branches.map((branch) => (
                                                        <Select.Option
                                                            key={branch.id}
                                                            value={branch.id}
                                                        >
                                                            {branch.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className="text-area-form">
                                                <label>{t("area")}</label>

                                                <SelectAreas
                                                    // onSearch={onSearch}
                                                    // filterOption={false}
                                                    // showSearch
                                                    value={selectedArea[i]}
                                                    disabled={value === 2}
                                                    onChange={(e) =>
                                                        handleSelectedArea(e, i)
                                                    }
                                                    allowClear
                                                    className="form-control paginated"
                                                    name="area"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className="text-area-form">
                                                <label className="label">
                                                    {t("address")}
                                                </label>

                                                <Form.Control
                                                    className="formTextField text-area"
                                                    // title={"Weight"}
                                                    name="address"
                                                    onChange={(e) =>
                                                        handleInput(e, i)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    <p className="error">
                        {addressErr ? `${t("addressFields")}` : ""}
                    </p>

                    {value === 1 && (
                        <Row className="mt-4">
                            <Col sm="12">
                                <p
                                    onClick={addAnotherAddress}
                                    className="addBtn"
                                >
                                    <i className="fas fa-plus-circle mx-2"></i>
                                    {t("addAddress")}
                                </p>
                            </Col>
                        </Row>
                    )}
                </fieldset>

                <Row>
                    <Col className="text-end buttons-margin" sm="12">
                        <button
                            className="cancel"
                            type="button"
                            onClick={onBack}
                        >
                            {t("back")}
                        </button>
                        <input
                            className="confirm"
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </ModifiedForm>
            <CreationConfirmationModal
                getErrors={getErrors}
                setID={setID}
                setPassword={setPassword}
                title={lang === "ar" ? "انشاء حساب" : "Create Account"}
                show={show}
                showConfirm={showConfirmModal}
                hide={hide}
                account={account}
                onBack={onBack}
            />
            <AccountConfirmationModal
                show={showConfirm}
                hide={hideConfirm}
                id={ID}
                password={password}
                account={account}
            />
            {/* <button className="cancel" onClick={back}>
                        Back
                    </button> */}
        </>
    );
}
