import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
    CheckBox,
} from "../../../../../components/form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addAccount } from "../../../../../global-state/actions/addAccountAction";
import {
    AccountConfirmationModal,
    CreationConfirmationModal,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SHOW_ACCOUNT_CREATION_ERROR } from "../../../../../global-state/actions/actionTypes";

export default function CourierPersonalInfo({ value, handleChange, onBack }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [zones, setZones] = useState([]);
    const [next, SetNext] = useState("");
    const [loadMore, setLoadMore] = useState(true);

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [ID, setID] = useState("");
    const [password, setPassword] = useState("");
    const errors = useSelector((state) => state.account.errors) ?? "";

    const branch = useSelector((state) => state.account.branch);
    const account = useSelector((state) => state.account);
    const { t, i18n } = useTranslation();

    const getErrors = (e) => {
        console.log(e);
        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: e,
        });
    };
    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        history.push("/all_accounts");
    };

    const validate = (data) => {
        console.log(`Form Data = ${data}`);
        dispatch(addAccount(data)); // console.log(formState.isValid)
        if (value === 1) {
            handleChange(undefined, value + 1);
            dispatch({ type: SHOW_ACCOUNT_CREATION_ERROR, payload: "" });
        } else showModal();

        // handleChange(null, value + 1)
    };

    const getZones = async () => {
        let result = await axiosInstance
            .get(`/FilterZones/?branch=${branch}`)
            .catch((err) => console.log(err));
        if (result) {
            setZones(result.data.data);
            SetNext(result.data.next);
        }
    };

    const onScroll = async (event, link) => {
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${next}`)
                .catch((err) => console.log(err));

            if (result) {
                setZones([...zones, ...result.data.data]);
                SetNext(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    return (
        <ModifiedForm
            submitFunction={validate}
            defaultValues={account ?? undefined}
        >
            <fieldset disabled={value === 2}>
                <Row>
                    <Col sm="12" md="6">
                        <div className="text-area-form">
                            <TextField
                                id="name"
                                className="formTextField text-area"
                                title={t("fullname")}
                                disabled={false}
                                name="name"
                                validation={{ required: true }}
                                shouldRegister
                            />

                            <TextField
                                id="phone"
                                className="formTextField text-area"
                                title={t("phone")}
                                shouldRegister
                                backendErrors={
                                    errors !== ""
                                        ? errors.phone?.length > 0
                                            ? errors.phone[0]
                                            : null
                                        : null
                                }
                                name="phone"
                                type="text"
                                numiric={true}
                                disabled={false}
                                validation={{
                                    required: true,
                                    pattern: /^\+[1-9]\d{10,14}$/,
                                }}
                            />
                            <TextField
                                id="phone_alt"
                                className="formTextField text-area"
                                type="text"
                                numiric={true}
                                title={t("altPhone")}
                                shouldRegister
                                name="phone_alt"
                                disabled={false}
                                validation={{
                                    pattern: /^\+[1-9]\d{10,14}$/,
                                }}
                            />
                        </div>
                    </Col>
                    <Col
                        sm="12"
                        md="6"
                        className="d-flex flex-column justify-content-between"
                    >
                        <div className="text-area-form">
                            <TextField
                                id="email"
                                className="formTextField text-area"
                                title={t("email")}
                                shouldRegister
                                disabled={false}
                                backendErrors={
                                    errors !== ""
                                        ? errors.email?.length > 0
                                            ? errors?.email[0]
                                            : null
                                        : null
                                }
                                name="email"
                                validation={{ required: true }}
                            />

                            <TextField
                                id="vehicle"
                                className="formTextField text-area"
                                title={t("typeofvehicles")}
                                shouldRegister
                                validation={{ required: true }}
                                name="vehicle"
                                disabled={false}
                            />
                        </div>
                        <div className="text-area-form">
                            <CheckBox
                                id="assign_to_terminal"
                                className="formCheckBox text-area"
                                title={t("Assign To Terminal")}
                                shouldRegister
                                name="assign_to_terminal"
                                disabled={false}
                            />
                        </div>
                    </Col>
                </Row>
            </fieldset>

            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="cancel" type="button" onClick={onBack}>
                        {t("discard")}
                    </button>
                    <input
                        className="confirm"
                        value={t("confirm")}
                        type="submit"
                    />
                </Col>
            </Row>
            <CreationConfirmationModal
                getErrors={getErrors}
                setPassword={setPassword}
                setID={setID}
                title={t("accountCreation")}
                show={show}
                showConfirm={showConfirmModal}
                hide={hide}
                account={account}
                onBack={onBack}
            />
            <AccountConfirmationModal
                show={showConfirm}
                hide={hideConfirm}
                id={ID}
                password={password}
                account={account}
            />
        </ModifiedForm>
    );
}
