import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {Form, Input, Button, Select} from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeader from "../../layout/PageHeader";
import {toast, ToastContainer} from "react-toastify";
import { Spin } from "antd";


const { Option } = Select;

export const ThirdPartyKeys = ({keys, setKeys, thirdPartyId}) => {
    const { t } = useTranslation();
    const apiFields = [
        {
            name: "api_key",
            label: t("API Key"),
        },
        {
            name: "hmac_secret",
            label: t("HMAC Secret"),

        }
    ];
    const [loading, setLoading] = useState(false);
    const [currentKey, setCurrentKey] = useState(null);

    const regenrateKey = async (key_name) => {
        setLoading(true);
        setCurrentKey(key_name);
        try {
            const result = await axiosInstance.post(`/RegenrateKeys/${thirdPartyId}`, {key_name})
            .catch((err) => {
                if (err.response.status === 400) {
                    toast.error(err.response.data.error);
                } else {
                    toast.error("Something went wrong");
                }
            })
            setKeys({...keys, [key_name]: result.data[key_name]});
            toast.success(result.data.message);
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setLoading(false);
            setCurrentKey(null);
        }
    }



    return (
        <Form
            className="w-100"
            layout="vertical"
        >
            <div className="row">
                {
                    apiFields.map((field) => (
                        <div className="col-md-6">
                            <Form.Item
                                key={field.name}
                                name={field.name}
                                label={field.label}
                            >
                                <div className="d-flex flex-column gap-2 justify-content-start align-items-start">
                                    <div className="d-flex justify-content-between w-100">
                                        <Input
                                            type="text"
                                            name={field.name}
                                            value={keys[field.name]}
                                            disabled
                                        />
                                        <Button
                                            type="primary"
                                            style={{
                                                backgroundColor: "#57BE6C",
                                                color: "#fff",
                                                borderColor: "#57BE6C",
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(keys[field.name]);
                                                toast.warning("Copied to clipboard");
                                            }}
                                        >
                                            <i className="far fa-copy"></i>
                                        </Button>
                                    </div>
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: "#57BE6C",
                                            color: "#fff",
                                            borderColor: "#57BE6C",
                                        }}
                                        onClick={() => regenrateKey(field.name)}
                                        loading={loading && currentKey === field.name}
                                        disabled={loading && currentKey === field.name}
                                    >
                                        {t("Regenerate")}
                                    </Button>
                                </div>
                            </Form.Item>
                        </div>
                    ))
                }
            
            </div>
        </Form>
    )
}

const ThirdPartyInfo = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [errors, setErrors] = useState({});
    const [thirdParty, setThirdParty] = useState(null);
    const [dirty, setDirty] = useState(false);
    const { id } = useParams();
    const [form] = Form.useForm();
    const fields = [
        {
            name: "name",
            label: t("name"),
            type: "text",
            rules: [
                {
                    required: true,
                    message: t("requiredField"),
                },
            ],
        },
        {
            name: "name_ar",
            label: t("nameAr"),
            type: "text",
        },
        {
            name: "email",
            label: t("email"),
            type: "email",
            rules: [
                {
                    required: true,
                    message: t("requiredField"),
                },
            ],
        },
        {
            name: "phone",
            label: t("phone"),
            type: "text",
            rules: [
                {
                    required: true,
                    message: t("requiredField"),
                },
                {
                    pattern: /^\+[1-9]\d{10,14}$/,
                    message: t("Invalid phone number must be in format +XXXXXXXXXXX"),
                }
            ],
        },
        {
            name: "phone_alt",
            label: t("phoneAlt"),
            type: "text",
            rules: [
                {
                    required: false,
                },
                {
                    pattern: /^\+[1-9]\d{10,14}$/,
                    message: t("Invalid phone number must be in format +XXXXXXXXXXX"),
                }
            ],
        },
        {
            name: "address",
            label: t("address"),
            type: "text",
            rules: [
                {
                    required: true,
                    message: t("requiredField"),
                },
            ],
        },
        {
            name: "website",
            label: t("website"),
            type: "text",
            rules: [
                {
                    required: false,
                },
                {
                    pattern: /^(http|https):\/\/[^ "]+$/,
                    message: t("Invalid URL"),
                },
            ],

        },
        {
            name: "facebook",
            label: t("facebook"),
            type: "text",
            rules: [
                {
                    required: false,
                },
                {
                    pattern: /^(http|https):\/\/[^ "]+$/,
                    message: t("Invalid URL"),
                },
            ],
        },
        {
            name: "instagram",
            label: t("instagram"),
            type: "text",
            rules: [
                {
                    required: false,
                },
                {
                    pattern: /^(http|https):\/\/[^ "]+$/,
                    message: t("Invalid URL"),
                },
            ],
        },
        {
            name: "twitter",
            label: t("twitter"),
            type: "text",
            rules: [
                {
                    required: false,
                },
                {
                    pattern: /^(http|https):\/\/[^ "]+$/,
                    message: t("Invalid URL"),
                },
            ],
        },
    ];



    const getThirdParty = async () => {
        try {
            setFetching(true);
            const response = await axiosInstance.get(`/ThirdPartyCompany/${id}`)
            .catch((err) => {
                if (err.response.status === 400) {
                    const nonFieldErrors = [];
                    form.setFields([
                        Object.entries(err.response.data).filter(([key, value]) => {
                            if (fields.map((field) => field.name).includes(key)) {
                                return true;
                            } else {
                                nonFieldErrors.push(value);
                                return false;
                            }
                        })
                        .map(([key, value]) => ({
                            name: key,
                            errors: value instanceof Array ? value : [value],
                        })),
                    ]);
                    if (nonFieldErrors.length > 0) {
                        nonFieldErrors.forEach((error) => toast.error(error));
                    }
                } else {
                    throw err;
                }
            });
            // form.setFieldsValue(response.data);
            setThirdParty(response.data);
            
            console.log(response.data, "third party");
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setFetching(false);
        }
    }

    useEffect(() => {
        getThirdParty();
    }, []);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const result = await axiosInstance.put(`/ThirdPartyCompany/${id}`, {...values, user: thirdParty.user})
            .catch((err) => {
                if (err.response.status === 400) {
                    const nonFieldErrors = [];
                    form.setFields([
                        Object.entries(err.response.data).filter(([key, value]) => {
                            if (fields.map((field) => field.name).includes(key)) {
                                return true;
                            } else {
                                nonFieldErrors.push(value);
                                return false;
                            }
                        })
                        .map(([key, value]) => ({
                            name: key,
                            errors: value instanceof Array ? value : [value],
                        })),
                    ]);
                    if (nonFieldErrors.length > 0) {
                        nonFieldErrors.forEach((error) => toast.error(error));
                    }
                } else {
                    throw err;
                }
            });
            if (result) {
                toast.success("Third party company updated successfully");
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (event) => {
        form.setFieldValue(event.target.name, event.target.value);
        setDirty(form.isFieldsTouched(false));
    };

    const regenrateKey = async (key_name) => {
        const result = await axiosInstance.post(`/RegenrateKeys/${id}`, {key_name})
        .catch((err) => {
            if (err.response.status === 400) {
                toast.error(err.response.data.error);
            } else {
                toast.error("Something went wrong");
            }
        })
        if (result) {
            toast.success(result.data.message);
            setThirdParty({...thirdParty, [key_name]: result.data[key_name]});
        }
    }


    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{zIndex: 999999}}
            />
            <div>
                <PageHeader
                    title={t("thirdPartyInfo")}
                    group={t("sideMenu.accounts")}
                />
            </div>
            <div className="d-flex flex-column gap-3">
                {
                    (fetching || !thirdParty) && <div className="d-flex justify-content-center align-items-center w-100" style={{height: "50vh"}}> <Spin size="large" /> </div>
                }
                {
                    !fetching && thirdParty?.id && (
                        <>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={thirdParty}
                        >
                            <div className="row">
                                {
                                    fields.map((field) => (
                                        <div className="col-md-6">
                                            <Form.Item
                                                key={field.name}
                                                name={field.name}
                                                label={field.label}
                                                rules={field.rules}
                                            >
                                                {
                                                    field.type === "select" ? (
                                                        <Select
                                                            showSearch
                                                            placeholder={field.label}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                                        >
                                                            {
                                                                field.options.map((option) => (
                                                                    <Option value={option.value}>{option.label}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    ) : (
                                                        <Input
                                                            type={field.type}
                                                            name={field.name}
                                                            onChange={handleChange}
                                                            placeholder={field.label}
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button htmlType="submit" style={{
                                    backgroundColor: "#57BE6C",
                                    color: "#fff",
                                    borderColor: "#57BE6C",
                                }} type="primary" loading={loading}
                                disabled={loading || !dirty}
                                >{t("save")}</Button>
                            </div>
                        </Form>
                       <ThirdPartyKeys keys={thirdParty} setKeys={setThirdParty} thirdPartyId={id} />
                        </>
                                                
                    )
                }
            </div>

        </div>
    )
}

export default ErrorHandler(ThirdPartyInfo, axiosInstance);

            




