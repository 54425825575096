
import React, { useState, useEffect } from 'react'
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { UnSuccessfulPayment } from '../modals/confirmationModal';
import { useLocation } from 'react-router-dom';
function PaymentFail() {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();

    const [show, setShow] = useState(true)
    return (
        <div>
            <UnSuccessfulPayment body={query.get("status")} setShow={setShow} show={show} />
        </div>
    )
}

export default ErrorHandler(PaymentFail, axiosInstance)
