import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import StatusCard from "../../../layout/StatusCard";
import { CircleRightMajor, ViewMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxBranch } from "../../../../global-state/actions/branchAction";
import { getSeller } from "../../../../global-state/actions/getSelectedAction";
import { addRunsheetOrders } from "../../../../global-state/actions/rsOrdersAction";
import { useTranslation } from "react-i18next";
import { SuccessfulRequest } from "../../../modals/confirmationModal";
import { Upload, message,Image } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import useLanguage from "../../../../hooks/useLanguage";

function SellersPaidRequests(props) {

  const { Dragger } = Upload;
  const options = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setFile(file);
      console.log(file);
      return false;
    },
  };
  const dispatch = useDispatch();
  const sellerDispatch = useDispatch();
  const dispatchOrders = useDispatch();
  const { t } = useTranslation();

  const [file, setFile] = useState("");
  const [selected, setSelected] = useState("Sent");
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [payment, setPayment] = useState("");
  const [show, setShow] = useState(false);
  const [showImage , setShowImage] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false);
  const [ paymentProof , setPaymentProof] = useState("")
  const [page, setPage] = useState(1);
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);
  const [statesNumbers , setStatesNumbers] = useState({1 : 0, 2 :0 , 3 :0})
  const cur = useSelector(state => state.auth.user.user.organization.country.currency)
  const hide = () => {
    setShow(false);
    setShowImage(false)
  };

  const hideSuccess = () => {
    setShowSuccess(false);
    selectState(currentState)
    getSummary()

  };
  const [columns, setColumns] = useState([
    {
      field: "payment_id",
      headerName: `${t("paymentID")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">#{row.row?.id}</p>
      )
    },
    {
      field: "id",
      headerName: `${t("sellerID")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">#{row.row?.seller.id}</p>
      )
    },
    {
      field: "name",
      headerName: `${t("sellername")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.seller.user.name}</p>
      )
    },
    {
      field: "store",
      headerName: `${t("storename")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.seller.store_name}</p>
      )
    },
   
    {
      field: "total_amount",
      headerName: `${t("totalAmount")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <strong>
          {cur}{" "}
          {parseInt(row?.row.amount)}
        </strong>
      )
    },
    {
      field: "create_date",
      headerName: `${t("createDate")}`,
      width: 190,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row.create_date?.split("T")[0]}</p>
      )
    },
    {
      field: "image",
      headerName: `${t("image")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <div 
        className="sellers-wallets"

         onClick={()=> {  
          console.log("fwef")
          setPaymentProof(row.row.payment_proof)
          setShowImage(true)
        }}>
          <Icon source={ViewMajor} color="base" />
       </div>

      )
    },
    {
      field: "more",
      headerName: `${t("more")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <Link
          onClick={() => {
            console.log(row.row)
            dispatch(reduxBranch(row.row.seller.user.name));
            sellerDispatch(getSeller(row.row.seller));
            dispatchOrders(addRunsheetOrders(row.row.orders));
          }}
          to={`/view_orders`}
          className="sellers-wallets"
        >
          <Icon source={CircleRightMajor} color="base" />
        </Link>
      )
    }
  ]);

  const [columns1, setColumns1] = useState([
    {
      field: "payment_id",
      headerName: `${t("paymentID")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">#{row.row?.id}</p>
      )
    },
    {
      field: "id",
      headerName: `${t("sellerID")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">#{row.row?.seller.id}</p>
      )
    },
    {
      field: "name",
      headerName: `${t("sellername")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.seller.user.name}</p>
      )
    },
    {
      field: "store",
      headerName: `${t("storename")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.seller.store_name}</p>
      )
    },
 
    {
      field: "total_amount",
      headerName: `${t("totalAmount")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <strong>
          {cur}{" "}
          {parseInt(row.row.amount)}
        </strong>
      )
    },
    {
      field: "create_date",
      headerName: `${t("createDate")}`,
      width: 190,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row.create_date?.split("T")[0]}</p>
      )
    },
    {
      field: "more",
      headerName: `${t("more")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <Link
          onClick={() => {
            dispatch(reduxBranch(row.row.seller.user.name));
            sellerDispatch(getSeller(row.row.seller));
            dispatchOrders(addRunsheetOrders(row.row.orders))
          }}
          to={`/view_orders`}
          className="sellers-wallets"
        >
          <Icon source={CircleRightMajor} color="base" />
        </Link>
      )
    },
    {
      field: "Pay",
      headerName: `${t("pay")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <div
          onClick={() => {
            setShow(true);
            setPayment(row.row);
          }}
          to={`/sellers_orders/${row.row.id}`}
          className="sellers-wallets"
        >
          <Icon source={ViewMajor} color="base" />
        </div>
      )
    }
  ]);
  const lang = useLanguage();

  const [currentState, setCurrentState] = useState({
    id: "2",
    type: `${lang=="ar"?"طلب":"Request"}`,
    number: "10",
    styleClass: "statusCard",
    background: "#E0F3E8",
    style: { borderLeft: `solid 20px #BFFFDA` }
  });
  const cards = [
    {
      id: "2",
      type: `${lang=="ar"?"طلب":"Request"}`,
      number: statesNumbers["2"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: `solid 20px #BFFFDA` }
    },
    {
      id: "1",
      type:` ${lang=="ar"?"استجابة":"Responded"}`,
      number: statesNumbers["1"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: `solid 20px #57BE6C` }
    },
    {
      id: "3",
      type: `${lang=="ar"?"المدفوع":"Paid"}`,
      number: statesNumbers["3"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: `solid 20px #148060` }
    }
  ];

  const confirmPayment = async () => {
    let formData = new FormData();
    formData.append("payment_proof", file);
    let result = await axiosInstance.post(
      `/UploadProof/${payment.id}`,formData);
    if (result) {
      setShow(false);
      setShowSuccess(true);
    }
  };


  const getSummary =async () =>{
    let result = await axiosInstance.get(`/PaymentSummary/?sender=${2}`)

    if(result){
      console.log(result.data)
      setStatesNumbers(result.data)
    }
  }
  const selectState = async (
    state = {
      id: "2",
      type: `${lang=="ar"?"المستلمة":"Recieved"}`,
      number: statesNumbers["2"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: `solid 20px #BFFFDA` }
    }
  ) => {
    setCurrentState(state);
    let result;

    result = await axiosInstance.get(
      `/FilterPaymentRequest/?state=${state.id}&sender=${2}`
    );
    if (result) {
      setData(result.data.data);
      console.log(result.data);
      setCount(result.data.count);
    }
    setSelected(state.type);
  };

  useEffect(() => {
    selectState();
    getSummary()
  }, []);
  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.requestsToPay")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
        </Col>
      </Row>
      <Row className="cardsMargin">
        {cards.map((card, index) => {
          return (
            <Col
              sm="12"
              md="4"
              onClick={() => selectState(card, cards[index + 1])}
            >
              <StatusCard
                key={card.id}
                selectedCard={selected}
                type={card.type}
                number={card.number}
                styleClass={"statusCard"}
                background={card.background}
                style={card.style}
              />
            </Col>
          );
        })}
      </Row>

      <Row>
        <Col sm="12">
          <div className="reqTableContainer">
            <div className="tableHeader">
              <h6>{t("sentRequest")}</h6>
            </div>

            <div dir="ltr" className="requestTable">
              <DataGrid
                rows={data}
                columns={currentState.id === "2" ? columns1 : columns}
                rowCount={count}
                pagination
                selectionModel={selectionModel}
                onPageChange={async (page) => {
                  setPage(page);

                  let result = await axiosInstance.get(
                    `/FilterPaymentRequest/?state=${
                      currentState.id
                    }&sender=${2}&page=${page + 1}`
                  );
                  setData(result.data.data);
                  setCount(result.data.count);
                  prevSelectionModel.current = selectionModel;
                }}
              />
            </div>
          </div>
        </Col>
        <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("invoiceAttached")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="totalAmount">{t("addManualAttachment")}</label>
            <Dragger {...options}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t("attachmentText")}
              </p>
            </Dragger>
        </Modal.Body>
        {currentState.id === "4" ? null : (
          <Modal.Footer>
            <button onClick={hide} className="cancel">
            {t("cancel")}
            </button>
            <button
              disabled={file === ""}
              className="confirm"
              onClick={confirmPayment}
            >
              {t("confirm")}
            </button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={showImage} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("image")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentProof ? <Image src={paymentProof} /> : `${t("noProof")}`}
            
        </Modal.Body>
       
      </Modal>
      <SuccessfulRequest show={showSuccess} hide={hideSuccess} title={t("settleTicket")}
          body={t("settleTicketBody")} />

      </Row>
    </>
  );
}

export default ErrorHandler(SellersPaidRequests, axiosInstance);
