import React, { useEffect, useState } from "react";
import styles from "./WMEditableTable.module.css";
import { Form, Input, Select } from "antd";
import { ReactComponent as AddIcon } from "../../../../images/add_wm.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/delete_wm.svg";
import useQuery from "../../../../hooks/useQuery";
import WMButton from "../../AllWorkModels/WMButton";
import axiosInstance from "../../../../api/axios";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ToastContext from "react-bootstrap/ToastContext";
import DimensionRepository from "../../../../api/DimensionRepository";
import { useTranslation } from "react-i18next";
import ChangeAttrsModal from "../../AllWorkModels/ChangeAttrsModal";

export default function WMEditableTable({}) {
    const { query } = useQuery();
    const { t } = useTranslation();
    const history = useHistory();
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const [currentBranch, setCurrentBranch] = useState();
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [selectedAttr, setSelectedAttr] = useState("");
    const [actualCities, setActualCities] = useState([]);
    const [boxes, setBoxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = {
        shipping_fees: {
            title: t("work_model.shipping_fees"),
        },
        return_fees: {
            title: t("work_model.return_fees"),
        },
        replace_fees: {
            title: t("work_model.replace_fees"),
        },
        delivery_time: {
            title: t("work_model.delivery_time"),
        },
        return_time: {
            title: t("work_model.return_time"),
        },
    };

    const [data, setData] = useState([{}]);
    const [form] = Form.useForm();

    function insertNewRow() {
        // if (Object.values(data[data.length - 1]).length === 0) return;
        setData((prev) => [...prev, {}]);
    }

    function deleteRow(index) {
        console.log({ data });
        const copy = [...data];
        console.log({ copy });
        if (copy.length === 1) {
            copy[0] = {};
            setData(copy);
        } else setData([...copy.slice(0, index), ...copy.slice(index + 1)]);
    }

    function onInputChange(index, key, value) {
        const copy = [...data];
        console.log(copy, key);
        if (key.id) {
            try {
                copy[index]["diementions"][key.i]["increment"] = value;
            } catch (e) {
                copy[index]["diementions"]
                    ? copy[index]["diementions"].push({
                          diemention: key.id,
                          increment: value,
                      })
                    : (copy[index]["diementions"] = [
                          { diemention: key.id, increment: value },
                      ]);
            }
        } else copy[index][key] = value;
        setData(copy);
    }

    async function getCities() {
        const res = await axiosInstance.get("/City/?page_size=10000");
        if (res.data) setCities(res.data.data);

        return res.data.data;
    }

    async function getAreas(city) {
        console.log("areas", areas);

        const res = await axiosInstance.get(
            `/FilterAreas/?city=${city}&page_size=1000`
        );
        if (res.data) setAreas({ ...areas, [city]: res.data.data });
    }

    async function getBranch() {
        const res = await axiosInstance.get("/Branch/" + query.branch);
        if (res.data) setCurrentBranch(res.data);
    }

    console.log({ data });

    async function getDimensions() {
        const { data: dimens } = await DimensionRepository.get({ limit: 1000 });
        setDimensions(dimens);

        setBoxes(dimens);
    }

    useEffect(() => {
        const prepare = async () => {
            // if (window.location.pathname.startsWith("/edit")) {
            //     const prices = await axiosInstance.get(
            //         `/NewWorkModel/${window.location.pathname.split("/")[2]}`
            //     );
            //     setData(prices.data.data.prices);
            // }
            const resCities = await getCities();
            if (resCities[0]?.id && query.cities.length < 1) {
                await getAreas(resCities[0].id);
                setData([
                    { city: resCities[0].id, area: areas[resCities[0].id]?.id },
                ]);
            }
            if (+query.model_type == 2) await getDimensions();

            getBranch();
        };
        prepare();
    }, []);

    async function onFinish(formData) {
        if (loading) return;
        setLoading(true);
        let fieldName =
            query.model_type == 1 ? "weight_prices" : "diemention_prices";
        const res = await axiosInstance
            .post(
                `${
                    query.model_type == 1
                        ? "/NewWeightWorkModel/"
                        : "/NewDiementionWorkModel/"
                }`,
                {
                    ...query,
                    [fieldName]: data.map((p) => {
                        return {
                            ...p,
                            diementions_prices: p.diementions ?? [],
                        };
                    }),
                }
            )
            .catch((e) => {
                toast.error(t("req_all_fields"));
                setLoading(false);
            });

        if (res?.status >= 200 && res?.status < 210) {
            toast.success(t("work_model.success_create"));
            setTimeout(() => history.push("/all_work_models"), 800);
        }

        // setLoading(false);
    }

    useEffect(() => {
        if (query.cities && query.cities.length > 0) {
            const initData = async () => {
                console.log("query", query.cities);
                let arr = query.cities.split(",");
                let areass = {};
                const res = await axiosInstance.get(
                    `FilterAreas/?city_in=${query.cities}&page_size=10000000`
                );
                if (res) {
                    const initAreas = res.data.data.map((area) => {
                        return { area: area.id, city: area.city.id };
                    });
                    setData(initAreas);
                    console.log("init", initAreas);
                    for (const c of arr) {
                        areass = {
                            ...areass,
                            [c]: res.data.data.filter((a) => (a.city.id = c)),
                        };
                    }
                    setAreas(areass);
                }
            };
            initData();
        }
        // console.log("new_data", newData)
        // setData(newData)
    }, [query.cities]);

    const actualUsedCities = () => {
        const citiesSet = new Set(
            data.map((c) => {
                return c.city;
            })
        );
        const actual = cities.filter((c) => citiesSet.has(c.id));
        console.log(citiesSet, actual);
        setActualCities(actual);
    };

    const onOpenChangeModal = (attr) => {
        setSelectedAttr(attr);
        actualUsedCities();
        setShowChangeModal(true);
    };
    const closeChangeModeal = () => {
        setShowChangeModal(false);
    };
    const calculateNewValue = (
        changeType,
        changeValueType,
        value,
        oldValue = 0
    ) => {
        let result = 0;
        if (+changeValueType === 0) {
            result =
                +changeType === 0
                    ? value
                    : +changeType === 1
                    ? +oldValue + +value
                    : +oldValue - +value;
        } else {
            result =
                +changeType === 0
                    ? value
                    : +changeType === 1
                    ? +oldValue + (+oldValue * +value) / 100
                    : +oldValue - (+oldValue * +value) / 100;
        }

        return Math.max(0, result);
    };
    const onEditAttr = (attr, value, city, changeType, changeValueType) => {
        console.log({ attr, value, city, changeType, changeValueType });
        console.log({ data });
        let copy = data.map((price) => {
            if (+price.city === +city) {
                console.log("catch city");
                if (attr.startsWith("diementions")) {
                    console.log({ price });
                    return {
                        ...price,
                        diementions: boxes?.map((d) => {
                            const dimen = {
                                diemention: d.id,
                                increment: d.increment,
                            };
                            console.log({
                                d,
                                dimen,
                                new: +attr.split("__")[1],
                            });
                            if (+dimen.diemention === +attr.split("__")[1])
                                return {
                                    ...dimen,
                                    increment: calculateNewValue(
                                        changeType,
                                        changeValueType,
                                        value,
                                        d.increment
                                    ),
                                };
                            else return dimen;
                        }),
                    };
                } else {
                    return {
                        ...price,
                        [attr]: calculateNewValue(
                            changeType,
                            changeValueType,
                            value,
                            price[attr] ?? 0
                        ),
                    };
                }
            } else return price;
        });
        console.log(data, copy);
        setData(copy);
        setShowChangeModal(false);
    };

    return (
        <div className={styles.container}>
            <ToastContainer position={"bottom-right"} />
            <ChangeAttrsModal
                show={showChangeModal}
                onClose={closeChangeModeal}
                cities={actualCities}
                attr={selectedAttr}
                onChange={onEditAttr}
            />
            <Form onFinish={onFinish}>
                <header>
                    <div className={styles.info}>
                        {[
                            `${t("name")}: ${query.name}`,
                            currentBranch
                                ? `${t("work_model.branch_name")}: ${
                                      currentBranch.name
                                  }`
                                : undefined,
                            +query.model_type === 1 &&
                            query.default_weight !== undefined
                                ? `${t("work_model.default_weight")}: ${
                                      query.default_weight
                                  }`
                                : undefined,
                            // : `${t("work_model.default_diemention")}: ${
                            //     boxes.find((b) => b.id == 1)?.name
                            // }`,
                        ].map((it) => (
                            <>
                                {it && (
                                    <div key={it} className={styles.chip}>
                                        {it}
                                    </div>
                                )}
                            </>
                        ))}
                        <Select
                            className={[styles.select, styles.info].join(" ")}
                            size="large"
                            onSelect={onOpenChangeModal}
                            placeholder={t("select")}
                            options={[
                                ...Object.keys(columns).map((c) => {
                                    return {
                                        value: c,
                                        label: columns[c].title,
                                    };
                                }),
                                query.model_type == 1
                                    ? {
                                          value: "over_weight_price",
                                          label: t("work_model.weight_table"),
                                      }
                                    : undefined,
                                ...(dimensions ?? [])
                                    .filter((d) => d.id != 1)
                                    .map((box) => ({
                                        value: `diementions__${box.id}`,
                                        label: box.name,
                                    })),
                            ].filter((it) => it)}
                        />
                    </div>

                    <div>
                        <WMButton
                            disabled={loading}
                            loading={loading}
                            htmlType="submit"
                        >
                            {t("apply")}
                        </WMButton>
                    </div>
                </header>
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    <div>{t("city")}</div>
                                </th>
                                <th>
                                    <div>{t("area")}</div>
                                </th>
                                {Object.values(columns).map((column) => (
                                    <th key={column.title}>
                                        <div>{column.title}</div>
                                    </th>
                                ))}
                                {+query.model_type === 1 ? (
                                    <th>
                                        <div>
                                            {t("work_model.weight_table")}
                                        </div>
                                    </th>
                                ) : (
                                    boxes.map((box) => {
                                        return box.id != 1 ? (
                                            <th>
                                                <div>{`${box.name} ${t(
                                                    "increment"
                                                )}`}</div>
                                            </th>
                                        ) : null;
                                    })
                                )}
                                <th className={styles.actionHeader}>
                                    <div>{t("actions")}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item = {}, rowIndex) => (
                                <tr key={item.title}>
                                    <td>
                                        <Form.Item>
                                            <select
                                                className={styles.select}
                                                value={item?.["city"]}
                                                onChange={(e) => {
                                                    onInputChange(
                                                        rowIndex,
                                                        "city",
                                                        e.target.value
                                                    );
                                                    getAreas(e.target.value);
                                                }}
                                            >
                                                <option
                                                    disabled
                                                    hidden
                                                    value={-1}
                                                ></option>
                                                {cities.map((city) => (
                                                    <option
                                                        key={city.id}
                                                        value={city.id}
                                                    >
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item>
                                            <select
                                                className={styles.select}
                                                value={item?.["area"] ?? -1}
                                                disabled={
                                                    item?.["city"] === undefined
                                                }
                                                onChange={(e) =>
                                                    onInputChange(
                                                        rowIndex,
                                                        "area",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option
                                                    disabled
                                                    hidden
                                                    value={-1}
                                                ></option>
                                                {(
                                                    areas[item?.["city"]] ?? []
                                                ).map((city) => (
                                                    <option
                                                        key={city.id}
                                                        value={city.id}
                                                    >
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Item>
                                    </td>
                                    {Object.keys(columns).map((column) => (
                                        <td key={rowIndex + column}>
                                            <Form.Item
                                            // name={`${column}-${rowIndex}`}
                                            >
                                                <Input
                                                    type="number"
                                                    min="1"
                                                    value={item?.[column] ?? ""}
                                                    onChange={(e) =>
                                                        onInputChange(
                                                            rowIndex,
                                                            column,
                                                            e.target.value
                                                        )
                                                    }
                                                    // defaultValue={
                                                    //     item?.[column] ?? ""
                                                    // }
                                                />
                                            </Form.Item>
                                        </td>
                                    ))}
                                    {+query.model_type === 1 ? (
                                        <td>
                                            <Form.Item>
                                                <Input
                                                    type="number"
                                                    min="1"
                                                    value={
                                                        item?.over_weight_price ??
                                                        ""
                                                    }
                                                    onChange={(e) =>
                                                        onInputChange(
                                                            rowIndex,
                                                            "over_weight_price",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </td>
                                    ) : (
                                        boxes.map((box) =>
                                            box.id != 1 ? (
                                                <td key={rowIndex + box.id}>
                                                    <Form.Item
                                                    // name={`${box}-${rowIndex}`}
                                                    >
                                                        <Input
                                                            type="number"
                                                            min="1"
                                                            value={
                                                                item?.diementions?.find(
                                                                    (d) =>
                                                                        d.diemention ==
                                                                        box.id
                                                                )?.increment ??
                                                                ""
                                                            }
                                                            onChange={(e) =>
                                                                onInputChange(
                                                                    rowIndex,
                                                                    {
                                                                        ...box,
                                                                        i: item?.diementions?.findIndex(
                                                                            (
                                                                                d
                                                                            ) =>
                                                                                d.diemention ==
                                                                                box.id
                                                                        ),
                                                                    },
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            defaultValue={0}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            ) : null
                                        )
                                    )}
                                    <td>
                                        <button
                                            className={styles.action}
                                            type="button"
                                            onClick={() => deleteRow(rowIndex)}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <button
                                        onClick={insertNewRow}
                                        type="button"
                                    >
                                        <AddIcon />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Form>
        </div>
    );
}
