import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import { Input, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { getTicketPrintedData } from "../../../../../global-state/actions/getSelectedAction";
import {
    OrderConfirmationModal,
    OrderDeleteModal,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    getSeller,
    getClient,
} from "../../../../../global-state/actions/getSelectedAction";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../../../../api/axios";
import useLanguage from "../../../../../hooks/useLanguage";
import OrderSummary from "../../order-summary/ui/OrderSummary";
import { ToastContainer, toast } from "react-toastify";

export default function OrderInfoForm({ value, handleChange }) {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const dispatch = useDispatch();
    const dispatchSeller = useDispatch();
    const dispatchClient = useDispatch();
    const clearDispatch = useDispatch();
    const dispatchPrinted = useDispatch();
    const [deliveryRangesErr, setDeliveryRangesErr] = useState("");
    const [branchErr, setBranchErr] = useState("");
    const [orderDetails, setOrderDetails] = useState("");
    const [deliveryFees, setDeliveryFees] = useState(0);
    const [calDeliveryFees, setCalDeliveryFees] = useState(deliveryFees);
    const [showCalculations, setShowCalculations] = useState(false);
    const [nextBranches, setNextBranches] = useState([]);
    const [branches, setBranches] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [nextRanges, setNextRanges] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [sellerInfo, setSellerInfo] = useState("");
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [printedTickets, setPrintedTickets] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [pos, setPos] = useState(null);
    const [cod, setCod] = useState(null);
    const [note, setNote] = useState("");
    const columns = [
        {
            field: "id",
            headerName: "Ticket ID",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
        },
        {
            field: "userbranch",
            headerName: "Seller Name",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.user.name}
                </p>
            ),
        },
        {
            field: "storename",
            headerName: "Store Name",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.store_name}
                </p>
            ),
        },
        {
            field: "client",
            headerName: "Client Name",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.client?.name}
                </p>
            ),
        },
        {
            field: "deliveryfees",
            headerName: "Delivery Fees",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.delivery_fees}</p>
            ),
        },
    ];

    const columnsAr = [
        {
            field: "id",
            headerName: "رقم البوليصة",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
        },
        {
            field: "userbranch",
            headerName: "اسم التاجر",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.user.name}
                </p>
            ),
        },
        {
            field: "storename",
            headerName: "اسم المتجر",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.store_name}
                </p>
            ),
        },
        {
            field: "client",
            headerName: "اسم العميل",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.client?.name}
                </p>
            ),
        },
        {
            field: "deliveryfees",
            headerName: "مصاريف الشحن",
            width: 190,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.delivery_fees}</p>
            ),
        },
    ];
    let history = useHistory();

    const user = useSelector((state) => state.auth.user);
    const showConfirmationModal = () => {
        setShow(true);
        clearDispatch(addOrder(""));
    };
    const showDeleteModal = () => {
        setShowDelete(true);
    };
    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };

    const hideDelete = () => {
        setShowDelete(false);
        setTimeout(() => {
            handleChange(null, 0);
        }, 1000);
    };
    const order = useSelector((state) => state.order);
    console.log({ order });
    const sellerID = useSelector(
        (state) => state.selectedData.selectedSeller.id
    );

    const validate = async (data) => {
        if (!data.branch && !order.branch && user.role !== "Seller") {
            console.log("brnach needed");
            setBranchErr(t("branchErr"));
        } else {
            setBranchErr("");
            setDeliveryRangesErr("");
            data.delivery_fees = deliveryFees;
            dispatch(addOrder({ ...data, cod, pos , note}));
            setSend(true);
            console.log(order);
        }
    };
    const discard = () => {
        if (orderDetails !== "") {
            showDeleteModal();
            let result = axiosInstance.delete(`/Order/${orderDetails.id}`);
            clearDispatch(addOrder(""));
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
        } else {
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
            clearDispatch(addOrder(""));
            handleChange(null, 0);
        }
    };

    useEffect(() => {
        if (send) {
            console.log(order);

            try {
                sendRequest();
            } catch (err) {
                console.log(err);
            }
        }
    }, [send]);

    const updateRequest = async () => {
        setLoading(true);

        let result = await axiosInstance
            .put(`/Order/${orderDetails.id}`, {
                delivery_fees: calDeliveryFees,
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    if (err.response.data instanceof String) {
                        toast.error(err.response.data);
                    } else {
                        Object.entries(err.response.data).forEach(
                            ([key, value]) =>
                                toast.error(
                                    `${key}: ${
                                        value instanceof Array
                                            ? value[0]
                                            : value
                                    }`
                                )
                        );
                    }
                }
            });

        if (result) {
            dispatchPrinted(getTicketPrintedData(result.data));
            setLoading(false);
            setShow(true);
            clearDispatch(addOrder(""));
        }
    };

    const sendRequest = async () => {
        setLoading(true);
        console.log(order);
        let result = await axiosInstance.post(`/Order/`, {
            ...order,
            including_fees: order.including_fees == "true",
        })
        .catch((err) => {
            if (err.response.status === 400) {
                if (err.response.data instanceof String) {
                    toast.error(err.response.data);
                } else {
                    Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                }
            }
        });

        if (result) {
            setOrderDetails(result.data);
            console.log(result.data.tickets);
            console.log(result.data);
            setTickets(result.data.tickets);
            setDeliveryFees(parseFloat(result.data.delivery_fees));
            setCalDeliveryFees(parseFloat(result.data.delivery_fees));
            // setShowCalculations(true);
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
            dispatchPrinted(getTicketPrintedData(result.data));
            setLoading(false);
        }
    };

    const getBranches = async () => {
        let result = await axiosInstance.get(`/Branch/`);
        if (result) {
            setBranches(result.data.data);
            setNextBranches(result.data.next);
        }
    };

    const search = async (value) => {
        // setSearchTerm(value)
        let result = await axiosInstance.get(`/FilterSeller/?search=${value}`);
        if (result) {
            console.log(result.data.data);
            setBranches(result.data.data);
            setNextBranches(result.data.next);
        }
        console.log(value);
    };

    const onScroll = async (event, link) => {
        console.log("ee");
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextBranches}`)
                .catch((err) => console.log(err));

            if (result) {
                setBranches([...branches, ...result.data.data]);
                setNextBranches(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const getRanges = async () => {
        let result = await axiosInstance.get(
            `/SellerRanges/${
                user.role === "Seller" ? user.user.user_id : sellerID
            }`
        );
        if (result) {
            setRanges(result.data.data);
            setNextRanges(result.data.next);
        }
    };

    const onScrollRange = async (event, link) => {
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextRanges}`)
                .catch((err) => console.log(err));

            if (result) {
                setRanges([...ranges, ...result.data.data]);
                setNextRanges(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
        dispatch(addOrder({ ...order }));
    };

    const getSellerInfo = async () => {
        if (user.role === "Seller") {
            let result = await axiosInstance.get(
                `/Seller/${user.user.user_id}`
            );
            if (result) {
                console.log(result.data);
                setSellerInfo(result.data);
            }
        }
    };

    useEffect(() => {
        getSellerInfo();
        getBranches();
    }, []);

    useEffect(() => {
        console.log(
            deliveryFees,
            calDeliveryFees,
            deliveryFees === calDeliveryFees
        );
    }, [deliveryFees, calDeliveryFees]);
    const lang = useLanguage();

    return (
        <>
            <ToastContainer
                autoClose={5000}
                draggable
                pauseOnFocusLoss
                pauseOnHover
                position="top-right"
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={lang === "ar" ? true : false}
            />
                
            <ModifiedForm
                submitFunction={validate}
                listenToValues={(getValues) => {
                    const v = getValues("payment_method");
                    console.log({ v });
                }}
            >
                <Row>
                    <Col sm="12">
                        {orderDetails !== "" ? (
                            <p className="orderID">
                                {t("orderID")}#{orderDetails.id}
                            </p>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <div className="position-relative text-area-form">
                            <div className="addressCard">
                                <label>{t("shippmentType")}</label>
                                <Radio
                                    value="1"
                                    id="type"
                                    validation={{ required: true }}
                                    name="shippmentType"
                                    className="radio-btn"
                                    txt={t("complete")}
                                />

                                <Radio
                                    value="2"
                                    id="type"
                                    validation={{ required: true }}
                                    name="shippmentType"
                                    className="radio-btn"
                                    txt={t("partial")}
                                />
                            </div>
                            {/* <PaginatedSelect
                                id="delivery_range"
                                title={t("deliveryRange")}
                                search={search}
                                validation={{ required: true }}
                                onScroll={(e) => onScrollRange(e, nextRanges)}
                                onClick={getRanges}
                                className="mt-4"
                            >
                                {ranges.map((range) => (
                                    <Select.Option
                                        key={range.id}
                                        value={range.id}
                                    >
                                        {range.min} - {range.max}
                                    </Select.Option>
                                ))}
                            </PaginatedSelect> */}
                            {user.role === "Seller" ? null : (
                                <>
                                    <PaginatedSelect
                                        id="branch"
                                        title={t("sideMenu.branches")}
                                        defaultValue={order.branch}
                                        search={search}
                                        validation={{ required: true }}
                                        onScroll={(e) =>
                                            onScroll(e, nextBranches)
                                        }
                                        className="mt-4"
                                        allowClear={false}
                                    >
                                        {branches.map((branch) => (
                                            <Select.Option
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.name}
                                            </Select.Option>
                                        ))}
                                    </PaginatedSelect>
                                    <p className="error">
                                        {branchErr !== "" ? branchErr : null}
                                    </p>
                                </>
                            )}
                            <Form.Item
                                name="note"
                                id="note"
                                label={t("notes")}
                            >
                                <Input.TextArea
                                    style={{
                                        display: "block",
                                        width: "100%",
                                    }}
                                    rows={4}
                                    onChange={(e) =>
                                        setNote(e.target.value)
                                    }
                                    value={note}
                                />
                            </Form.Item>

                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div
                            className="addressCard"
                            onChange={(e) => {
                                if (
                                    e.target.value === "1" ||
                                    e.target.value === "2" ||
                                    e.target.value === "3"
                                )
                                    setPaymentType(e.target.value);
                            }}
                        >
                            <label>{t("paymentMethod")}</label>
                            <Radio
                                value="2"
                                id="payment_method"
                                validation={{ required: true }}
                                name="payment_method"
                                className="radio-btn"
                                txt={t("cash")}
                            />
                            <Radio
                                value="1"
                                id="payment_method"
                                validation={{ required: true }}
                                name="payment_method"
                                className="radio-btn"
                                txt={t("online")}
                            />
                            <Radio
                                value="3"
                                id="payment_method"
                                validation={{ required: true }}
                                name="payment_method"
                                className="radio-btn"
                                txt={t("POS")}
                            />
                            {/* {paymentType == 1 && (
                                <div
                                    style={{
                                        marginInlineStart: "20px",
                                        fontSize: "13px",
                                    }}
                                >
                                    <Radio
                                        value={true}
                                        id="including_fees"
                                        validation={{ required: true }}
                                        name="including_fees"
                                        className="radio-btn"
                                        txt={t("includingFees")}
                                    />
                                    <Radio
                                        value={false}
                                        id="including_fees"
                                        validation={{ required: true }}
                                        name="including_fees"
                                        className="radio-btn"
                                        txt={t("notIncludingFees")}
                                    />
                                </div>
                            )} */}
                            <div className="mt-3">
                                {paymentType === "2" && (
                                    <Form.Item
                                        name="cod"
                                        id="cod"
                                        label={t("CashOnDelivery")}
                                        rules={[
                                            {
                                                required: paymentType === "2",
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                display: "block",
                                                width: "100%",
                                            }}
                                            type="number"
                                            onChange={(e) =>
                                                setCod(e.target.value)
                                            }
                                            value={cod}
                                        />
                                    </Form.Item>
                                )}
                                {paymentType === "3" && (
                                    <Form.Item
                                        name="pos"
                                        id="pos"
                                        label={t("pos")}
                                        rules={[
                                            {
                                                required: paymentType === "3",
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                display: "block",
                                                width: "100%",
                                            }}
                                            type="number"
                                            onChange={(e) =>
                                                setPos(e.target.value)
                                            }
                                            value={pos}
                                        />
                                    </Form.Item>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm="12" md="6">
                        <div className="d-flex flex-column justify-content-start align-items-start gap-3 w-100">
                            {user.role === "Seller" && sellerInfo !== ""
                                ? sellerInfo?.seller_addresses.map(
                                      (address) => {
                                          return (
                                              <div className="addressCard w-100">
                                                  <label>
                                                      {t("chooseSellerAddress")}{" "}
                                                  </label>
                                                  <Radio
                                                      value={address.id}
                                                      id="seller_address"
                                                      validation={{
                                                          required: true,
                                                      }}
                                                      name="address"
                                                      className="radio-btn"
                                                      txt={address.address}
                                                  />
                                              </div>
                                          );
                                      }
                                  )
                                : null}
                        </div>
                    </Col>
                </Row>
                <hr />
                <OrderSummary
                    orderDetails={orderDetails}
                    onShipmentFeesChange={(v) => setCalDeliveryFees(v)}
                />
                <Row
                    style={{
                        marginInlineEnd: "-32px",
                    }}
                >
                    <Col className="text-end buttons-margin">
                        <button className="cancel" onClick={discard}>
                            {t("discard")}
                        </button>
                        {/* orderdetails != "" and deliveryFees===calDeliveryFees ? (scan):(deliveryFees === calDeliveryFees ?(calculate) : (update) ) */}
                        <>
                            <button className="previous" onClick={previous}>
                                {t("previous")}
                            </button>

                            {orderDetails !== "" &&
                            deliveryFees === calDeliveryFees ? (
                                <input
                                    className="confirm doneBtn mx-2"
                                    onClick={showConfirmationModal}
                                    value={t("submit")}
                                />
                            ) : orderDetails !== "" &&
                              deliveryFees !== calDeliveryFees ? (
                                <button
                                    onClick={updateRequest}
                                    className="calculateBtn mx-2"
                                    type="button"
                                    disabled={loading}
                                >
                                    {t("update")}
                                </button>
                            ) : (
                                <input
                                    className="calculateBtn mx-2"
                                    value={t("calculate")}
                                    type="submit"
                                />
                            )}
                        </>
                    </Col>
                </Row>
                {showCalculations && orderDetails !== "" ? (
                    <Row>
                        <Col sm="12">
                            <button
                                className="confirm"
                                disabled={printedTickets.length === 0}
                            >
                                <a
                                    style={{ color: "white" }}
                                    href={`${BASE_URL}/CreateInvoice/?tickets=${printedTickets.join(
                                        ","
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("exportToPDF")}
                                </a>
                            </button>
                            <div dir="ltr" className="requestTable1 rs mt-2">
                                <DataGrid
                                    rowHeight="80"
                                    rows={tickets}
                                    columns={
                                        lang === "en" ? columns : columnsAr
                                    }
                                    disableSelectionOnClick
                                    // pageSize={5}
                                    // rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    // selectionModel={arr}
                                    // onSelectionModelChange={onSelect}
                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        setPrintedTickets(ids);

                                        const selectedRowData =
                                            orderDetails.tickets.filter(
                                                (row) => {
                                                    selectedIDs.has(row.id);
                                                    console.log(selectedIDs);
                                                }
                                            );
                                        console.log(
                                            "onSelectionModelChange:",
                                            selectedRowData
                                        );
                                    }}
                                    onCellClick={async (p, e) => {
                                        console.log(p.field);
                                        let result;
                                        if (p.field == "id") {
                                            console.log(p.row.order);
                                            setLoading(true);

                                            // currentState.title === "Pickup Request" ? setDetails(p.row) : setDetails(p.row.order);
                                        }

                                        e.defaultMuiPrevented = true;
                                    }}
                                />
                            </div>{" "}
                        </Col>
                    </Row>
                ) : null}
            </ModifiedForm>
            <OrderConfirmationModal
                show={show}
                hide={hide}
                id={orderDetails.id}
            />
            <OrderDeleteModal
                show={showDelete}
                hide={hideDelete}
                id={orderDetails.id}
            />
        </>
    );
}
