import React, {useEffect, useState} from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import TablePro from "../tables/TablePro";
import {NavLink} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SuccessfulRequest, UnSuccessfulRequest } from "../modals/confirmationModal";
import { Spin, Menu, Dropdown } from "antd";
import { CheckCircleFilled, CloseCircleFilled, PlusCircleFilled, MinusCircleFilled, EyeFilled, UserSwitchOutlined } from "@ant-design/icons";
import useLanguage from "../../hooks/useLanguage";

function AllInternals() {

    const [sheets, setSheets] = useState([]);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [loadingAction, setLoadingAction] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modaltype, setModalType] = useState("success");
    const lang = useLanguage();
    const [modalData, setModalData] = useState({
        title: "",
        body: "",
        success: false,
    });
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const handleModalClose = () => {
        setShowModal(false);
        setModalData({
            title: "",
            body: "",
            success: false,
        });
    };


    const getRunSheets = async () => {
        setLoading(true)
        let result = await axiosInstance.get("/FilterInternalRunsheet/", {
            params: {
                page,
                search: searchTerm,
                ordering: "-id",
            },
        }).catch(e => console.log({e}));
        if (result) {
            setSheets(result.data.data);
            setCount(result.data.count);
        }
        setLoading(false);
    };
    useEffect(() => {
        getRunSheets();
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            getRunSheets();
        }
    }, [searchTerm]);

    const acceptRunsheet = async (id, state) => {
        setLoadingAction(true);
        const result = await axiosInstance.post(`/InternalRunsheetState/${id}`, {state})
        .catch(err => {
            setModalType("error");
            if (err.response.status === 400) {
                console.log("ERRIR", err.response.data);
                setModalData({
                    title: t(`runsheet${state ===  "accepted" ? "Accept" : "Reject"} Runsheet`),
                    body:  typeof err.response.data === "string" ?
                    t(err.response.data) :
                    t(Object.entries(err.response.data).map(([key, value]) => `${key}: ${value instanceof Array ? value[0] : value}`).join("\n")),
                    success: false,
                });
            } else {
                setModalData({
                    title: t(`runsheet${state ===  "accepted" ? "Accept" : "Reject"} Runsheet`),
                    body: t("server_error"),
                    success: false,
                });
            }
        });
        if (result) {
            setModalType("success");
            setModalData({
                title: t(`runsheet${state ===  "accepted" ? "Accept" : "Reject"} Runsheet`),
                body: t(`Runsheet ${state ===  "accepted" ? "Accepted" : "Rejected"} Successfully`),
                success: true,
            });
            getRunSheets();
        }
        setLoadingAction(false);
        setShowModal(true);
    }

    const columns = [
        {
            title: t("id"),
            key: "id",
            dataIndex: "id",
        },
        {
            title: t("courier"),
            key: "courier",
            dataIndex: "courier",
        },
        {
            title: t("branches.from"),
            key: "branch",
            dataIndex: "branch",
        },
        {
            title: t("branches.to"),
            key: "to_branch",
            dataIndex: "to_branch",
        },
        {
            title: t("status"),
            dataIndex: "state_display",
            width: 150,
            render: (_, row) =>
                row.state == "1" ? (
                    <button className="btn accepted">Accepted</button>
                ) : row.state == "2" ? (
                    <p className="btn rejected">Rejected</p>
                ) : row.state == "3" ? (
                    <p className="btn pending">Pending</p>
                ) : (
                    <p className="btn closed">Closed</p>
                ),
        },
        {
            title: t("actions"),
            key: "actions",
            render: (_, row) =>(
                <Dropdown
                    overlay={<ThreeDotsDropdown row={row} acceptRunsheet={acceptRunsheet} loadingAction={loadingAction} t={t} history={history} />}
                    trigger={['click', 'hover']}
                    placement={lang === "en" ? "bottomLeft" : "bottomRight"}
                    disabled={loadingAction}
                    arrow={true}
                >
                    <i className="fas fa-ellipsis-v fa-lg cursor-pointer"></i>
                </Dropdown>)

        }
    ];


    return (
        <Container>
            <SuccessfulRequest
                hide={handleModalClose}
                show={showModal && modaltype === "success"}
                {...modalData}
            />
            <UnSuccessfulRequest
                hide={handleModalClose}
                show={showModal && modaltype === "error"}
                {...modalData}
            />
            <Row className="mb-4" >
                <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.internalRunSheet")}
                        </span>

                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                            {t("sideMenu.allInternals")}
                        </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.allInternals")}
                    </h2>
                </Col>
            </Row>
            <div className={"tableContainer"}>
                <TablePro
                    loading={loading}
                    dataSource={sheets}
                    columns={columns}
                    onSearch={setSearchTerm}
                    pagination={{
                        size: "medium",
                        current: page,
                        onChange: setPage,
                        total: count,
                    }}
                    rowKey={(row) => row.id}
                />
            </div>
        </Container>
    )
}

export default ErrorHandler(AllInternals, axiosInstance);


const ThreeDotsDropdown = ({row, acceptRunsheet, loadingAction, t, history}) => {

    return (
        <Menu>
            <Menu.Item
                key="4"
                icon={<EyeFilled style={{color: "#1890ff"}}/>}
                onClick={() => {
                    history.push(`/edit_runsheet_priorities/${row.id}/int`);
                }}
                disabled={loadingAction}
            >
                {t("View Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="0"
                icon={<CheckCircleFilled style={{color: "#52c41a"}}/>}
                onClick={() => {
                    acceptRunsheet(row.id, "accepted");
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Accept Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="1"
                icon={<CloseCircleFilled style={{color: "#ff4d4f"}}/>}
                onClick={() => {
                    acceptRunsheet(row.id, "rejected");
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Reject Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="2"
                icon={<PlusCircleFilled style={{color: "#1890ff"}}/>}
                onClick={() => {
                    history.push(`/edit_internal/${row.id}/add`);
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Add Orders to Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="3"
                icon={<MinusCircleFilled style={{color: "#ff4d4f"}}/>}
                onClick={() => {
                    history.push(`/edit_internal/${row.id}/remove`);
                }}
                disabled={loadingAction || row.orders.length < 1 || row.state !== "3"}
            >
                {t("Remove Orders from Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="5"
                icon={<UserSwitchOutlined style={{color: "#1890ff"}}/>}
                onClick={() => {
                    history.push(`/reassign_internal/${row.id}`);
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Reassign Runsheet")}
            </Menu.Item>
                
        </Menu>
    );

}
