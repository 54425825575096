import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ThreeDotsMenuRS from "./ThreeDotsMenuRS";
import { NavLink } from "react-router-dom";
import { ReactComponent as SeeMoreIcon } from "../../images/see_more_arrow.svg";
import { useSelector } from "react-redux";
import useLanguage from "../../hooks/useLanguage";
import { Spin, Switch } from "antd";
import {DeleteMajor} from "@shopify/polaris-icons";
import {Icon} from '@shopify/polaris';
// const { t } = useTranslation();

const SeeMore = ({ arabic, margin }) =>
    arabic ? (
        <p className={margin ?? ""}>
            اعرف اكتر{" "}
            <span style={{ rotate: "180deg" }}>
                <SeeMoreIcon />
            </span>
        </p>
    ) : (
        <p className={margin ?? ""}>
            See More <SeeMoreIcon />
        </p>
    );
export const useOrdersColumns = () => {
    const { t } = useTranslation();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
        },
        {
            title: t("store"),
            dataIndex: ["seller", "store_name"],
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row?.seller?.store_name
                        ? row.seller?.store_name
                        : "No Name"}
                </p>
            ),
        },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            dataIndex: "sub_total",
        },
        {
            title: t("status"),
            width: 270,
            dataIndex: "runsheet_type",
            render: (_, row) =>
                row.runsheet_type?.map((status) => {
                    return (
                        <div
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
                    );
                }),
        },
    ];
};

export const useTicketsColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("orderId"),
            dataIndex: ["order", "id"],
        },
        {
            title: t("ticketId"),
            dataIndex: "id",
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("orderValue"),
            dataIndex: ["order", "sub_total"],
        },
        {
            title: t("weight"),
            dataIndex: "weight",
        },
        // {
        //     title: t("dimensions"),
        //     render: (_, row) => (
        //         <>
        //             {typeof row.length === "undefined" ||
        //             typeof row.width === "undefined" ||
        //             typeof row.height === "undefined" ? (
        //                 <></>
        //             ) : (
        //                 <>
        //                     {row.length}x{row.width}x{row.height}
        //                 </>
        //             )}
        //         </>
        //     ),
        // },
        {
            title: t("specialLabel"),
            dataIndex: "special_label",
        },
    ];
};

export const useRunSheetColumns = (
    { selectable } = {
        selectable: false,
    }
) => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    return [
        { title: t("id"), width: 190, dataIndex: "id" },
        {
            title: t("couriername"),
            width: 240,
            dataIndex: ["courier", "user", "name"],
            render: (_, row) => (
                <>
                    <span
                        style={
                            selectable
                                ? {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                  }
                                : {}
                        }
                    >
                        {row.courier?.user?.name}
                    </span>
                </>
            ),
        },

        {
            title: t("rsValue"),
            width: 240,
            dataIndex: "runsheet_value",
            render: (_, row) => (
                <>
                    {cur} {row.runsheet_value}
                </>
            ),
        },
        {
            title: t("status"),
            dataIndex: "status",
            width: 150,
            render: (_, row) =>
                row.state == "1" ? (
                    <button className="btn accepted">Accepted</button>
                ) : row.state == "2" ? (
                    <p className="btn rejected">Rejected</p>
                ) : row.state == "3" ? (
                    <p className="btn pending">Pending</p>
                ) : (
                    <p className="btn closed">Closed</p>
                ),
        },
        {
            dataIndex: "modify_date",
            title: t("lastUpdated"),
            width: 170,
            render: (_, row) => {
                const date = row.modify_date
                    ? row.modify_date.split("T")[0]
                    : "-";
                return <div>{date}</div>;
            },
        },
    ];
};

export const usePostponedColumns = (
    { selectable } = {
        selectable: false,
    }
) => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    return [
        { title: t("id"), width: 190, dataIndex: "id" },
        {
            title: t("branch"),
            width: 240,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("rsValue"),
            width: 240,
            dataIndex: "runsheet_value",
            render: (_, row) => (
                <>
                    {cur} {row.runsheet_value}
                </>
            ),
        },
        {
            title: t("status"),
            dataIndex: "status",
            width: 150,
            render: (_, row) =>
                row.state == "1" ? (
                    <button className="btn accepted">Accepted</button>
                ) : row.state == "2" ? (
                    <p className="btn rejected">Rejected</p>
                ) : row.state == "3" ? (
                    <p className="btn pending">Pending</p>
                ) : (
                    <p className="btn closed">Closed</p>
                ),
        },
        {
            dataIndex: "modify_date",
            title: t("lastUpdated"),
            width: 170,
            render: (_, row) => {
                const date = row.modify_date
                    ? row.modify_date.split("T")[0]
                    : "-";
                return <div>{date}</div>;
            },
        },
    ];
};

export const usePersonColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t("id"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{row.id}</span>,
        },
        {
            title: t("name"),
            width: 290,
            dataIndex: ["user", "name"],
        },
        {
            title: t("phone"),
            width: 250,
            dataIndex: ["user", "phone"],
        },
    ];
};

export const useOrgEmployeesColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("branch"),
            width: 190,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("userType"),
            width: 190,
            dataIndex: "type",
        },
        {
            title: t("email"),
            width: 250,
            dataIndex: ["user", "email"],
        },
    ];
};

export const useSellerColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("sideMenu.workModels"),
            render: (_, row) => row.saved_address[0]?.new_work_model?.name,
        },
    ];
};

export const useCourierColumns = () => {
    const { t } = useTranslation();
    return [
        {
            title: t("courierId"),
            width: 170,
            dataIndex: "id",
        },
        {
            field: "name",
            title: t("couriername"),
            width: 190,
            dataIndex: ["user", "name"],
        },
        {
            field: "branch",
            title: t("branch"),
            width: 190,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("typeofvehicles"),
            width: 190,
            dataIndex: "vehicle",
        },
        {
            title: t("email"),
            width: 240,
            dataIndex: ["user", "email"],
        },
        {
            title: t("phone"),
            width: 240,
            dataIndex: ["user", "phone"],
        },
        {
            title: `${t("phone")} 2`,
            width: 240,
            dataIndex: ["user", "phone_alt"],
        },
    ];
};

export const useArchivedUsers = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("archivedDate"),
            width: 220,
            dataIndex: "history_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.history_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("archivedBy"),
            width: 220,
            // valueGetter: (row) => row.row?.id,
            dataIndex: ["changed_by", "type_name"],
            render: (_, row) => (
                <p className="paragraphMargin">{row.changed_by?.type_name}</p>
            ),
        },
    ];
};

/**
 * OLD
 * */

export const pickupRequests = [
    {
        field: "id",
        headerName: "Order ID",
        width: 170,
        renderCell: (row) => <p className="tableID">#{row.row.id}</p>,
    },
    {
        field: "store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row.seller?.store_name ? row.row.seller.store_name : null,
        renderCell: (row) => (
            <p className="tableID">
                {row.row.seller?.store_name
                    ? row.row.seller.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "type",
        headerName: "Shipment Type",
        width: 220,
        renderCell: (row) => <p className="paragraphMargin">{row.row.type}</p>,
    },
    {
        field: "ticket_count",
        headerName: "Item Quantity",
        width: 215,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.ticket_count}</p>
        ),
    },
    {
        field: "sub_total",
        headerName: "Order Value",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.sub_total}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "Request Date",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]} - {row.row.create_date?.split("T")[1]}
            </p>
        ),

    },
    {
        field: "payment_method",
        headerName: "Payment method",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "Online" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                )}
            </strong>
        ),
    },
    {
        field: "action",
        show: true,
        headerName: "Action",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore />
            </NavLink>
        ),
    },
];

export const pickupRequestsArabic = [
    {
        field: "id",
        headerName: "رقم الشحنة",
        width: 190,
        renderCell: (row) => <p className="tableID">#{row.row.id}</p>,
    },
    {
        field: "store_name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row.seller?.store_name ? row.row.seller.store_name : null,
        renderCell: (row) => (
            <p className="tableID">
                {row.row.seller?.store_name
                    ? row.row.seller.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "type",
        headerName: "نوع الشحنة",
        width: 220,
        renderCell: (row) => <p className="paragraphMargin">{row.row.type}</p>,
    },
    {
        field: "ticket_count",
        headerName: "عدد القطع",
        width: 215,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.ticket_count}</p>
        ),
    },
    {
        field: "sub_total",
        headerName: "قيمة الطلب",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.sub_total}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "تاريخ الانشاء",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]} - {row.row.create_date?.split("T")[1]}
            </p>
        ),
    },
    {
        field: "payment_method",
        headerName: "طريقة الدفع",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "Online" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                )}
            </strong>
        ),
    },
    {
        field: "action",
        show: true,
        headerName: "الإجراءات",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore arabic />
            </NavLink>
        ),
    },
    {
        field: "payment_method",
        headerName: "طريقة الدفع",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "Online" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                )}
            </strong>
        ),
    },
    {
        show: true,
        field: "action",
        headerName: "الإجراءات",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore arabic />
            </NavLink>
        ),
    },
];

export const pickupConfirmedArabic = (cur) => [
    {
        field: "order.id",
        key: "Order ID",
        headerName: "رقم الشحنة",
        width: 200,
        valueGetter: (row) => row.row.order?.id,
        renderCell: (row) => <p className="tableID">{row.row.order?.id}</p>,
    },

    {
        field: "id",
        key: "Ticket ID",
        headerName: "رقم البوليصة",
        width: 200,
        renderCell: (row) => <p className="tableID">{row.row.id}</p>,
    },
    {
        field: "seller.store_name",
        key: "Store Name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row.order?.seller.store_name
                ? row.row.order.seller?.store_name
                : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name
                    ? row.row.order.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        key: "Date Created",
        headerName: "تاريخ الانشاء",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]} - {row.row.create_date?.split("T")[1]}
            </p>
        ),
    },

    {
        field: "create_date",
        key: "Request Date",
        headerName: "موعد الطلب",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "order.promise_date",
        key: "Postponed Date",
        headerName: "موعد التاجيل",
        width: 220,
        valueGetter: (row) => row.row?.new_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },

    {
        field: "order.delivery_range",
        key: "Delivery Range",
        headerName: "نطاق التسليم",
        width: 220,
        valueGetter: (row) => row.row.order?.delivery_range,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_range?.min}-
                {row.row.order?.delivery_range?.max}
            </p>
        ),
    },

    {
        field: "type",
        key: "Shipment Type",
        headerName: "نوع الشحنة",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.type ? "Pickup" : "Return"}
            </p>
        ),
    },

    {
        field: "quantity",
        key: "Item Quantity",
        headerName: "عدد القطع",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.quantity}</p>
        ),
    },

    {
        field: "length",
        key: "Dimension",
        headerName: "الابعاد",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.length}x{row.row.width}x{row.row.height}
            </p>
        ),
    },

    {
        field: "weight",
        key: "Weight",
        headerName: "الوزن",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.weight}</p>
        ),
    },
    {
        field: "special_label",
        key: "Special Label",
        headerName: "ملصق خاص",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.special_label?.en}</p>
        ),
    },

    {
        field: "comment",
        key: "Feedback Comment",
        headerName: "تعليقات",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.comment?.text_ar}</p>
        ),
    },

    {
        field: "order.sub_total",
        key: "Order Value",
        headerName: "قيمة الطلب",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.sub_total} {cur}
            </p>
        ),
    },

    {
        field: "order.delivery_fees",
        key: "Shipment Fee",
        headerName: "سعر الشحن",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_fees} {cur}
            </p>
        ),
    },

    {
        field: "order.courier.id",
        key: "Courier ID",
        headerName: "رقم المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.courier?.id}</p>
        ),
    },

    {
        field: "order.courier.name",
        key: "Courier name",
        headerName: "اسم المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.name}
            </p>
        ),
    },

    {
        field: "order.courier.branch",
        key: "Courier Branch",
        headerName: "فرع المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.branch.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.branch.name}
            </p>
        ),
    },

    {
        field: "order.courier.vehicle",
        key: "Vehicle",
        headerName: "المركبة",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.vehicle,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.courier?.vehicle}</p>
        ),
    },

    {
        field: "order.courier.phone",
        key: "Courier Phone",
        headerName: "رقم هاتف المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier.user.phone}
            </p>
        ),
    },

    {
        field: "order.seller.id",
        key: "Seller ID",
        headerName: "رقم التاجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.seller.id}</p>
        ),
    },

    {
        field: "order.seller.name",
        key: "Store Name",
        headerName: "اسم المتجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.store_name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name}
            </p>
        ),
    },

    {
        field: "order.seller_address",
        key: "Store Address",
        headerName: "عنوان المتجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller_address.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller_address.address}
            </p>
        ),
    },

    {
        field: "order.client.name",
        key: "Customer Name",
        headerName: "اسم العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.name,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client.name}</p>
        ),
    },

    {
        field: "order.client.id",
        key: "Customer ID",
        headerName: "رقم العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.client.id}</p>
        ),
    },

    {
        field: "order.client.phone",
        key: "Customer Phone",
        headerName: "رقم هاتف العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client.phone}</p>
        ),
    },

    {
        field: "order.client_address",
        key: "Customer Address",
        headerName: "عنوان العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client_address.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.client_address.address}
            </p>
        ),
    },

    {
        field: "order.assigned_state",
        key: "Status",
        headerName: "الحالة",
        width: 230,
        valueGetter: (row) => row.row.order?.assigned_state,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="onlineBtn"
                >
                    {row.row.order?.assigned_state}
                </Button>
            </strong>
        ),
    },

    {
        field: "order.payment_method",
        key: "Payment Method",
        headerName: "طريقة الدفع",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "1" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        Online
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        Cash
                    </Button>
                )}
            </strong>
        ),
    },
    {
        show: true,
        field: "action",
        headerName: "الإجراءات",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}}`}>
                <SeeMore arabic />
            </NavLink>
        ),
    },
];
export const pickupConfirmed = (cur) => [
    {
        field: "order.id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row.order?.id,
        renderCell: (row) => <p className="tableID">{row.row.order?.id}</p>,
    },

    {
        field: "id",
        headerName: "Ticket ID",
        width: 200,
        renderCell: (row) => <p className="tableID">{row.row.id}</p>,
    },
    {
        field: "seller.store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row.order?.seller.store_name
                ? row.row.order.seller?.store_name
                : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name
                    ? row.row.order.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]} - {row.row.create_date?.split("T")[1]}
            </p>
        ),
    },

    {
        field: "create_date",
        headerName: "Request Date",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "order.promise_date",
        headerName: "Postponed Date",
        width: 220,
        valueGetter: (row) => row.row?.new_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },

    {
        field: "order.delivery_range",
        headerName: "Delivery Range",
        width: 220,
        valueGetter: (row) => row.row.order?.delivery_range,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_range?.min}-
                {row.row.order?.delivery_range?.max}
            </p>
        ),
    },

    {
        field: "type",
        headerName: "Shipment Type",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.type ? "Pickup" : "Return"}
            </p>
        ),
    },

    {
        field: "quantity",
        headerName: "Item Quantity",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.quantity}</p>
        ),
    },

    {
        field: "length",
        headerName: "Dimension",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.length}x{row.row.width}x{row.row.height}
            </p>
        ),
    },

    {
        field: "weight",
        headerName: "Weight",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.weight}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "Special Label",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.special_label?.en}</p>
        ),
    },

    {
        field: "comment",
        headerName: "Feedback Comment",
        width: 270,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.comment?.text}</p>
        ),
    },

    {
        field: "order.sub_total",
        headerName: "Order Value",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.sub_total} {cur}
            </p>
        ),
    },

    {
        field: "order.delivery_fees",
        headerName: "Shipment Fee",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_fees} {cur}
            </p>
        ),
    },

    {
        field: "order.courier.id",
        headerName: "Courier ID",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.courier?.id}</p>
        ),
    },

    {
        field: "order.courier.name",
        headerName: "Courier name",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.name}
            </p>
        ),
    },

    {
        field: "order.courier.branch",
        headerName: "Courier Branch",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.branch.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.branch.name}
            </p>
        ),
    },

    {
        field: "order.courier.vehicle",
        headerName: "Vehicles",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.vehicle,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.courier?.vehicle}</p>
        ),
    },

    {
        field: "order.courier.phone",
        headerName: "Courier Phone ",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.phone}
            </p>
        ),
    },

    {
        field: "order.seller.id",
        headerName: "Seller ID",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.seller.id}</p>
        ),
    },

    {
        field: "order.seller.name",
        headerName: "Store Name",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.store_name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller?.store_name}
            </p>
        ),
    },

    {
        field: "order.seller_address",
        headerName: "Store Address",
        width: 220,
        valueGetter: (row) => row.row.order?.seller_address?.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller_address?.address}
            </p>
        ),
    },

    {
        field: "order.client.name",
        headerName: "Customer Name",
        width: 220,
        valueGetter: (row) => row.row.order?.client?.name,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client?.name}</p>
        ),
    },

    {
        field: "order.client.id",
        headerName: "Customer ID",
        width: 220,
        valueGetter: (row) => row.row.order?.client?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.client?.id}</p>
        ),
    },

    {
        field: "order.client.phone",
        headerName: "Customer Phone",
        width: 220,
        valueGetter: (row) => row.row.order?.client?.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client?.phone}</p>
        ),
    },

    {
        field: "order.client_address",
        headerName: "Customer Address",
        width: 220,
        valueGetter: (row) => row.row.order?.client_address?.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.client_address?.address}
            </p>
        ),
    },

    {
        field: "order.assigned_state",
        headerName: "Status",
        width: 230,
        valueGetter: (row) => row.row.order?.assigned_state,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="onlineBtn"
                >
                    {row.row.order?.assigned_state}
                </Button>
            </strong>
        ),
    },

    {
        field: "order.payment_method",
        headerName: "Payment Method",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "1" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        Online
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        Cash
                    </Button>
                )}
            </strong>
        ),
    },
    {
        show: true,
        field: "action",
        headerName: "Action",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore />
            </NavLink>
        ),
    },
];
export const moveOrders = [
    {
        field: "id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "create_branch",
        headerName: "Created Branch",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch.name}</p>
        ),
    },
    {
        field: "current_branch",
        headerName: "Current Branch",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.current_branch?.name}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "Delivery Range",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.delivery_range?.min.toString()} - ${row.row?.delivery_range?.max.toString()}`}</p>
        ),
    },
];

export const moveOrdersAr = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "create_branch",
        headerName: "الفرع المؤسس",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch.name}</p>
        ),
    },
    {
        field: "current_branch",
        headerName: "الفرع الحالي",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.current_branch?.name}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "تاريخ الإنشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "مدي التوصيل",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {toString(row.row?.delivery_range?.min) -
                    toString(row.row?.delivery_range?.max)}
            </p>
        ),
    },
];
export const runsheetOrders = [
    {
        field: "id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row?.seller?.store_name ? row.row.seller?.store_name : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.seller?.store_name
                    ? row.row.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "order_value",
        headerName: "Order Value",
        width: 210,
        valueGetter: (row) => row.row?.promise_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.sub_total}</p>
        ),
    },

    {
        field: "status",
        headerName: "Status",
        width: 230,
        renderCell: (row) =>
            row.row.runsheet_type?.map((status) => {
                return (
                    <div className={status + " btn btn-outline-primary mx-2"}>
                        {status}
                    </div>
                );
            }),
    },
];

export const runsheetOrders_ar = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "store_name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row?.seller?.store_name ? row.row.seller?.store_name : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.seller?.store_name
                    ? row.row.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "تاريخ الانشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "order_value",
        headerName: "قيمة الطلب",
        width: 210,
        valueGetter: (row) => row.row?.promise_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.sub_total}</p>
        ),
    },

    {
        field: "status",
        headerName: "الحالة",
        width: 230,
        renderCell: (row) =>
            row.row.runsheet_type?.map((status) => {
                return (
                    <div className={status + " btn btn-outline-primary mx-2"}>
                        {status}
                    </div>
                );
            }),
    },
];

export const couriers = [
    {
        field: "id",
        headerName: "Courier ID",
        width: 170,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "name",
        headerName: "Courier name",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.user?.name ? row.row.user?.name : "No Name"}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.branch?.name}</p>
        ),
    },
    {
        field: "vehichles",
        headerName: "Vehichles",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.vehicle}</p>
        ),
    },
    {
        field: "phone",
        headerName: "Phone Number",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone}</p>
        ),
    },
    {
        field: "phone_alt",
        headerName: "Phone Number 2",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone_alt}</p>
        ),
    },
];

export const couriers_ar = [
    {
        field: "id",
        headerName: "رقم المندوب",
        width: 170,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "name",
        headerName: "اسم المندوب",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.user.name ? row.row.user?.name : "No Name"}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.branch?.name}</p>
        ),
    },
    {
        field: "vehichles",
        headerName: "وسيلة المواصلات",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.vehicle}</p>
        ),
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone}</p>
        ),
    },
    {
        field: "phone_alt",
        headerName: "رقم الهاتف",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone_alt}</p>
        ),
    },
];

export const logsColumns = [
    {
        field: "date",
        headerName: "Date/Time",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="">{row.row.history_date?.split("T")[0]}</p>
        ),
    },

    // {
    //   field: "type",
    //   headerName: "User Type",
    //   width: 190,
    //   // renderCell: (row) => <p className="paragraphMargin">{row.row.branch.name}</p>,
    // },

    {
        field: "Action",
        headerName: "Action Type",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.history_type == "~"
                    ? "Changed"
                    : row.row.history_type == "-"
                    ? "Delete"
                    : "Create"}
            </p>
        ),
    },
    {
        field: "users",
        headerName: "Users",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.history_user?.name}
                </strong>
            </>
        ),
    },
    {
        field: "userType",
        headerName: "Users Type",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.history_user?.type_name}</p>
        ),
    },
    {
        field: "actionID",
        headerName: "Action ID",

        width: 240,
        renderCell: (row) => <p className="tableID">#{row.row.history_id}</p>,
    },
];

export const logsColumns_ar = [
    {
        field: "date",
        headerName: "الوقت/التاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="">{row.row.history_date?.split("T")[0]}</p>
        ),
    },
    {
        field: "Action",
        headerName: "نوع الاجراء",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.history_type == "~"
                    ? "تعديل"
                    : row.row.history_type == "-"
                    ? "مسح"
                    : "إنشاء"}
            </p>
        ),
    },
    {
        field: "users",
        headerName: "المستخدمون",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.history_user?.name}
                </strong>
            </>
        ),
    },
    {
        field: "userType",
        headerName: "نوع المستخدم",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.history_user?.type_name === "Organization Admin"
                    ? "مدير الشركة"
                    : row.row.history_user?.type_name === "Seller"
                    ? "بائع"
                    : "مدير فرع"}
            </p>
        ),
    },
    {
        field: "actionID",
        headerName: "رقم الاجراء",

        width: 240,
        renderCell: (row) => <p className="tableID">#{row.row.history_id}</p>,
    },
];

export const archivedRunsheets = [
    {
        field: "id",
        headerName: "Runsheet ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },

    {
        field: "created_by",
        headerName: "Created by",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.courier?.user?.name
                    ? row.row.courier?.user?.name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "date_created",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "courier_id",
        headerName: "Courier ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="tableID paragraphMargin">{row.row?.courier?.id}</p>
        ),
    },
    {
        field: "date_closed",
        headerName: "Date Closed",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.modify_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.branch?.name}</p>
        ),
    },
    {
        field: "courier_name",
        headerName: "Courier Name",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.user?.name}</p>
        ),
    },
];

export const archivedRunsheets_ar = [
    {
        field: "id",
        headerName: "رقم خط السير",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },

    {
        field: "created_by",
        headerName: "انشاء بواسطة",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.courier.user.name
                    ? row.row.courier?.user?.name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "date_created",
        headerName: "تاريخ الانشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "courier_id",
        headerName: "رقم المندوب",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="tableID paragraphMargin">{row.row?.courier?.id}</p>
        ),
    },
    {
        field: "date_closed",
        headerName: "تاريخ الانتهاء",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.modify_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.branch?.name}</p>
        ),
    },
    {
        field: "courier_name",
        headerName: "اسم المندوب",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.user?.name}</p>
        ),
    },
];

export const productInfo = [
    {
        field: "id",
        headerName: "Order ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "tid",
        headerName: "Ticket ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "store",
        headerName: "Store Name",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "date",
        headerName: "Create Date",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "weight",
        headerName: "Weight",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.weight}</p>
        ),
    },
    {
        field: "Dimension",
        headerName: "Dimension",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.width}x{row.row?.height}{" "}
            </p>
        ),
    },
    {
        field: "price",
        headerName: "Order value",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.price}</p>
        ),
    },
    {
        field: "label",
        headerName: "Label",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
];

export const productInfo_ar = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order.id}</p>,
    },
    {
        field: "tid",
        headerName: "رقم البوليصة",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "store",
        headerName: "اسم المتجر",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order.seller.store_name}
            </p>
        ),
    },
    {
        field: "date",
        headerName: "تاريخ الانشاء",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order.seller.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "weight",
        headerName: "الوزن",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.weight}</p>
        ),
    },
    {
        field: "Dimension",
        headerName: "الابعاد",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.width}x{row.row?.height}{" "}
            </p>
        ),
    },
    {
        field: "price",
        headerName: "قيمة الطلب",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.price}</p>
        ),
    },
    {
        field: "label",
        headerName: "اشارة مميزة",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
];

export const managerCol = [
    {
        field: "mid",
        title: "Employee ID",
        width: 250,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => <p className="tableID">{row.id}</p>,
    },
    {
        field: "name",
        title: "Employee name",
        width: 290,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="paragraphMargin">{row.user?.name}</p>,
    },
    {
        field: "phone",
        title: "Phone Number",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        title: "Branch",
        width: 190,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.branch?.name}</p>
        ),
    },

    {
        field: "E-mail",
        title: "E-mail",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.email}</p>
        ),
    },
];

export const managerCol_ar = [
    {
        field: "mid",
        title: "رقم الموظف",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="tableID">{row.id}</p>,
    },
    {
        field: "name",
        title: "الاسم",
        width: 290,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="paragraphMargin">{row.user?.name}</p>,
    },
    {
        field: "phone",
        title: "رقم الهاتف",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        title: "الفرع",
        width: 190,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.branch?.name}</p>
        ),
    },

    {
        field: "E-mail",
        title: "البريد الالكترونى",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.email}</p>
        ),
    },
];

export const sellerCol = [
    {
        field: "sid",
        headerName: "Seller Id",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Seller Name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },

    {
        field: "phone",
        headerName: "phone number",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "work model",
        headerName: "work model",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.work_model?.name}</p>
        ),
    },
];
export const sellerCol_ar = [
    {
        field: "sid",
        headerName: "رقم التاجر",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },

    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "work model",
        headerName: "نموذج العمل",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.work_model?.name}</p>
        ),
    },
];

export const courierCol = [
    {
        field: "cid",
        headerName: "Courier ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Courier name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },
    {
        field: "phone",
        headerName: "Phone Number",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Vichels",
        headerName: "Vichels",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.vehicle}</p>
        ),
    },
];
export const courierCol_ar = [
    {
        field: "cid",
        headerName: "رقم المندوب",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Vichels",
        headerName: "وسيلة التنقل",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.vehicle}</p>
        ),
    },
];
export const archivedUsers = [
    {
        field: "cid",
        headerName: "user ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "user name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
    {
        field: "usertype",
        headerName: "type",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_user?.type_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "Archived Date",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "Archived By",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.changed_by?.type_name}</p>
        ),
    },
];
export const archivedUsers_ar = [
    {
        field: "cid",
        headerName: "رقم المستخدم",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
    {
        field: "usertype",
        headerName: "النوع",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_user?.type_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "الفرع",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "التاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "تمت بواسطة",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.changed_by?.type_name}</p>
        ),
    },
];

export const reports = [
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch}</p>
        ),
    },

    {
        field: "orders",
        headerName: "Total Orders",
        width: 190,
        valueFormatter: (row) => `${row.row?.total}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.total}</p>
        ),
    },
    {
        field: "received",
        headerName: "Received",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "No Data"
            }`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "inHub",
        headerName: "In Hub",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "onDelivery",
        headerName: "On Delivery",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data.filter((state) => state.state_id === 8).length > 0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data.filter((state) => state.state_id === 8)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "delivered",
        headerName: "Delivered",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 6).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 6)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => {
            // console.log(row.row?.data.filter(state=> state.state_id === 9)[0].total)
            return (
                <p className="paragraphMargin">
                    {row.row?.data?.filter((state) => state.state_id === 6)
                        .length > 0
                        ? row.row?.data?.filter(
                              (state) => state.state_id === 6
                          )[0].total
                        : "No Data"}
                </p>
            );
        },
    },

    {
        field: "returned",
        headerName: "Returned",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "cash_on_delivery",
        headerName: "Cash on delivery",
        width: 220,
        valueFormatter: (row) => `${row.row?.cash}`,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.cash}</p>,
    },
    {
        field: "online_payment",
        headerName: "Online payment",
        width: 220,
        valueFormatter: (row) => `${row.row?.online}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.online}</p>
        ),
    },
    {
        field: "archived",
        headerName: "Archived",
        width: 190,
        valueFormatter: (row) => `${row.row?.archived}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.archived}</p>
        ),
    },
];

export const reports_ar = [
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        valueFormatter: (row) => `${row.row?.branch}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch}</p>
        ),
    },

    {
        field: "orders",
        headerName: "مجموع الطلبات",
        width: 210,
        valueFormatter: (row) => `${row.row?.total}`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.total}</p>
        ),
    },
    {
        field: "received",
        headerName: "المستلمة",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "inHub",
        headerName: "فى المخزن",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "onDelivery",
        headerName: "جارى التوصيل",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "delivered",
        headerName: "تم التوصيل",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 6).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 6)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => {
            // console.log(row.row?.data.filter(state=> state.state_id === 9)[0].total)
            return (
                <p className="paragraphMargin">
                    {row.row?.data?.filter((state) => state.state_id === 6)
                        .length > 0
                        ? row.row?.data?.filter(
                              (state) => state.state_id === 6
                          )[0].total
                        : "لا يوجد بيانات"}
                </p>
            );
        },
    },

    {
        field: "returned",
        headerName: "المرتجع",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "cash_on_delivery",
        headerName: "نقدى عند الاستلام",
        width: 230,
        valueFormatter: (row) => `${row.row?.cash}`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.cash}</p>,
    },
    {
        field: "online_payment",
        headerName: "دفع الكترونى",
        width: 190,
        valueFormatter: (row) => `${row.row?.online}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.online}</p>
        ),
    },
    {
        field: "archived",
        headerName: "ارشيف",
        width: 190,
        valueFormatter: (row) => `${row.row?.archived}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.archived}</p>
        ),
    },
];

export const scan = [
    {
        field: "date",
        headerName: "Date Created",
        flex: 2,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "postpone_date",
        headerName: "Postponed Date",
        flex: 2,
        minWidth: 230,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },
    {
        field: "order_id",
        headerName: "Order ID",
        flex: 2,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.id}</p>
        ),
    },

    {
        field: "id",
        headerName: "Ticket ID",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.id}</p>,
    },
    {
        field: "seller_id",
        headerName: "Seller ID",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.seller.id}</p>
        ),
    },
    {
        field: "store_name",
        headerName: "Store Name",
        flex: 1,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller.store_name}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "Delivery Range",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.order?.delivery_range?.min.toString()} - ${row.row?.order?.delivery_range?.max.toString()}`}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "Special Label",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
    {
        field: "feedback_comment",
        headerName: "Feedback Comment",
        flex: 1,
        minWidth: 270,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.comment?.text}</p>
        ),
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.state?.title}</p>
        ),
    },
    {
        field: "quantity",
        headerName: "Item Quantity",
        flex: 1,
        minWidth: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.quantity}</p>
        ),
    },
];

export const scan_ar = [
    {
        field: "date",
        headerName: "تاريخ الانشاء",
        flex: 2,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "postpone_date",
        headerName: "تاريخ التأجيل",
        flex: 2,
        minWidth: 210,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },
    {
        field: "order_id",
        headerName: "رقم الطلب",
        flex: 2,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.id}</p>
        ),
    },

    {
        field: "id",
        headerName: "رقم البوليصة",
        flex: 1,
        minWidth: 200,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.id}</p>,
    },
    {
        field: "seller_id",
        headerName: "رقم التاجر",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order?.seller?.id}</p>
        ),
    },
    {
        field: "store_name",
        headerName: "اسم المتجر",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "متوسط ايام الدفع",
        flex: 1,
        minWidth: 230,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.order?.delivery_range?.min.toString()} - ${row.row?.order?.delivery_range?.max.toString()}`}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "علامة مميزة",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label.en}</p>
        ),
    },
    {
        field: "feedback_comment",
        headerName: "التعليقات",
        flex: 1,
        minWidth: 260,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.comment?.text_ar}</p>
        ),
    },
    {
        field: "status",
        headerName: "الحالة",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.state.title}</p>
        ),
    },
    {
        field: "quantity",
        headerName: "الكمية",
        flex: 1,
        minWidth: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.quantity}</p>
        ),
    },
];

export const archivedTickets = [
    {
        field: "cid",
        headerName: "Ticket ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Order ID",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "usertype",
        headerName: "Store Name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "Seller Name",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.user.name}
            </p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "Archived Date",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "Archived By",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
];

export const archivedTickets_ar = [
    {
        field: "cid",
        headerName: "رقم البوليصة",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "رقم الطلب",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "usertype",
        headerName: "اسم المتجر",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "اسم التاجر",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.user?.name}
            </p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "تاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "بواسطة",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
];

export const useCloseRunsheetColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{row.id}</span>,
        },

        {
            title: t("store"),
            dataIndex: "store_name",
        },
        {
            title: t("createDate"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {parseFloat(row.total_amount).toFixed(2)}
                </p>
            ),
        },
    ];
};

export const useThirdPartyColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("Company Name"),
            dataIndex: "name",
        },
        {
            title: t("Area"),
            dataIndex: ["area", "name"],
        }
    ];
}

export const useSmsTemplateColumns = (handleActivateAction, loadingActivateAction, selectedTemplate) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("ID"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{_}</span>,
        },
        {
            title: t("Template Name"),
            dataIndex: ["sms_template", "name"],
        },
        {
            title: t("Template Description"),
            dataIndex: "description"
        },
        {
            title: t("Template Code"),
            dataIndex: "code_display",
        },
        {
            title: t("Recipient Type"),
            dataIndex: "recipient_type_display",
        },
        {
            title: t("Actions"),
            render: (_, row) => (
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <NavLink
                        to={`/sms_templates/${row.id}`}
                    >
                        <SeeMore margin="m-0" arabic={lang === "ar"} />
                    </NavLink>
                    <Switch
                        checked={row.is_active}
                        onChange={() => handleActivateAction(row.id)}
                        loading={loadingActivateAction && selectedTemplate === row.id}
                    />
                </div>
            ),
        },
    ];

}

export const useSellerSmsTemplateColumns = (handleDeleteAction, loadingDeleteAction, selectedTemplate) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("ID"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{_}</span>,
        },
        {
            title: t("Seller Name"),
            dataIndex: ["seller_display", "store_name"],
        },
        {
            title: t("Template Content"),
            dataIndex: "message_content"
        },
        {
            title: t("Actions"),
            render: (_, row) => (
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <NavLink
                        to={`/sms_templates/${row.sms_action.id}/sellers_templates/${row.id}`}
                    >
                        <SeeMore margin="m-0" arabic={lang === "ar"} />
                    </NavLink>
                    {
                        loadingDeleteAction && selectedTemplate === row.id ? <Spin size="small" /> :
                            <span 
                                onClick={() => handleDeleteAction(row.id)}
                                className="text-danger cursor-pointer"
                                style={{ fontSize: "1rem" }}
                            >
                                <i className="fas fa-trash"></i>
                            </span>
                    }
                </div>
            ),
        },
    ];
};