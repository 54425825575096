import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { useDispatch, useSelector } from "react-redux";
import CreateNewCustomer from "../../../../../components/modals/CreateNewCustomer";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../../hooks/useLanguage";
import orders from "../../../../../components/pages/wallets/orders";

export default function SellerCustomerInfo({ value, handleChange }) {
    const dispatch = useDispatch();
    const [clients, setClients] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [nextClients, setNextClients] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [nextLink, setNextLink] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [created, setCreated] = useState(true);
    const { t } = useTranslation();
    const adminAddresses = useSelector(
        (state) => state.selectedData?.selectedSeller?.saved_address
    );
    const adminSelectedAddress = useSelector(
        (state) => state.order.seller_address
    );
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(undefined);

    const selectedClient = useSelector(
        (state) => state.selectedData?.selectedClient
    );

    const selectedSeller = useSelector(
        (state) => state.selectedData?.selectedSeller
    );

    const user = useSelector((state) => state.auth.user);
    const openCreateModal = () => {
        setShowCreate(true);
        setCreated(false);
    };

    const hideCreateModal = (e) => {
        setShowCreate(false);
    };

    useEffect(() => {
        console.log(value);
    }, []);

    const getClients = async () => {
        let result;
        if (user.role === "Seller") {
            result = await axiosInstance.get(`/FilterClients/`, {
                params: {
                    seller: user.user?.user_id,
                },
            });
        } else {
            result = await axiosInstance.get(`/FilterClients/`, {
                params: {
                    seller: selectedSeller.id,
                },
            });
        }

        if (result) {
            setClients(result.data.data);
            setNextClients(result.data.next);
        }
    };
    // const search = async (value) => {
    //     // setSearchTerm(value)
    //     let result = await axiosInstance.get(`/FilterSeller/?search=${value}`);
    //     if (result) {
    //         console.log(result.data.data);
    //         setClients(result.data.data);
    //         setNextClients(result.data.next);
    //     }
    //     console.log(value);
    // };
    const onScroll = async (event, link) => {
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextClients}`)
                .catch((err) => console.log(err));

            if (result) {
                setClients([...clients, ...result.data.data]);
                setNextClients(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const changeAddress = (value) => {
        setSelectedAddress(value);
    };
    const validate = (data) => {
        console.log(`Form Data = ${data}`);

        if (data.client == undefined && selectedClient == "") {
            console.log("not valid");
            setErrMsg("This field is required");
        } else {
            dispatch(
                addOrder({
                    ...data,
                    seller_address: selectedAddress
                        ? selectedAddress
                        : adminSelectedAddress,
                })
            );
            console.log(" valid");
            setErrMsg("");
            handleChange(null, parseInt(value) + 1);
        }
    };

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };
    const getSellerAddresses = async () => {
        const result = await axiosInstance.get(`/Seller/${user.user.user_id}`);
        setAddresses(result.data.seller_addresses);
    };
    useEffect(() => {
        console.log(created);
        if (created) {
            try {
                getClients();
            } catch (err) {
                console.log(err);
            }
        }
    }, [created,         user,
        addresses,
        selectedAddress,
        adminSelectedAddress,
        adminAddresses,]);
    useEffect(() => {
        setErrMsg("");
    }, [selectedClient]);
    const lang = useLanguage();
    useEffect(() => {
        if (user.role == "Seller") getSellerAddresses();
    }, []);
    
    return (
        <ModifiedForm submitFunction={validate}>
            {user.role === "Seller" && (
                <Row>
                    <Col sm={6}>
                        <Form.Label>{t("chooseSellerAddress")}</Form.Label>
                        <Select
                            id="seller_address"
                            style={{ width: "100%" }}
                            value={selectedAddress}
                            onChange={changeAddress}
                        >
                            {
                                //return sellers[index]
                                (addresses || []).map((address) => (
                                    <Select.Option
                                        key={address.id}
                                        value={address.id}
                                    >
                                        {address.address}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <a
                            className={
                                lang == "en"
                                    ? "over-select-link-en"
                                    : "over-select-link-ar"
                            }
                            onClick={openCreateModal}
                        >
                            {t("createNewCustomer")}
                        </a>
                        <PaginatedSelect
                            id="client"
                            title={t("fullname")}
                            disabled={!selectedAddress && !adminSelectedAddress}
                            onScroll={(e) => onScroll(e, nextClients)}
                            choice={selectedClient.id}
                        >
                            {
                                //return sellers[index]
                                clients.map((client) => (
                                    <Select.Option
                                        key={client.id}
                                        clientinfo={client}
                                        value={client.id}
                                    >
                                        {client.name}

                                    </Select.Option>
                                ))
                            }
                        </PaginatedSelect>
                        <p className="error">{errMsg !== "" ? errMsg : null}</p>
                        <TextField
                            id="phone1client"
                            className="formTextField text-area"
                            validation={{ 
                                required: true,
                                pattern: /^\+[1-9]\d{10,14}$/,
                            }}
                            title={t("phone")}
                            value={
                                selectedClient !== ""
                                    ? `${selectedClient.phone}`
                                    : ""
                            }
                        />{" "}
                        <TextField
                            id="phone2client"
                            className="formTextField text-area"
                            validation={{ 
                                required: true,
                                pattern: /^\+[1-9]\d{10,14}$/,
                            }}
                            title={t("phone")}
                            value={
                                selectedClient !== ""
                                    ? `${selectedClient.phone_alt}`
                                    : ""
                            }
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="addressCard">
                        <label>{t("chooseCustomerAddress")} </label>
                        {Object.keys(selectedClient ?? {}).length > 0
                            ? selectedClient?.client_addresses?.map(
                                  (address) => {
                                      return (
                                          <>
                                              <Radio
                                                  value={address.id}
                                                  id="client_address"
                                                  validation={{
                                                      required: true,
                                                  }}
                                                  name="address"
                                                  className="radio-btn"
                                                  txt={address.address}
                                              />
                                          </>
                                      );
                                  }
                              )
                            : null}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-end" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>
                    {/* <button className="cancel" disabled>
            {t("discard")}
          </button> */}
                    <input
                        // onClick={(e) => handleChange(e, value + 1)}
                        className="confirm"
                        value={t("proceed")}
                        type="submit"
                    />
                </Col>
            </Row>
            <CreateNewCustomer
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
            />
        </ModifiedForm>
    );
}
