import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Select, Modal, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { ConnectMinor, IqMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { useSelector } from "react-redux";
import useLanguage from "../../hooks/useLanguage";

const WorkModelEdit = ({ data, setCreated, show, hide ,getData}) => {
  console.log(data);
  const user = useSelector((state) => state.auth.user);

  const [name, setName] = useState("");
  const { t } = useTranslation();
  // const formRef = React.createRef();


  const [sameBranchShip, setSameBranchShip] = useState(0);
  const [sameBranchRto, setSameBranchRto] = useState(0);
  const [sameZoneShip, setSameZoneShip] = useState(0);
  const [sameZoneRto, setSameZoneRto] = useState(0);
  const [branches, setBranches] = useState([]);
  const [branchesCount, setBranchesCount] = useState(1);
  const [selecetedBranch1, setSelectedBranch1] = useState("");
  const [selecetedBranch2, setSelectedBranch2] = useState("");
  const [deliveryRanges, setDeliveryRanges] = useState([]);
  const [priceList, setPriceList] = useState([
    {
      branch_from: "",
      branch_to: "",
      price: 0,
      price_rto: 0,
    },
  ]);

  const handleInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...priceList];
    list[index][name] = value;
    setPriceList(list);
  };

  const handleSelectedBranch1 = (e, i) => {
    const list = [...priceList];
    list[i].branch_from = e;
    let copy = [...selecetedBranch1];
    copy[i] = e;
    setSelectedBranch1(copy);
  };

  const handleSelectedBranch2 = (e, i) => {
    const list = [...priceList];
    list[i].branch_to = e;
    let copy = [...selecetedBranch2];
    copy[i] = e;
    setSelectedBranch2(copy);
  };
  const getBranches = async () => {
    const result = await axiosInstance.get("/Branch/");
    if (result) {
      setBranches(result.data.data);
      setBranchesCount(result.data.count);
    }
  };
  const AddAnotherPrice = () => {
    let pricesList = [...priceList];
    setPriceList([
      ...pricesList,
      {
        branch_from: "",
        branch_to: "",
        price: 0,
        price_rto: 0,
      },
    ]);
  };
  const getDeliveryRanges = async () => {
    let result = await axiosInstance
      .get("/DeliveryRange/")
      .catch((err) => console.log(err));

    if (result) {
      setDeliveryRanges(result.data.data);
    }
  };
  const onFinish = async (values) => {
    console.log("Success:", values);
    let result = await axiosInstance
      .put(`/WorkModel/${data.id}`, values)
      .catch((err) => console.log(err));

    if (result) {
      getData()
      setCreated(true);
      hide();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    console.log(priceList);
  }, [priceList]);

  useEffect(() => {
    getDeliveryRanges();
  }, []);
  const lang = useLanguage();

  return (
    <Modal destroyOnClose={true} width={700} size="lg" onCancel={() => hide()} visible={show}>
      <Form
        // ref={formRef}
        dir={lang == "ar" ? "rtl" : "ltr"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="workModal"
        initialValues={{
          delivery_ranges: data?.delivery_ranges.map((range) => range.id),
          name: data?.name,
          same_zone_price: data?.same_zone_price,
          same_branch_price: data?.same_branch_price,
          same_zone_price_rto: data?.same_zone_price_rto,
          same_branch_price_rto: data?.same_branch_price_rto,
        }}
      >
        <Row>
          <Col lg="12">
            <h6 className="boldTitle">{t("createWorkModel")}</h6>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <label className="secTitle">{t("workModelName")}</label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("err.name")}`,
                },
              ]}
            >
              <Input value={name} type="text" />
            </Form.Item>
          </Col>
          <Col sm="12" lg="6">
            <label className="secTitle">{t("rangeOverAll")}</label>

            <Form.Item
              name="delivery_ranges"
              rules={[
                {
                  required: true,
                  message: `${t("err.range")}`,
                },
              ]}
            >
              <Select mode="multiple">
                {deliveryRanges.map((deliveryRange) => {
                  return (
                    <Select.Option
                      value={deliveryRange.id}
                      id={deliveryRange.id}
                    >
                      {deliveryRange.min} - {deliveryRange.max}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12">
            <h6 className="boldTitle" style={{ marginBottom: "0px" }}>
              {t("listInput")}
            </h6>
          </Col>
        </Row>
        <Row className="">
          <Col className="position-relative my-2" sm="8" md="6">
            <div className="position-relative text-area-form">
              <label className="secTitle">{t("sideMenu.general")}</label>

              <Form.Item disabled>
                <Icon source={IqMajor} color="base" />
                <Input disabled placeholder={t("sameZone")} />
              </Form.Item>
              <Form.Item disabled>
                <Icon source={IqMajor} color="base" />
                <Input disabled placeholder={t("sameBranch")} />
              </Form.Item>
            </div>
          </Col>
          <Col className="position-relative my-2" sm="8" md="3">
            <div className="position-relative text-area-form">
              <label className="secTitle">{t("shipmentFee")}</label>
              <Form.Item
                name="same_zone_price"
                rules={[
                  {
                    required: true,
                    message: `${t("err.price")}`,
                  },
                ]}
              >
                <Input value={sameZoneShip} type="number" />
              </Form.Item>
              <Form.Item
                name="same_branch_price"
                rules={[
                  {
                    required: true,
                    message: `${t("err.price")}`,
                  },
                ]}
              >
                <Input value={sameBranchShip} type="number" />
              </Form.Item>
            </div>
          </Col>

          <Col className="position-relative my-2" sm="8" md="3">
            <div className="position-relative text-area-form">
              <label className="secTitle">{t("returnedFee")}</label>
              <Form.Item
                name="same_zone_price_rto"
                rules={[
                  {
                    required: true,
                    message: `${t("err.price")}`,
                  },
                ]}
              >
                <Input value={sameZoneRto} type="number" />
              </Form.Item>
              <Form.Item
                name="same_branch_price_rto"
                rules={[
                  {
                    required: true,
                    message: `${t("err.price")}`,
                  },
                ]}
              >
                <Input value={sameBranchRto} type="number" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col lg="6">
            <label className="secTitle">{t("custom")}</label>
            <Form.Item disabled>
              <Icon source={ConnectMinor} color="base" />

              <Input disabled placeholder={t("between2Branch")} />
            </Form.Item>
          </Col>
          <Col lg="6" className="label-position">
            <Row className="label-row">
              <Col sm="6">
                <label className="secTitle">{t("shipmentFee")}</label>
              </Col>
              <Col sm="6">
                <label className="secTitle">{t("returnedFee")}</label>
              </Col>
            </Row>
          </Col>
        </Row>
        {priceList.map((price, i) => {
          return (
            <Row>
              <Col sm="3">
                <Form.Item
                  name={`frombranch${i}`}
                  rules={[{ required: true, message: "Please select branch!" }]}
                >
                  <Select
                    value="Main Branch"
                    onChange={(e) => handleSelectedBranch1(e, i)}
                    allowClear
                    onClick={getBranches}
                    className="form-control paginated"
                  >
                    {branches.map((branch) => (
                      <Select.Option
                        key={branch.id}
                        id={branch.id}
                        value={branch.name}
                      >
                        {branch.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm="3">
                <Form.Item
                  name={`tobranch${i}`}
                  rules={[{ required: true, message: "Please select branch!" }]}
                >
                  <Select
                    // onSearch={onSearch}
                    // filterOption={false}
                    // showSearch
                    value={selecetedBranch2[i]}
                    onChange={(e) => handleSelectedBranch2(e, i)}
                    allowClear
                    // ref={ref}
                    onClick={getBranches}
                    // onPopupScroll={onScroll}
                    className="form-control paginated"
                  >
                    {branches.map((branch) => (
                      <Select.Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="position-relative" sm="3">
                <div className="position-relative text-area-form">
                  <Form.Item
                    name={`shipprice${i}`}
                    rules={[
                      {
                        required: true,
                        message: `${t("err.price")}`,
                      },
                    ]}
                  >
                    <Input onChange={(e) => handleInput(e, i)} type="number" />
                  </Form.Item>
                </div>
              </Col>
              <Col className="position-relative" sm="3">
                <div className="position-relative text-area-form">
                  <Form.Item
                    name={`rtoprice${i}`}
                    rules={[
                      {
                        required: true,
                        message: `${t("err.price")}`,
                      },
                    ]}
                  >
                    <Input
                      name="price_rto"
                      onChange={(e) => handleInput(e, i)}
                      type="number"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          );
        })} */}
        {/* <Row className="mt-4">
          <Col sm="12">
            <p onClick={AddAnotherPrice} className="addBtn">
              <i className="fas fa-plus-circle mx-2"></i>
              {t("addAnotherBranch")}
            </p>
          </Col>
        </Row> */}
        <button
          onClick={hide}
          className="cancel"
        >
          {t("cancel")}
        </button>
        <Button className="confirm" type="primary" htmlType="submit">
          {t("edit")}
        </Button>
      
      </Form>
    </Modal>
  );
};

export default ErrorHandler(WorkModelEdit, axiosInstance);
