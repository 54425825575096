import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
// import { archivedRunsheets,archivedRunsheets_ar } from "../layout/tableColumns";
import CourierDetails from "./courierDetails";
import { useTranslation } from "react-i18next";
import { addRunsheetOrders } from "../../global-state/actions/rsOrdersAction";
import { Link } from "react-router-dom";
import { CircleRightMajor, PaymentsMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { useDispatch } from "react-redux";
import ArchivedCourierCard from "./ArchivedCourierCard";
import TablePro from "../tables/TablePro";
import ThreeDotsMenuRS from "./ThreeDotsMenuRS";
import { useRunSheetColumns } from "./tableColumns";
import useLanguage from "../../hooks/useLanguage";

function ArchivedRunsheets() {
    const { t } = useTranslation();
    const [currentRunSheetCourier, setCurrentRunSheetCourier] = useState();
    const columns = useRunSheetColumns({selectable: true});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [courierDetails, setCourierDetails] = useState({});

    const onClose = (e) => {
        setShow(e);
    };

    const getArchived = async () => {
        setLoading(true);
        let result = await axiosInstance
            .get("/FilterRunsheet/", {
                params: {
                     state: 4,
                    page,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            setData(result.data.data ?? []);
            setCount(result.data.count);
        }
        setLoading(false);
    };
    useEffect(() => {
        getArchived();
    }, [page]);

    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.runsheets")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.archived")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="12" style={{position: "relative"}}>
                    <ArchivedCourierCard
                        current={currentRunSheetCourier}
                        onClose={() => setCurrentRunSheetCourier(undefined)}
                    />
                    <div>
                        <TablePro
                            loading={loading}
                            dataSource={data}
                            columns={columns}
                            pagination={{
                                current: page,
                                total: count,
                                onChange: (page) => setPage(page),
                            }}
                            onRowClick={(i, record) =>
                                setCurrentRunSheetCourier(record.courier)
                            }
                        />
                        {show ? (
                            <CourierDetails
                                setClose={onClose}
                                data={courierDetails}
                                show={show}
                            />
                        ) : null}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ErrorHandler(ArchivedRunsheets, axiosInstance);
