import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../../api/axios";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    productInfo,
    productInfo_ar,
} from "../../../../../components/layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../../../../components/modals/confirmationModal";
import {
    getSeller,
    getTicketPrintedData,
    getTicketsReturned,
} from "../../../../../global-state/actions/getSelectedAction";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useLanguage from "../../../../../hooks/useLanguage";
import OrderSummary from "../../order-summary/ui/OrderSummary";

function ReviewReplacementRequest({ value, handleChange, returnedObj }) {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const order = useSelector((state) => state.order);
    const [forcePrice, setForcePrice] = useState(undefined);
    const selectedSellerDispatch = useDispatch();
    const selectedAddressDispatch = useDispatch();
    const selectedTicketsDispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [orderDetails, setOrderDetails] = useState("");
    const [calDeliveryFees, setCalDeliveryFees] = useState();
    const [confirmMessage, setConfirmMessage] = useState("");
    useEffect(() => {
        console.log(returnedObj);
    }, []);
    // const sendRequest = () => {
    //     setShow(true);
    // };
    //
    // const hide = () => {
    //     setShow(false);
    // };
    const hideShowConfirm = () => {
        setShowConfirm(false);
        history.push("/dashboard");
    };
    console.log({ order });
    const confirm = async () => {
        // const orders = new Set(returnedObj.map((it) => it.order.id));
        let data = {
            order: order.id,
            tickets: order.tickets,
            returned_tickets: order.return_tickets.map((it) => it.id),
            return_address: order.return_address,
            seller_address: order.seller_address,
            force_price: forcePrice ? forcePrice : undefined,
            // tickets: returnedTicketsID,
            // orders: [...orders],
            // return_address: sellerAddress,
        };
        console.log(data);
        let result = await axiosInstance
            .post("/ReplacementOrder/", data)
            .catch((err) => {
                console.log(err.response);
                const error =typeof err.response.data ==="string" ? err.response.data: Object.values(err.response.data ?? {})[0];
                setError(Array.isArray(error) ? error[0] : error);
                setShowError(true);
            });
        if (result) {
            console.log(result.data);
            selectedAddressDispatch(addOrder(""));
            selectedTicketsDispatch(getTicketsReturned(""));
            selectedSellerDispatch(getSeller(""));
            if (result.data?.msg) {
                setOrderDetails(result.data.data);
                setConfirmMessage(Object.values(result.data.msg)[0]);
            }

            // setShowConfirm(true);
        }
    };
    const dispatchPrinted = useDispatch();
    const clearDispatch = useDispatch();

    const updateRequest = async () => {
        let result = await axiosInstance
            .put(`/ReplacementOrder/${orderDetails.id}`, {
                delivery_fees: calDeliveryFees,
            })
            .catch((err) => console.log(err));

        if (result) {
            dispatchPrinted(getTicketPrintedData(result.data));
            setShowConfirm(true);
            clearDispatch(addOrder(""));
        }
    };

    const { t } = useTranslation();
    const lang = useLanguage();

    return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("orderID")}</label>
                        <Form.Control
                            defaultValue={order.id}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("sellername")}</label>
                        <Form.Control
                            defaultValue={order.seller?.user?.name}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("phone")}</label>
                        <Form.Control
                            defaultValue={order.seller?.user?.phone}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("forcePrice")}</label>
                        <Form.Control
                            min={0}
                            value={forcePrice}
                            onChange={(e) => setForcePrice(e.target.value)}
                            type="number"
                        />
                    </div>
                </Col>
            </Row>

            {/*<Row className="mt-5">*/}
            {/*    <Col sm="12">*/}
            {/*        <div className="requestTable">*/}
            {/*            <DataGrid*/}
            {/*                rowHeight="50"*/}
            {/*                rows={order.tickets}*/}
            {/*                columns={*/}
            {/*                    lang == "ar" ? productInfo_ar : productInfo*/}
            {/*                }*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <hr />

            <OrderSummary
                orderDetails={orderDetails}
                onShipmentFeesChange={(v) => setCalDeliveryFees(v)}
            />
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button
                        onClick={() => handleChange(null, value - 1)}
                        className="previous"
                    >
                        {t("previous")}
                    </button>
                    {orderDetails ? (
                        calDeliveryFees ? (
                            <button
                                onClick={updateRequest}
                                className={
                                    user.role == "Seller"
                                        ? "confitmSeller"
                                        : "confirm"
                                }
                            >
                                {t("update")}
                            </button>
                        ) : (
                            <button
                                onClick={() => setShowConfirm(true)}
                                className={
                                    user.role == "Seller"
                                        ? "confitmSeller"
                                        : "confirm"
                                }
                            >
                                {t("confirm")}
                            </button>
                        )
                    ) : (
                        <button
                            onClick={confirm}
                            className={
                                user.role == "Seller"
                                    ? "confitmSeller"
                                    : "confirm"
                            }
                        >
                            {t("calculate")}
                        </button>
                    )}
                </Col>
            </Row>
            {/*<CreationConfirmation*/}
            {/*    sendRequest={confirm}*/}
            {/*    show={show}*/}
            {/*    hide={hide}*/}
            {/*    body={*/}
            {/*        lang === "en"*/}
            {/*            ? `Are you sure that you want to Replace ${order.return_tickets?.length} Tickets From Order ID #${order.id}`*/}
            {/*            : `هل انت متأكد انك تريد إرجاع عدد ${order.return_tickets?.length} بوليصة من الطلب رقم #${order.id}`*/}
            {/*    }*/}
            {/*    title={t("returnConfirmation")}*/}
            {/*/>*/}
            <SuccessfulRequest
                show={showConfirm}
                hide={hideShowConfirm}
                title={t("replacementConfirmation")}
                body={confirmMessage ?? t("replacementConfirmText")}
            />
            <UnSuccessfulRequest
                title={t("replacementConfirmation")}
                show={showError}
                body={error}
                hide={() => setShowError(false)}
            />
        </>
    );
}

export default ErrorHandler(ReviewReplacementRequest, axiosInstance);
