import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { addRunsheetOrders } from "../../../global-state/actions/rsOrdersAction";
import { useTranslation } from "react-i18next";
import ConfirmPayment from "../../../features/seller/wallet/confirm-payment/ui";
import TablePro from "../../tables/TablePro";

function ViewOrders(props) {
    const dispatchOrders = useDispatch();
    const { t } = useTranslation();
    const orders = useSelector((state) => state.rsOrders.orders);
    console.log({ orders });
    const history = useHistory();
    const branch = useSelector((state) => state.branch.branch);
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const user = useSelector((state) => state.auth.user);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [type, setType] = useState("1");
    const [seller, setSeller] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);
    const [changed, setChanged] = useState(false);
    const [columns, setColumns] = useState([
        {
            field: "id",
            title: `${t("orderID")}`,
            width: 220,
            // valueGetter: (row) => row?.id,
            render: (_, row) => (
                <p className="paragraphMargin tableID">#{row.id}</p>
            ),
        },
        {
            field: "sfee",
            title: `${t("shipmentFee")}`,
            width: 220,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin credit">{row.delivery_fees}</p>
            ),
        },
        // {
        //   field: "rto",
        //   title: `${t("returnedFee")}`,
        //   width: 220,
        //   // valueGetter: (row) => row.id,
        //   render: (_,row) => (
        //     <p className="paragraphMargin debit">EGP {row.return_fees}</p>
        //   )
        // },
        {
            field: "value",
            title: `${t("orderValue")}`,
            width: 200,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin credit">
                    {cur} {row.sub_total}
                </p>
            ),
        },
        {
            field: "method",
            title: `${t("paymentMethod")}`,
            width: 240,
            // valueGetter: (row) => row.id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method}</p>
            ),
        },
    ]);
    const prevSelectionModel = useRef(selectionModel);
    const prevOrders = useRef(orders);

    useEffect(() => {
        setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
        console.log("selection model ", selectionModel);
    }, [page, data]);

    useEffect(() => {
        console.log("ORDERS ", orders);
    }, [orders]);

    return (
        <>
            <div>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.wallet")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("totalBalance")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sellerWallet")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                    </Col>
                </Row>

                <TablePro
                    title={
                        <div
                            className="tableHeader"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: 0,
                                width: "100%",
                            }}
                        >
                            <h6>
                                {branch} {t("orderDetails")}
                            </h6>
                        </div>
                    }
                    headerExtra={
                        user.role === "Seller" ? <ConfirmPayment /> : undefined
                    }
                    dataSource={orders.orders? orders.orders : orders}
                    columns={[...columns]}
                    rowCount={count}
                    pagination
                />
            </div>
        </>
    );
}

export default ErrorHandler(ViewOrders, axiosInstance);
