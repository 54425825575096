import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { useSellerSmsTemplateColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { notifyErrors } from "../../../api/ErrorNotifier";
import WMButton from "../AllWorkModels/WMButton";

const SellerTemplates = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [queryParams, setQueryParams] = useState({
        page: 1,
        page_size: 10,
    });
    const {templateId} = useParams();
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loadingAction, setLoadingAction] = useState(false);

    const handleDeleteAction = async (id) => {
        setLoadingAction(true);
        setSelectedTemplate(id);
        const response = await SmsTemplateRepository.deleteSellerTemplate(id);
        if (response.success) {
            getSmsTemplates();
        } else {
            notifyErrors(response.error);
        }
        setLoadingAction(false);
        setSelectedTemplate(null);
    }

    const columns = useSellerSmsTemplateColumns(handleDeleteAction, loadingAction, selectedTemplate);

    const getSmsTemplates = async () => {
        setLoading(true);
        const response = await SmsTemplateRepository.getSellerTemplates(templateId, queryParams);
        if (response.success) {
            setSmsTemplates(response.data);
            setCount(response.count);
        } else {
            notifyErrors(response.error);
        }
        setLoading(false);
    }

    const handleChangeQueryParams = (param, value) => {
        setQueryParams({ ...queryParams, [param]: value, page: param === "page" ? value : 1 });
    }

    useEffect(() => {
        getSmsTemplates();
    }, [queryParams]);

    



    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.smsTemplates")}
                        </span>

                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.sellersSmsTemplates")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="pageTitle">
                                {t("sideMenu.sellersSmsTemplates")}
                            </h2>
                            <WMButton
                                type="primary"
                                onClick={() => history.push(`/sms_templates/${templateId}/sellers_templates/new`)}
                            >
                                {t("Create Seller Template")}
                            </WMButton>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <div>
                        <div>
                            <TablePro
                                loading={loading}
                                emptyPlaceholder={
                                    <div>
                                        <h6 className="noDataHead">
                                            {t("noDataFound")}
                                        </h6>
                                        <p>
                                            {t("There are no sms templates available")}
                                        </p>
                                    </div>
                                }
                                dataSource={smsTemplates}
                                columns={columns}
                                pagination={{
                                    current: queryParams.page,
                                    total: count,
                                    onChange: (page) => handleChangeQueryParams("page", page),
                                }}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(SellerTemplates, axiosInstance);
