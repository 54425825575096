import axiosInstance from "../../../../../api/axios";
import React, { useEffect, useState } from "react";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import PageHeader from "../../../../../components/layout/PageHeader";
import { useTranslation } from "react-i18next";
import TablePro from "../../../../../components/tables/TablePro";
import { Link } from "react-router-dom";
import useLanguage from "../../../../../hooks/useLanguage";
import ThreeDotsMenu from "../../../../../components/layout/threeDotsMenu";
import EditTier from "../edit-tier/edit-tier";

function AllTiersPage() {
    const { t } = useTranslation();
    const [tiers, setTiers] = useState([]);
    const [editingTier, setEditingTier] = useState();
    const lang = useLanguage();

    function cityNameChecker(city) {
        return lang.includes("en") || city.name_ar?.length === 0
            ? city.name
            : city.name_ar;
    }

    const columns = [
        {
            key: "name",
            title: `${t("name")}`,
            dataIndex: "name",
            render: (_, row) => (
                <>{lang.includes("en") ? row.name : row.name_ar}</>
            ),
        },
        {
            key: "areas",
            title: `${t("areas")}`,
            dataIndex: "tier_areas",

            render: (tier_areas) => {
                const first = tier_areas.first_3_areas
                    .map((it) => cityNameChecker(it))
                    .join(", ");
                const extra = tier_areas.areas_count - 3;
                return (
                    <p className="paragraphMargin">
                        {first}
                        {extra > 0 ? `, +${extra}` : undefined}
                    </p>
                );
            },
        },
        {
            key: "actions",
            title: `${t("actions")}`,
            render: (_, row) => (
                <ThreeDotsMenu
                    // onClick={() => setEditingTier(row.id)}
                    link="Area"
                    deleted
                    setEdited={() => setEditingTier(row.id)}
                    // setDeleted={setDeleted}
                    // showModal={setShowDelete}
                    data={row}
                />
            ),
        },
    ];

    async function getTiers() {
        try {
            const res = await axiosInstance.get("/Tier/?page_size=100000");
            setTiers(res.data.data ?? []);
        } catch (e) {}
    }

    useEffect(() => {
        getTiers();
    }, []);

    return (
        <div>
            <EditTier
                id={editingTier}
                onClose={() => setEditingTier(undefined)}
            />
            <PageHeader
                title={t("sideMenu.all_tiers")}
                group={t("sideMenu.tiers")}
            />
            <TablePro dataSource={tiers} columns={columns} />
        </div>
    );
}

// function TierCard({ tier }) {
//     const lang = useLanguage();
//     return (
//         <Link to={`/tier/${tier.id}`}>
//             <div>
//                 <div>{lang.includes("en") ? tier.name : tier.name_ar}</div>
//                 <div>{tier.description}</div>
//                 <div></div>
//             </div>
//         </Link>
//     );
// }

export default ErrorHandler(AllTiersPage, axiosInstance);
