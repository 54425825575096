import React from "react";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@shopify/polaris";
import {
    InventoryMajor,
    PaymentsMajor,
    DesktopMajor,
    LockMajor,
    SecureMajor,
    LanguageMinor,
    QuestionMarkMajor,
    ThemeEditMajor,
    LocationMajor,
    ButtonCornerSquareMajor,
    StoreMajor,
} from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Settings() {
    const user = useSelector((state) => state.auth.user);

    const { t, i18n } = useTranslation();

    console.log({ user });
    const cards =
        user.role === "Organization Admin"
            ? [
                  {
                      icon: InventoryMajor,
                      title: `${t("organization")}`,
                      link: "/organization",
                      description: `${t("orgSubTitle")}`,
                  },
                  // {
                  //   icon: PaymentsMajor,
                  //   title: `${t("billing")}`,
                  //   link: "/billing",
                  //   description: `${t("billingSubTitle")}`
                  // },
                  {
                      icon: ThemeEditMajor,
                      title: `${t("systemTheme")}`,
                      link: "/theme",
                      description: `${t("systemThemeSubtitle")}`,
                  },
                  {
                      icon: LockMajor,
                      title: `${t("security")}`,
                      link: "/security",

                      description: `${t("securitySubTitle")}`,
                  },
                  {
                      icon: SecureMajor,
                      title: `${t("permissions")}`,
                      link: "/permissions",

                      description: `${t("permissionsSubTiltle")}`,
                  },
                  {
                      icon: LanguageMinor,
                      title: `${t("lang")}`,
                      link: "/lang_settings",

                      description: `${t("langSubTitle")}`,
                  },
                  {
                      icon: QuestionMarkMajor,
                      title: `${t("FAQs")}`,
                      link: "https://lace-booth-6cf.notion.site/3f5ed58a721443b4935f4fe5ad9b69eb",

                      description: `${t("FAQsSubTitle")}`,
                  },
                  {
                      icon: StoreMajor,
                      title: `${t("sideMenu.branches")}`,
                      link: "/branches",
                      description: `${t("branchesSubtitle")}`,
                  },
                  {
                      icon: LocationMajor,
                      title: `${t("areas")}`,
                      link: "/areas",
                      description: `${t("areasSubTiltle")}`,
                  },
                  {
                      icon: ButtonCornerSquareMajor,
                      title: `${t("dimensions")}`,
                      link: "/dimensions",
                      description: `${t("dimensionsSubTiltle")}`,
                  },
              ]
            : [
                  {
                      icon: LockMajor,
                      title: `${t("security")}`,
                      link: "/security",

                      description: `${t("securitySubTitle")}`,
                  },
                  {
                      icon: LanguageMinor,
                      title: `${t("lang")}`,
                      link: "/lang_settings",

                      description: `${t("langSubTitle")}`,
                  },
                  {
                      icon: QuestionMarkMajor,
                      title: `${t("FAQs")}`,
                      link: "https://lace-booth-6cf.notion.site/3f5ed58a721443b4935f4fe5ad9b69eb",

                      description: `${t("FAQsSubTitle")}`,
                  },
              ];

    return (
        <div className="p-5">
            <Row>
                <Col sm="6">
                    <h6 className="reqTypeTitle">{t("sideMenu.settings")}</h6>
                </Col>
            </Row>
            <Row>
                {cards.map((card) => {
                    return (
                        <Col sm="6">
                            <div className="setting-card">
                                <div className="setting-icon">
                                    <Icon source={card.icon} color="base" />

                                    {/* <i className={card.icon}></i> */}
                                </div>
                                <div>
                                    {card.title === `${t("FAQs")}` ? (
                                        <a
                                            className="setting-card-title"
                                            target="_blank"
                                            href={card.link}
                                            rel="noreferrer"
                                        >
                                            {card.title}
                                        </a>
                                    ) : (
                                        <Link
                                            to={card.link}
                                            className="setting-card-title"
                                        >
                                            {card.title}
                                        </Link>
                                    )}

                                    <p className="setting-card-description">
                                        {card.description}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}
