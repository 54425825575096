import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {notification} from "antd";
import { useParams } from "react-router-dom";
import {Form as AntForm, Button, Select, Input, Spin, Collapse} from "antd";
import { notifyErrors } from "../../../api/ErrorNotifier";
import {RECIPIENT_TYPE} from "./Constants";
import KeywordsSelector from "./KeywordsSelector";
import WMButton from "../AllWorkModels/WMButton";

const { Panel } = Collapse;


const SmsTemplateDetails = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {id: templateId} = useParams();
    const [template, setTemplate] = useState(null);
    const [templateForm] = AntForm.useForm();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [sections, setSections] = useState([]);

    const contentRefEn = useRef(null);
    const contentRefAr = useRef(null);




    const handleChange = (event) => {
        const oldValues = templateForm.getFieldValue(event.target.name);
        console.log("Old values", oldValues);
        templateForm.setFieldValue(event.target.name, event.target.value);
        setDirty(templateForm.isFieldsTouched(false));
        console.log("Is dirty", dirty);
    };

    const getTemplate = async () => {
        setLoading(true);
        const response = await SmsTemplateRepository.getTemplateById(templateId);
        if (response.success) {
            setTemplate(response.data);
        } else {
            notifyErrors(response.error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getTemplate();
    }, []);


    const onFinish = async (values) => {
        console.log("Form values", values);
        setSubmitting(true);
        let response = await SmsTemplateRepository.updateTemplate(templateId, values);
        if (response.success) {
            notification.success({message: t("Sms Template updated successfully"), placement: "bottomRight"});
        } else {
            notifyErrors(response.error);
        }
        setSubmitting(false);
        setDirty(false);
    };

    const onSelectKeyword = (keyword, setFieldValue, field_name) => {
        let contentRef;
        console.log("Field name", field_name);
        if (field_name[1].includes("ar")) {
            contentRef = contentRefAr.current;
        } else {
            contentRef = contentRefEn.current;
        }
        console.log("Content ref", contentRef.value);
        console.log("Template form", templateForm.getFieldValue(field_name));
        const startPostion = contentRef.selectionStart;
        const endPostion = contentRef.selectionEnd;
        const newContent = contentRef.value.substring(0, startPostion) + `{{${keyword}}}` + contentRef.value.substring(endPostion);
        console.log("New content", newContent);
        setFieldValue(field_name, newContent);
        setTimeout(() => {
        contentRef.focus();
        contentRef.setSelectionRange(startPostion + keyword.length + 4, startPostion + keyword.length + 4);
        }, 0);
    };
    
    const setContentRefEn = (element) => {
        if (element) {
            contentRefEn.current = element.resizableTextArea.textArea;
        }
    };

    const setContentRefAr = (element) => {
        if (element) {
            contentRefAr.current = element.resizableTextArea.textArea;
        }
    };


    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.smsTemplates")}
                        </span>

                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("Edit Sms Template")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="pageTitle">
                                {template?.sms_template?.name}
                            </h2>
                            {
                                template?.seller_template_available && (
                                <WMButton
                                    type="primary"
                                    onClick={() => history.push(`${templateId}/sellers_templates`)}
                                >
                                    {t("Sellers Templates")}
                                </WMButton>
                                )
                            }
                        </div>
                                
                    </Col>
                </Row>
                <Row>
                    <div>
                        {
                            (loading || !template) && <div className="d-flex justify-content-center align-items-center w-100" style={{height: "50vh"}}> <Spin size="large" /> </div>
                        }
                        {
                            !loading && template?.sms_template &&  (
                        <div>

                           <AntForm
                            layout="vertical"
                            // className="grid-form small-gap"
                            onFinish={onFinish}
                            form={templateForm}
                            // onFinishFailed={onFinishFailed}
                            initialValues={template}
                            requiredMark
                            labelWrap
                            >
                                <Row>
                                    <Col sm="12" md="6" lg="4">
                                        <AntForm.Item
                                            name={["sms_template", "name"]}
                                            label={t("Name")}
                                            disabled
                                        >
                                            <Input
                                                type="text"
                                                disabled
                                                placeholder={t("Template Name")}
                                                // value={template?.sms_template?.name}
                                            />
                                        </AntForm.Item>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                        <AntForm.Item
                                            name="description"
                                            label={t("Description")}
                                            disabled
                                        >
                                            <Input
                                                type="text"
                                                disabled
                                                placeholder={t("Description")}
                                                // value={template?.description}
                                            />
                                        </AntForm.Item>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                        <AntForm.Item
                                            name="recipient_type"
                                            label={t("Recipient Type")}
                                            rules={[
                                                {
                                                required: true,
                                                message: t("Please select recipient type"),
                                                },
                                                // must be one of the RECIPIENT_TYPE
                                                {
                                                    validator: (_, value) => {
                                                        if (Object.values(RECIPIENT_TYPE).includes(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(t("Invalid recipient type"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder={t("Recipient Type")}
                                                onChange={(value) => handleChange({target: {name: "recipient_type", value}})}
                                                options={Object.values(RECIPIENT_TYPE).map((type) => ({value: type, label: t(type[0] + type.slice(1).toLowerCase())}))}
                                            />
                                        </AntForm.Item>
                                    </Col>
                                </Row>
                                <Collapse defaultActiveKey={["message_content", "message_content_ar"]}>
                                    <Panel header={t("Message Content (English)")} key="message_content">
                                        <div className="d-flex gap-4 justify-content-start align-items-center">
                                            <AntForm.Item
                                                className="flex-grow-1"
                                                name={["sms_template", "message_content"]}
                                                label={t("Message Content (English)")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("Please enter message content"),
                                                    },
                                                    {
                                                        max: 160,
                                                        message: t("Message content should not exceed 160 characters"),
                                                    }
                                                ]}
                                            >
                                                    <Input.TextArea
                                                        ref={setContentRefEn}
                                                        placeholder={t("Message Content (English)")}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />

                                            </AntForm.Item>
                                            <KeywordsSelector keywords={template.sms_template.keywords} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, ["sms_template", "message_content"])} />
                                        </div>
                                    </Panel>
                                    <Panel header={t("Message Content (Arabic)")} key="message_content_ar">
                                        <div className="d-flex gap-4 justify-content-start align-items-center">
                                            <AntForm.Item
                                                className="flex-grow-1"
                                                name={["sms_template", "message_content_ar"]}
                                                label={t("Message Content (Arabic)")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("Please enter message content"),
                                                    },
                                                    {
                                                        max: 160,
                                                        message: t("Message content should not exceed 70 characters"),
                                                    }
                                                ]}
                                            >
                                                    <Input.TextArea
                                                        ref={setContentRefAr}
                                                        placeholder={t("Message Content (Arabic)")}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                            </AntForm.Item>
                                            <KeywordsSelector keywords={template.sms_template.keywords} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, ["sms_template", "message_content_ar"])} />
                                        </div>
                                        
                                    </Panel>
                                </Collapse>
                            </AntForm>
                                <div className="w-100 d-flex justify-content-end py-4">
                                    <AntForm.Item>
                                        <Button htmlType="submit" style={{
                                            backgroundColor: "#57BE6C",
                                            color: "#fff",
                                            borderColor: "#57BE6C",
                                            }} type="primary" loading={submitting}
                                            disabled={submitting || !dirty}
                                            onClick={() => templateForm.submit()}
                                        >
                                            {t("Update Template")}
                                        </Button>
                                    </AntForm.Item>
                                </div>
                        </div>
                        )
                        }
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(SmsTemplateDetails, axiosInstance);
