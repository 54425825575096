import React from "react";
import styles from "./PricesStep.module.css";
import WMEditableTable from "../../WMEditableTable";

export default function PricesStep({}) {
    return (
        <div className={styles.container}>
            <WMEditableTable/>
        </div>
    )
}