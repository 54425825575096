import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addRunsheetOrders } from "../../../global-state/actions/rsOrdersAction";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function Settelment(props) {
    const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
    const { t } = useTranslation();
    const history = useHistory();
    const orders = useSelector((state) => state.rsOrders.orders);
    console.log({ orders });
    const price = orders?.map((order) =>
        props.match.params.id === "2"
            ? parseFloat(order.delivery_fees)
            : parseFloat(order.sub_total)
    );
    const ordersIDS = orders?.map((order) => order.orderid);
    const totalPrice = price?.reduce(reducer, 0);
    const seller = useSelector((state) => state.selectedData.selectedSeller);
    console.log({ seller });
    const { Dragger } = Upload;
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const [amount, setAmount] = useState("");
    const [show, setShow] = useState(false);
    const [file, setFile] = useState("");
    const options = {
        name: "file",
        multiple: false,
        beforeUpload: (file) => {
            setFile(file);
            console.log(file);
            return false;
        },
    };

    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };

    const handleInput = (e) => {
        setAmount(e.target.value);
    };

    const sendReq = async () => {
        console.log(ordersIDS);
        let data = {
            sender: props.match.params.id,
            seller: seller.seller_id,
            orders: ordersIDS,
            amount: amount === "" ? totalPrice : amount,
        };
        let formData = new FormData();
        formData.append("payment_proof", file);
        formData.append("sender", props.match.params.id);
        formData.append("seller", seller.seller_id);
        // formData.append("ticket", ticketsIDS);
        for (let x = 0; x < ordersIDS.length; x++) {
            formData.append("orders", ordersIDS[x]);
        }
        if (amount == "") {
            formData.append("amount", totalPrice);
        } else {
            formData.append("amount", amount);
        }

        let result = await axiosInstance.post(
            `/PaymentRequest/`,
            props.match.params.id === "1" ? formData : data
        );
        if (result) {
            console.log(result);
            setShow(true);
        }
    };
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="my-3">
                <Col sm="12" md="6">
                    <label className="bold-label">{t("sellername")}</label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        value={seller.seller_name}
                        disabled
                    />
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("storename")}</label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        value={seller.store}
                        disabled
                    />
                </Col>
            </Row>
            <Row className="mt-5 mb-2">
                <Col className="amountDisplay" sm="12">
                    <label className="totalAmount">
                        {t("totalAmountToSettlement")}
                    </label>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col sm="12" md="6">
                    <label className="totalPriceLabel my-2">
                        {t("totalOrderValues")}
                    </label>
                    <div>
                        <strong className="totalPrice">
                            {t(cur)}{" "}
                            <span className="priceSpan">
                                {totalPrice.toFixed(2)}
                            </span>
                        </strong>
                    </div>

                    {/* <Form.Control type="text" placeholder="" value={totalPrice.toFixed(3)} disabled /> */}
                </Col>
                <Col className="amountDisplay" sm="12" md="6">
                    <br />
                    <Form.Control
                        type="text"
                        className="amountToPay"
                        onChange={handleInput}
                        placeholder=""
                        value={amount}
                    />
                </Col>
            </Row>
            {props.match.params.id === "1" ? (
                <Row className="my-3">
                    <Col sm="12">
                        <label className="totalAmount">
                            {t("addManualAttachment")}
                        </label>
                        <Dragger {...options}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                {t("attachmentText")}
                            </p>
                        </Dragger>
                    </Col>
                </Row>
            ) : null}

            <Row className="mt-5">
                <Col className="amountDisplay" sm="12">
                    <button
                        disabled={file === "" && props.match.params.id === "1"}
                        className="confirm"
                        onClick={sendReq}
                    >
                        {t("submit")}
                    </button>
                </Col>
                <SuccessfulRequest
                    show={show}
                    hide={hide}
                    title={t("settleTicket")}
                    body={t("settleTicketBody")}
                />
            </Row>
        </>
    );
}

export default ErrorHandler(Settelment, axiosInstance);
