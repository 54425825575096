import React, { useState, useEffect } from 'react'
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { SuccessfulPayment } from '../modals/confirmationModal';
import { useLocation } from 'react-router-dom';

function PaymentSuccess() {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    const [show , setShow] = useState(true)

    return (
        <div>
            <SuccessfulPayment setShow={setShow} show={show} body={query.get("status")} />
        </div>
    )
}


export default ErrorHandler(PaymentSuccess , axiosInstance)
