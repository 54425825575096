import { Form, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../api/axios";
import useLanguage from "../../../../../hooks/useLanguage";
import WMButton from "../../../../../components/pages/AllWorkModels/WMButton";

export default function EditTier({ id, onClose }) {
    const { t } = useTranslation();
    const [areas, setAreas] = useState([]);
    const [tier, setTier] = useState({});
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const lang = useLanguage();
    useEffect(() => {
        axiosInstance
            .get("/FilterAreas/?page_size=10000")
            .then((areas) => setAreas(areas?.data?.data ?? []));
    }, []);

    useEffect(() => {
        if (typeof id === "undefined") return;
        axiosInstance.get(`/Tier/${id}`).then((tier) => {
            const t = tier?.data ?? {};
            setTier(t);
            form.setFieldValue("areas", t.tier_areas ?? []);
        });
    }, [id]);

    function onCloseModal() {
        form.setFieldValue("areas", []);
        setTier({});
        onClose();
    }

    async function onFinish(data) {
        setLoading(true);
        try {
            const res = await axiosInstance.put(`/Tier/${id}`, {
                ...tier,
                tier_areas: data.areas,
            });
            onCloseModal();
        } catch (e) {
            form.setFields([
                { name: "areas", errors: e.response?.data?.tier_areas },
            ]);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            onOk={onCloseModal}
            onCancel={onCloseModal}
            closeIcon={null}
            icon={null}
            footer={null}
            open={typeof id !== "undefined"}
        >
            <h4>{t("editTiersAreas")}</h4>
            <Form onFinish={onFinish} form={form} layout={"vertical"}>
                <Form.Item name={"areas"} label={t("areas")}>
                    <Select
                        mode="multiple"
                        style={{
                            overflow: "auto",
                            maxHeight: "200px",
                        }}
                    >
                        {areas.map((area) => (
                            <Select.Option value={area.id}>
                                {lang.includes("en") ||
                                area.name_ar?.length === 0
                                    ? area.name
                                    : area.name_ar}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "16px",
                    }}
                >
                    <WMButton
                        disabled={loading}
                        loading={loading}
                        htmlType="submit"
                    >
                        {t("save")}
                    </WMButton>
                </div>
            </Form>
        </Modal>
    );
}
