import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import loginImg from "../../images/loginImg.png";
import sellerLoginImg from "../../images/sellerLoginImg.png";
import logoSeller from "../../images/logoSeller.png";
import logoAdmin from "../../images/logoAdmin.png";
import logoAdminAr from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png";
import { connect, useSelector } from "react-redux";
import { userLogin, LogOut } from "../../global-state/actions/authAction";
import { bindActionCreators } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../style-sheets/login.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../components/contexts/LanguageContext";
import useLanguage from "../../hooks/useLanguage";

const Login = (props) => {
    const params = useParams();
    const isAuth = useSelector((state) => state.auth.isAuth);

    const history = useHistory();

    const { t, i18n } = useTranslation();

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setEmailerr] = useState("");
    const [passErr, setpassErr] = useState("");
    const [loading, setLoading] = useState(false);

    const language = useLanguage();
    const isAdmin = useMemo(
        () =>
            props.history.location.pathname === "/login" ||
            props.history.location.pathname === "/login/ar" ||
            props.history.location.pathname === "/login/en",

        [props.history.location.pathname]
    );

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (
            e.target.value.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            )
        ) {
            setEmail(e.target.value);
            setEmailerr("");
        } else {
            setEmailerr("Enter valid email!");
        }
    };
    const handlePass = (e) => {
        setPassword(e.target.value);
        // if (
        //   e.target.value.match(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
        //   )
        // ) {
        //   setPassword(e.target.value);
        //   setpassErr("");
        // } else {
        //   setpassErr(
        //     "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters"
        //   );
        // }
    };

    const submit = async (e) => {
        setLoading(true);

        let loginrequest = {};
        // const salt = bcrypt.genSaltSync(saltRounds);
        // const hash = bcrypt.hashSync(this.state.password, salt);
        loginrequest.email = email;
        loginrequest.password = password;

        const userData = await props.userLogin(loginrequest, props.history);
        // console.log(userData);
        if (userData) {
            console.log(userData);
            // this.setState({ BeErr: userData });
            setTimeout(() => {
                toast.error(userData);
            }, 500);
        }
        setLoading(false);
    };

    // useEffect(() => {
    //     if (isAuth) {
    //         history.push("/dashboard");
    //     }
    // }, []);
    const languageChecker = (englishCallback, arabicCallback) =>
        language.includes("en") ? englishCallback : arabicCallback;
    console.log({ directionLOG: languageChecker("ltr", "rtl"), language });

    return (
        <LanguageContext.Provider value={{ language }}>
            <div
                className="p-3 login-header"
                dir={languageChecker("ltr", "rtl")}
            >
                {isAdmin ? (
                    <img
                        src={languageChecker(logoAdmin, logoAdminAr)}
                        className="img-fluid"
                    />
                ) : (
                    <img
                        src={languageChecker(logoSeller, logoSellerAr)}
                        className="img-fluid"
                    />
                )}

                <button
                    onClick={() => {
                        const newLang = languageChecker("ar", "en");
                        i18n.changeLanguage(newLang);
                        // window.location.reload();
                    }}
                >
                    {languageChecker("ع", "EN")}
                </button>
            </div>

            <Container className="pageContainer">
                <Row>
                    <Col md="6" sm="12">
                        <img
                            src={isAdmin ? loginImg : sellerLoginImg}
                            className="img-fluid"
                        />
                    </Col>
                    <Col md="1" sm="12"></Col>
                    <Col className="mt-5 " md="4" sm="12">
                        <h3 className="title">{t("welcome")}</h3>
                        <p className="subTitle">{t("welcomeSubtitle")}</p>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            //   onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("inputEmailErr")}`,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <MailOutlined className="site-form-item-icon" />
                                    }
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </Form.Item>
                            <p className="error">{emailErr}</p>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("inputPasswordErr")}`,
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                    type="password"
                                    placeholder={t("password")}
                                    name="password"
                                    value={password}
                                    onChange={handlePass}
                                />
                            </Form.Item>
                            <p className="error">{passErr}</p>

                            <Form.Item className="forgot">
                                {isAdmin ? (
                                    <a
                                        className="login-form-forgot"
                                        href="/admin_forget_password"
                                    >
                                        {t("forgotPassword")}
                                    </a>
                                ) : (
                                    <a
                                        className="login-form-forgot"
                                        href="/forget_password"
                                    >
                                        {t("forgotPassword")}
                                    </a>
                                )}
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={`login-form-button ${
                                        isAdmin ? "adminBtn" : "sellerBtn"
                                    }`}
                                    disabled={loading}
                                    onClick={submit}
                                >
                                    {t("login")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <ToastContainer position="bottom-right" />
            </Container>
        </LanguageContext.Provider>
    );
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ userLogin, LogOut }, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
