import React, {useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";

export default function ChangeAttrsModal({
                                             cities,
                                             attr,
                                             onChange,
                                             onClose,
                                             show,
                                         }) {
    const {t} = useTranslation();
    const lang = useLanguage();
    const direction = useMemo(() => lang === "ar" ? "rtl" : "ltr", [lang]);
    const [selectedCity, setSelectedCity] = useState("0");
    const [changeType, setChangeType] = useState(0);
    const [value, setValue] = useState();
    const [changeValueType, setChangeValueType] = useState(0);
    const onApplyChange = () => {
        onChange(attr, value, selectedCity, changeType, changeValueType);

        setSelectedCity("0");
        setValue(undefined);
        setChangeType(0);
        setChangeValueType(0);

    };

    const onChangeCity = (e) => {
        setSelectedCity(e.target.value);
    };

    const onHideModal = () => {
        setSelectedCity("0");
        setValue(undefined);
        setChangeType(0);
        onClose();
    };
    return (
        <>
            <style>
                {`
                .radio-select label,
                .radio-select input {
                    font-size: 1.1em ;
                }
                .radio-select input {
                    border-color: #2C6ECB;
                }
                .radio-select .form-check-input:checked {
                    border-color: #2C6ECB;
                    background-color: #2C6ECB;
                }
                .apply-btn,
                .apply-btn:disabled
                 {
                    background-color: #008060;
                    border-color: #015c46;
                }
                .apply-btn:hover {
                    background-color: #008060;
                    border-color: #015c46;
                    opacity: 0.9;
                }
            `}
            </style>
            {show ? (
                <div
                    className="modal show"
                    style={{display: "block", position: "initial"}}
                >
                    <Modal show={show} onHide={onHideModal}
                           dir={direction}
                    >
                        <Modal.Header>
                            <Modal.Title style={{width: "100%"}}>
                                {t("work_model.applyOneValueOnAllAreas")}
                                <Form.Select
                                    dir={direction}

                                    onChange={onChangeCity}
                                    size="lg"
                                    className="mt-3 mb-1"
                                    value={selectedCity}
                                >
                                    <option disabled value="0">
                                        {t("city")}
                                    </option>
                                    {cities.map((city) => {
                                        return (
                                            <option value={city.id}>
                                                {lang === "ar" ? city.name_ar ?? city.name : city.name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="px-1 my-2">
                                {[t("fixed"), t("increase"), t("decrease")].map(
                                    (type, i) => {
                                        return (
                                            <Form.Check
                                                className="radio-select"
                                                inline
                                                value={i}
                                                label={type}
                                                name="changeType"
                                                type={"radio"}
                                                id={`${type}--${i}`}
                                                onClick={(e) =>
                                                    setChangeType(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        );
                                    }
                                )}
                            </div>
                            <div className="row-centered-vertical" style={{
                                gap: "8px"
                            }}>
                                <Form.Control
                                    onChange={(e) => setValue(e.target.value)}
                                    className="my-3"
                                    size="lg"
                                    type="number"
                                    min={1}
                                    id="value"
                                    placeholder={t("value")}
                                    aria-describedby="Change Value"
                                />
                                {
                                    changeType > 0 &&
                                    <Form.Select className="my-3"
                                                 style={{
                                                     maxWidth: "30%"
                                                 }}
                                                 value={changeValueType}
                                                 onChange={(e) => setChangeValueType(e.target.value)}
                                    >
                                        {[t("by_value"), t("by_percentage")].map((it, i) => <option key={i}
                                                                                              value={i}>{it}</option>)}
                                    </Form.Select>
                                }
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={onHideModal} variant="light">
                                {t("close")}
                            </Button>
                            <Button
                                disabled={
                                    selectedCity === "0" ||
                                    changeType === 0 ||
                                    !value
                                }
                                onClick={onApplyChange}
                                className="apply-btn"
                                variant="primary"
                            >
                                {t("apply")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            ) : null}
        </>
    );
}
