import useLanguage from "../hooks/useLanguage";
import React from "react";
import { ReactComponent as SeeMoreIcon } from "../images/see_more_arrow.svg";

export default function SeeMoreButton() {
    const lang = useLanguage();
    return (
        <div style={{ whiteSpace: "nowrap" }}>
            {lang === "ar" ? (
                <span>
                    اعرف اكتر{" "}
                    <span style={{ rotate: "180deg" }}>
                        <SeeMoreIcon />
                    </span>
                </span>
            ) : (
                <span>
                    See More <SeeMoreIcon />
                </span>
            )}
        </div>
    );
}
