import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import StatusCard from "../../layout/StatusCard";
import { CircleRightMajor, ViewMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxBranch } from "../../../global-state/actions/branchAction";
import { getSeller } from "../../../global-state/actions/getSelectedAction";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { addRunsheetOrders } from "../../../global-state/actions/rsOrdersAction";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../tables/TablePro";

function RecievedRequests(props) {
    const dispatch = useDispatch();
    const sellerDispatch = useDispatch();
    const dispatchOrders = useDispatch();

    const { t } = useTranslation();

    const [selected, setSelected] = useState("Sent");
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [payment, setPayment] = useState("");
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const [statesNumbers, setStatesNumbers] = useState({ 1: 0, 2: 0, 3: 0 });
    const prevSelectionModel = useRef(selectionModel);
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const hide = () => {
        setShow(false);
    };

    const hideSuccess = async () => {
        setShowSuccess(false);
        selectState(currentState);
        getSummary();
    };
    const [columns, setColumns] = useState([
        {
            field: "payment_id",
            title: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row..id,
            render: (_, row) => <p className="paragraphMargin">#{row.id}</p>,
        },
        {
            field: "id",
            title: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
            ),
        },
        {
            field: "name",
            title: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            title: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
            ),
        },
        {
            field: "total_amount",
            title: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            title: `${t("createDate")}`,
            width: 190,
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            title: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <Link
                    onClick={() => {
                        dispatch(reduxBranch(row.seller.user.name));
                        sellerDispatch(getSeller(row.seller));
                        dispatchOrders(addRunsheetOrders(row.orders));
                    }}
                    to={`/view_orders`}
                    className="sellers-wallets"
                >
                    <Icon source={CircleRightMajor} color="base" />
                </Link>
            ),
        },
    ]);

    const [column1, setColumn1] = useState([
        {
            field: "payment_id",
            title: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row..id,
            render: (_, row) => <p className="paragraphMargin">#{row.id}</p>,
        },
        {
            field: "id",
            title: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
            ),
        },
        {
            field: "name",
            title: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            title: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
            ),
        },
        {
            field: "total_amount",
            title: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            title: `${t("createDate")}`,
            width: 190,
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            title: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row..id,
            render: (_, row) => (
                <div
                    onClick={() => {
                        setShow(true);
                        setPayment(row);
                    }}
                    to={`/sellers_orders/${row.id}`}
                    className="sellers-wallets"
                >
                    <Icon source={ViewMajor} color="base" />
                </div>
            ),
        },
    ]);
    const lang = useLanguage();

    const [currentState, setCurrentState] = useState({
        id: "2",
        type: ` ${lang == "ar" ? "المرسلة" : "Sent"}`,
        number: statesNumbers["2"],
        styleClass: "statusCard",
        background: "#E0F3E8",
        style: { borderLeft: `solid 20px #BFFFDA` },
    });
    const cards = [
        {
            id: "2",
            type: ` ${lang == "ar" ? "المرسلة" : "Sent"}`,
            number: statesNumbers["2"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #BFFFDA` },
        },
        {
            id: "1",
            type: ` ${lang == "ar" ? "استجابة" : "Response"}`,
            number: statesNumbers["1"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #57BE6C` },
        },
        {
            id: "3",
            type: ` ${lang == "ar" ? "تجميع" : "Collected"}`,
            number: statesNumbers["3"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #148060` },
        },
    ];

    const getSummary = async () => {
        let result = await axiosInstance.get(`/PaymentSummary/?sender=${2}`);

        if (result) {
            console.log(result.data);
            setStatesNumbers(result.data);
        }
    };

    const rejectPayment = async () => {
        let result = await axiosInstance.post(
            `/AcceptPaymentRequest/${payment.id}`,
            { verdict: "False" }
        );

        if (result) {
            setShow(false);
            getSummary();
            selectState(currentState);
        }
    };
    const confirmPayment = async () => {
        let result = await axiosInstance.post(
            `/AcceptPaymentRequest/${payment.id}`,
            { verdict: "True" }
        );
        if (result) {
            setShow(false);
            setShowSuccess(true);
        }
    };

    const selectState = async (
        state = {
            id: "2",
            type: "Sent",
            number: statesNumbers["2"],
            styleClass: "statusCard",
            selectedCard: "Sent",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #BFFFDA` },
        }
    ) => {
        setCurrentState(state);
    };

    async function fetchState() {
        const state = currentState;
        let result;

        result = await axiosInstance.get(`/FilterPaymentRequest/`, {
            params: {
                state: state.id,
                sender: 2,
                page,
            },
        });
        if (result) {
            setData(result.data.data);
            console.log(result.data);
            setCount(result.data.count);
        }
        setSelected(state.type);
    }

    useEffect(() => {
        selectState();
        getSummary();
    }, []);
    // const lang = localStorage.getItem("lang");
    useEffect(() => {
        fetchState();
    }, [page, currentState]);
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="cardsMargin">
                {cards.map((card, index) => {
                    return (
                        <Col
                            sm="12"
                            md="4"
                            onClick={() => selectState(card, cards[index + 1])}
                        >
                            <StatusCard
                                key={card.id}
                                selectedCard={selected}
                                type={card.type}
                                number={card.number}
                                styleClass={"statusCard"}
                                background={card.background}
                                style={card.style}
                            />
                        </Col>
                    );
                })}
            </Row>
            <TablePro
                title={<h6>{t("sentRequest")}</h6>}
                dataSource={data}
                columns={currentState.id == "3" ? columns : column1}
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
            />

            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("invoiceAttached")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        style={{ width: "100%" }}
                        src={payment.payment_proof}
                        alt={t("paymentProof")}
                    />
                </Modal.Body>
                {currentState.id === "4" ? null : (
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("cancel")}
                        </button>
                        <button onClick={rejectPayment}>{t("reject")}</button>
                        <button
                            // onClick={}
                            className="confirm"
                            onClick={confirmPayment}
                        >
                            {t("collected")}
                        </button>
                    </Modal.Footer>
                )}
            </Modal>
            <SuccessfulRequest
                title={t("collectInvoice")}
                body={t("collectInvoiceSuccess")}
                hide={hideSuccess}
                show={showSuccess}
            />
        </>
    );
}

export default ErrorHandler(RecievedRequests, axiosInstance);
