import React from "react";
import styles from "./StepsNavigator.module.css";
import useQuery from "../../../../../hooks/useQuery";
import BranchStep from "../BranchStep";
import WorkDirectionStep from "../WorkDirectionStep";
import WeightStep from "../WeightStep";
import PricesStep from "../PricesStep";
import CitiesStep from "../CitiesStep/CitiesStep";

export default function StepsNavigator({}) {
    const { query } = useQuery();
    if (query.branch === undefined) {
        return <BranchStep />;
    } else if (
        query.model_type === undefined ||
        (+query.model_type !== 1 && +query.model_type !== 2)
    ) {
        return <WorkDirectionStep />;
    } else if (+query.model_type === 1 && query.default_weight === undefined) {
        return <WeightStep />;
    } else if (query.cities === undefined) {
        return <CitiesStep />
    } else return <PricesStep />;
}