import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import TablePro from "../tables/TablePro";
import axiosInstance from "../../api/axios";
import { useCourierColumns } from "./tableColumns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SuccessfulRequest, UnSuccessfulRequest } from "../modals/confirmationModal";
import { Button, Spin } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function RunSheetCouriers() {
    const { t } = useTranslation();
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingChange, setLoadingChange] = useState(false);
    const [loadingCourier, setLoadingCourier] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modaltype, setModalType] = useState("success");
    const [modalData, setModalData] = useState({
        title: "",
        body: "",
        success: false,
    });
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const history = useHistory();
    const { id } = useParams();
    const columns = useCourierColumns();
    const [runsheet, setRunsheet] = useState({});

    const handleModalClose = (nav) => {
        setShowModal(false);
        setModalData({
            title: "",
            body: "",
            success: false,
        });
        if (nav) {
            history.push(nav);
        }
    }

    const getRunSheet = async () => {
        setLoading(true);
        let result = await axiosInstance.get(`/InternalRunsheet/${id}`).catch(e => console.log({ e }));
        if (result) {
            setRunsheet(result.data);
        }
        setLoading(false);
    }

    const getCouriers = async () => {
        setLoadingCourier(true);
        let result = await axiosInstance.get("/FilterCourier/", {
            params: {
                page,
                search: searchTerm,
                branch__in: `${runsheet?.branch?.id},${runsheet?.to_branch?.id}`,
                available: "available"
            },
        }).catch(e => console.log({ e }));
        if (result) {
            setCouriers(result.data.data);
            setCount(result.data.count);
        }
        setLoadingCourier(false);
    }

    useEffect(() => {
        getRunSheet();
    }, []);

    useEffect(() => {
        getCouriers();
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            getCouriers();
        }
    }, [searchTerm, runsheet]);

    const handleSelect = (courier) => {
        setSelectedRow(courier.length > 0 ? courier[0] : null);
    }
    const assignCourier = async (courier) => {
        setLoadingChange(true);
        let result = await axiosInstance.post(`/ReassignInternalRunsheet/${id}`, {
            courier,
        }).catch(e => {
            setModalType("error");
            if (e.response.status === 400) {
                setModalData({
                    title: t("Reassign Runsheet"),
                    body: t(e.response.data),
                    success: false,
                });
            } else {
                setModalData({
                    title: t("Reassign Runsheet"),
                    body: t("server_error"),
                    success: false,
                });
            }
        }
        );
        if (result) {
            setModalType("success");
            setModalData({
                title: t("Reassign Runsheet"),
                body: t("Reassign Runsheet Success"),
                success: true,
            });
            getRunSheet();
        }
        setLoadingChange(false);
        setShowModal(true);
    }

    return (
        <Container>
            <SuccessfulRequest
                show={modaltype === "success" && showModal}
                hide={() => handleModalClose(`/all_internals`)}
                {...modalData}
            />
            <UnSuccessfulRequest
                show={modaltype === "error" && showModal}
                hide={handleModalClose}
                {...modalData}
            />

            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.internalRunSheet")}</span>

                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("Reassign Runsheet")}
                    </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("Change Runsheet Courier")}</h2>
                </Col>
            </Row>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3 position-relative">
                <div className="w-100">
                    <TablePro
                        loading={loading || loadingCourier}
                        dataSource={couriers}
                        columns={columns}
                        onSearch={setSearchTerm}
                        pagination={{
                            size: "medium",
                            current: page,
                            onChange: setPage,
                            total: count,
                        }}
                        rowKey={(row) => row.id}
                        selection="radio"
                        setSelectedRows={handleSelect}
                    />
                </div>
                <div className="d-flex justify-content-end align-items-center gap-2 w-100 row-reverse">
                    <Button
                        onClick={() => history.goBack()}
                        ghost
                        danger
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => assignCourier(selectedRow)}
                        loading={loadingChange}
                        disabled={!selectedRow}
                        style={{ color: "#fff", backgroundColor: "#2CCF41" }}
                    >
                        {t("Reassign Runsheet")}
                    </Button>
                </div>
            </div>
        </Container>
    );
}

export default ErrorHandler(RunSheetCouriers, axiosInstance);


                

