import ErrorHandler from "../../../error-handler/ErrorHandler";
import {Form, Input, Spin, Button, Row, Col} from "antd";
import { useState, useEffect } from "react";
import axiosInstance from "../../../api/axios";
import { useTranslation } from "react-i18next";
import PageHeader from "../../layout/PageHeader";
import { ToastContainer, toast } from "react-toastify";

const WebhooksPage = () => {
    const { t } = useTranslation();
    const [webhook, setWebhook] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);

    const getWebhooks = async () => {
        try {
            setFetching(true);
            const response = await axiosInstance.get("/Webhook/me")
            .catch((err) => {
                if (err.response.status === 400) {
                    Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                } else {
                    throw err;
                }
            });
            setWebhook(response.data);
            form.setFieldsValue(response.data);
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        } finally {
            setFetching(false);
        }
    }

    const updateWebhooks = async (values) => {
        try {
            setLoading(true);
            const response = await axiosInstance.put("/Webhook/me", values)
            .catch((err) => {
                if (err.response.status === 400) {
                    Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                } else {
                    throw err;
                }
            });
            setWebhook(response.data);
            toast.success(t("Webhooks updated successfully"));
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        } finally {
            setLoading(false);
        }
        setIsFormDirty(false);
    }

    useEffect(() => {
        getWebhooks();
    } , []);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{zIndex: 999999}}
                />
            <div>
                <PageHeader title={t("My Webhooks")} group={t("Third party integrations")} />
            </div>
            <div className="d-flex flex-column align-items-start justify-content-start gap-3 w-100">
                {
                    (fetching || !webhook) && 
                    <div className="d-flex align-items-center justify-content-center w-100" style={{minHeight: "50vh"}}>
                        <Spin size="large" />
                    </div>
                }
                {
                    webhook && webhook.actions.length > 0 &&
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={updateWebhooks}
                        initialValues={webhook}
                        className="w-100"
                    >
                        <Form.Item
                            name="url"
                            label={t("URL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("Please input your URL"),
                                },
                                {
                                    type: "url",
                                    message: t("Please input a valid URL"),
                                }
                            ]}
                        >
                            <Input onChange={(e) => form.setFieldValue("url", e.target.value)} />
                        </Form.Item>
                        <Form.List name="actions">
                            {(fields, { add, remove }) => (
                                <Row gutter={[16, 16]}>
                                {console.log("FFFFFF", fields)}
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Col span={24} lg={12} key={key}>
                                        <div key={key} className="d-flex flex-column align-items-start justify-content-start gap-2 w-100 h-100 p-3 border border-success rounded">
                                            <Form.Item
                                                {...restField}
                                                key={key}
                                                label={form.getFieldValue("actions")?.[name]?.webhook_action.name}
                                                name={[name, "enabled"]}
                                                rules={[{ required: true, message: t("Missing action") }]}
                                                
                                            >
                                                <Input type="checkbox" 
                                                defaultChecked={form.getFieldValue("actions")?.[name]?.enabled}
                                                onChange={(e) => {
                                                    const oldActions = form.getFieldValue("actions");
                                                    oldActions[name].enabled = e.target.checked;
                                                    if (!e.target.checked) {
                                                        oldActions[name].headers = [];
                                                    }
                                                    form.setFieldValue("actions", oldActions);
                                                    setIsFormDirty(true);

                                                }} />
                                            </Form.Item>
                                            <Form.List name={[name, "headers"]}>
                                                {(headersFields, { add: addHeader, remove: removeHeader }) => (
                                                    <>
                                                        {headersFields.map(({ key: headerKey, name: headerName, ...restHeaderField }) => (
                                                            <div key={headerKey} className="d-flex flex-row align-items-end justify-content-start gap-3">
                                                                <Form.Item
                                                                    {...restHeaderField}
                                                                    key={headerKey}
                                                                    label={t("Key")}
                                                                    name={[headerName, "key"]}
                                                                    rules={[{ required: true, message: t("Missing key") }]}
                                                                >
                                                                    <Input onChange={(e) => {
                                                                        const oldActions = form.getFieldValue("actions");
                                                                        oldActions[name].headers[headerName].key = e.target.value;
                                                                        form.setFieldValue("actions", oldActions);
                                                                        setIsFormDirty(true);
                                                                    }} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restHeaderField}
                                                                    key={headerKey}
                                                                    label={t("Value")}
                                                                    name={[headerName, "value"]}
                                                                    rules={[{ required: true, message: t("Missing value") }]}
                                                                >
                                                                    <Input onChange={(e) => {
                                                                        const oldActions = form.getFieldValue("actions");
                                                                        oldActions[name].headers[headerName].value = e.target.value;
                                                                        form.setFieldValue("actions", oldActions);
                                                                        setIsFormDirty(true);
                                                                    }} />
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Button onClick={() => {
                                                                        removeHeader(headerKey);
                                                                        setIsFormDirty(true);
                                                                    }}>
                                                                        <i className="fas fa-trash text-danger"></i>
                                                                    </Button>
                                                                </Form.Item>
                                                            </div>
                                                        ))}
                                                        <Form.Item>
                                                            {console.log("Field" + name, form.getFieldsValue())}
                                                            <Button block onClick={() => addHeader()} disabled={headersFields.length >= 10 || !form.getFieldValue("actions")?.[name]?.enabled}>
                                                                <i className="fas fa-plus"></i> {t("Add header")}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </Form.List>
                        <Form.Item
                            className="mt-3"
                        >
                            <div className="d-flex flex-row align-items-center justify-content-end gap-3 w-100">
                                <Button type="primary" htmlType="submit" loading={loading} disabled={loading || !isFormDirty}  className="ms-auto"
                                style={{backgroundColor: "#5cb85c", borderColor: "#5cb85c"}}
                                >
                                    {t("Update")}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                }
            </div>
        </div>
    );
}

export default ErrorHandler(WebhooksPage, axiosInstance);


