import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../style-sheets/form.css";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useSelector } from "react-redux";
import UserTable from "./UserTable";
import {
    managerCol,
    sellerCol,
    courierCol,
    managerCol_ar,
    sellerCol_ar,
    courierCol_ar,
    useOrgEmployeesColumns,
    useSellerColumns,
    useCourierColumns,
    useThirdPartyColumns,
} from "../../layout/tableColumns";
import { useTranslation } from "react-i18next";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const AccountsTabs = () => {
    const [value, setValue] = useState(0);
    const [showCreate, setShowCreate] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [returnedObj, setReturnedTicketsObj] = useState("");
    const { t } = useTranslation();

    const [allFilters, setAllFilters] = useState({
        branch: {
            title: t("branch"),
            selectedValue: undefined,
            values: [],
        },
        work_model: {
            title: t("sideMenu.workModels"),
            selectedValue: undefined,
            values: [],
        },
        type: {
            title: t("userType"),
            selectedValue: undefined,
            values: [
                {
                    id: 2,
                    name: t("operation"),
                },
                {
                    id: 4,
                    name: t("accountant"),
                },
                {
                    id: 5,
                    name: t("inventoryManager"),
                },
                {
                    id: 6,
                    name: t("operationTeamMember"),
                },
            ],
        },
    });

    console.log({ allFilters });
    const getBranches = async () => {
        const result = await axiosInstance.get("/Branch/?page_size=100000");
        if (result) return result.data.data;
        else return [];
    };

    const getWorkModels = async () => {
        const result = await axiosInstance.get(
            "/NewWorkModel/?page_size=10000"
        );
        if (result) return result.data.data;
        else return [];
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const user = useSelector((state) => state.auth.user);

    useEffect(async () => {
        const branches = await getBranches();
        const work_model = await getWorkModels();
        setAllFilters({
            ...allFilters,
            branch: { ...allFilters.branch, values: branches },
            work_model: { ...allFilters.work_model, values: work_model },
        });
    }, []);

    function onFilterChange(key, value) {
        console.log({ key, value });
        setAllFilters({
            ...allFilters,
            [key]: { ...allFilters[key], selectedValue: value },
        });
    }

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.accounts")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.allAccounts")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.allAccounts")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className={`${
                            user.role == "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("orgUsers")} {...a11yProps(0)} />
                        <Tab label={t("seller")} {...a11yProps(1)} />
                        <Tab label={t("courier")} {...a11yProps(2)} />
                        <Tab label={t("thirdParty")} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <UserTable
                        columns={useOrgEmployeesColumns()}
                        userType="manager"
                        searchText={t("managerSearch")}
                        fetchEndpoint={"/FilterOrgEmployee/"}
                        deleteEndpoint={"/OrgEmployee/"}
                        filters={{
                            branch: allFilters.branch,
                            type: allFilters.type,
                        }}
                        onFilterChange={onFilterChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <UserTable
                        columns={useSellerColumns()}
                        userType="seller"
                        searchText={t("sellerSearch")}
                        fetchEndpoint={"/FilterSeller/"}
                        deleteEndpoint={"/Seller/"}


                        onFilterChange={onFilterChange}
                    />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <UserTable
                        columns={useCourierColumns()}
                        userType="courier"
                        searchText={t("courierSearch")}
                        fetchEndpoint={"/FilterCourier/"}
                        deleteEndpoint={"/Courier/"}
                        filters={{
                            branch: allFilters.branch,
                        }}
                        onFilterChange={onFilterChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <UserTable
                        columns={useThirdPartyColumns()}
                        userType="thirdParty"
                        searchText={t("thirdPartySearch")}
                        fetchEndpoint={"/FilterThirdPartyCompany/"}
                        deleteEndpoint={"/ThirdPartyCompany/"}
                        onFilterChange={onFilterChange}
                    />
                </TabPanel>
            </Box>
        </div>
    );
};

export default ErrorHandler(AccountsTabs, axiosInstance);
