import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";
import SelectAreas from "../../select-search/AreasSelect";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";

export default function SellerEditAddresses({
    seller,
    setSeller,
    isFetched,
    errors,
    setErrors,
}) {
    const { t } = useTranslation();
    const addresses = seller.seller_addresses;
    const [branches, setBranches] = useState([]);

    const fetchBranches = async () => {
        const res = await axiosInstance.get(`/Branch/`);
        setBranches(res.data?.data);
    };

    useEffect(() => {
        fetchBranches();
    }, []);

    const onChange = (index, name, value) => {
        if (errors["seller_addresses"]?.[index]?.[name]) {
            const errorsCopy = { ...errors };
            delete errorsCopy["seller_addresses"][index][name];
            setErrors(errorsCopy);
        }
        const sellerCopy = { ...seller };
        if (
            sellerCopy.seller_addresses[index].area &&
            sellerCopy.seller_addresses[index].branch &&
            sellerCopy.seller_addresses[index].address
        ) {
            sellerCopy.seller_addresses[index].isNew = false;
        }
        sellerCopy.seller_addresses[index][name] = value;
        setSeller(sellerCopy);
    };

    const onAddNewAddress = () => {
        const sellerCopy = { ...seller };
        sellerCopy.seller_addresses.push({
            branch: "",
            area: "",
            address: "",
            isNew: true,
        });
        setSeller(sellerCopy);
    };

    const onRemoveAddress = (index) => {
        const sellerCopy = { ...seller };
        sellerCopy.seller_addresses.splice(index, 1);
        setSeller(sellerCopy);
    };
    return (
        <div>
            <h4>{t("sellerAddress")}</h4>
            <div>
                {addresses?.map((address, index) => {
                    return (
                        <div
                            style={
                                index === addresses.length - 1
                                    ? {}
                                    : {
                                          paddingBottom: "24px",
                                          marginBottom: "24px",
                                          borderBottom: "1px solid #ccc",
                                      }
                            }
                        >
                            <div
                                style={{
                                    display:
                                        addresses.length < 2 ? "none" : "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <button
                                    className="clear-circular-button "
                                    onClick={() => onRemoveAddress(index)}
                                >
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                            <div className="grid-form" key={index}>
                                <div>
                                    <label>{t("branch")}</label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        type="text"
                                        name={"branch"}
                                        value={address.branch}
                                        onChange={(value) =>
                                            onChange(index, "branch", value)
                                        }
                                    >
                                        {branches.map((branch) => (
                                            <Select.Option
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    {errors["seller_addresses"]?.[index]?.[
                                        "branch"
                                    ] ? (
                                        <p className="error">
                                            {
                                                errors["seller_addresses"]?.[
                                                    index
                                                ]?.["branch"][0]
                                            }
                                        </p>
                                    ) : (
                                        isFetched &&
                                        !address.isNew &&
                                        !address.branch && (
                                            <p className="error">
                                                {t("requiredField")}
                                            </p>
                                        )
                                    )}
                                </div>
                                <div>
                                    <label>{t("area")}</label>
                                    <SelectAreas
                                        onChange={(value) =>
                                            onChange(index, "area", value)
                                        }
                                        value={address.area}
                                        currentArea={address.area}
                                    />
                                    {errors["seller_addresses"]?.[index]?.[
                                        "area"
                                    ] ? (
                                        <p className="error">
                                            {
                                                errors["seller_addresses"]?.[
                                                    index
                                                ]?.["area"][0]
                                            }
                                        </p>
                                    ) : (
                                        isFetched &&
                                        !address.isNew &&
                                        !address.area && (
                                            <p className="error">
                                                {t("requiredField")}
                                            </p>
                                        )
                                    )}
                                </div>
                                <div>
                                    <label>{t("address")}</label>
                                    <Input
                                        type="text"
                                        name={"address"}
                                        value={address.address}
                                        onChange={(e) =>
                                            onChange(
                                                index,
                                                "address",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {errors["seller_addresses"]?.[index]?.[
                                        "address"
                                    ] ? (
                                        <p className="error">
                                            {
                                                errors["seller_addresses"]?.[
                                                    index
                                                ]?.["address"][0]
                                            }
                                        </p>
                                    ) : (
                                        isFetched &&
                                        !address.isNew &&
                                        !address.address && (
                                            <p className="error">
                                                {t("requiredField")}
                                            </p>
                                        )
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "16px",
                                    }}
                                >
                                    <label>{t("approved")}</label>
                                    <Input
                                        type="checkbox"
                                        name={"approved"}
                                        style={{ width: "fit-content" }}
                                        checked={address.approved ?? false}
                                        onChange={(e) =>
                                            onChange(
                                                index,
                                                "approved",
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}

                <div
                    className="confirm mt-3 text-center"
                    role="button"
                    style={{
                        width: "fit-content",
                    }}
                    onClick={onAddNewAddress}
                >
                    <i className="fas fa-plus-circle"></i> {t("addAddress")}
                </div>
            </div>
        </div>
    );
}
