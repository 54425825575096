import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../layout/PageHeader";
import SellerEditPersonalInfo from "./seller-edit-personal-info";
import { useParams } from "react-router-dom";
import SellerEditAddresses from "./seller-edit-adresses";
import SellerEditPricing from "./seller-edit-pricing";
import WMButton from "../AllWorkModels/WMButton";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import useLanguage from "../../../hooks/useLanguage";
import {Button, Badge, Card} from "antd";
import { toast, ToastContainer } from "react-toastify";
import {ThirdPartyKeys} from "../ThirdPartyInfo";

function SellerAccountInfo() {
    const { t } = useTranslation();
    const [seller, setSeller] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFetched, setIsFetched] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);
    const id = useParams().id;
    const lang = useLanguage();

    async function fetchSeller() {
        const res = await axiosInstance.get(`/Seller/${id}`);
        setSeller(res.data);
        setIsFetched(true);
    }

    async function updateSeller() {
        try {
            setLoading(true);
            await axiosInstance.put(`/Seller/${id}`, seller);
            setSuccess(true);
        } catch (e) {
            const flattenObject = (obj, prefix = "") =>
                Object.keys(obj).reduce((acc, k) => {
                    const pre = prefix.length ? prefix + "." : "";
                    if (typeof obj[k] === "object" && !Array.isArray(obj[k]))
                        Object.assign(acc, flattenObject(obj[k], pre + k));
                    else acc[k] = obj[k];
                    return acc;
                }, {});
            setErrors(flattenObject(e.response.data ?? {}));
        } finally {
            setLoading(false);
        }
    }

    const activateThirdParty = async () => {
        try {
            setLoadingActivate(true);
            const res = await axiosInstance.post(`/CreateThirdPartyForSeller/${id}`)
            .catch((err) => {
                if (err.response.status === 400) {
                    Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                } else {
                    toast.error("Something went wrong");
                }
            });
            setSeller(res.data);
            toast.success("Third party activated successfully");
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingActivate(false);
        }
    };

    useEffect(() => {
        fetchSeller();
    }, []);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{zIndex: 999999}}
                />
            <div className="d-flex justify-content-between align-items-start flex-wrap">
                <PageHeader
                    title={t("personalInfo")}
                    group={t("sideMenu.accounts")}
                />
                {
                    !seller?.third_party ? (
                    <Button
                    type="ghost"
                    size="large"
                    danger
                    style={{
                        backgroundColor: "transparent",
                    }}
                        onClick={activateThirdParty}
                        loading={loadingActivate}
                        disabled={loadingActivate}
                    >
                        {t("Activate Third Party")}
                    </Button>
                    ) : <Badge.Ribbon text={t("Active")} color="#57BE6C">
                    <div
                        className="bg-white rounded"
                        style={{
                            padding: "1.75rem 0.75rem 0.75rem",
                        }}
                        >
                            {t("Third Party Activated")}
                        </div>

                    </Badge.Ribbon>



                }
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "64px",
                }}
            >
                {Object.keys(errors).length > 0 && (
                    <p className="error">
                        {lang.includes("en")
                            ? "Please solve the errors below"
                            : "من فضلك قم بحل الاخطاء التالية"}
                    </p>
                )}
                <SellerEditPersonalInfo
                    isFetched={isFetched}
                    seller={seller}
                    setSeller={setSeller}
                    errors={errors}
                    setErrors={setErrors}
                />
                {
                    seller?.third_party && (
                    <div className="w-100">
                        <h4>
                            {t("Third Party Keys")}
                        </h4>
                        <ThirdPartyKeys keys={seller.third_party} setKeys={({api_key, hmac_secret}) => {
                            setSeller({
                                ...seller,
                                third_party: {
                                    ...seller.third_party,
                                    api_key: api_key ?? seller.third_party.api_key,
                                    hmac_secret: hmac_secret ?? seller.third_party.hmac_secret,
                            }});
                        }} thirdPartyId={seller.third_party?.id}

                        />
                    </div>
                    )
                }
                <SellerEditAddresses
                    isFetched={isFetched}
                    seller={seller}
                    setSeller={setSeller}
                    errors={errors}
                    setErrors={setErrors}
                />
                <SellerEditPricing
                    isFetched={isFetched}
                    seller={seller}
                    setSeller={setSeller}
                    errors={errors}
                    setErrors={setErrors}
                />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <WMButton
                        onClick={() => updateSeller()}
                        style={{
                            width: "100px",
                        }}
                        loading={loading}
                    >
                        {t("save")}
                    </WMButton>
                </div>

                <SuccessfulRequest
                    show={success}
                    hide={() => setSuccess(false)}
                    title={
                        lang == "ar"
                            ? "تحديث معلومات المستخدم"
                            : "Update User Info"
                    }
                    body={
                        lang == "ar"
                            ? "تم تحديث المعلومات بنجاح"
                            : "This user has been updated successfully"
                    }
                />
            </div>
        </div>
    );
}

export default ErrorHandler(SellerAccountInfo, axiosInstance);
