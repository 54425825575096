import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../style-sheets/table.css";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
// import { Pagination } from "antd";
// import { viewRS } from "../layout/tableColumns";
import { useDispatch } from "react-redux";
import ThreeDotsMenuRS from "../layout/ThreeDotsMenuRS";

import isReAssigned, {
    getReassignedSheet,
} from "../../global-state/actions/reassignedRsAction";
import { useTranslation } from "react-i18next";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import { useRunSheetColumns } from "../layout/tableColumns";
import TablePro from "../tables/TablePro";
import { search } from "../../global-state/actions/searchAction";
import useLanguage from "../../hooks/useLanguage";

const ViewAssignedRunSheets = (props) => {
    const [sheets, setSheets] = useState([]);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState();
    const [isSending, setIsSending] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRS, setSelectedRs] = useState("");
    const [page, setPage] = useState(1);
    const [isUnAssigned, setUnassigned] = useState(false);
    const [isArchived, setArchived] = useState(false);
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [error, setError] = useState("");
    const columns = useRunSheetColumns();
    const [loading, setLoading] = useState(false);

    const selectedRsDispatch = useDispatch();
    const isReassignedDispatch = useDispatch();

    const showConfirm = () => {
        setShow(true);
    };

    const hide = () => {
        setShow(false);
        setShowSuccess(false);
        setShowFailed(false);
    };
    const getRunSheets = async () => {
        setLoading(true);
        let result = await axiosInstance.get(`/FilterRunsheet/`, {
            params: {
                page,
                search,
            },
        });
        if (result) {
            setSheets(result.data.data);
            setCount(result.data.count);
        }
        setLoading(false);
    };
    useEffect(() => {
        getRunSheets();
    }, [page, search]);

    useEffect(() => {
        getRunSheets();
    }, [isUnAssigned, isArchived]);

    useEffect(() => {
        selectedRsDispatch(getReassignedSheet(selectedRS[0]));
    }, [selectionModel]);

    const forceAccept = async () => {
        console.log({ selectionModel });
        let result = await axiosInstance
            .post("/ForceRunsheet/", {
                runsheets: selectionModel,
                verdict: true,
            })
            .catch((err) => {
                console.log(err.response);
                setError(err.response.data);
                setShow(false);
                setShowFailed(true);
            });

        if (result) {
            setShowSuccess(true);
            setShow(false);
            getRunSheets();
        }
    };
    const onpageChangeHandle = async (page) => {
        setPage(page);
        let result = await axiosInstance.get(`/Runsheet/?page=${page + 1}`);
        setSheets(result.data.data);
        setCount(result.data.count);
    };
    const { t } = useTranslation();

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.runsheets")}
                        </span>

                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.assinedRunsheet")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("sideMenu.assinedRunsheet")}
                        </h2>
                    </Col>
                </Row>
                {/*<Row className="mb-4">*/}
                {/*    <Col className="text-end" sm="12">*/}
                {/*        <button disabled className="cancel">*/}
                {/*            {t("cancel")}*/}
                {/*        </button>*/}
                <Row className="mb-4">
                    <Col className="text-end" sm="12">
                        <button
                            onClick={showConfirm}
                            className="calculateBtn"
                            disabled={selectionModel.length == 0 ? true : false}
                        >
                            {t("forceConfirm")}
                        </button>
                        {/*<button*/}
                        {/*    disabled={selectionModel.length == 0 ? true : false}*/}
                        {/*    // onClick={go}*/}
                        {/*    className="confirm"*/}
                        {/*>*/}
                        {/*    {t("reAssign")}*/}
                        {/*</button>*/}
                    </Col>
                </Row>

                <Row>
                    <div>
                        <div>
                            <TablePro
                                setSelectedRows={setSelectionModel}
                                selection={selectionModel}
                                loading={loading}
                                emptyPlaceholder={
                                    <div className="noDataDivAnt">
                                        <h6 className="noDataHead">
                                            {t("archivedRsTxt1")}
                                        </h6>
                                        <p></p>
                                        <button className="confirm">
                                            {t("refresh")}
                                        </button>
                                    </div>
                                }
                                onSearch={setSearch}
                                dataSource={sheets}
                                columns={[
                                    ...columns,
                                    {
                                        field: "actions",
                                        headerName: "Actions",
                                        flex: 1,
                                        minWidth: 150,
                                        render: (_, row) =>
                                            row.state == "2" ? null : (
                                                <ThreeDotsMenuRS
                                                    link="UnassignRunsheet"
                                                    setUnassigned={
                                                        setUnassigned
                                                    }
                                                    data={row}
                                                    setArchived={setArchived}
                                                />
                                            ),
                                    },
                                ]}
                                pagination={{
                                    current: page,
                                    total: count,
                                    onChange: (page) => setPage(page),
                                }}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
            <CreationConfirmation
                title={t("forceConfirm")}
                show={show}
                hide={hide}
                sendRequest={forceAccept}
                body={t("forceAcceptText")}
            />
            <SuccessfulRequest
                show={showSuccess}
                hide={hide}
                title={t("forceConfirm")}
                body={t("forceAcceptedSuccess")}
            />
            <UnSuccessfulRequest
                show={showFailed}
                hide={hide}
                title={t("forceConfirm")}
                body={error}
            />
        </div>
    );
};

export default ErrorHandler(ViewAssignedRunSheets, axiosInstance);
