import axios from "axios";

const domain = window.location.host.split(".")[0];

// testing
// const BASE_URL = "http://barq.localhost:1111";
// "https://barqexp-test.ghenastore.com";
// : `https://${domain}.ghenastore.com`;
// const BASE_URL = `http://${domain}.localhost:1111`;
// const BASE_URL = `https://${domain}.ghenastore.com`;
// const BASE_URL = `https://treecode.loca.lt`;

// production
const BASE_URL = `https://${domain}.treecode.website`;
// const BASE_URL = `http://barqexpress.treecode.website:22000`;
//const REGISTRATION_URL = `https://main.treecode.website`;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});
export { BASE_URL };
export default axiosInstance;
