import React from "react";
import { Col, Row } from "react-bootstrap";
import { Slider, Spin } from "antd";
import StatusCard from "../../../../components/layout/StatusCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import useLanguage from "../../../../hooks/useLanguage";
import { useSelector } from "react-redux";

export default function CardsHeader({
    loading,
    status,
    currentStateTitle,
    selectState,
    summaryFilters,
    cityFilter,
    plan,
}) {
    const { t } = useTranslation();
    const lang = useLanguage();
    const user = useSelector((state) => state.auth.user);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const contactSupport = () => {
        document
            .getElementsByClassName("b24-widget-button-inner-block")[0]
            .click();
    };
    return (
        <header>
            <Row>
                <Col className="first-step" lg="8">
                    <Row>
                        {loading ? (
                            <div className="spinnerAnt">
                                <Spin indicator={antIcon} />
                            </div>
                        ) : status.length > 0 ? (
                            status.map((state, index) => {
                                return (
                                    <Col
                                        key={state.state.id}
                                        onClick={() =>
                                            selectState(
                                                state,
                                                status[index + 1]
                                            )
                                        }
                                        sm="12"
                                        md="4"
                                    >
                                        <StatusCard
                                            key={state.state.id}
                                            id={state.state.id}
                                            selectedCard={currentStateTitle}
                                            description={
                                                state.state.description
                                            }
                                            summaryFilters={summaryFilters}
                                            cityFilter={cityFilter}
                                            type={
                                                lang === "ar"
                                                    ? state.state.title_ar
                                                    : state.state.title
                                            }
                                            number={state.count}
                                            styleClass={"statusCard"}
                                            background={`${state.state.accent_color}`}
                                            style={{
                                                borderLeft: `solid 20px ${state.state.color}`,
                                            }}
                                        />
                                    </Col>
                                );
                            })
                        ) : (
                            <div className="d-flex flex-row justify-content-center align-items-center text-center my-4">
                                <h3>
                                    <strong>{t("noOrders")}</strong>
                                </h3>
                            </div>
                        )}
                    </Row>
                </Col>
                <Col lg="4">
                    {!["Seller", "Third Party"].includes(user.role) ? (
                        <Row className="statusCard p-3 my-4">
                            <p className="plan-header">{t("packageUsage")}</p>
                            <p className="plan-name">
                                {plan?.plan?.name} {t("plan")}
                            </p>
                            <Row className="orders-row">
                                <Col className="orders-count" sm="12">
                                    <p>{t("orderPackage")}</p>
                                    <span>
                                        {plan?.orders_count} {t("outOf")}{" "}
                                        {plan?.orders_remaining}
                                    </span>
                                </Col>
                            </Row>
                            <Slider
                                value={plan?.orders_count}
                                disabled
                                max={plan?.orders_remaining}
                            />
                            <Link style={{ padding: "0px" }} to="/payments">
                                <p style={{ marginBottom: "0px" }}>
                                    {t("payments")}
                                </p>
                            </Link>
                        </Row>
                    ) : null}

                    <Row
                        className="statusCard p-3 my-4"
                        style={{ cursor: "unset" }}
                    >
                        <Row>
                            <h3>
                                <strong>{t("needHelp")}</strong>
                            </h3>
                        </Row>

                        <Row className="mt-4">
                            <Col lg="1" className="me-2">
                                <i className="fas fa-comment-dots fs-4 side-icon"></i>
                            </Col>

                            <Col
                                onClick={contactSupport}
                                style={{ cursor: "pointer" }}
                            >
                                <strong>{t("contactSupport1")}</strong>
                                <div className="desc-sm my-2">
                                    {t("contactSupport2")}
                                </div>
                            </Col>
                        </Row>

                        <Row className="my-2">
                            <hr />
                        </Row>
                        <Row>
                            <Col lg="1" className="me-2">
                                <i className="fas fa-question-circle fs-4 side-icon"></i>
                            </Col>

                            <Col>
                                <a
                                    style={{ color: "black" }}
                                    target="_blank"
                                    href="https://lace-booth-6cf.notion.site/427d045aa04a4b28ae4aad271700c2bb"
                                    rel="noreferrer"
                                >
                                    <strong>{t("helpCenter1")}</strong>
                                </a>
                                <div className="desc-sm my-2">
                                    {t("helpCenter2")}
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </header>
    );
}
