import React, { useState } from "react";
import styles from "./WorkDirection.module.css";
import SecondaryWMButton from "../../../AllWorkModels/WMButton/SecondaryWMButton";
import WMButton from "../../../AllWorkModels/WMButton";
import WorkModelCard from "../../../AllWorkModels/WorkModelCard";
import useQuery from "../../../../../hooks/useQuery";
import { useTranslation } from "react-i18next";

export default function WorkDirection({}) {
    const [selectedDirection, setSelectedDirection] = useState(undefined);
    const { appendQuery } = useQuery();
    const { t } = useTranslation();
    const types = [
        {
            id: 1,
            title: t("work_model.weight"),
            description: t("work_model.weight_desc"),
        },
        {
            id: 2,
            title: t("work_model.dimensions"),
            description: t("work_model.dimensions_desc"),
        },
    ];

    return (
        <div className={styles.container}>
            <WorkModelCard
                title={t("work_model.work_direction_select")}
                actions={
                    <>
                        <SecondaryWMButton>{t("close")}</SecondaryWMButton>
                        <WMButton
                            onClick={() => {
                                appendQuery("model_type", selectedDirection);
                            }}
                        >
                            {t("next")}
                        </WMButton>
                    </>
                }
            >
                <div className={styles.centeredCard}>
                    {types.map((it) => (
                        <div
                            key={it.id}
                            className={[
                                styles.typeCard,
                                it.id === selectedDirection
                                    ? styles.selected
                                    : "",
                            ].join(" ")}
                            onClick={() => setSelectedDirection(it.id)}
                        >
                            <header>{it.title}</header>
                            <p>{it.description}</p>
                        </div>
                    ))}
                </div>
            </WorkModelCard>
        </div>
    );
}
