import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import {
    couriers,
    couriers_ar,
    useCourierColumns,
} from "../layout/tableColumns";
import { addRunsheet } from "../../global-state/actions/addRunsheet";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../../global-state/actions/searchAction";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TablePro from "../tables/TablePro";

function RunsheetCourier({ value, isReAssigned, handleChange }) {
    const searchDispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const reAssignedRS = useSelector(
        (state) => state.reAssignedRs.reAssignedRs
    );
    const runSheet = useSelector((state) => state.runsheet);
    const dispatch = useDispatch();
    const dispatchCourier = useDispatch();
    const { t } = useTranslation();
    const columns = useCourierColumns();

    useEffect(() => {
        if (selectedRows[0] === undefined) return;
        dispatch(addRunsheet({ courier: selectedRows[0] }));
        dispatchCourier(
            addRunsheet({
                courierObj: data.find((it) => it.id === selectedRows[0]),
            })
        );
    }, [selectedRows]);

    const getCouriers = async () => {
        let result = await axiosInstance
            .get("/FilterCourier/", {
                params: {
                    page,
                    search: searchTerm,
                    available: "available",
                    branch: isReAssigned ? reAssignedRS.branch.id : runSheet.branch,
                    // page_size: 10000,
                },
            })
            .catch((err) => console.log(err));
        if (result) {
            setData(result.data.data ?? []);
            setCount(result.data.count);
        }
    };

    const searching = (v) => {
        setSearchTerm(v);
        searchDispatch(search(v));
    };

    useEffect(() => {
        getCouriers();
    }, [page, searchTerm]);

    const proceed = () => {
        handleChange(null, value + 1);
    };
    return (
        <>
            <Row>
                <Col sm="6">
                    {isReAssigned ? (
                        <p className="orderID">
                            {t("runsheetID")}#{reAssignedRS.id}
                        </p>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    {/* <button className="cancel">{t("discard")}</button> */}
                    <button
                        disabled={selectedRows.length === 0}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("choose")}
                    </button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col sm="12">
                        <div>
                            <TablePro
                                dataSource={data}
                                columns={columns}
                                disableSelectionOnClick
                                paginationMode="server"
                                pagination={{
                                    current: page,
                                    total: count,
                                    onChange: (page) => setPage(page),
                                }}
                                selection={"radio"}
                                onSearch={searching}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ErrorHandler(RunsheetCourier, axiosInstance);