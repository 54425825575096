import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRunsheet } from "../../../global-state/actions/addRunsheet";
import axiosInstance from "../../../api/axios";
import { search } from "../../../global-state/actions/searchAction";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    couriers,
    couriers_ar,
    useCourierColumns,
} from "../../layout/tableColumns";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import TablePro from "../../tables/TablePro";

function ChooseCouriersStep({ value, handleChange }) {
    const searchDispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const dispatch = useDispatch();
    const dispatchCourier = useDispatch();
    const selectedBranches = useSelector((state) => state.runsheet?.branches);

    useEffect(() => {
        if (selectedRows[0] === undefined) return;
        dispatch(addRunsheet({ courier: selectedRows[0] }));
        dispatchCourier(
            addRunsheet({
                courierObj: data.find((it) => it.id === selectedRows[0]),
            })
        );
    }, [selectedRows]);

    const getCouriers = async () => {
        let result = await axiosInstance
            .get("/FilterCourier/", {
                params: {
                    branch__in: `${selectedBranches.from?.id},${selectedBranches.to?.id}`,
                    page,
                    search: searchTerm,
                    available: true,
                },
            })
            .catch((err) => console.log(err));
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    const searching = (v) => {
        setSearchTerm(v);
        searchDispatch(search(v));
    };

    useEffect(() => {
        getCouriers();
    }, [page, searchTerm]);

    const proceed = () => {
        handleChange(null, value + 1);
    };
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button
                        disabled={selectedRows.length === 0}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("choose")}
                    </button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col sm="12">
                        <div>
                            <TablePro
                                dataSource={data}
                                columns={useCourierColumns()}
                                disableSelectionOnClick
                                paginationMode="server"
                                pagination={{
                                    current: page,
                                    total: count,
                                    onChange: (page) => setPage(page),
                                }}
                                selection={"radio"}
                                onSearch={searching}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ErrorHandler(ChooseCouriersStep, axiosInstance);
