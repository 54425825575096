import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import loginImg from "../../images/loginImg.png";
import "../../style-sheets/login.css";
import ReactCodeInput from "react-verification-code-input";
import ReactInputVerificationCode from "react-input-verification-code";
import logoSeller from "../../images/logoSeller.png";
import logoAdmin from "../../images/logoAdmin.png";
import arabicLogo from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png"
import adminConfirm from "../../images/adminConfirm.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { code } from "../../global-state/actions/codeAction";

const ConfirmationCode = (props) => {
  const { t } = useTranslation();

  const email = useSelector((state) => state.code.code);
  const dispatch = useDispatch();
  const [codeState, setCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(10);
  const [disable, setDisable] = useState(false);
  const handleCode = (e) => {
    console.log(e);
    setCode(e);
  };

  const resendCode = async () => {
    setCounter(10);
    let result = await axios.post(BASE_URL + "/forgot_password", {
      email: email,
    });
  };
  const submit = async () => {
    setLoading(true);
    setError(null);
    let result = await axios
      .post(BASE_URL + "/code_response", { code: code })
      .catch((err) => {
        console.log(err.response);
        setError(err.response.data);
      });
    setLoading(false);
    console.log(code);
    dispatch(code(codeState));
    props.history.location.pathname == "/admin_confirm"
      ? props.history.push("/admin_reset_password")
      : props.history.push("/reset_password");
  };
  useEffect(() => {
    console.log("called");
    setTimeout(() => {
      if (counter > 0) {
        setDisable(true);
        setCounter((prev) => prev - 1);
      } else {
        setDisable(false);
        // c=60
      }
    }, 1000);
  }, [counter]);

  return (
    <div>
      <div className="p-3">
        {props.history.location.pathname == "/admin_confirm" ? (
          <img src={localStorage.getItem("lang") === "en" ? logoAdmin : arabicLogo} className="img-fluid" />
        ) : (
          <img src={localStorage.getItem("lang") === "en" ? logoSeller : logoSellerAr} className="img-fluid" />
        )}
      </div>
      <Container className="pageContainer">
        <Row>
          <Col md="6" sm="12">
            {props.history.location.pathname == "/admin_confirm" ? (
              <img src={loginImg} className="img-fluid" />
            ) : (
              <img src={adminConfirm} className="img-fluid" />
            )}
          </Col>
          <Col md="1" sm="12"></Col>
          <Col className="mt-5 p-5" md="4" sm="12">
            <h3 className="title">{t("emailedYou")}</h3>
            <p className="subTitle mt-2">
              {t("enterCode")}
            </p>
            <p className="mt-5">{t("confirmationCode")}</p>
            <div dir="ltr" className="custom-styles mt-4">
              <ReactCodeInput
                fields={4}
                type="text"
                value={codeState}
                onChange={handleCode}
              />

              <p className="error">{error ?? ""}</p>
            </div>{" "}
            <div className="mt-5">
              {props.history.location.pathname == "/admin_confirm" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  className="login-form-button adminBtn"
                  onClick={submit}
                >
                  {t("confirm")}
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  className="login-form-button sellerBtn"
                  onClick={submit}
                >
                  {t("confirm")}
                </Button>
              )}
              <button
                disabled={disable}
                onClick={resendCode}
                className="subTitle2 mt-2"
              >
                {t("resendCode")}
              </button>
              <p>{counter}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmationCode;
