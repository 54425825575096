import React, { useState, useEffect } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { Slider, InputNumber } from "antd";
import { Row, Col } from "react-bootstrap";
import { DISPLAY_MODE, FawryPay } from "./fawrypay-payments";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../api/axios";
import { sha256 } from "js-sha256";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

let signature;
let return_url = `${BASE_URL}/pay_fawry`;
let merchantCode = "siYxylRjSPwVaIFPiORyNQ==";
let hash_key = "8250efc06ee94f97b08c2fb018fd3e38";

function Payments() {
  const { t } = useTranslation();
  const lang = useLanguage()
  const user = useSelector((state) => state.auth.user.user);
  const [inputValue, setInputValue] = useState(100);
  const [plan, setPlan] = useState([]);
  //   const [percent, setPercent] = useState(null);
  const onChange = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    getPrice();
    console.log(getPrice());
  }, [inputValue]);

  const getPlan = async () => {
    let result = await axiosInstance
      .get("/Plan/")
      .catch((err) => console.log(err));

    if (result) {
      console.log(result.data.data);
      setPlan(result.data.data);
    }
  };

  const getPrice = () => {
    const price = plan?.filter(
      (range) => inputValue >= range.range_from && inputValue < range.range_to
    );
    return price[0]?.price_per_order;
  };

  //   const intent = async () => {
  //     let result = await axiosInstance.post("/request_pay_fawry", {
  //       amount: inputValue * getPrice(),
  //       orders_count: inputValue,
  //     });
  //     if (result) {
  //       console.log(result.data);
  //     }
  //   };
  async function checkout() {
    // intent();
    let result = await axiosInstance.post("/request_pay_fawry", {
      amount: inputValue * getPrice(),
      orders_count: inputValue,
    });
    if (result.data) {
      signature = sha256(
        merchantCode +
          result.data.data.id +
          "" +
          return_url +
          "1" +
          inputValue +
          getPrice() +
          hash_key
      );
      console.log(signature);
      console.log(result.data);
      const configuration = {
        locale: lang, //default en
        mode: DISPLAY_MODE.POPUP, //required, allowed values [POPUP, INSIDE_PAGE, SIDE_PAGE]
      };
      FawryPay.checkout(
        buildChargeRequest(result.data.data.id, signature),
        configuration
      );
    }
  }

  function buildChargeRequest(refNum, signature) {
    // const chargeRequest = {
    //     merchantCode: '1tSa6uxz2nRbgY+b+cZGyA==',
    //     merchantRefNum: refNum,
    //     customerMobile: user.mobile,
    //     customerEmail: user.email,
    //     customerName: user.name,
    //     chargeItems: [
    //             {
    //                 itemId: '1',
    //                 price: (inputValue * getPrice()).toFixed(2),
    //                 quantity: inputValue,
    //             },

    //     ],
    //     paymentMethod: 'PayAtFawry',
    //     returnUrl: `${BASE_URL}/pay_fawry`,
    //     authCaptureModePayment: false,
    //     signature: "2ca4c078ab0d4c50ba90e31b3b0339d4d4ae5b32f97092dd9e9c07888c7eef36"
    // };
    const chargeRequest = {
      merchantCode: "siYxylRjSPwVaIFPiORyNQ==",
      merchantRefNum: refNum,
      customerMobile: user.phone,
      customerEmail: user.email,
      customerName: user.name,
      chargeItems: [
        {
          itemId: "1",
          price: getPrice(),
          quantity: inputValue,
        },
      ],
      returnUrl: `${BASE_URL}/pay_fawry`,
      authCaptureModePayment: false,
      signature: signature,
    };
    return chargeRequest;
  }

  useEffect(() => {
    getPlan();
  }, []);
  return (
    <div className="payments">
      <Row style={{marginBottom : "45px"}}>
        <Col sm="12">
          <h2 className="pageTitle"> {t("paymentGate")}</h2>
        </Col>
      </Row>
      <Row >
        <Col sm="12">
          <Slider
          tooltipVisible={true}
            min={100}
            max={100000}
            onChange={onChange}
            step={5}
            // dots
            value={typeof inputValue === "number" ? inputValue : 0}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "20px" }} sm="12">
          <label>{t("ordersNumber")}</label>

          <InputNumber
            min={100}
            max={100000}
            style={{ margin: "20px 16px",width : "135px" }}
            value={inputValue}
            onChange={onChange}
          />
          <div>
            {t("amountpayed")} :
            <strong>
              {" "}
              {(inputValue * getPrice()).toFixed(2) === NaN
                ? "0"
                : (inputValue * getPrice()).toFixed(2)}
            </strong>
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "15px" }} sm="12">
          <input
            type="image"
            disabled={inputValue === null ? true : false}
            onClick={checkout}
            src="https://www.atfawry.com/assets/img/FawryPayLogo.jpg"
            alt="pay-using-fawry"
            id="fawry-payment-btn"
          />
        </Col>
      </Row>
    </div>
  );
}

export default ErrorHandler(Payments, axiosInstance);
