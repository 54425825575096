import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {
    productInfo,
    productInfo_ar,
    useTicketsColumns,
} from "../../layout/tableColumns";
import { useDispatch, useSelector } from "react-redux";
import {
    getSeller,
    getTicketsReturned,
} from "../../../global-state/actions/getSelectedAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import { CheckBox } from "../../form/TextInputFields";
import { Input } from "antd";
import TablePro from "../../tables/TablePro";

const ProductInfoTable = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [changed, setChanged] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const orderSellerDispatch = useDispatch();
    const selectedTickets = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const getData = async () => {
        setLoading(true);
        const params = {
            page,
            search: keyword,
        };
        let result;
        if (user.role == "Seller") {
            result = await axiosInstance.get(`/FilterTicket/`, {
                params: {
                    ...params,
                    order__seller__id: user.user.user_id,
                    state__in: "8",
                },
            });
        } else {
            result = await axiosInstance.get(`/FilterTicket/`, {
                params: {
                    ...params,
                    state__in: "6,8",
                },
            });
        }

        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }

        setLoading(false);
    };

    useEffect(() => {
        console.log(selectedTicket);
        props.getReturnedTicketsObject(selectedTicket);
    }, [selectedTicket]);

    useEffect(() => {
            getData();
    }, [keyword, page]);

    const getSellerData = async (id) => {
        const result = await axiosInstance.get(`/Seller/${id}`);
        if (result.data) {
            orderSellerDispatch(getSeller(result.data));
        }
    };

    const proceed = async () => {
        // console.log(selectedTicket);
        if (selectedTicket.length !== 0) {
            props.handleChange(null, props.value + 1);
            await getSellerData(selectedTicket[0].order.seller.id);
        }
    };
    const { t } = useTranslation();

    return (
        <div>
            <Container>
                <TablePro
                    onSearch={setKeyword}
                    dataSource={data}
                    columns={useTicketsColumns()}
                    loading={loading}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                    selection={"checkbox"}
                    selectedRows={selectionModel}
                    setSelectedRows={setSelectionModel}
                    setSelectedRecords={setSelectedTicket}
                    // emptyPlaceholder={
                    //     <div className="noDataDiv">
                    //         <h6 className="noDataHead">
                    //             {t("refreshTitle")}
                    //         </h6>
                    //         <p>{t("refreshText")}</p>
                    //     </div>
                    // }
                    unselectableRowsCondition={(record) => {
                        const firstTicket = selectionModel[0]
                            ? data.filter((it) => it.id == selectionModel[0])[0]
                            : data[0];

                        return (
                            record.order?.seller?.id !==
                            firstTicket.order?.seller?.id
                        );
                    }}
                />
                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button className="cancel" disabled>
                            {t("discard")}
                        </button>
                        <button
                            disabled={
                                selectedTicket.length !== 0 ? false : true
                            }
                            className={
                                user.role == "Seller"
                                    ? "confirmSeller"
                                    : "confirm"
                            }
                            onClick={proceed}
                            type="submit"
                        >
                            {t("proceed")}
                        </button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(ProductInfoTable, axiosInstance);
