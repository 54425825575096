import React, { useEffect, useState } from "react";
import styles from "./BranchStep.module.css";
import InformationCard from "../../../../layout/informationCard";
import WorkModelCard from "../../../AllWorkModels/WorkModelCard";
import CenteredWMCard from "../../../AllWorkModels/WorkModelCard/CenteredWMCard";
import { Form, Input, Select } from "antd";
import axiosInstance from "../../../../../api/axios";
import { PaginatedSelect } from "../../../../form/TextInputFields";
import SecondaryWMButton from "../../../AllWorkModels/WMButton/SecondaryWMButton";
import WMButton from "../../../AllWorkModels/WMButton";
import useQuery from "../../../../../hooks/useQuery";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BranchStep({}) {
    const [branches, setBranches] = useState({ data: [], page: 1 });
    const { appendQuery } = useQuery();
    const history = useHistory();

    async function getBranches() {
        const res = await axiosInstance.get("/Branch/?page_size=10000000");
        setBranches({ ...branches, data: res.data.data });
    }

    useEffect(() => {
        getBranches();
    }, [branches.page]);

    const { t } = useTranslation();
    const list = Array.isArray(
        t("work_model.info_header.steps", {
            returnObjects: true,
        })
    )
        ? t("work_model.info_header.steps", {
              returnObjects: true,
          })
        : [];

    return (
        <div className={styles.container}>
            <InformationCard
                title={t("work_model.info_header.title")}
                subTitle={t("work_model.info_header.sub")}
                list={list.map((it) => (
                    <p>{it}</p>
                ))}
            />

            <Form
                layout={"vertical"}
                onFinish={async (data) => {
                    history.push(`?name=${data.name}&branch=${data.branch}`);
                }}
            >
                <CenteredWMCard
                    title={t("work_model.work_model_details")}
                    actions={
                        <>
                            <SecondaryWMButton>{t("cancel")}</SecondaryWMButton>
                            <WMButton htmlType="submit">{t("next")}</WMButton>
                        </>
                    }
                >
                    <Form.Item
                        name="name"
                        label={t("work_model.work_model_name")}
                        rules={[
                            {
                                required: true,
                                message: t("work_model.work_model_name_req")
                            },
                        ]}
                    >
                        <Input placeholder={t("work_model.work_model_name_ph")} />
                    </Form.Item>
                    <Form.Item
                        name="branch"
                        label={t("work_model.branch_name")}
                        rules={[
                            {
                                required: true,
                                message: t("work_model.branch_name_req")

                            },
                        ]}
                    >
                        <Select placeholder={t("work_model.branch_name_ph")}>
                            {branches.data.map((it) => (
                                <Select.Option key={it.id} value={it.id}>
                                    {it.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </CenteredWMCard>
            </Form>
        </div>
    );
}
