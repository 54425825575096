import React, {useState, useEffect, useMemo} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Row, Col} from "react-bootstrap";
import "../../style-sheets/form.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import RunSheetInfo from "../forms/runsheetInfo";
import RunsheetOrders from "../forms/runsheetOrders";
import RunsheetCourier from "../forms/runsheetCourier";
import {useSelector} from "react-redux";
import RunsheetRequest from "../forms/runsheetRequest";
import {useTranslation} from "react-i18next";
import isReAssignedAction from "../../global-state/actions/reassignedRsAction";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CreateRunsheet = ({isReAssigned, ...props}) => {
    const dispacthReassign = useDispatch();

    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const reAssignedRS = useSelector((state) => state.reAssignedRs.reAssignedRs);
    const history = useHistory();
    useEffect(() => {
        console.log({reAssignedRS})
        if (!reAssignedRS && isReAssigned) history.push("/assigned_rs")
    }, [reAssignedRS]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const user = useSelector((state) => state.auth.user);
    const {t} = useTranslation();
    useEffect(() => {
        return () => {
            dispacthReassign(isReAssignedAction(false));
        };
    }, []);
    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.runsheets")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    {isReAssigned ? (
                        <span className="title2">{t("reassignRunsheet")}</span>
                    ) : (
                        <span className="title2">{t("createNewRunsheet")}</span>
                    )}
                </Col>
                <Col sm="12">
                    {isReAssigned ? (
                        <h2 className="pageTitle">{t("reassignRunsheet")}</h2>
                    ) : (
                        <h2 className="pageTitle">{t("createNewRunsheet")}</h2>
                    )}
                </Col>
            </Row>
            <Box sx={{width: "100%"}}>
                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    {isReAssigned ? (
                        <>
                            <Tabs
                                value={value}
                                aria-label="basic tabs example"
                                className={`${
                                    user.role == "Seller" ? "seller" : "admin"
                                }`}
                            >
                                <Tab
                                    label={t("runsheetInfo")}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={t("chooseCourier")}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label={t("reviewRequest")}
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <RunsheetOrders
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <RunsheetCourier
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <RunsheetRequest
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                        </>
                    ) : (
                        <>
                            <Tabs
                                className={`${
                                    user.role == "Seller" ? "seller" : "admin"
                                }`}
                                value={value}
                                aria-label="basic tabs example"
                            >
                                {" "}
                                <Tab label={t("createRs")} {...a11yProps(0)} />
                                <Tab
                                    label={t("runsheetInfo")}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label={t("chooseCourier")}
                                    {...a11yProps(2)}
                                />
                                <Tab
                                    label={t("reviewRequest")}
                                    {...a11yProps(3)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <RunsheetOrders
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <RunSheetInfo
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <RunsheetCourier
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <RunsheetRequest
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                        </>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default ErrorHandler(CreateRunsheet, axiosInstance);
