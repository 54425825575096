import axiosInstance from "./axios";
import formattedError from "./errorFormatter";

const WorkModelRepository = {
    get: async (page) => {
        try {
            const res = await axiosInstance.get(
                `/NewWorkModel/?page=${page}&page_size=10000000`
            );

            if (res.data) {
                return {
                    success: true,
                    data: res.data.data,
                };
            }
        } catch (e) {
            return formattedError(e);
        }
    },
    getById: async (id) => {
        try {
            const res = await axiosInstance.get(`/NewWorkModel/${id}`);

            if (res.status >= 200 && res.status < 210)
                return {
                    success: true,
                    data: res.data,
                };
            else return formattedError();
        } catch (e) {
            return formattedError(e);
        }
    },
    getLinkedSellers: async (id) => {
        try {
            const workModelRes = await WorkModelRepository.getById(id);

            if (!workModelRes.success)
                return formattedError(workModelRes.error);
            let sellers = workModelRes.data.sellers_addresses.map((address) => {
                return { ...address.seller, address: address.address };
            });
            return {
                success: true,
                data: sellers,
            };
        } catch (e) {
            formattedError(e);
        }
    },
    put: async (data) => {
        let fieldName =
            +data.model_type === 1 ? "weight_prices" : "diemention_prices";
        try {
            const res = await axiosInstance.put(
                `${
                    +data.model_type === 1
                        ? "/NewWeightWorkModel/"
                        : "NewDiementionWorkModel/"
                }${data.id}`,
                {
                    [fieldName]: data.prices.map((p) => {
                        return {
                            ...p,
                            area: p.area?.id,
                            changed_by: p.changed_by?.id,
                            diemention: p.diemention?.id,
                            diementions_prices: p.diementions,
                        };
                    }),
                }
            );
            if (res.status >= 200 && res.status < 210)
                return {
                    success: true,
                };
            else return formattedError();
        } catch (e) {
            console.log({ e });
            return formattedError(e);
        }
    },
    post: async (data, workmodel) => {
        try {
            const res = await axiosInstance.post(
                "/WorkModelPrice/",
                data.map((it) => ({
                    ...it,
                    work_model: workmodel,
                    area: it.area?.id,
                    changed_by: it.changed_by?.id,
                    diemention: it.diemention?.id,
                }))
            );
            if (res.status >= 200 && res.status < 210)
                return {
                    success: true,
                };
            else return formattedError();
        } catch (e) {
            console.log({ e });
            return formattedError(e);
        }
    },
    delete: async (id) => {
        try {
            const res = await axiosInstance.delete(`/NewWorkModel/${id}`);

            if (res.status >= 200 && res.status < 210)
                return {
                    success: true,
                    data: "Work Model Deleted Successfully",
                };
            else return formattedError();
        } catch (e) {
            return formattedError();
        }
    },
};
export default WorkModelRepository;
