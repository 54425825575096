import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useQuery from "../../../../../hooks/useQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SellerInfoForm from "../../../../admin/tickets/create/ui/sellerInfoForm";
import ProductInfoForm from "../../../../common/tickets/create/ui/productInfoForm";
import OrderInfoForm from "../../../../common/tickets/create/ui/orderInfoForm";
import SellerCustomerInfo from "./SellerCustomerInfo";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import {
    getSeller,
    getTicketsReturned,
} from "../../../../../global-state/actions/getSelectedAction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function SellerCreateParcel() {
    const { t } = useTranslation();
    const { query, appendQuery } = useQuery();
    const value = +(query.page ?? 0);
    const handleChange = (event, newValue) => {
        appendQuery("page", parseInt(newValue));
    };

    const order = useSelector((state) => state.order);
    const dispatch = useDispatch();
    useEffect(() => {
        console.log({ order });
    }, [order]);

    useEffect(() => {
        appendQuery("page", 0);
        dispatch(addOrder(""));
        dispatch(getTicketsReturned(""));
        dispatch(getSeller(""));
    }, []);
    return (
        <>
            <Tabs
                value={value}
                aria-label="basic tabs example"
                className="admin"
            >
                <Tab label={t("customerInfo")} {...a11yProps(0)} />
                <Tab label={t("productInfo")} {...a11yProps(1)} />
                <Tab label={t("orderInfo")} {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <SellerCustomerInfo value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProductInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <OrderInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
        </>
    );
}

export default ErrorHandler(SellerCreateParcel, axiosInstance);
