import axiosInstance from "./axios";
import formattedError from "./errorFormatter";

const root = "/sms-templates";

const SmsTemplateRepository = {
    async getAllTemplates({ page, page_size, search, ...params }) {
        try {
            const res = await axiosInstance.get(
                `${root}/`,
                {
                    params: {
                        page,
                        page_size,
                        search,
                        ...params,
                    },
                }
            );
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data.data,
                    count: res.data.count,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async getTemplateById(id) {
        try {
            const res = await axiosInstance.get(`${root}/${id}/`);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async updateTemplate(id, data) {
        try {
            const res = await axiosInstance.put(`${root}/${id}/`, data);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async actionActivateSwitch(id) {
        try {
            const res = await axiosInstance.post(`${root}/${id}/activate-switch/`);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async getSellerTemplates(templateId, { page = 1, page_size = 10, }) {
        try {
            const res = await axiosInstance.get(`${root}/${templateId}/sellers-sms-templates/`, {
                params: {
                    page,
                    page_size,
                },
            });
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data.data,
                    count: res.data.count,
                };
            } else {
                return formattedError(res);
            }
        }
        catch (e) {
            return formattedError(e);
        }
    },


    async getSellerTemplateById(sellerTemplateId) {
        try {
            const res = await axiosInstance.get(`${root}/seller-sms-template/${sellerTemplateId}/`);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        }
        catch (e) {
            return formattedError(e);
        }
    },

    async createSellerTemplate(templateId, data) {
        try {
            const res = await axiosInstance.post(`${root}/${templateId}/create-seller-sms-template/`, data);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async updateSellerTemplate(sellerTemplateId, data) {
        try {
            const res = await axiosInstance.put(`${root}/update-seller-sms-template/${sellerTemplateId}/`, data);
            if (res.data) {
                return {
                    success: true,
                    status: res.status,
                    data: res.data,
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    },

    async deleteSellerTemplate(sellerTemplateId) {
        try {
            const res = await axiosInstance.delete(`${root}/delete-seller-sms-template/${sellerTemplateId}/`);
            if (res.status === 204) {
                return {
                    success: true,
                    status: res.status,
                    data: ""
                };
            } else {
                return formattedError(res);
            }
        } catch (e) {
            return formattedError(e);
        }
    }

};

export default SmsTemplateRepository;
