import { padding } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const WorkModelDR = ({ data, show, hide, setClose, type }) => {
  const { t } = useTranslation();
  const cur = useSelector(state => state.auth.user.user.organization.country.currency)
  const cssClasses = [
    "Modal deliveryRangeDetails",
    show ? "ModalOpen" : "ModalClosed"
  ];
  const close = () => {
    setClose(false);
  };

  return (
    <>
      <div className={cssClasses.join(" ")}>
        <h6>
          {type === "prices"
            ? `${t("rangeOverAll")}`
            : type === "fee"
            ? `${t("shipmentFee")}`
            : `${t("returnedFee")}`}
        </h6>
        {type === "fee" ? (
          <>
            <h6 className="prices-headers">{t("sideMenu.general")}</h6>
            <Row>
              <Col className="prices" sm="12">
                <p>{t("sameZone")}</p>
                <div className="deactivate">{cur} {data.same_zone_price}</div>
              </Col>
              <Col className="prices" sm="12">
                <p>{t("sameBranch")}</p>
                <div className="deactivate">{cur} {data.same_branch_price}</div>
              </Col>
            </Row>
            <h6 className="prices-headers">{t("custom")}</h6>
            <Row>
              {data.price_list.map((price) => {
                return (
                  <Col className="prices" sm="12">
                    <p>
                      {price.branch_from.name} - {price.branch_to.name}
                    </p>
                    <div className="deactivate">{cur} {price.price}</div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : type === "rto" ? (
          <>
            <h6 className="prices-headers">{t("sideMenu.general")}</h6>
            <Row>
              <Col className="prices" sm="12">
                <p>{t("sameZone")}</p>
                <div className="deactivate">{cur} {data.same_zone_price_rto}</div>
              </Col>
              <Col className="prices" sm="12">
                <p>{t("sameBranch")}</p>
                <div className="deactivate">
                  {cur} {data.same_branch_price_rto}
                </div>
              </Col>
            </Row>
            <h6 className="prices-headers">{t("custom")}</h6>
            <Row>
              {data.price_list.map((price) => {
                return (
                  <Col className="prices" sm="12">
                    <p>
                      {price.branch_from.name} - {price.branch_to.name}
                    </p>
                    <div className="deactivate">{cur} {price.price_rto}</div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          data?.delivery_ranges.map((dr) => {
            return (
              <p className="delivery-range">
                {dr.min} - {dr.max}
              </p>
            );
          })
        )}

        <Row>
          <Col style={{ textAlign: "end", padding: "12px" }}>
            <button onClick={close} className="deactivate">
            {t("cancel")}
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ErrorHandler(WorkModelDR, axiosInstance);
