import React, {useEffect, useState} from "react";
import ReportsRepository, {
    branchReportsTypes,
} from "../../../../api/ReportsRepository";
import axiosInstance from "../../../../api/axios";
import CommonReportsPage from "../CommonReportsPage";
import {useTranslation} from "react-i18next";

export default function BranchReportsPage({}) {
    const [selectedType, setSelectedType] = useState();
    const [branches, setBranches] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [courierSearch, setCourierSearch] = useState();
    const {t} = useTranslation();
    const fields = [
        {
            name: "branch",
            label: t("reports.branch_name"),
            multiple: true,
            type: "select",
            placeholder: t("reports.branch_name_ph"),
            rules: [{required: true, message: t("reports.branch_name_req")}],
            options: branches.map((it) => (
                <option key={it.id} value={it.id}>
                    {it.name}
                </option>
            )),
        },
        {
            name: "type",
            label: t("reports.report_type"),
            type: "select",
            placeholder: t("reports.report_type_ph"),
            rules: [{required: true, message: t("reports.report_type_req")}],
            onChange: (v) => setSelectedType(branchReportsTypes[v]),
            options: Object.keys(branchReportsTypes).map((key) => (
                <option key={key}>{t(`reports.branch_list.${branchReportsTypes[key].title}`)}</option>
            )),
        },
        selectedType === branchReportsTypes.courier
            ? {
                name: "couriers",
                label: t("reports.courier_name"),
                type: "select",
                multiple: true,
                placeholder: t("reports.courier_name_ph"),
                search: true,
                onClick: () => setCourierSearch(undefined),
                onSearch: (input) => {
                    setCourierSearch(input);
                    return false;
                },

                rules: [{required: true, message: t("reports.courier_name_req")}],
                options: couriers.map((it) => (
                    <option key={it.id} value={it.id}>
                        {it.user.name}
                    </option>
                )),
            }
            : undefined,
    ];

    async function fetchBranches() {
        const res = await axiosInstance
            .get("/Branch/?page_size=1000")
            .catch((err) => console.log({err}));
        if (res?.data) {
            setBranches(res.data?.data ?? []);
        }
    }

    async function fetchCouriers() {
        const res = await axiosInstance
            .get("/FilterCourier/", {
                params: {
                    page_size: 1000,
                    search: courierSearch,
                },
            })
            .catch((err) => console.log({err}));

        if (res.data) setCouriers(res.data.data ?? []);
    }

    useEffect(() => {
        fetchBranches();
    }, []);

    useEffect(() => {
        fetchCouriers();
    }, [courierSearch]);
    return (
        <CommonReportsPage
            formFields={fields}
            selectedType={selectedType}
            section={t("reports.branch_reports")}
        />
    );
}
