import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import { useCloseRunsheetColumns } from "../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function CloseRunsheet(props) {
    const history = useHistory();
    const searchDispatch = useDispatch();
    const [page, setPage] = useState(1);

    const [count, setCount] = useState(1);
    const [courierName, setCourierName] = useState("");
    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [orders, setOrders] = useState([]);
    const [showFailed, setShowFailed] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showForce, setShowForce] = useState(false);
    const [error, setError] = useState("");
    const [ordersValue, setOrdersValue] = useState(0);

    let sumArray = [];
    let sum = 0;
    const getOrders = async () => {
        let result = await axiosInstance
            .get(`/ReceiveMoneyFromCourier/${props.match.params.id}`, {
                params: {
                    page,
                },
            })
            .catch((err) => {
                setError(err.response.data);
                setShowFailed(true);
            });
        if (result) {
            console.log(result.data.data);
            setCourierName(result.data.courier_name);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    const hide = () => {
        setShowForce(false);
    };

    const hideSuccess = () => {
        setShowSuccess(false);
        history.push("/assigned_rs");
    };

    const hideFailed = () => {
        setShowFailed(false);
        history.push("/assigned_rs");
    };

    const closeRs = async (verdict = false) => {
        let result = await axiosInstance
            .post(
                `/ReceiveMoneyFromCourier/${props.match.params.id}?force=${verdict}`,
                {
                    money_returned: ordersValue,
                    orders_money: selectionModel,
                }
            )
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    console.log(err.response);
                    setError(err.response.data);
                    setShowForce(true);
                } else {
                    setError(err.response.data);
                    setShowFailed(true);
                }
            });
        if (result) {
            console.log(result);
            setShowSuccess(true);
        }
    };

    const handleChange = (e) => {
        setOrdersValue(e.target.value);
    };

    useEffect(() => {
        if (selectionModel.length > 0) {
            console.log({ orders });
            sumArray = orders.map((order) =>
                parseFloat(order.total_amount ?? 0)
            );
            sum = sumArray.reduce((accumulator, curr) => accumulator + curr);
            console.log(sum);
            setOrdersValue(sum);
        }
    }, [orders]);
    useEffect(() => {
        getOrders();
    }, [page]);
    const { t } = useTranslation();
    const lang = useLanguage();

    return (
        <>
            <Row>
                <Col sm="6">
                    <p className="orderID">
                        {t("runsheetID")}#{props.match.params.id}
                    </p>
                </Col>
            </Row>

            <Row className="my-3">
                <Col sm="12" md="6">
                    <label className="bold-label">{t("couriername")}</label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        value={courierName}
                        disabled
                    />
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">
                        {t("courierReturnAmount")}
                    </label>
                    <Form.Control
                        type="number"
                        onChange={handleChange}
                        value={selectionModel.length === 0 ? 0 : ordersValue}
                    />
                </Col>
            </Row>

            <Row className="my-2">
                <Col className="text-end buttons-margin" sm="12">
                    {/* <button className="cancel">{t("discard")}</button> */}

                    <button
                        disabled={selectionModel.length === 0}
                        onClick={() => closeRs(false)}
                        className="confirm"
                    >
                        {t("submit")}
                    </button>
                </Col>
            </Row>

            <TablePro
                dataSource={data.map((order) => ({
                    ...order,
                    id: order.order_id,
                }))}
                columns={useCloseRunsheetColumns()}
                selection={"checkbox"}
                unselectableRowsCondition={(row) => !row.available_to_settle}
                disable_reason={
                    "Order has tickets that are in a non-settled state, settle states: (Pickup Confirmed, Delivered, Returned, In Hub)"
                }
                selectedRows={selectionModel}
                setSelectedRows={setSelectionModel}
                setSelectedRecords={setOrders}
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
                selectionModel={selectionModel}
            />
            <SuccessfulRequest
                title={t("settleTicket")}
                body={t("moneyRecievedText")}
                show={showSuccess}
                hide={hideSuccess}
            />
            <UnSuccessfulRequest
                title={t("settleTicket")}
                show={showFailed}
                hide={hideFailed}
                body={error}
            />
            <CreationConfirmation
                sendRequest={() => closeRs(true)}
                title={t("settleTicket")}
                body={error}
                show={showForce}
                hide={hide}
            />
        </>
    );
}

export default ErrorHandler(CloseRunsheet, axiosInstance);
