import React from "react";
import styles from "./TableComponent.module.css";
import {Empty} from "antd";

export default function TableEmpty() {
    return (
        <tbody>
        <tr className={[styles.empty].join(" ")}>
            <td colSpan="200">
                <Empty/>
            </td>
        </tr>
        </tbody>
    );
}