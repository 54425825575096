import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import {notification} from "antd";
import { useParams, useHistory } from "react-router-dom";
import {Form as AntForm, Button, Select, Input, Spin, Collapse} from "antd";
import { notifyErrors } from "../../../api/ErrorNotifier";
import {RECIPIENT_TYPE} from "./Constants";
import KeywordsSelector from "./KeywordsSelector";
import WMButton from "../AllWorkModels/WMButton";
import { debounce } from "lodash";

const { Panel } = Collapse;


const SmsTemplateDetails = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {templateId, sellerTemplateId} = useParams();
    const [template, setTemplate] = useState(null);
    const [templateForm] = AntForm.useForm();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [sections, setSections] = useState([]);
    const [sellersPage, setSellersPage] = useState(1);
    const [sellersSearch, setSellersSearch] = useState("");
    const [sellers, setSellers] = useState([]);
    const [fetchMoreSellers, setFetchMoreSellers] = useState(false);
    const [sellersLoading, setSellersLoading] = useState(false);
    const [baseTemplate, setBaseTemplate] = useState(null);


    const contentRefEn = useRef(null);
    const contentRefAr = useRef(null);


    const getSellers = async (onlyNew = false) => {
        setSellersLoading(true);
        const response = await axiosInstance.get("/FilterSeller/", {
            params: {
                page: sellersPage,
                search: sellersSearch,
                page_size: 10,
            }
        });
        if (response.data) {
            if (onlyNew) {
                setSellers(response.data.data);
            } else {
                setSellers([...sellers, ...response.data.data]);
            }
            setFetchMoreSellers(response.data.next !== null);
            setSellersPage(response.data.next ? response.data.page + 1 : response.data.page);
        } else {
            notifyErrors(response.error);
        }
        setSellersLoading(false);
    };

    const getBaseTemplate = async () => {
        const response = await SmsTemplateRepository.getTemplateById(templateId);
        if (response.success) {
            setBaseTemplate(response.data);
        } else {
            notifyErrors(response.error);
        }
    };


    const handleChange = (event) => {
        const oldValues = templateForm.getFieldValue(event.target.name);
        console.log("Old values", oldValues);
        templateForm.setFieldValue(event.target.name, event.target.value);
        setDirty(templateForm.isFieldsTouched(false));
        console.log("Is dirty", dirty);
    };

    const getTemplate = async () => {
        setLoading(true);
        const response = await SmsTemplateRepository.getSellerTemplateById(sellerTemplateId);
        if (response.success) {
            setTemplate(response.data);
        } else {
            notifyErrors(response.error);
        }
        setLoading(false);
    };

    const onSearchSellers = debounce((value) => {
        setSellersPage(1);
        setSellersSearch(value);
    }, 300);

    const onFetchMoreSellers = (e) => {
        if ((e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) && fetchMoreSellers) {
            getSellers();
        }
    };

    useEffect(() => {
        if (sellerTemplateId !== "new") {
            getTemplate();
        } else {
            getBaseTemplate();
            setTemplate({
                seller: null,
                message_content: "",
                message_content_ar: "",
            });
        }

    }, [sellerTemplateId]);

    useEffect(() => {
        getSellers(true);
    }, [sellersSearch]);

    const createTemplate = async (values) => {
        const response = await SmsTemplateRepository.createSellerTemplate(templateId, values);
        if (response.success) {
            notification.success({message: t("Sms Template created successfully"), placement: "bottomRight"});
            history.push(`/sms_templates/${templateId}/sellers_templates`);
        } else {
            notifyErrors(response.error);
        }
    };

    const updateTemplate = async (values) => {
        const response = await SmsTemplateRepository.updateSellerTemplate(sellerTemplateId, values);
        if (response.success) {
            notification.success({message: t("Sms Template updated successfully"), placement: "bottomRight"});
            await getTemplate();
        } else {
            notifyErrors(response.error);
        }
    };


    const onFinish = async (values) => {
        console.log("Form values", values);
        setSubmitting(true);
        if (sellerTemplateId === "new") {
            await createTemplate(values);
        } else {
            await updateTemplate(values);
        }
        setSubmitting(false);
        setDirty(false);
    };

    const onSelectKeyword = (keyword, setFieldValue, field_name) => {
        let contentRef;
        console.log("Field name", field_name);
        if (field_name.includes("ar")) {
            contentRef = contentRefAr.current;
        } else {
            contentRef = contentRefEn.current;
        }
        console.log("Content ref", contentRef.value);
        console.log("Template form", templateForm.getFieldValue(field_name));
        const startPostion = contentRef.selectionStart;
        const endPostion = contentRef.selectionEnd;
        const newContent = contentRef.value.substring(0, startPostion) + `{{${keyword}}}` + contentRef.value.substring(endPostion);
        console.log("New content", newContent);
        setFieldValue(field_name, newContent);
        setTimeout(() => {
        contentRef.focus();
        contentRef.setSelectionRange(startPostion + keyword.length + 4, startPostion + keyword.length + 4);
        }, 0);
    };
    
    const setContentRefEn = (element) => {
        if (element) {
            contentRefEn.current = element.resizableTextArea.textArea;
        }
    };

    const setContentRefAr = (element) => {
        if (element) {
            contentRefAr.current = element.resizableTextArea.textArea;
        }
    };


    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.sellersSmsTemplates")}
                        </span>

                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {sellerTemplateId === "new" ? t("Create Template") : t("Edit Template")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {sellerTemplateId === "new" ? t("Create Template") : template?.name || t("Edit Template")}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <div>
                        {
                            (loading || !template) && <div className="d-flex justify-content-center align-items-center w-100" style={{height: "50vh"}}> <Spin size="large" /> </div>
                        }
                        {
                            !loading && template &&  (
                        <div>

                           <AntForm
                            layout="vertical"
                            // className="grid-form small-gap"
                            onFinish={onFinish}
                            form={templateForm}
                            // onFinishFailed={onFinishFailed}
                            initialValues={template}
                            requiredMark
                            labelWrap
                            >
                                <Row>
                                    <Col sm="12" md="6" lg="4">
                                        <AntForm.Item
                                            name="seller"
                                            label={t("Seller")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("Please select a seller"),
                                                }
                                            ]}
                                        >
                                            <Select
                                                placeholder={t("Seller")}
                                                options={sellers.map((seller) => ({value: seller.id, label: seller.store_name}))}
                                                loading={sellersLoading}
                                                onPopupScroll={onFetchMoreSellers}
                                                onSearch={onSearchSellers}
                                                showSearch
                                                filterOption={false}
                                                notFoundContent={sellersLoading ? <Spin size="small" /> : null}
                                                onChange={(value) => handleChange({target: {name: "seller", value}})}
                                            />
                                        </AntForm.Item>
                                    </Col>
                                </Row>
                                <Collapse defaultActiveKey={["message_content", "message_content_ar"]}>
                                    <Panel header={t("Message Content (English)")} key="message_content">
                                        <div className="d-flex gap-4 justify-content-start align-items-center">
                                            <AntForm.Item
                                                className="flex-grow-1"
                                                name="message_content"
                                                label={t("Message Content (English)")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("Please enter message content"),
                                                    },
                                                    {
                                                        max: 160,
                                                        message: t("Message content should not exceed 160 characters"),
                                                    }
                                                ]}
                                            >
                                                    <Input.TextArea
                                                        ref={setContentRefEn}
                                                        placeholder={t("Message Content (English)")}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />

                                            </AntForm.Item>
                                            <KeywordsSelector keywords={template.keywords ?? baseTemplate?.sms_template.keywords ?? []} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, "message_content")} />
                                        </div>
                                    </Panel>
                                    <Panel header={t("Message Content (Arabic)")} key="message_content_ar">
                                        <div className="d-flex gap-4 justify-content-start align-items-center">
                                            <AntForm.Item
                                                className="flex-grow-1"
                                                name="message_content_ar"
                                                label={t("Message Content (Arabic)")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("Please enter message content"),
                                                    },
                                                    {
                                                        max: 160,
                                                        message: t("Message content should not exceed 70 characters"),
                                                    }
                                                ]}
                                            >
                                                    <Input.TextArea
                                                        ref={setContentRefAr}
                                                        placeholder={t("Message Content (Arabic)")}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                            </AntForm.Item>
                                            <KeywordsSelector keywords={template.keywords ?? baseTemplate?.sms_template.keywords ?? []} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, "message_content_ar")} />
                                        </div>
                                    </Panel>
                                </Collapse>
                            </AntForm>
                                <div className="w-100 d-flex justify-content-end py-4">
                                    <AntForm.Item>
                                        <Button htmlType="submit" style={{
                                            backgroundColor: "#57BE6C",
                                            color: "#fff",
                                            borderColor: "#57BE6C",
                                            }} type="primary" loading={submitting}
                                            disabled={submitting || !dirty}
                                            onClick={() => templateForm.submit()}
                                        >
                                            { sellerTemplateId === "new" ? t("Create Template") : t("Update Template")}
                                        </Button>
                                    </AntForm.Item>
                                </div>
                        </div>
                        )
                        }
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(SmsTemplateDetails, axiosInstance);
