import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/layout/Header";
import SideMenu from "./components/layout/SideMenu";
import { LanguageContext } from "./components/contexts/LanguageContext";
import { useTranslation } from "react-i18next";
import { useAdminRoutes, useSellerRoutes, useThirdPartyRoutes } from "./global-state/routes";
import generateRoutes from "./global-state/routes/generate-routes";
import { NotFound } from "./components/NotFound";
import RedirectedLogin from "./RedirectedLogin";
import useLanguage from "./hooks/useLanguage";
import { ConfigProvider } from "antd";
import moment from "moment";

function App() {
    const user = useSelector((state) => state.auth);

    const org = useSelector((state) => state.auth.user.org);

    const lang = useLanguage();

    const languageChecker = (englishCallback, arabicCallback) =>
        lang.includes("en") ? englishCallback : arabicCallback;

    const direction = languageChecker("ltr", "rtl");
    const antLocale = languageChecker(
        moment.locale("en_US"),
        moment.locale("ar_EG")
    );

    console.log({ directionApp: direction, lang });

    const theme = createTheme({
        typography: {
            fontFamily: languageChecker(
                ["SF Pro Text"],
                ["Cairo", "sans-serif"].join(",")
            ),
        },
    });
    useEffect(() => {
        document.documentElement.style.setProperty(
            "--primaryColor",
            org?.primary_color ? org?.primary_color : "#57be6c"
        );

        document.documentElement.style.setProperty(
            "--secondaryColor",
            org?.secondary_color ? org?.secondary_color : "#1F0765"
        );
    }, [org]);
    const { unAuthed: unAuthedAdminRoutes, ...authedAdminRoutes } =
        useAdminRoutes();
    const { unAuthed: unAuthedSellerRoutes, ...authedSellerRoutes } =
        useSellerRoutes(user.user?.user?.is_third_party);

    const { unAuthed: unAuthedThirdPartyRoutes, ...authedThirdPartyRoutes } =
        useThirdPartyRoutes();

    console.log({
        direction,
        lang: localStorage.getItem("lang"),
        // langNext: localStorage.getItem("i18nextLng"),
        langState: lang,
    });

    return (
        <LanguageContext.Provider value={{ language: lang }}>
            <ThemeProvider theme={theme}>
                <ConfigProvider
                    direction={direction}
                    locale={antLocale}
                    theme={{
                        "primary-color":
                            document.documentElement.style.getPropertyValue(
                                "--primaryColor"
                            ),
                    }}
                >
                    <div
                        dir={direction}
                        className={languageChecker("App", "AppAr")}
                    >
                        <Router>
                            {user.isAuth ? (
                                <>
                                    <Header />
                                    <SideMenu />
                                    <div
                                        className="pagePadding"
                                        dir={direction}
                                    >
                                        <Switch>
                                            <Route
                                                path={"/"}
                                                exact
                                                component={() => (
                                                    <Redirect
                                                        to={"/dashboard"}
                                                    />
                                                )}
                                            />
                                            {user.user.role === "Seller"
                                                ? Object.values(
                                                      authedSellerRoutes
                                                  ).map((routesGroup) =>
                                                      generateRoutes(
                                                          routesGroup
                                                      )
                                                  )
                                                : user.user.role === "Third Party" ?
                                                Object.values(
                                                      authedThirdPartyRoutes
                                                  ).map((routesGroup) =>
                                                      generateRoutes(
                                                          routesGroup
                                                      )
                                                  )
                                                : Object.values(
                                                      authedAdminRoutes
                                                  ).map((routesGroup) =>
                                                      generateRoutes(
                                                          routesGroup,
                                                          user
                                                      )
                                                  )}
                                            {/*<Route path="*" component={NotFound} />*/}
                                        </Switch>
                                    </div>
                                </>
                            ) : (
                                <Switch>
                                    {generateRoutes(unAuthedAdminRoutes)}
                                    {generateRoutes(unAuthedSellerRoutes)}
                                    {generateRoutes(unAuthedThirdPartyRoutes)}
                                    <Route
                                        path="/"
                                        exact
                                        component={() => (
                                            <Redirect to={"/login"} />
                                        )}
                                    />
                                </Switch>
                            )}
                        </Router>
                        <div className="footer-section">
                            {lang === "en" ? (
                                <p>
                                    © 2022{" "}
                                    <a
                                        target="_blank"
                                        href={`https://tree-code.com/${lang}/`}
                                        rel="noreferrer"
                                    >
                                        TreeCode.
                                    </a>{" "}
                                    All rights reserved.
                                </p>
                            ) : (
                                <p>
                                    @ 2022{" "}
                                    <a
                                        target="_blank"
                                        href={`https://tree-code.com/${lang}/`}
                                        rel="noreferrer"
                                    >
                                        تري كود
                                    </a>{" "}
                                    .جميع الحقوق محفوظة.
                                </p>
                            )}
                        </div>
                    </div>
                </ConfigProvider>
            </ThemeProvider>
        </LanguageContext.Provider>
    );
}

export default App;
