import { LOGOUT, LOGIN, ACCESS_TOKEN } from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import Cookies from "js-cookie";

export const userLogin = (userdata, history) => async (dispatch) => {
    const logindata = await new Promise((resolve, reject) => {
        axios
            .post(BASE_URL + "/auth/jwt/create", userdata)
            .then((res) => {
                resolve(res.data);
                if (res.data) {
                    console.log(res.data);
                    const accessToken = res.data.user.access;
                    const refreshToken = res.data.user.refresh;
                    localStorage.setItem("refreshToken", refreshToken);
                    Cookies.set("accessToken", accessToken);
                    console.log({ data: res.data });
                    dispatch({ type: ACCESS_TOKEN, payload: res.data });
                    dispatch({ type: LOGIN, payload: res.data });
                    history.push("/dashboard");
                    //  const device = axios
                    // .post(
                    //   BASE_URL + "/devices",
                    //   {
                    //     registration_id: localStorage.getItem("fireToken"),
                    //     type: "web",
                    //     user:res.data.id,
                    //     device_id:res.data.email
                    //   },
                    //   {
                    //     headers: {
                    //       Authorization: `Bearer ${accessToken}`,
                    //     },
                    //   }
                    // )
                    // .catch((error) => {
                    //   console.log(error.response);
                    // });
                    if (res.data) {
                        console.log(res.data);
                    }
                }
            })
            .catch((err) => {
                // reject(err);
                if (err.response) {
                    console.log(err.response.data.detail);
                    resolve(err.response.data.detail);
                }
            });
    });
    return logindata;
};

export const LogOut = () => async (dispatch) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    dispatch({ type: LOGOUT });
};
