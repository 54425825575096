import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./AddTicketsToRunSheet.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SortableTable from "../../SortableTable";
import axiosInstance from "../../../api/axios";
import { DataGrid } from "@mui/x-data-grid";
import { runsheetOrders, runsheetOrders_ar } from "../tableColumns";
import useLanguage from "../../../hooks/useLanguage";
import { Form, Select } from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import {
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../modals/confirmationModal";
import TablePro from "../../tables/TablePro";
import OrderDetails from "../OrderDetails";

function AddTicketsToRunSheet({}) {
    const lang = useLanguage();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const prevOrders = useRef(orders);
    const [changed, setChanged] = useState(false);
    const [errors, setErrors] = useState();
    const prevSelectionModel = useRef(selectionModel);
    const [success, setSuccess] = useState(false);
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const history = useHistory();
    const [modalData, setModalData] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filters, setFilters] = useState({
        branch: undefined,
        city: undefined,
        runsheet_type: undefined,
        // area: undefined,
    });
    const [filterData, setFilterData] = useState({
        branch: [],
        city: [],
        runsheet_type: ["RPS", "ODRS", "RRS", "HRS"],
        // area: [],
    });
    const filterCriterion = {
        branch: (it) => +it.branch.id === +filters.branch,
        city: (it) => +it.branch.city === +filters.city,
        runsheet_type: (it) => it.runsheet_type.includes(filters.runsheet_type),
    };
    const filteredData = useMemo(() => {
        const filtered = data.filter((it) => {
            let condition = true;
            for (const key in filters) {
                if (filters[key] !== undefined) {
                    condition = filterCriterion[key](it);
                }
            }
            return condition;
        });

        if (searchTerm)
            return filtered.filter((it) =>
                it.id.toString().includes(searchTerm)
            );
        else return filtered;
    }, [data, filters, searchTerm]);
    const columns = [
        {
            title: t("orderId"),
            dataIndex: "id",
        },
        {
            title: t("store"),
            dataIndex: ["seller", "store_name"],
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row?.seller?.store_name
                        ? row.seller?.store_name
                        : "No Name"}
                </p>
            ),
        },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            dataIndex: "sub_total",
        },
        {
            title: t("status"),
            width: 270,
            dataIndex: "runsheet_type",
            render: (_, row) =>
                row.runsheet_type?.map((status) => {
                    return (
                        <div
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
                    );
                }),
        },
    ];

    const fetchOrderDetails = async (id, page) => {
        const result = await axiosInstance.get(`/Order/${id}`, {
            params: {
                page: page ?? 1,
                page_size: 4,
            },
        })
        .catch((err) => console.log(err));
        if (result) {
            setModalData(result.data);
        }
    };

    const onModalPagination = async (page) => {
        setModalLoading(true);
        await fetchOrderDetails(modalData.id, page);
        setModalLoading(false);
    };

    const getOrders = async () => {
        let result = await axiosInstance
            .get(`/FilterOrder/`, {
                params: {
                    state: "1",
                    page
                },
            })
            .catch((err) => console.log(err));
        if (result) {
            console.log(result.data.data);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };
    const getBranches = async () => {
        let result = await axiosInstance
            .get("/Branch/?page_size=10000")
            .catch((err) => console.log(err));
        if (result) {
            setFilterData((prev) => ({ ...prev, branch: result.data.data }));
        }
    };

    const getCities = async () => {
        let result = await axiosInstance
            .get("City/?page_size=10000")
            .catch((err) => console.log(err));
        if (result) {
            setFilterData((prev) => ({ ...prev, city: result.data.data }));
        }
    };

    useEffect(() => {
        getBranches();
        getCities();
    }, []);

    useEffect(() => {
        getOrders();
    }, [page]);

    async function submit() {
        try {
            const res = await axiosInstance.put(`AddToRunsheet/${id}`, {
                orders: selectedRowKeys,
            });

            if (res.data) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    history.push(`/edit_runsheet_priorities/${id}/ext`);
                }, 400);
            }
        } catch (e) {
            // if (e.response.status < 500) {
            setErrors(t("server_error"));
            setTimeout(() => {
                setErrors();
            }, 1000);
            // }
            // else setErrors()
        }
    }

    return (
        <Container className={styles.container}>
            <SuccessfulRequest
                show={success}
                title={t("runsheetConfirm")}
                body={
                    lang === "en"
                        ? `Runsheet with ID #${id} has been successfully updated`
                        : ` تم تعديل خط سير برقم ${id}`
                }
            />
            <UnSuccessfulRequest
                show={errors}
                title={t("runsheetConfirm")}
                body={errors}
            />
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.runsheets")}</span>

                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.assinedRunsheet")}
                    </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("addTicketsToRunsheet")}</h2>
                </Col>
            </Row>
            <div>
                <div>
                    <Form layout="vertical" className="grid-form">
                        {Object.keys(filters).map((key) => (
                            <Form.Item key={key} label={t(key)}>
                                <Select
                                    allowClear
                                    value={filters[key]}
                                    onChange={(v) =>
                                        setFilters((prev) => ({
                                            ...prev,
                                            [key]: v,
                                        }))
                                    }
                                >
                                    {filterData[key].map((it) => (
                                        <Select.Option
                                            value={
                                                typeof it === "string"
                                                    ? it
                                                    : it.id
                                            }
                                            key={
                                                typeof it === "string"
                                                    ? it
                                                    : it.id
                                            }
                                        >
                                            {typeof it === "string"
                                                ? it
                                                : it.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ))}
                    </Form>
                </div>
                <Row>
                    <Col sm="12">
                        <div style={{ textAlign: "end" }}>
                            <button className="confirm" onClick={submit}>
                                {t("confirm")}
                            </button>
                        </div>
                        <div className="position-relative">
                            <TablePro
                                onSearch={setSearchTerm}
                                columns={columns}
                                selection={"checkbox"}
                                selectedRows={selectedRowKeys}
                                setSelectedRows={setSelectedRowKeys}
                                dataSource={filteredData}
                                onRowClick={(i, row) => fetchOrderDetails(row.id)}
                                pagination={{
                                    size: "medium",
                                    current: page,
                                    onChange: (page) => setPage(page),
                                    total: count,
                                    // showSizeChanger: true,
                                    // pageSizeOptions: ["10", "20", "30"],
                                }}
                            />
                            {
                                modalData && (
                                    <OrderDetails
                                        data={modalData}
                                        loading={modalLoading}
                                        onPagination={onModalPagination}
                                        show={modalData}
                                        setClose={() => setModalData(null)}
                                        current={modalData.tickets.page ?? 1}
                                        tickets={modalData.tickets}
                                        ticketsCount={modalData.tickets.count}
                                    />
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default ErrorHandler(AddTicketsToRunSheet, axiosInstance);
