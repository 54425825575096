import { useHistory, useLocation } from "react-router-dom";
import { useMemo } from "react";

function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}

export function appendQuery(search, key, value) {
    const searchParams = new URLSearchParams(search);
    searchParams.set(key, value);
    return searchParams.toString();
}

export default function useQuery() {
    const { search } = useLocation();
    const history = useHistory();
    return {
        query: useMemo(() => {
            const searchParams = new URLSearchParams(search);
            const entries = searchParams.entries();
            return paramsToObject(entries);
        }, [search]),

        appendQuery: async (key, value, wait = false) => {
            const newUrl = appendQuery(search, key, value);
            if (!wait) await history.push("?" + newUrl);
        },
    };
}
