import { BRANCH } from './actionTypes'

export const reduxBranch = (info) => {
    return {
        type: BRANCH,
        payload: info
    }

}

