import React, {useEffect, useState} from "react";
import styles from "./CommonReportsPage.module.css";
import {toast, ToastContainer} from "react-toastify";
import {Col, Row} from "react-bootstrap";
import {Form, Input, message, Select} from "antd";
import SectionedDatePicker from "../../../form/SectionedDatePicker";
import WMButton from "../../AllWorkModels/WMButton";
import SecondaryWMButton from "../../AllWorkModels/WMButton/SecondaryWMButton";
import StatusCard from "../../../layout/StatusCard";
import ReportsRepository, {
    branchReportsTypes,
    ordersDetailsRequest,
} from "../../../../api/ReportsRepository";
import axiosInstance from "../../../../api/axios";
import ReportsTable from "./ReportsTable";
import {useTranslation} from "react-i18next";
import selectSearchFunction from "../../../../utils/select-search-function";

export default function CommonReportsPage({
                                              formFields = [],
                                              selectedType,
                                              section = "Reports",
                                          }) {
    const [reports, setReports] = useState({});
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState(undefined);
    const [openTable, setOpenTable] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();
    const {t} = useTranslation();
    const fields = [
        ...formFields,
        {
            name: "file_name",
            label: t("reports.report_name"),
            placeholder: t("reports.report_name_ph"),
        },
    ];

    async function onSubmit(formData) {
        setLoading(true);
        const {type, ...params} = formData;
        const {success, data, error} = await ReportsRepository.get(
            selectedType,
            params
        );

        console.log({data});
        console.log({data})
        if (success) setReports(data);
        else {
            messageApi.error(error)
        }
        setOpenTable(false);
        setLoading(false);
    }

    async function onDownload() {
        const {type, ...params} = form.getFieldsValue();
        const {success, data, error} = await ReportsRepository.get(
            selectedType,
            params,
            true
        );

        if (success) {
            messageApi.success("File Downloaded Successfully");
        } else {
            console.log(error.constructor.name)
            messageApi.error(error)
        }
    }

    async function onFetchTable(key, orders) {
        const {success, data, extra} = await ReportsRepository.getTable(
            key,
            orders,
            form.getFieldsValue()
        );

        if (success) setTable({data, extra});
    }

    return (
        <div>
            {contextHolder}
            <Row className="mb-4">
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle">{section}</h2>
                </Col>
            </Row>
            <Form
                form={form}
                layout="vertical"
                className={styles.form}
                onFinish={onSubmit}
            >
                {fields.map((field) => {
                    if (field === undefined) return;
                    if (field.type === "select")
                        return (
                            <Form.Item key={field.name} {...field}>
                                <Select
                                    mode={field.multiple ? "multiple" : undefined}
                                    onClick={field.onClick}
                                    showSearch={field.search}
                                    onChange={field.onChange}
                                    filterOption={field.onSearch ?? selectSearchFunction}
                                    placeholder={field.placeholder}
                                >
                                    {field.options}
                                    {field.onSearch && (
                                        <Select.Option
                                            value={" "}
                                            hidden
                                            disabled
                                        />
                                    )}
                                </Select>
                            </Form.Item>
                        );
                    else
                        return (
                            <Form.Item key={field.name} {...field}>
                                <Input placeholder={field.placeholder}/>
                            </Form.Item>
                        );
                })}
                <Form.Item name="dates" label=" ">
                    <SectionedDatePicker/>
                </Form.Item>
                <div className={styles.buttons}>
                    <WMButton type="submit" htmlType="submit" loading={loading}>
                        {t("reports.generate")}
                    </WMButton>
                    <SecondaryWMButton
                        style={{background: "white"}}
                        onClick={() => {
                            form.resetFields();
                            setReports({});
                        }}
                    >
                        {t("reports.clear")}
                    </SecondaryWMButton>
                </div>
            </Form>
            {reports.summary &&
                (openTable && table?.data && table.data.length > 0 ? (
                    <ReportsTable table={table}/>
                ) : (
                    <div className={styles.reportsContainer}>
                        <div
                            className="row-centered-horizontal"
                            style={{justifyContent: "flex-end"}}
                        >
                            <WMButton onClick={onDownload}>
                                {t("reports.download")}
                            </WMButton>
                        </div>
                        <div className={styles.reportContainer}>
                            <div className={styles.paddingSection}>
                                <div className={styles.reportsBody}>
                                    {Object.keys(reports.summary ?? {})?.map(
                                        (key) => (
                                            <StatusCard
                                                key={key}
                                                onClick={() => {
                                                    setOpenTable(true)
                                                    onFetchTable(
                                                        key,
                                                        reports.orders
                                                    );
                                                }}
                                                type={
                                                    t(`reports.cards.${key}`)
                                                }
                                                number={
                                                    reports.summary[key]
                                                        .value ?? 0
                                                }
                                                styleClass={"statusCard"}
                                                background="white"
                                                description={""}
                                                style={{
                                                    cursor: ordersDetailsRequest[
                                                        key
                                                        ]
                                                        ? "pointer"
                                                        : "auto",
                                                    width: "220px",
                                                    borderLeft: `solid 20px ${reports.summary[key].color}`,
                                                    boxShadow:
                                                        "0px 0px 10px rgba(0, 0, 0, 0.12)",
                                                }}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}
