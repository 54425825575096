import React, { useEffect, useMemo, useState } from "react";
import styles from "./SingleWorkModel.module.css";
import WMTable from "../AllWorkModels/WMTable";
import { Link, useParams } from "react-router-dom";
import WMButton from "../AllWorkModels/WMButton";
import WorkModelRepository from "../../../api/WorkModelRepository";
import { Input, Form, Select } from "antd";
import { ReactComponent as SearchIcon } from "../../../images/search.svg";
import useQuery from "../../../hooks/useQuery";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import SearchInput from "../../SearchInput";
import DimensionRepository from "../../../api/DimensionRepository";
import axiosInstance from "../../../api/axios";
import { ReactComponent as AddIcon } from "../../../images/add_wm.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { string } from "i/lib/util";
import { useSelector } from "react-redux";
import ChangeAttrsModal from "../AllWorkModels/ChangeAttrsModal";
import areas from "../areas";

function EditableRow({ edit, row, rowKey, onRowValueChange }) {
    let dIndex = row.diementions?.findIndex((d) => d.diemention == row.box?.id);
    return (
        <>
            {edit ? (
                <input
                    style={{ color: "#1f5199", minWidth: "50px" }}
                    type="number"
                    min={0}
                    value={
                        row.box
                            ? row[rowKey]?.find(
                                  (d) => d.diemention == row.box?.id
                              )?.increment ?? ""
                            : +row[rowKey]
                    }
                    onChange={(e) =>
                        onRowValueChange(
                            row.box ? row : row.index,
                            rowKey,
                            e.target.value,
                            dIndex
                        )
                    }
                />
            ) : (
                <span style={{ color: "#1f5199" }}>
                    {row.box
                        ? row[rowKey]?.find((d) => d.diemention == row.box?.id)
                              ?.increment ?? ""
                        : +row[rowKey]}
                </span>
            )}
        </>
    );
}

export default function SingleWorkModel(props) {
    const [workModelData, setWorkModelData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [modelType, setModelType] = useState(1);
    const [dimensions, setDimensions] = useState([]);
    const [cities, setCities] = useState([]);
    const [workModel, setWorkModel] = useState("");
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState("");
    const [modelName, setModelName] = useState("");
    const [defaultWeight, setDefaultWeight] = useState("");
    const diementions = useSelector((state) => state.dimention.diementions);
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [selectedAttr, setSelectedAttr] = useState("");
    const [actualCities, setActualCities] = useState([]);
    const { t } = useTranslation();
    const history = useHistory();
    const [areas, setAreas] = useState({});

    async function getAllAreas() {
        const res = await axiosInstance.get(
            `/FilterAreas/?page_size=100000000`
        );
        const rawAreas = res.data.data ?? [];
        const categorizedAreas = rawAreas.reduce((prev, curr) => {
            const cityId = curr.city.id;
            return {
                ...prev,
                [cityId]: [...(prev[cityId] ?? []), curr],
            };
        }, {});

        console.log({ categorizedAreas });
        setAreas(categorizedAreas);
    }

    async function getAreas(city, ind) {
        console.log({ areas });
        console.log({ area: areas[city]?.[ind] });
        return areas[city];
    }

    async function getCities() {
        const res = await axiosInstance.get("/City/?page_size=100000000");
        if (res.data) setCities(res.data.data);

        return res.data.data;
    }

    const changeCity = async (id, ind, list = null) => {
        let copy = list ? [...list] : [...workModelData];
        console.log(ind);
        copy[ind].city = cities.find((c) => c.id == id);
        let res = await getAreas(id, ind);
        copy[ind].areas = res;
        copy[ind].area = res?.[0];
        console.log(copy[ind]);
        setWorkModelData([...copy]);
    };

    const { id } = useParams();
    const filtered = useMemo(() => {
        if (searchQuery !== undefined && searchQuery.length > 0) {
            return workModelData.filter(
                (it) =>
                    it.area.city.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    it.area.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
            );
        } else return [...workModelData];
    }, [searchQuery, workModelData]);

    const extractDimensions = (dimension) =>
        dimension
            ? `${dimension.length}x${dimension.width}x${dimension.height}`
            : "";

    const Dimentionscolumns = Array.isArray(diementions)
        ? diementions
              .filter((d) => d.id != 1)
              .map((box) => {
                  return {
                      key: `diementions__${box.id}`,
                      title: box.name,
                      dataIndex: "diementions",
                      render: (_, row) => (
                          <EditableRow
                              edit={edit}
                              row={{ ...row, box }}
                              rowKey={"diementions"}
                              onRowValueChange={onRowValueChange}
                          />
                      ),
                  };
              })
        : [];
    const columns = [
        {
            title: t("price_id"),
            key: "id",
            dataIndex: "id",
        },
        {
            title: t("city"),
            key: "city",
            dataIndex: "city.name",
            // render: (_, row) => <>{row.area?.city.name}</>,
            render: (_, row) =>
                edit ? (
                    <select
                        style={{ color: "#1f5199", minWidth: "100px" }}
                        className={styles.tableSelect}
                        value={row.city?.id}
                        onChange={(e) => changeCity(e.target.value, row.index)}
                    >
                        {cities.map((city) => (
                            <option key={city.id} value={city.id}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <span style={{ color: "#1f5199" }}>{row.city?.name}</span>
                ),
        },
        {
            title: t("Area"),
            key: "area",
            dataIndex: "area.name",
            // render: (_, row) => <>{row.area?.name}</>,
            render: (_, row) =>
                edit ? (
                    <select
                        style={{ color: "#1f5199", minWidth: "100px" }}
                        className={styles.tableSelect}
                        value={row.area?.id}
                        onChange={(e) =>
                            onRowValueChange(row.index, "area", {
                                city: row.city,
                                name: row.areas.find(
                                    (a) => a.id == e.target.value
                                ).name,
                                id: e.target.value,
                            })
                        }
                    >
                        {row.areas?.map((area) => (
                            <option key={area.id} value={area.id}>
                                {area.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <span style={{ color: "#1f5199" }}>{row.area?.name}</span>
                ),
        },
        {
            title: t("shipping_price"),
            key: "shipping_fees",
            dataIndex: "shipping_fees",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"shipping_fees"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        },
        {
            key: "return_fees",
            title: t("return_price"),
            dataIndex: "return_fees",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"return_fees"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        },
        {
            key: "replace_fees",
            title: t("replacement_price"),
            dataIndex: "replace_fees",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"replace_fees"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        },

        {
            key: "delivery_time",
            title: t("delivery_time"),
            dataIndex: "delivery_time",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"delivery_time"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        },
        {
            key: "return_time",
            title: t("return_time"),
            dataIndex: "return_time",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"return_time"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        },
        // +modelType === 1 && {
        //     key: "over_weight_price",
        //     title: t("over_weight_price"),
        //     dataIndex: "over_weight_price",
        //     render: (_, row) => (
        //         <EditableRow
        //             edit={edit}
        //             row={row}
        //             rowKey={"over_weight_price"}
        //             onRowValueChange={onRowValueChange}
        //         />
        //     ),
        // },
        {
            key: "create_date",
            title: t("createDate"),
            render: (_, row) => <>{row.create_date?.split("T")?.[0] ?? "-"}</>,
        },
    ];

    if (+modelType === 1)
        columns.splice(columns.length - 1, 0, {
            key: "over_weight_price",
            title: t("over_weight_price"),
            dataIndex: "over_weight_price",
            render: (_, row) => (
                <EditableRow
                    edit={edit}
                    row={row}
                    rowKey={"over_weight_price"}
                    onRowValueChange={onRowValueChange}
                />
            ),
        });
    else
        columns.splice.apply(
            columns,
            [columns.length - 1, 0].concat(Dimentionscolumns)
        );

    const prepareData = async () => {
        if (props.location.pathname.startsWith("/copy_work_model")) {
            setEdit(true);
            const brs = await axiosInstance.get("/Branch/?page_size=100");
            setBranches(
                brs.data.data.map((br) => {
                    return { value: br.id, label: br.name };
                })
            );
        }
        const { success, data, error } = await WorkModelRepository.getById(id);
        if (success) {
            let copy = [];
            setWorkModel(data.id);

            for (const it of data.prices) {
                let areas = await getAreas(
                    it.area.city.id,
                    data.prices.indexOf(it)
                );
                copy.push({
                    ...it,
                    diementions: it.diementions_prices,
                    index: data.prices.indexOf(it),
                    areas,
                    city: it.area.city,
                });
            }
            setWorkModelData(copy);
            setModelType(+data.model_type);
            return data;
        }
    };
    useEffect(async () => {
        setLoading(true);
        getCities();
        getAllAreas();
    }, []);

    useEffect(async () => {
        if (Object.keys(areas).length === 0) return;
        setLoading(true);
        let data = await prepareData();
        if (+data.model_type === 2) await getDimensions();

        setLoading(false);
    }, [Object.keys(areas).length]);

    function onRowValueChange(i, key, val, dIndex) {
        const copy = [...workModelData];
        if (key.startsWith("diementions")) {
            console.log(copy[i.index]["diementions"], val, dIndex, i);
            try {
                copy[i.index]["diementions"][dIndex]["increment"] = val;
            } catch (e) {
                copy[i.index]["diementions"]
                    ? copy[i.index]["diementions"].push({
                          diemention: i.box.id,
                          increment: val,
                      })
                    : (copy[i.index]["diementions"] = [
                          { diemention: i.box.id, increment: val },
                      ]);
            }
        } else copy[i][key] = val;
        console.log(copy[i]);
        setWorkModelData(copy);
    }

    console.log({ workModelData });

    async function applyEdit() {
        setEditLoading(true);
        if (props.location.pathname.startsWith("/copy_work_model")) {
            let fieldName =
                +modelType === 1 ? "weight_prices" : "diemention_prices";
            console.log("prices", workModelData);
            let data = {
                model_type: String(modelType),
                default_weight: defaultWeight,
                name: modelName,
                branch: selectedBranch,
                [fieldName]: workModelData.map((p) => {
                    return {
                        ...p,
                        area: p.area.id,
                        id: undefined,
                        diementions_prices: p.diementions
                            ? p.diementions.map((d) => {
                                  return {
                                      ...d,
                                      id: undefined,
                                  };
                              })
                            : undefined,
                    };
                }),
            };
            let result = await axiosInstance
                .post(
                    `${
                        modelType === 1
                            ? "/NewWeightWorkModel/"
                            : "/NewDiementionWorkModel/"
                    }`,
                    data
                )
                .catch((err) => {
                    if (err.response?.data) {
                        Object.keys(err.response.data).map((key) => {
                            toast.error(
                                `${key} : ${
                                    typeof err.response.data[key] === string
                                        ? err.response.data[key]
                                        : err.response.data[key][0]
                                }`
                            );
                        });
                    }
                });
            if (result) history.push("/all_work_models");
        } else {
            // let post_data = workModelData.filter((it) => it.new);
            // if (post_data.length > 0) {
            //     const res = await WorkModelRepository.post(
            //         post_data,
            //         workModel
            //     );
            //     if (res.error) {
            //         toast.error("Error Updating Table");
            //         setEditLoading(false);
            //         return;
            //     }
            // }
            const { success, error } = await WorkModelRepository.put({
                id: workModel,
                model_type: modelType,
                prices: workModelData,
            });

            if (success) {
                await prepareData();
                toast.success("Prices Updated Successfully");
                setEdit(false);
            } else {
                console.log({ error });
                toast.error("Error Updating Table");
            }
        }
        setEditLoading(false);
    }

    const addNew = async () => {
        console.log("add new");
        let copy = [
            {
                index: 0,
                new: true,
            },
        ];
        workModelData.map((it) => {
            copy.push({ ...it, index: it.index + 1 });
        });
        // setWorkModelData(copy);
        await changeCity(cities[0].id, 0, copy);
    };

    async function getDimensions() {
        const { success, data } = await DimensionRepository.get({
            limit: 1000,
        });
        if (success) setDimensions(data);
    }

    const closeChangeModeal = () => {
        setShowChangeModal(false);
    };

    const onOpenChangeModal = (attr) => {
        setSelectedAttr(attr);
        actualUsedCities();
        setShowChangeModal(true);
        setEdit(true);
    };

    const calculateNewValue = (
        changeType,
        changeValueType,
        value,
        oldValue = 0
    ) => {
        let result = 0;
        if (+changeValueType === 0) {
            result =
                +changeType === 0
                    ? value
                    : +changeType === 1
                    ? +oldValue + +value
                    : +oldValue - +value;
        } else {
            result =
                +changeType === 0
                    ? value
                    : +changeType === 1
                    ? +oldValue + (+oldValue * +value) / 100
                    : +oldValue - (+oldValue * +value) / 100;
        }

        return Math.max(0, result);
    };

    const onEditAttr = (attr, value, city, changeType, changeValueType) => {
        console.log({ attr, value, city, changeType, changeValueType });
        let copy = workModelData.map((price) => {
            if (+price.city.id === +city) {
                console.log("catch city");
                if (attr.startsWith("diementions")) {
                    return {
                        ...price,
                        diementions: price.diementions.map((d) => {
                            if (+d.diemention === +attr.split("__")[1])
                                return {
                                    ...d,
                                    increment: calculateNewValue(
                                        changeType,
                                        changeValueType,
                                        value,
                                        d.increment
                                    ),
                                };
                            else return d;
                        }),
                    };
                } else {
                    return {
                        ...price,
                        [attr]: calculateNewValue(
                            changeType,
                            changeValueType,
                            value,
                            price[attr]
                        ),
                    };
                }
            } else return price;
        });
        console.log(workModelData, copy);
        setWorkModelData(copy);
        setShowChangeModal(false);
    };

    const actualUsedCities = () => {
        const citiesSet = new Set(
            workModelData.map((c) => {
                return c.city.id;
            })
        );
        const actual = cities.filter((c) => citiesSet.has(c.id));
        console.log(citiesSet, actual);
        setActualCities(actual);
    };

    return (
        <div className={styles.container}>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.work_models")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.price_list")}</span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.view_work_model")}
                    </h2>
                </Col>
            </Row>
            <ToastContainer position="bottom-right" />
            <div
                className={"row-centered-horizontal"}
                style={{ justifyContent: "space-between" }}
            >
                {" "}
                <div className={styles.info}>
                    {props.location.pathname.startsWith("/copy_work_model") ? (
                        <>
                            <Input
                                placeholder={t("work_model.work_model_name")}
                                value={modelName}
                                onChange={(e) => setModelName(e.target.value)}
                            />
                            {modelType === 1 && (
                                <Input
                                    placeholder={t("work_model.weight")}
                                    value={defaultWeight}
                                    onChange={(e) =>
                                        setDefaultWeight(e.target.value)
                                    }
                                />
                            )}
                            <Select
                                className={styles.select}
                                onChange={(e) => setSelectedBranch(e)}
                                // value={selectedBranch}
                                size="large"
                                options={branches}
                                placeholder={t("work_model.branch_name_ph")}
                            />
                        </>
                    ) : (
                        <SearchInput
                            onChange={setSearchQuery}
                            className={styles.search}
                            placeholder={t("search_city_id")}
                        />
                    )}
                    <Select
                        className={styles.select}
                        size="large"
                        onChange={onOpenChangeModal}
                        placeholder={t("select")}
                        options={columns
                            .filter(
                                (c) =>
                                    ![
                                        "city",
                                        "area",
                                        "id",
                                        "create_date",
                                    ].includes(c.key)
                            )
                            .map((c) => {
                                return { value: c.key, label: c.title };
                            })}
                    />
                </div>
                {edit ? (
                    <WMButton
                        style={{ width: "124px" }}
                        loading={editLoading}
                        onClick={applyEdit}
                    >
                        {t("apply")}
                    </WMButton>
                ) : (
                    <WMButton
                        style={{ width: "124px" }}
                        onClick={() => setEdit(true)}
                    >
                        {t("edit")}
                    </WMButton>
                )}
            </div>
            <WMTable
                columns={columns}
                dataSource={filtered}
                loading={loading}
            />
            <button onClick={addNew} className={styles.addButton} type="button">
                <AddIcon />
            </button>
            <ChangeAttrsModal
                show={showChangeModal}
                onClose={closeChangeModeal}
                cities={actualCities}
                attr={selectedAttr}
                onChange={onEditAttr}
            />
        </div>
    );
}
