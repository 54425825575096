import React, {useState, useEffect} from "react";
import {Row, Col} from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import {Select} from "antd";
import {PaginatedSelect, Radio, TextField} from "../../../../../components/form/TextInputFields";
import {useSelector, useDispatch} from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import {addAccount} from "../../../../../global-state/actions/addAccountAction";
import {
    AccountConfirmationModal,
    CreationConfirmationModal,
} from "../../../../../components/modals/confirmationModal";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {SHOW_ACCOUNT_CREATION_ERROR} from "../../../../../global-state/actions/actionTypes";

export default function ThirPartyPersonalInfo({value, handleChange, onBack}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const errors = useSelector(state => state.account.errors) ?? "";

    const {t, i18n} = useTranslation();

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [ID, setID] = useState("");
    const [password, setPassword] = useState("");
    // const [errors, setErrors] = useState("");

    console.log({errors})
    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        history.push("/all_accounts");
    };
    const validate = (data) => {
        console.log(`Form Data = ${data}`);
        // console.log(formState.isValid)
        dispatch(addAccount(data));

        if (value === 1) {
            handleChange(undefined, value + 1);
            dispatch({type: SHOW_ACCOUNT_CREATION_ERROR, payload: ""})
        } else showModal();

        // handleChange(null, value + 1)
    };

    const getErrors = (e) => {
        console.log(e);
        // setErrors(e);
        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: e,
        })
    };

    console.log({value, account});
    return (
        <>
            <ModifiedForm
                submitFunction={validate}
                defaultValues={account ?? undefined}
            >
                <fieldset disabled={value === 2}>
                    <Row>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="name"
                                    className="formTextField text-area"
                                    title={t("Company Name")}
                                    disabled={false}
                                    name="name"
                                    errors={
                                        errors !== ""
                                            ? errors.name ?? [0]
                                            : null
                                    }
                                    validation={{required: true}}
                                    shouldRegister
                                />
                                <TextField
                                    id="name_ar"
                                    className="formTextField text-area"
                                    title={t("Company Name Arabic")}
                                    disabled={false}
                                    name="name_ar"
                                    errors={
                                        errors !== ""
                                            ? errors.name_ar ?? [0]
                                            : null
                                    }
                                    validation={{required: false}}
                                    shouldRegister
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="phone"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    shouldRegister
                                    name="phone"
                                    type="text"
                                    numiric={true}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.phone?.length > 0
                                                ? errors.phone[0]
                                                : null
                                            : null
                                    }
                                    disabled={false}
                                    validation={{
                                        required: true,
                                        pattern: /^\+[1-9]\d{10,14}$/,
                                    }}
                                />
                                <TextField
                                    id="phone_alt"
                                    className="formTextField text-area"
                                    title={t("altPhone")}
                                    shouldRegister
                                    type="text"
                                    numiric={true}
                                    name="phone_alt"
                                    disabled={false}
                                    validation={{
                                        pattern: /^\+[1-9]\d{10,14}$/,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="email"
                                    className="formTextField text-area"
                                    title={t("email")}
                                    shouldRegister
                                    type="email"
                                    disabled={false}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.email?.length > 0
                                                ? errors?.email[0]
                                                : null
                                            : null
                                    }
                                    name="email"
                                    validation={{required: true}}
                                />
                                <TextField
                                    id="address"
                                    className="formTextField text-area"
                                    title={t("address")}
                                    shouldRegister
                                    name="address"
                                    disabled={false}
                                    validation={{required: true}}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.address?.length > 0
                                                ? errors?.address[0]
                                                : null
                                            : null
                                    }
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="website"
                                    className="formTextField text-area"
                                    title={t("website")}
                                    shouldRegister
                                    name="website"
                                    disabled={false}
                                    validation={{required: false, pattern: /^(http|https):\/\/[^ "]+$/}}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.website?.length > 0
                                                ? errors?.website[0]
                                                : null
                                            : null
                                    }
                                />
                                <TextField
                                    id="facebook"
                                    className="formTextField text-area"
                                    title={t("facebook")}
                                    shouldRegister
                                    name="facebook"
                                    disabled={false}
                                    validation={{required: false, pattern: /^(http|https):\/\/[^ "]+$/}}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.facebook?.length > 0
                                                ? errors?.facebook[0]
                                                : null
                                            : null
                                    }
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="instagram"
                                    className="formTextField text-area"
                                    title={t("instagram")}
                                    shouldRegister
                                    name="instagram"
                                    disabled={false}
                                    validation={{required: false, pattern: /^(http|https):\/\/[^ "]+$/}}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.instagram?.length > 0
                                                ? errors?.instagram[0]
                                                : null
                                            : null
                                    }
                                />
                                <TextField
                                    id="twitter"
                                    className="formTextField text-area"
                                    title={t("twitter")}
                                    shouldRegister
                                    name="twitter"
                                    disabled={false}
                                    validation={{required: false, pattern: /^(http|https):\/\/[^ "]+$/}}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.twitter?.length > 0
                                                ? errors?.twitter[0]
                                                : null
                                            : null
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </fieldset>

                <Row>
                    <Col className="text-end buttons-margin" sm="12">
                        <button
                            className="cancel"
                            onClick={onBack}
                            type="button"
                        >
                            {t("discard")}
                        </button>
                        <input
                            className="confirm"
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </ModifiedForm>
            <CreationConfirmationModal
                getErrors={getErrors}
                setPassword={setPassword}
                setID={setID}
                title={t("accountCreation")}
                show={show}
                showConfirm={showConfirmModal}
                hide={hide}
                account={account}
                onBack={onBack}
            />
            <AccountConfirmationModal
                show={showConfirm}
                hide={hideConfirm}
                id={ID}
                password={password}
                account={account}
                onBack={onBack}
            />
        </>
    );
}
