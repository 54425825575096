import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../style-sheets/cards.css";
import { Tooltip } from "reactstrap";
import axiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";

const StatusCard = ({
    id,
    styleClass,
    style,
    background,
    number,
    type,
    selectedCard,
    description,
    onClick,
    summaryFilters,
    cityFilter,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [currentdownload, setCurrentdownload] = useState(null);

    const handleDownloadSheet = async (id) => {
        setCurrentdownload(id);
        const endpoint = `/Filter${
            [1, 2, 3].includes(id) ? "Order" : "Ticket"
        }/`;
        const res = await axiosInstance
            .get(endpoint, {
                params: {
                    state: ![1, 2, 3].includes(id) ? id : "3",
                    export: true,
                    order_type: [1, 2, 3].includes(id) ? id : "",
                    ...(summaryFilters ?? {}),
                    seller_address__area__city: [1, 2, 3].includes(id) ? cityFilter : "",
                    order__seller_address__area__city: ![1, 2, 3].includes(id) ? cityFilter : "",
                },
                responseType: "blob",
            })
            .catch((err) => {
                if (err.response && err.response.data instanceof Blob) {
                    // If the error response is a blob, attempt to read it as text
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        try {
                            const jsonData = JSON.parse(reader.result);
                            toast.error(jsonData);
                            // Handle the JSON error response
                        } catch (jsonError) {
                            console.log(
                                "Error response as Blob:",
                                reader.result
                            );
                            // Handle the blob error response
                        }
                    };
                    reader.readAsText(err.response.data);
                }
                setCurrentdownload(null);
            });
        if (!res) return;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
        setCurrentdownload(null);
        document.body.removeChild(link);
    };

    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div className="my-4" onClick={onClick} style={{ overflow: "hidden" }}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
            />
            <div style={style} className={styleClass}>
                <div
                    className="padding"
                    style={{
                        background:
                            selectedCard === type ? background : "white",
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            xs="12"
                        >
                            {" "}
                            <h6 className="statusTitle">{type}</h6>
                            <span>
                                {" "}
                                {description ? (
                                    <>
                                        {" "}
                                        <i
                                            style={{
                                                // position: "absolute",
                                                // top: "15px",
                                                // right: "15px",
                                                color:
                                                    selectedCard === type
                                                        ? "#1F0765"
                                                        : "#C0C0C0",
                                            }}
                                            id="TooltipExample"
                                            className="fas fa-info-circle ml-5"
                                        ></i>
                                        <Tooltip
                                            placement="right"
                                            isOpen={tooltipOpen}
                                            target="TooltipExample"
                                            toggle={toggle}
                                        >
                                            {description ?? "No Description"}
                                        </Tooltip>
                                    </>
                                ) : null}
                            </span>
                        </Col>
                        {/* <Col xs="2"> */} {/* </Col> */}
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div className="d-flex flex-row justify-content-between align-items-center pe-1">
                                <p className="statusNumber">{number}</p>
                                {/*here is will be export icon to export excel sheet*/}
                                {currentdownload === id ? (
                                    <i
                                        style={{
                                            // position: "absolute",
                                            // bottom: "15px",
                                            // right: "15px",
                                            color:
                                                selectedCard === type
                                                    ? "#1F0765"
                                                    : "#C0C0C0",
                                            fontSize: "24px",
                                        }}
                                        className="fas fa-spinner fa-spin"
                                    ></i>
                                ) : (
                                    <i
                                        style={{
                                            // position: "absolute",
                                            // bottom: "15px",
                                            // right: "15px",
                                            color:
                                                selectedCard === type
                                                    ? "#1F0765"
                                                    : "#C0C0C0",
                                            fontSize: "24px",
                                        }}
                                        className="fas fa-file-excel"
                                        role="button"
                                        onClick={() => handleDownloadSheet(id)}
                                    ></i>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default StatusCard;
