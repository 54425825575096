import React from "react";
import styles from "./AllWorkModels.module.css";
import WorkModelsTable from "./WorkModelsTable";

export default function AllWorkModels({}) {
    return (
        <div className={styles.container}>
            <WorkModelsTable/>
        </div>
    );
}
