import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LOGIN } from "../../../global-state/actions/actionTypes";
import useLanguage from "../../../hooks/useLanguage";
import { useHistory } from "react-router-dom";

function Organization() {
    const { t, i18n } = useTranslation();

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [showEmailModel, setShowEmailModel] = useState(false);
    const [firstName, setFirstName] = useState(user.user.name?.split(" ")[0]);
    const [lastName, setLastName] = useState(user.user.name?.split(" ")[1]);
    const [email, setEmail] = useState(user.user.email);
    const [newEmail, setNewEmail] = useState(user.user.email);
    const [org, setOrg] = useState("");
    const [phone, setPhone] = useState(user.user.phone);
    const [verify, setVerify] = useState();
    const [loading, setLoading] = useState(false);
    const language = useLanguage();
    const history = useHistory();
    const hide = () => {
        setShowEmailModel(false);
    };

    const handleInput = (e, setFunction) => {
        setFunction(e.target.value);
    };
    const sendCode = async () => {
        if (newEmail === user.user.email) {
            setTimeout(() => {
                toast.error(
                    language.includes("en")
                        ? "New email must be different from old email."
                        : "يجب ان يكون البريد الاكلتروني مختلف"
                );
            }, 500);
        } else {
            let result = await axiosInstance.post("/change_email", {
                email: newEmail,
            });
            if (result) {
                setTimeout(() => {
                    toast.success(
                        language.includes("en")
                            ? "Verification Code Sent."
                            : "تم ارسال الكود بنجاح"
                    );
                }, 500);
            }
        }
    };

    const getOrg = async () => {
        let result = await axiosInstance
            .get("/Organization/")
            .catch((err) => console.log(err));

        if (result) {
            setOrg(result.data.data[0].name);
        }
    };

    const openMailModel = () => {
        setShowEmailModel(true);
    };

    useEffect(() => {
        console.log(user);
        getOrg();
    }, []);

    useEffect(() => {
        console.log(phone);
    }, [phone]);

    const saveChanges = async () => {
        setLoading(true);
        console.log({ lastName });
        let data = {
            name: (firstName ?? "") + " " + (lastName ?? ""),
            org_name: org,
            phone: phone,
        };
        console.log({ data, user });
        const newUser = structuredClone(user);
        newUser.user.name = data.name;
        newUser.user.phone = data.phone;
        dispatch({
            type: LOGIN,
            payload: {
                ...user,
                user: {
                    ...user.user,
                    name: data.name,
                    phone: data.phone,
                    organization: {
                        ...user.user.organization,
                        name: data.org_name,
                    },
                },
            },
        });
        let result = await axiosInstance.put("/Organization/", data);
        if (result) {
            setLoading(false);
            toast.success(
                language.includes("en")
                    ? "Organization Details Updated Successfully"
                    : "تم تعديل معلومات المؤسسة بنجاح"
            );
            setTimeout(() => {
                history.push("/");
            }, 1000);
        }
    };

    const sendVerify = async (e) => {
        let data = {
            code: verify,
        };
        let result = await axiosInstance.post("/change_email_code", data);
        if (result) {
            setTimeout(() => {
                toast.success(
                    language.includes("en")
                        ? "Code Verified"
                        : "تم التعرف على الكود"
                );
            }, 500);
        }
    };
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.settings")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("organization")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("organization")}</h2>
                </Col>
            </Row>
            <div className="org-card">
                <p className="org-card-title">{t("details")}</p>
                <Row className="mt-3">
                    <Col sm="12" md="6">
                        <div className="position-relative text-area-form">
                            <label className="bold-label">
                                {t("firstName")}
                            </label>
                            <Form.Control
                                value={firstName}
                                onChange={(e) => handleInput(e, setFirstName)}
                                type="text"
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div className="position-relative text-area-form">
                            <label className="bold-label">
                                {t("lastName")}
                            </label>
                            <Form.Control
                                value={lastName}
                                onChange={(e) => handleInput(e, setLastName)}
                                type="text"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm="12" md="12">
                        <div className="position-relative text-area-form">
                            <label className="bold-label">
                                {t("companyName")}
                            </label>
                            <Form.Control
                                value={org}
                                onChange={(e) => handleInput(e, setOrg)}
                                type="text"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm="12" md="12">
                        <div className="position-relative text-area-form">
                            <div className="justified-label">
                                <label className="bold-label">
                                    {t("email")}
                                </label>
                                <p
                                    onClick={openMailModel}
                                    className="change-email"
                                >
                                    {t("changeEmail")}
                                </p>
                            </div>

                            <Form.Control value={email} disabled type="text" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <label className="mt-3">{t("phone")}</label>
                        <PhoneInput
                            defaultCountry="EG"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={setPhone}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm="12" md="12">
                        {/* <button
                            className="cancel"
                        >
                            Cancel
                        </button> */}
                        <button
                            onClick={saveChanges}
                            disabled={loading}
                            className="confirm"
                        >
                            {t("save")}
                        </button>
                    </Col>
                </Row>
                <Modal size="lg" show={showEmailModel} onHide={hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("verifyIdentity")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mt-3">
                            <Col className="position-relative" sm="8" md="8">
                                <div className="position-relative text-area-form">
                                    <label className="bold-label">Email</label>
                                    <Form.Control
                                        value={newEmail}
                                        onChange={(e) =>
                                            handleInput(e, setNewEmail)
                                        }
                                        type="email"
                                    />
                                </div>
                            </Col>
                            <Col className="position-relative" sm="4" md="4">
                                <button
                                    onClick={sendCode}
                                    className="verify-btn confirm"
                                >
                                    {t("sendVerificationCode")}
                                </button>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm="8" md="8">
                                <div className="position-relative text-area-form">
                                    <label className="bold-label">
                                        {t("verificationCode")}
                                    </label>
                                    <Form.Control
                                        value={verify}
                                        onChange={(e) =>
                                            handleInput(e, setVerify)
                                        }
                                        type="text"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("cancel")}
                        </button>
                        <button onClick={sendVerify} className="confirm">
                            {t("verify")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <ToastContainer position="bottom-right" />
        </>
    );
}

export default ErrorHandler(Organization, axiosInstance);
