import { useTranslation } from "react-i18next";
import PageHeader from "../../layout/PageHeader";
import axiosInstance from "../../../api/axios";
import { ThirdPartyKeys } from "../ThirdPartyInfo";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Spin } from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";

const ThirdPartyKeysPage = () => {
    const { t } = useTranslation();
    const [thirdPartyKeys, setThirdPartyKeys] = useState({});
    const [loading, setLoading] = useState(true);

    const getThirdPartyKeys = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get("/ThirdPartyCompany/me")
            .catch((err) => {
                if (err.response.status === 400) {
                    Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                } else {
                    throw err;
                }
            });
            setThirdPartyKeys(response.data);
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getThirdPartyKeys();
    } , []);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{zIndex: 999999}}
                />
            <div>
                <PageHeader title={t("My Third Party Keys")} />
            </div>            
            <div className="d-flex flex-column align-items-start justify-content-start gap-3">
                {
                    loading ? 
                    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
                        <Spin size="large" />
                    </div> :
                    <ThirdPartyKeys keys={thirdPartyKeys} setKeys={setThirdPartyKeys} thirdPartyId={thirdPartyKeys.id} />

                }
            </div>
        </div>
    );
};


export default ErrorHandler(ThirdPartyKeysPage, axiosInstance);