import React, { useState, useEffect } from "react";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { Row, Col, Modal, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LogOut } from "../../../global-state/actions/authAction";
import { useDispatch } from "react-redux";
import {toast, ToastContainer} from "react-toastify"

function Security() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordModal = () => {
    setShowPassword(true);
  };
  const hidePassword = () => {
    setShowPassword(false);
  };
  return (
    <div>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{zIndex: 999999}}
      />
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.settings")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("security")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("security")}</h2>
        </Col>
      </Row>
      <Card className="text-center">
        <Card.Header>{t("password")}</Card.Header>
        <Card.Body>
          {/* <Card.Title>Special title treatment</Card.Title> */}
          <Card.Text>{t("changePassword")}</Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        <Card.Footer className="text-muted">
          <button className="update-password">
            <Link to="/update_password">{t("updatePassword")}</Link>
          </button>
        </Card.Footer>
      </Card>
      {user.role !== "Seller" ?   <Card className="text-center">
        <Card.Header>{t("deleteOrg")}</Card.Header>
        <Card.Body>
          {/* <Card.Title>Special title treatment</Card.Title> */}
          <Card.Text>{t("deleteOrgTitle")}</Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        <Card.Footer className="text-muted">
          <button onClick={async() => {
            if(user.role === "Third Party") {
              await axiosInstance.delete(`/ThirdPartyCompany/me`)
              .then((res) => {
                toast.success("Deleted Successfully");
                dispatch(LogOut())
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  Object.entries(err.response.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
                } else {
                  toast.error("Something went wrong");
                }
              })
              
            } 
              
          } } className="delete-org">{t("deleteOrg")}</button>
        </Card.Footer>
      </Card> : null}
     
    </div>
  );
}

export default ErrorHandler(Security, axiosInstance);
