import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isReAssigned, {
    getReassignedSheet,
} from "../../../global-state/actions/reassignedRsAction";
import axiosInstance from "../../../api/axios";
import { Col, Form, Row } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    runsheetOrders,
    runsheetOrders_ar,
    useOrdersColumns,
} from "../../layout/tableColumns";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../modals/confirmationModal";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";

export default function RequestStep({ value, handleChange }) {
    let history = useHistory();
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [ID, setID] = useState("");

    const data = useSelector((state) => state.rsOrders?.orders) ?? {};
    const courier = useSelector((state) => state.runsheet?.courierObj);
    const branches = useSelector((state) => state.runsheet?.branches);

    const hide = () => {
        setError("");
        setSuccess(false);
        setShow(false);
        history.push("/all_internals");
    };
    const confirm = async () => {
        try {
            const res = await axiosInstance.post("InternalRunsheet/", {
                type: 2,
                courier: courier.id,
                orders: data.map((it) => it.id),
                branch: branches.from.id,
                to_branch: branches.to.id,
            });

            if (res.data) {
                setShow(false);
                setSuccess(true);

            }
        } catch (err) {
            setShow(false);
            console.log({ee:err.response?.data})
            if (err.response?.status < 500) setError(err.response?.data?.courier?.[0]);
            else setError(t("server_error"));
        }


    };

    const discardRunsheet = () => {
        handleChange(null, 0);
    };

    const lang = useLanguage();

    return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">
                            {t("branches.from")}
                        </label>
                        <Form.Control
                            defaultValue={branches.from?.name}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("branches.to")}</label>
                    <Form.Control
                        defaultValue={branches.to?.name}
                        disabled
                        type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("couriername")}</label>
                        <Form.Control
                            defaultValue={courier.user.name}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("courierBranch")}</label>
                    <Form.Control
                        defaultValue={courier.branch?.name}
                        disabled
                        type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">
                            {t("courierVehicle")}
                        </label>
                        <Form.Control
                            defaultValue={courier.vehicle}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("courierPhone")}</label>
                    <Form.Control
                        defaultValue={courier.user.phone}
                        disabled
                        type="text"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button onClick={discardRunsheet} className="cancel">
                        {t("discard")}
                    </button>
                    <button onClick={() => setShow(true)} className="confirm">
                        {t("sendRequest")}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <div>
                        <TablePro
                            dataSource={data}
                            columns={useOrdersColumns()}
                        />
                    </div>
                </Col>
                <CreationConfirmation
                    sendRequest={confirm}
                    show={show}
                    hide={hide}
                    body={`${t("runsheetCreateMsg1")} ${courier.user.name}`}
                    title={t("runsheetConfirm")}
                />
                <SuccessfulRequest
                    show={success}
                    hide={hide}
                    title={t("runsheetConfirm")}
                    body={
                        lang === "en"
                            ? `Runsheet with ID #${ID} has been successfully assigned to ${courier.user.name}`
                            : `تم تعيين خط سير برقم ${ID} للمندوب ${courier.user.name}`
                    }
                />
                <UnSuccessfulRequest
                    show={error.length > 0}
                    hide={hide}
                    title={t("runsheetConfirm")}
                    body={error}
                />
            </Row>
        </>
    );
}