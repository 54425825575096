import React, { useMemo } from "react";
import CenteredWMCard from "../../../AllWorkModels/WorkModelCard/CenteredWMCard";
import { Form, Select } from "antd";
import useQuery from "../../../../../hooks/useQuery";
import SecondaryWMButton from "../../../AllWorkModels/WMButton/SecondaryWMButton";
import WMButton from "../../../AllWorkModels/WMButton";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../../api/axios";
import selectSearchFunction from "../../../../../utils/select-search-function";
import useLanguage from "../../../../../hooks/useLanguage";

export default function CitiesStep({}) {
    const { appendQuery } = useQuery();
    const [cities, setCities] = React.useState([]);
    const [selectedCities, setSelectedCities] = React.useState([]);
    const lang = useLanguage();
    const handleChange = (value) => {
        setSelectedCities(value);
    };
    const handleRemove = (value) => {
        setSelectedCities(selectedCities.filter((c) => c !== value));
    };
    const { t } = useTranslation();

    React.useEffect(() => {
        const fetchCities = async () => {
            const result = await axiosInstance.get("City/?page_size=100");
            console.log(result);
            if (result) setCities(result.data.data);
        };
        fetchCities();
    }, []);

    return (
        <Form
            onFinish={(data) => {
                console.log({ selectedCities });
                appendQuery("cities", selectedCities.join(","));
            }}
        >
            <CenteredWMCard
                title={t("work_model.initial_cities")}
                actions={
                    <>
                        <SecondaryWMButton>{t("cancel")}</SecondaryWMButton>
                        <WMButton htmlType="submit">{t("confirm")}</WMButton>
                    </>
                }
            >
                <label>{t("work_model.choose_cities")}</label>
                <div style={{ gap: "8px", width: "50%", marginTop: "8px" }}>
                    <Form.Item
                        name="initial_cities"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            // value={selectedCities}
                            filterOption={selectSearchFunction}
                            onChange={handleChange}
                            placeholder={t(
                                "work_model.choose_cities_placeholder"
                            )}
                            onDeselect={handleRemove}
                        >
                            {cities.map((city) => {
                                return (
                                    <Select.Option value={city.id}>
                                        {lang === "ar"
                                            ? city.name_ar ?? city.name
                                            : city.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    {/*<Form.Item name="unit">*/}
                    {/*    <Input  placeholder="KG" />*/}
                    {/*</Form.Item>*/}
                </div>
            </CenteredWMCard>
        </Form>
    );
}
