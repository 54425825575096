import React, { useState, useEffect } from "react";
import TablePro from "../../../../components/tables/TablePro";
import {
    useConfirmedRequestsColumns,
    useUnConfirmedRequestsColumns,
} from "./dashboardColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OrderDetails from "../../../../components/layout/OrderDetails";
import axiosInstance from "../../../../api/axios";
import { Select, Switch } from "antd";

function EmptyPlaceholder({ currentStateTitle, onRefresh }) {
    const { t } = useTranslation();
    return (
        <div className="noDataDiv">
            <h6 className="noDataHead">
                {t("refreshTitle")} {currentStateTitle}
            </h6>
            <p>{t("refreshText")}</p>
            <button className="confirm" onClick={onRefresh}>
                {t("refresh")}
            </button>
        </div>
    );
}

export default function DashboardRequestsTable({
    currentState,
    currentStateTitle,
    isUnConfirmedRequest,
    data,
    onSearch,
    onRefresh,
    loading,
    selected,
    setSelected,
    pagination,
    cityFilter,
    setCityFilter,
    tableSelecedObjects,
    setTableSelecedObjects,
}) {
    const user = useSelector((state) => state.auth.user);
    const unConfirmedRequestsColumns = useUnConfirmedRequestsColumns();
    const confirmedRequestsColumns = useConfirmedRequestsColumns();
    const [details, setDetails] = useState();
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [checkStrictly, setCheckStrictly] = useState(false);


    console.log({ details });

    async function fetchDetails(id) {
        console.log("clicked");
        setDetailsLoading(true);
        let result = await axiosInstance.get(`/Order/${id}`);
        if (result) {
            setDetails(result.data);
        }
        setDetailsLoading(false);
    }



    async function onChangeTickets(page) {
        setDetailsLoading(true);
        const newDetails = { ...details };
        newDetails.tickets.page = page;

        let result = await axiosInstance.get(`/Order/${details.id}`, {
            params: {
                page_size: 4,
                page: page,
            },
        });
        if (result) {
            newDetails.tickets = result.data.tickets;
            newDetails.tickets.page = page;
            setDetails(newDetails);
        }
        setDetailsLoading(false);
    }

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <TablePro
                loading={loading}
                columns={
                    isUnConfirmedRequest
                        ? unConfirmedRequestsColumns
                        : confirmedRequestsColumns
                }
                dataSource={loading ? [] : data}
                onSearch={onSearch}
                headerExtra={<ExtraHeader cityFilter={cityFilter} setCityFilter={setCityFilter} checkStrictly={checkStrictly} setCheckStrictly={setCheckStrictly} />}
                selection={user.role !== "Seller" ? "checkbox" : undefined}
                emptyPlaceholder={
                    <EmptyPlaceholder
                        currentStateTitle={currentStateTitle}
                        onRefresh={onRefresh}
                    />
                }
                onRowClick={(i, row) => fetchDetails(row.order?.id ?? row.id)}
                checkStrictly={checkStrictly}
                selectedRows={selected}
                setSelectedRows={setSelected}
                selectedRecords={tableSelecedObjects}
                setSelectedRecords={setTableSelecedObjects}
                pagination={pagination}
                preserveSelectedRowKeys
            />
            {details ? (
                <OrderDetails
                    pagination={onChangeTickets}
                    current={details.tickets.page ?? 1}
                    tickets={details.tickets}
                    ticketsCount={details.tickets.count}
                    show={details}
                    loading={detailsLoading}
                    setClose={() => setDetails(undefined)}
                    data={details}
                />
            ) : null}
        </div>
    );
}


const ExtraHeader = ({ cityFilter, setCityFilter, checkStrictly, setCheckStrictly }) => {
    const { t } = useTranslation();
    const [cities, setCities] = useState([]);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [citiesSearch, setCitiesSearch] = useState("");

    const getCities = async () => {
        setCitiesLoading(true);
        let result = await axiosInstance.get("/FilterCity/", {
            params: {
                page_size: 20,
                search: citiesSearch,
            },
        }).catch((err) => {
            console.log(err);
        }
        );
        if (result) {
            setCities(result.data.data);
        }
        setCitiesLoading(false);
    };

    useEffect(() => {
        getCities();
        console.log("SET CITY FILTER", setCityFilter);
    } , [citiesSearch]);
    

    return (
        <div className="extraHeader">
            <div className="extraHeaderItem">
                <Select
                    value={cityFilter?.length > 0 ? cityFilter : undefined}
                    onChange={(area) => {
                        setCityFilter(area);
                        setCitiesSearch("");
                     }}
                    placeholder={t("Select Area")}
                    options={cities.map((area) => ({
                        value: area.id,
                        label: area.name,
                    }))}
                    loading={citiesLoading}
                    onSearch={setCitiesSearch}
                    onDropdownVisibleChange={getCities}
                    showSearch
                    filterOption={false}
                    style={{ width: "200px" }}
                    allowClear
                    onClear={() => {
                        setCityFilter(undefined);
                        setCitiesSearch("");

                    }}

                />
                <Switch
                className="ms-2"
                    checked={checkStrictly}
                    onChange={(checked) => {
                        console.log(checked);
                        setCheckStrictly(checked);
                    }}
                    checkedChildren={t("Unstrict")}
                    unCheckedChildren={t("Strict")}
                />
            </div>
        </div>
    );
}