import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import InformationCard from "../layout/informationCard";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { Form } from "react-bootstrap";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function BranchesPage(props) {
    const { t } = useTranslation();
    const language = useLanguage();
    const [page, setPage] = useState(1);
    const [type, setType] = useState("");
    const [data, setData] = useState([]);
    const [branches, setBranches] = useState("");
    const [count, setCount] = useState(1);
    const [deleted, setDeleted] = useState();
    const [show, setShow] = useState(false);
    const [editedBranch, setEditedBranch] = useState("");
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [branch, setBranch] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({});
    const [branchAdmin, setBranchAdmin] = useState("");
    const [orgEmployees, setOrgEmployees] = useState([]);
    const columns = [
        {
            key: "name",
            title: `${t("name")}`,
            dataIndex: "name",
        },
        {
            key: "branch_admin",
            title: `${t("Branch Admin")}`,
            dataIndex: "branch_admin",
            render: (_, row) => {
                return (
                    row.branch_admin?.user?.name +
                    " -- " +
                    row.branch_admin?.type
                );
            },
        },
        {
            key: "city",
            title: `${t("cities")}`,
            dataIndex: "city",

            render: (_, row) => {
                const cities = row.city ?? [];
                const first = cities
                    .slice(0, 3)
                    .map((it) => cityNameChecker(it))
                    .join(", ");
                const extra = cities.slice(3).length;
                return (
                    <p className="paragraphMargin">
                        {first}
                        {extra > 0 ? `, +${extra}` : undefined}
                    </p>
                );
            },
        },
        {
            key: "address",
            title: `${t("address")}`,
            dataIndex: "address",
        },
        {
            key: "phone",
            title: `${t("phone")}`,
            dataIndex: "phone",
        },

        {
            key: "actions",
            title: `${t("actions")}`,
            render: (_, row) => (
                <ThreeDotsMenu
                    onClick={() => settingEditedData(row)}
                    link="Area"
                    deleted
                    setEdited={setShow}
                    setDeleted={setDeleted}
                    showModal={setShowDelete}
                    data={row}
                />
            ),
        },
    ];

    function cityNameChecker(city) {
        return language.includes("en") || city.name_ar?.length === 0
            ? city.name
            : city.name_ar;
    }

    const settingEditedData = (data) => {
        setType("edit");
        setBranch(data.name);
        setEditedBranch(data);
        setSelectedCity(data.city.map((it) => it.id));
        setPhone(data.phone);
        setAddress(data.address);
        setBranchAdmin(data.branch_admin?.id);
    };
    useEffect(() => {
        getBranches();
    }, [deleted, page]);

    const getBranches = async () => {
        const result = await axiosInstance.get(`/Branch/?page=${page}`);
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    };
    const handleCity = (value) => {
        setSelectedCity(value);
    };
    const handleInput = (e, setFuntion, validation) => {
        if (validation && validation.required && e.target.value?.length === 0) {
            setErrors({ ...errors, [e.target.name]: [t("requiredField")] });
        } else if (
            e.target.value?.length > 0 &&
            validation &&
            validation.pattern &&
            !validation.pattern.test(e.target.value)
        ) {
            setErrors({ ...errors, [e.target.name]: [validation.message] });
        } else {
            const copy = { ...errors };
            delete copy[e.target.name];
            setErrors(copy);
        }
        setFuntion(e.target.value);
    };
    const createBranch = () => {
        setType("create");
        setAllEmpty();
        setShow(true);
    };
    const getCities = async () => {
        const result = await axiosInstance.get("/City/?page_size=10000");
        setCities(result.data.data);
    };

    const getOrgEmployees = async () => {
        const result = await axiosInstance
            .get("/FilterOrgEmployee/", {
                params: {
                    page_size: 10000,
                    type__in: "1,2",
                },
            })
            .catch((err) => {
                console.log(err.response);
            });
        if (result) {
            setOrgEmployees(result.data.data);
        }
    };

    const confirmRequest = async (type) => {
        let data = {
            name: branch,
            city: selectedCity,
            address,
            phone,
            branch_admin: branchAdmin,
        };
        type !== "create" && delete data.branch_admin;
        let result;
        setErrors({});
        setLoading(true);
        if (type === "create") {
            result = await axiosInstance.post("/Branch/", data).catch((err) => {
                setLoading(false);
                setErrors(err.response.data);
            });
        } else {
            result = await axiosInstance
                .put(`/Branch/${editedBranch.id}`, data)
                .catch((err) => {
                    setLoading(false);
                    setErrors(err.response.data);
                });
        }

        if (result) {
            hide();
            setLoading(false);
            getBranches();
            setAllEmpty();
        }
    };
    const setAllEmpty = () => {
        setBranch("");
        setPhone("");
        setSelectedCity([]);
        setAddress("");
        setErrors({});
        setBranchAdmin("");
    };
    const hide = () => {
        setAllEmpty();
        setShow(false);
    };
    useEffect(() => {
        getCities();
    }, []);
    const lang = useLanguage();

    useEffect(() => {
        getOrgEmployees();
    }, []);

    return (
        <>
            <style>
                {`
            @media (min-width: 600px) {
                .MuiToolbar-root .MuiButtonBase-root {
                  display: block;
                }
              }`}
            </style>
            <div>
                {/* <InformationCard
        title={t("workmodelInfoTitle")}
        subTitle={t("workmodelInfoSub")}
        list={[
          <p>
            {t("select")} <strong>{t("sideMenu.branches")}</strong>
          </p>,
          <p>
            {t("workModelCreateTxt1")} <strong>{t("workModelCreateTxt2")}</strong> {t("workModelCreateTxt3")}
          </p>,
          <p>
           {t("workModelCreateTxt4")} <strong>{t("workModelCreateTxt5")}</strong> {t("workModelCreateTxt6")}
          </p>,
          <p>
            {t("workModelCreateTxt7")} <strong>{t("workModelCreateTxt8")}</strong>.
          </p>,
          <p>
            {t("workModelCreateTxt9")} <strong>{t("workModelCreateTxt10")}</strong> {t("workModelCreateTxt11")}
          </p>
        ]}
      /> */}
                <Row>
                    <Col sm="12" className="btnPosition">
                        <button onClick={createBranch}>
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addNewBranch")}
                        </button>
                    </Col>
                </Row>
                <TablePro
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
                <Modal
                    dir={lang == "ar" ? "rtl" : "ltr"}
                    show={show}
                    onHide={hide}
                    style={{ zIndex: 1050 }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {type === "create"
                                ? t("addNewBranch")
                                : t("editBranch")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{t("branchName")}</label>
                        <Form.Control
                            value={branch}
                            onChange={(e) => handleInput(e, setBranch)}
                            type="text"
                        />
                        {errors.name && (
                            <p className="error">{errors.name[0]}</p>
                        )}
                        {!editedBranch.id && (
                            <>
                                <label>{t("Branch Admin")}</label>
                                <Select
                                    onChange={(value) =>
                                        handleInput(
                                            {
                                                target: { value },
                                            },
                                            setBranchAdmin,
                                            {
                                                required: true,
                                                message: t(
                                                    "Branch Admin is required"
                                                ),
                                            }
                                        )
                                    }
                                    value={branchAdmin}
                                    style={{ width: "100%" }}
                                >
                                    {orgEmployees.map((employee) => (
                                        <Select.Option
                                            key={employee.id}
                                            type={employee.id}
                                            value={employee.id}
                                            style={{
                                                zIndex: 10000,
                                                position: "relative",
                                            }}
                                        >
                                            {employee.user.name +
                                                " -- " +
                                                employee.type}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {errors.branch_admin && (
                                    <p className="error">
                                        {errors.branch_admin[0]}
                                    </p>
                                )}
                            </>
                        )}

                        <label>{t("cities")}</label>
                        {/* <Form.Control defaultValue={zone} disabled type="text" /> */}
                        <Select
                            mode="multiple"
                            onChange={handleCity}
                            value={selectedCity}
                            style={{ width: "100%" }}
                        >
                            {cities.map((city) => (
                                <Select.Option
                                    key={city.id}
                                    type={city.id}
                                    value={city.id}
                                    style={{
                                        zIndex: 10000,
                                        position: "relative",
                                    }}
                                >
                                    {cityNameChecker(city)}
                                </Select.Option>
                            ))}
                        </Select>
                        {errors.city && (
                            <p className="error">{errors.city[0]}</p>
                        )}

                        <label>{t("phone")}</label>
                        <Form.Control
                            value={phone}
                            name="phone"
                            onChange={(e) =>
                                handleInput(e, setPhone, {
                                    required: true,
                                    message: t("phoneErr"),
                                })
                            }
                            type="number"
                            min={0}
                        />
                        {errors.phone && (
                            <p className="error">{errors.phone[0]}</p>
                        )}

                        <label>{t("address")}</label>
                        <Form.Control
                            value={address}
                            onChange={(e) => handleInput(e, setAddress)}
                            type="text"
                        />
                        {errors.address && (
                            <p className="error">{errors.address[0]}</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("close")}
                        </button>
                        <button
                            className="confirm"
                            disabled={loading}
                            onClick={() => confirmRequest(type)}
                        >
                            {type === "create" ? t("addBranch") : t("edit")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default ErrorHandler(BranchesPage, axiosInstance);
