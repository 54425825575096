import React from "react";
import styles from "./TableComponent.module.css";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {ReactComponent as DragIcon} from "../../images/drag.svg";

const TableDragHandle = SortableHandle(() => <DragIcon />)

const TableItem = SortableElement(({
    className,
                                       data,
                                       currentPage,
                                       onSelect,
                                       onCheckBoxChange,
                                       columns,
                                       actions,
                                       disableActions,
                                       row
                                   }) => {
    return <tr tabIndex={0} key={row.id} className={[styles.tableRow, className].join(" ")}>
        {onSelect && (
            <td className={styles.selectable}>
                <div className="row-centered-horizontal">
                                <span
                                    className={[
                                        styles.checkboxContainer,
                                        "row-centered",
                                    ].join(" ")}
                                >
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            onCheckBoxChange(e, row.id)
                                        }
                                    />
                                </span>

                    <span>{row.id}</span>
                </div>
            </td>
        )}
        <td >
            <TableDragHandle />
        </td>
        {columns.map((it) => (
            <td
                key={it.name}
                className={it.className}
                onClick={(e) => it.onClick?.(e)}
            >
                {it.selector(row)}
            </td>
        ))}
        {actions && (!disableActions?.(row) ?? true) && (
            <td
                className={`${styles.actions} unselectable row-centered`}
            >
                {actions.map((it, i) => (
                    <div
                        key={i}
                        onClick={(e) => it.onClick(e, row.id, row)}
                    >
                        {it.component}
                    </div>
                ))}
            </td>
        )}
    </tr>
})

const TableContent = SortableContainer((props) => {
        const {data, currentDragIndex} = props;
        return (
            <tbody>
            {data.map((row, i) => (
                <TableItem  className={i === currentDragIndex ? styles.dragRow : ""} key={`item-${row.id}`} {...props} index={i} row={row} />
            ))}
            </tbody>
        );
    }
)

export default TableContent;