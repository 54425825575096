import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import InformationCard from "../layout/informationCard";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import { useDispatch } from "react-redux";
import WorkModalData from "../modals/workModalData";
import { SuccessfulRequest } from "../modals/confirmationModal";
import WorkModelDR from "../modals/workModelDR";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WorkModalEdit from "../modals/editWorkModal"
function PriceLists() {

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [branch, setBranch] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [count, setCount] = useState(1);
  const [deleted, setDeleted] = useState(false);
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [created, setCreated] = useState(false);
  const [workModelDetails, setWorkModelDetails] = useState(false);
  const [shipmentFee, setShipmentFee] = useState(false);
  const [rtoFee, setRtoFee] = useState(false);
  const [details, setDetails] = useState();
  const [type, setType] = useState("");
  
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: `${t("workModelName")}`,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{row.row?.name}</p>
    },

    {
      field: "prices",
      headerName: `${t("rangeOverAll")}`,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">{t("deliveryRangesAvailable")}</p>
    },

    {
      field: "fee_prices",
      headerName: `${t("shipmentFee")}`,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => (
        <p className="tableID">{t("shipFee")}</p>
      )
    },
    {
      field: "rto_prices",
      headerName: `${t("returnedFee")}`,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => (
        <p className="tableID">{t("RTO")}</p>
      )
    },
    {
      field: "actions",
      headerName: `${t("actions")}`,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => (
        <ThreeDotsMenu
          link="WorkModel"
          getData={getWorkModel}
          setDeleted={setDeleted}
          showModal={setShowDelete}
          setEdited={setShow}
          data={row.row}
        />
      )
    }
  ]);

  const addWorkModel = () => {
    let permission = user.user.permissions?.map((permission) =>permission[1] === "Can add work model" ? true : null);
    return permission.length > 0 ? permission.filter((perm) => perm)[0] : false;
  };

  const hideSuccess = () => {
    setShowSuccess(false);
  };

  const handleInput = (e, setFuntion) => {
    setFuntion(e.target.value);
  };

  const getWorkModel = async () => {
    const result = await axiosInstance.get("/WorkModel/");
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getWorkModel();
  }, [deleted, created]);

  const createWorkModel = () => {
    setShowAdd(true);
  };

  const hide = () => {
    setShow(false);
    setShowAdd(false);
    setShowDelete(false);
    setWorkModelDetails(false);
    setRtoFee(false);
    setShipmentFee(false);
  };

  

  return (
    <div>
      <Row>
        <Col sm="12">
          <span className="title1 text-uppercase"> {t("sideMenu.workModels")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.priceList")}</span>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <h6 className="reqTypeTitle">{t("sideMenu.priceList")}</h6>
        </Col>
      </Row>
      <InformationCard
        title={t("workmodelInfoTitle")}
        subTitle={t("workmodelInfoSub")}
        list={[
          <p>
            {t("select")} <strong>{t("sideMenu.branches")}</strong>
          </p>,
          <p>
            {t("workModelCreateTxt1")}{" "}
            <strong>{t("workModelCreateTxt2")}</strong>{" "}
            {t("workModelCreateTxt3")}
          </p>,
          <p>
            {t("workModelCreateTxt4")}{" "}
            <strong>{t("workModelCreateTxt5")}</strong>{" "}
            {t("workModelCreateTxt6")}
          </p>,
          <p>
            {t("workModelCreateTxt7")}{" "}
            <strong>{t("workModelCreateTxt8")}</strong>.
          </p>,
          <p>
            {t("workModelCreateTxt9")}{" "}
            <strong>{t("workModelCreateTxt10")}</strong>{" "}
            {t("workModelCreateTxt11")}
          </p>
        ]}
      />
      {(user.role === "Operation" && addWorkModel()) ||  user.role === "Organization Admin"  ?  <Row>
        <Col sm="12" className="btnPosition">
          <button onClick={createWorkModel}>
            <i className="fas fa-plus-circle"></i> {t("createWorkModel")}
          </button>
        </Col>
      </Row> :null }
     
      <div className="requestTable">
        <DataGrid
          rowHeight="80"
          rows={data}
          columns={columns}
          disableSelectionOnClick
          columnsPanel
          pagination
          paginationMode="server"
          rowCount={count}
          pageSize={10}
          onCellClick={(p, e) => {
            setDetails(p.row);

            if (p.field === "prices") {
              setType("prices");
            } else if (p.field === "fee_prices") {
              setType("fee");
            } else if (p.field === "rto_prices") {
              setType("rto");
            }
            else{
              return
            }
            console.log(p.row);
            if (details !== []) {
              setWorkModelDetails(true);
            }
          }}
          // onPageChange={async (page) => {
          //     setPage(page);

          //     let result = await axiosInstance.get(`/FilterRunsheet/?state=4&page=${page + 1}`);
          //     setData(result.data.data)
          //     setCount(result.data.count)
          // }}
        />
        {workModelDetails ? (
          <WorkModelDR
            data={details}
            type={type}
            setClose={setWorkModelDetails}
            show={workModelDetails}
          />
        ) : null}
      </div>
      <WorkModalData
        data={details}
        setCreated={setCreated}
        show={showAdd}
        hide={hide}
      />
      <WorkModalEdit data={details} setCreated={setCreated} show={show} hide={hide} getData={getWorkModel} /> 
      {/* <WorkModelDR show={workModelDetails} hide={hide} /> */}
      <SuccessfulRequest
        show={showSuccess}
        hide={hideSuccess}
        title={t("createWorkModel")}
        body={t("createWorkModelText")}
      />
    </div>
  );
}

export default ErrorHandler(PriceLists, axiosInstance);
