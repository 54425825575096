import React from "react";
import styles from "./CreateWorkModel.module.css";
import {Col, Row} from "react-bootstrap";
import Steps from "../Steps";
import {useTranslation} from "react-i18next";

export default function CreateWorkModel({}) {
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.work_models")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.price_list")}</span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.createWorkModel")}
                    </h2>
                </Col>
            </Row>
            <div>
                <Steps/>
            </div>
        </div>
    )
}