import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Select, Form, Table } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import {
    getOrdersColumns,
    runsheetOrders,
    runsheetOrders_ar,
    useOrdersColumns,
} from "../layout/tableColumns";
import { addRunsheet } from "../../global-state/actions/addRunsheet";
import { addRunsheetOrders } from "../../global-state/actions/rsOrdersAction";
import { Pagination } from "antd";
import { search } from "../../global-state/actions/searchAction";
import isReAssignedAction from "../../global-state/actions/reassignedRsAction";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TablePro from "../tables/TablePro";
import { toast, ToastContainer } from "react-toastify";
import OrderDetails from "../layout/OrderDetails";

function RunsheetOrders({ value, isReAssigned, handleChange }) {
    const dispacthReassign = useDispatch();
    const searchDispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const { t } = useTranslation();
    const columns = useOrdersColumns();
    const [filters, setFilters] = useState({
        branch: undefined,
        city: undefined,
        runsheet_type: undefined,
        // area: undefined,
    });
    const [filterData, setFilterData] = useState({
        branch: [],
        city: [],
        runsheet_type: ["RPS", "ODRS", "RRS", "HRS"],
        // area: [],
    });

    const fetchOrderDetails = async (id, page) => {
        const result = await axiosInstance
            .get(`/Order/${id}`, {
                params: {
                    page_size: 4,
                    page: page ?? 1,
                },
            })
            .catch((err) => console.log(err));
        if (result) {
            setModalData(result.data);
        }
    };

    const onPaginateTickets = async (page) => {
        setModalLoading(true);
        await fetchOrderDetails(modalData.id, page);
        setModalLoading(false);
    };

    const filterCriterion = {
        branch: (it) => +it.current_branch.id === +filters.branch,
        city: (it) => {
            return it.current_branch.city?.reduce((prev, cur) => {
                return prev | (+cur.id === +filters.city);
            }, false);
        },
        runsheet_type: (it) => it.runsheet_type.includes(filters.runsheet_type),
    };

    const filteredData = useMemo(() => {
        const filtered = data.filter((it) => {
            let condition = true;
            for (const key in filters) {
                if (filters[key] !== undefined) {
                    condition = filterCriterion[key](it);
                }
            }
            return condition;
        });

        if (searchTerm)
            return filtered.filter((it) =>
                it.id.toString().includes(searchTerm)
            );
        else return filtered;
    }, [data, filters, searchTerm]);

    const [orders, setOrders] = useState([]);

    const prevSelectionModel = useRef(selectionModel);
    const prevOrders = useRef(orders);

    const dispatch = useDispatch();
    useEffect(() => {
        console.log(value);
    }, []);
    const dispatchOrders = useDispatch();
    const reAssignedRS = useSelector(
        (state) => state.reAssignedRs.reAssignedRs
    );
    const getOrders = async () => {
        let result = await axiosInstance
            .get(`/FilterOrder/`, {
                params: {
                    state: "1",
                    page_size: 100000,
                },
            })
            .catch((err) => console.log(err));
        if (result) {
            console.log(result.data.data);
            setData(result.data.data);
        }
    };

    const getBranches = async () => {
        let result = await axiosInstance
            .get("/Branch/?page_size=10000")
            .catch((err) => console.log(err));
        if (result) {
            setFilterData((prev) => ({ ...prev, branch: result.data.data }));
        }
    };

    const getCities = async () => {
        let result = await axiosInstance
            .get("City/?page_size=10000")
            .catch((err) => console.log(err));
        if (result) {
            setFilterData((prev) => ({ ...prev, city: result.data.data }));
        }
    };

    useEffect(() => {
        getBranches();
        getCities();
    }, []);

    useEffect(() => {
        getOrders();
    }, []);

    const searching = (e) => {
        setSearchTerm(e.target.value);
        searchDispatch(search(e.target.value));
    };

    useEffect(() => {
        let orderIDs = [
            ...new Set([...selectionModel, ...prevSelectionModel.current]),
        ];
        let orderObject = [...new Set([...orders, ...prevOrders.current])];
        console.log({ orderIDs, orderObject });
        dispatch(addRunsheet({ orders: orderIDs }));
        dispatchOrders(addRunsheetOrders(orderObject));
    }, [selectionModel]);
    const handleOptions = async (e, v, option) => {
        console.log(e);
        console.log(v);
        let key = v.type;
        dispatch(addRunsheet({ [key]: v.name }));
        if (key === "branch") {
            dispatch(addRunsheet({ selectedBranch: e }));
        }
        option(e);
        let result = await axiosInstance
            .get(`/FilterOrder/?state=1&current_branch=${e}`)
            .catch((err) => console.log(err));

        if (result) {
            setData(result.data.data);
        }
    };

    useEffect(() => {
        if (isReAssigned) {
            getSelectedRsOrders();
        } else {
            getOrders();
        }
    }, []);

    useEffect(() => {
        setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
        setOrders([...orders, ...prevOrders.current]);
        console.log("selection model ", selectionModel);
    }, [page, data]);

    useEffect(() => {
        console.log({ reAssignedRS });
        if (isReAssigned) {
            setFilters({
                runsheet_type: reAssignedRS.type,
                branch: reAssignedRS.branch?.id,
                city: reAssignedRS.branch?.city,
                // area: reAssignedRS.branch.area,
            });
        }
    }, [isReAssigned]);
    const proceed = () => {
        if (!filters.branch) {
            toast.error("Please select branch");
            return;
        }
        const selectedOrders = data.filter((it) =>
            selectedRowKeys.includes(it.id)
        );
        dispatch(
            addRunsheet({ orders: selectedRowKeys, branch: filters.branch })
        );
        dispatchOrders(addRunsheetOrders(selectedOrders));
        const filterObj = Object.keys(filters).reduce((prev, key) => {
            return { ...prev, [key]: filters[key] };
        }, {});
        console.log("FILTER OBJ", filterObj);
        dispatch(addRunsheet(filterObj));
        // }
        handleChange(null, value + 1);
    };

    const getSelectedRsOrders = async () => {
        console.log("ORDER");
        let result = await axiosInstance
            .get(`/Runsheet/${reAssignedRS.id}`)
            .catch((err) => console.log(err));
        if (result) {
            console.log(result.data.data);
            setData(result.data.orders);
            // setSelectedBranch(result.data.data[0].branch);
        }
    };

    useEffect(() => {
        return () => {
            dispacthReassign(isReAssignedAction(false));
            console.log("cleaned up");
        };
    }, []);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={true}
                pauseOnHover={true}
            />
            <Row>
                <Col sm="6">
                    {isReAssigned ? (
                        <p className="orderID">Runsheet ID#{reAssignedRS.id}</p>
                    ) : null}
                </Col>
                <Col className="text-end" sm={6}>
                    {isReAssigned ? (
                        <>
                            {/* <button className="cancel">{t("back")}</button> */}

                            <button onClick={proceed} className="confirm">
                                {t("next")}
                            </button>
                        </>
                    ) : null}
                </Col>
            </Row>
            <div>
                <Form layout="vertical" className="grid-form">
                    {Object.keys(filters).map((key) => (
                        <Form.Item key={key} label={t(key)}>
                            <Select
                                allowClear
                                value={filters[key]}
                                disabled={isReAssigned}
                                onChange={(v) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        [key]: v,
                                    }))
                                }
                            >
                                {filterData[key].map((it) => (
                                    <Select.Option
                                        value={
                                            typeof it === "string" ? it : it.id
                                        }
                                        key={
                                            typeof it === "string" ? it : it.id
                                        }
                                    >
                                        {typeof it === "string" ? it : it.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ))}
                </Form>
            </div>

            {isReAssigned ? null : (
                <Row className="my-2">
                    <Col className="text-end buttons-margin" sm="12">
                        {/* <button className="cancel">{t("discard")}</button> */}

                        <button
                            disabled={selectedRowKeys.length === 0}
                            onClick={proceed}
                            className="confirm"
                        >
                            {t("next")}
                        </button>
                    </Col>
                </Row>
            )}

            <div>
                <Row>
                    <Col sm="12">
                        <div className="position-relative">
                            <TablePro
                                onSearch={setSearchTerm}
                                columns={columns}
                                selection={
                                    !isReAssigned ? "checkbox" : undefined
                                }
                                onRowClick={async (i, row) => {
                                    await fetchOrderDetails(row.id);
                                }}
                                selectedRows={selectedRowKeys}
                                setSelectedRows={setSelectedRowKeys}
                                dataSource={isReAssigned ? data : filteredData}
                                pagination={{
                                    size: "medium",
                                    showSizeChanger: true,
                                }}
                                
                            />
                            {
                                modalData && (
                                    <OrderDetails
                                        pagination={onPaginateTickets}
                                        current={modalData.tickets.page ?? 1}
                                        tickets={modalData.tickets}
                                        ticketsCount={
                                            modalData.tickets.count
                                        }
                                        show={modalData}
                                        loading={modalLoading}
                                        setClose={() => setModalData(null)}
                                        data={modalData}
                                    />
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ErrorHandler(RunsheetOrders, axiosInstance);
