import React, { useEffect, useState } from "react";
import styles from "../OrderChainDetails.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OrderTag from "../OrderTag";
import { Collapse } from "antd";
import { NavLink, useParams } from "react-router-dom";
import SeeMoreButton from "../../../SeeMoreButton";
import TablePro from "../../../tables/TablePro";
import OrderChainHeader from "../OrderChainHeader";
import OrderChainRepository from "../../../../api/OrderChainRepository";
import TicketChainDetails from "../TicketChain";

function OrderChainPersonDetails({ details }) {
    const { t } = useTranslation();
    return (
        <ul>
            {Object.entries(details).map(([key, value]) => (
                <li>
                    <strong>{t(key)}: </strong>
                    <span>{value}</span>
                </li>
            ))}
        </ul>
    );
}

export default function OrderChainDetails() {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const columns = [
        {
            title: t("ticket"),
            dataIndex: "id",
        },
        {
            title: t("desc"),
            dataIndex: "id",
        },
        {
            title: t("price"),
        },
        {
            title: t("weight"),
        },
        {
            title: t("height"),
        },

        {
            title: t("width"),
        },
        {
            title: t("specialLabel"),
        },
        {
            title: t("actions"),
            render: (_, row) => (
                <NavLink to={`ticket_details/${row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];

    const { id } = useParams();

    async function fetchOrderChain() {
        const res = await OrderChainRepository.getById(id);

        if (res.success) setData(res.data);
    }

    useEffect(() => {
        fetchOrderChain();
    }, []);
    return (
        <Container className={styles.container}>
            <OrderChainHeader order={data.order} />

            <div>
                <Collapse
                    bordered={false}
                    expandIconPosition={"end"}
                    className={styles.collapse}
                    style={{ background: "transparent" }}
                >
                    <Collapse.Panel
                        header={t("sellerInfo")}
                        key="1"
                        className={styles.panel}
                        style={{ borderRadius: "7px" }}
                    >
                        <OrderChainPersonDetails details={data.seller ?? {}} />
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={t("customerInfo")}
                        key="1"
                        className={styles.panel}
                        style={{ borderRadius: "7px" }}
                    >
                        <OrderChainPersonDetails
                            details={data.customer ?? {}}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        style={{ display: "none" }}
                        header=""
                        key="2"
                    />
                </Collapse>
            </div>
            <div>
                <TicketChainDetails data={data.summary ?? {}} />
                {/*<TablePro columns={columns} dataSource={[]} />*/}
            </div>
        </Container>
    );
}
