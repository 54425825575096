import i18n from "i18next"

export default function formattedError(error) {
    return {
        success: false,
        error: error?.response?.status < 500 ?
            error?.response.data :
            i18n.t("server_error"),
        status: error?.response?.status || 500,
    };
}