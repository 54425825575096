import styles from "./TableComponent.module.css";
import TableFooter from "./TableFooter";
import {useEffect, useState} from "react";
import TableHeader from "./TableHeader";
import TableEmpty from "./TableEmpty";
import TableColumnsHeader from "./TableColumnsHeader";
import TableLoading from "./TableLoading";
import TableContent from "./TableContent";
import {arrayMoveImmutable} from "array-move";

export default function SortableTable({
                                          columns,
                                          data,
                                          setData,
                                          header,
                                          headerExtra,
                                          actions,
                                          disableAction,
                                          count,
                                          countText,
                                          onSearch,
                                          onSelect,
                                          onPageChange,
                                          onLimitChange,
                                          isLastPage,
                                          actionsTitle,
                                          className,
                                          loading,
                                          error,
                                          ...rest
                                      }) {
    const [selection, setSelection] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    // const messageApi = useMessage();
    useEffect(() => {
        onSelect?.(selection);
    }, [selection]);

    // useEffect(() => {
    //     if (error) messageApi.error("Error Fetching.");
    // }, [error]);

    useEffect(() => {
        onPageChange?.(currentPage + 1);
    }, [currentPage]);

    useEffect(() => {
        if (data.length === 0) setCurrentPage(0);
    }, [data]);

    function onCheckBoxChange(e, id) {
        const {checked} = e.target;

        if (checked) setSelection((prev) => [...prev, id]);
        else setSelection((prev) => prev.filter((it) => it != id));
    }


    function onSortEnd({oldIndex, newIndex}) {
        console.log(oldIndex, newIndex)
        setData(arrayMoveImmutable(data, oldIndex, newIndex))

        // setData(arrayMove(data, oldIndex, newIndex));
    }

    return (
        <div className={[styles.container, className].join(" ")} {...rest}>
            {/*<TableHeader*/}
            {/*    header={header}*/}
            {/*    countText={countText}*/}
            {/*    onSearch={onSearch}*/}
            {/*    headerExtra={headerExtra}*/}
            {/*/>*/}
            <div className={styles.tableContainer}>
                <table className={[styles.table].join(" ")} cellSpacing={0} {...rest}>
                    <TableColumnsHeader
                        onSelect={onSelect}
                        columns={columns}
                        actions={actions}
                        actionsTitle={actionsTitle}
                        disableActions={disableAction}
                    />
                    <>
                        {loading ? (
                            <TableLoading/>
                        ) : (
                            <>
                                {data?.length === 0 ? (
                                    <TableEmpty/>
                                ) : (
                                    <TableContent
                                        lockToContainerEdges={true}
                                        lockAxis={"y"}
                                        useDragHandle={true}
                                        onSortEnd={onSortEnd}
                                        data={data}
                                        disableActions={disableAction}
                                        currentPage={currentPage}
                                        onSelect={onSelect}
                                        onCheckBoxChange={onCheckBoxChange}
                                        columns={columns}
                                        actions={actions}
                                    />
                                )}
                            </>
                        )}
                    </>
                </table>
            </div>
            {/*<TableFooter*/}
            {/*    currentPage={currentPage}*/}
            {/*    onLimitChange={onLimitChange}*/}
            {/*    onPageChange={(page) => setCurrentPage(page)}*/}
            {/*    isLastPage={isLastPage}*/}
            {/*    count={count}*/}
            {/*/>*/}
        </div>
    );
}
