import React from "react";
import styles from "./SearchInput.module.css";
import { Input } from "antd";
import { ReactComponent as SearchIcon } from "../../images/search.svg";

export default function SearchInput({ className, onChange, placeholder }) {
    return (
        <Input
            prefix={<SearchIcon />}
            onChange={(e) => onChange(e.target.value)}
            className={[styles.search, className].join(" ")}
            placeholder={placeholder ?? "Search"}
        />
    );
}