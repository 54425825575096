import React, { useEffect, useState } from "react";
import styles from "./WorkModelsTable.module.css";
import { Col, Row } from "react-bootstrap";
import { addOrder } from "../../../../global-state/actions/addOrderAction";
import { Icon } from "@shopify/polaris";
import { CircleLeftMajor } from "@shopify/polaris-icons";
import WMButton from "../WMButton";
import { ReactComponent as ButtonAddIcon } from "../../../../images/add_button_wm.svg";
import { Table } from "antd";
import WMTable from "../WMTable";
import axiosInstance from "../../../../api/axios";
import { Link } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { ReactComponent as CopyIcon } from "../../../../images/copy.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/delete_wm.svg";
import WorkModelRepository from "../../../../api/WorkModelRepository";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import ToastContext from "react-bootstrap/ToastContext";
import AddSuppliersItem from "../AddSuppliersItem";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDiementions } from "../../../../global-state/actions/diementionsAction";
import {
    ConfirmationModal,
    CreationConfirmation,
    SuccessfulRequest,
    WMDeleteConfirmation,
} from "../../../modals/confirmationModal";
import useLanguage from "../../../../hooks/useLanguage";

export default function WorkModelsTable({}) {
    const [workModelsData, setWorkModelsData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [workModelToDelete, setWorkModelToDelete] = useState();
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const { t } = useTranslation();
    const lang = useLanguage();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleView = async (id) => {
        let result = await axiosInstance.get("DefaultDiemention/");
        if (result) {
            dispatch(setDiementions(result.data.data));
            history.push(`/view_work_model/${id}`);
        }
    };

    const deleteWorkModel = async (row) => {

        const { success, data, error } = await WorkModelRepository.delete(
            row.id
        );
        if (success) {
            // toast.success(t("workmodelDeleted"));
            setWorkModelToDelete(undefined);
            setDeleteSuccess(true);
            setTimeout(() => setDeleteSuccess(false), 3000);
            const copy = [...workModelsData];
            copy.filter((it) => it.id !== row.id);
            setWorkModelsData(copy.filter((it) => it.id !== row.id));
        } else {
            toast.error(error);
        }
    };

    const columns = [
        {
            title: t("trackingTable.number"),
            key: "id",
            dataIndex: "id",
        },
        {
            title: t("name"),
            key: "name",
            dataIndex: "name",
        },
        {
            title: t("model_type"),
            key: "model_type",
            dataIndex: "model_type",

            render: (_, row) => (
                <>
                    {+row.model_type === 1
                        ? t("weight_type")
                        : t("dimensions_type")}
                </>
            ),
        },
        {
            title: t("actions"),
            width: 10,
            key: "actions",
            render: (_, row) => (
                <div className={"actionRow"}>
                    <button onClick={() => handleView(row.id)}>
                        <EditIcon />
                    </button>

                    <Link to={`/copy_work_model/${row.id}`}>
                        <button>
                            <CopyIcon />
                        </button>
                    </Link>
                    <button
                        onClick={async () => {
                            setWorkModelToDelete(row);
                        }}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            ),
        },
    ];

    async function getWorkModels() {
        setLoading(true);
        const res = await axiosInstance.get(
            `/NewWorkModel/?page=${page}&page_size=10000000`
        );

        if (res.data) setWorkModelsData(res.data.data);

        setLoading(false);
    }

    useEffect(() => {
        getWorkModels();
    }, [page]);

    return (
        <div className={styles.container}>
            <ToastContainer position={"bottom-right"} />
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.work_models")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.price_list")}</span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.all_work_models")}
                    </h2>
                </Col>
            </Row>

            <div
                className={"row-centered-horizontal"}
                style={{ justifyContent: "flex-end" }}
            >
                <Link to={"/create_work_model"}>
                    <WMButton icon={<ButtonAddIcon />}>
                        {t("add_new_work_model")}
                    </WMButton>
                </Link>
            </div>
            <WMTable
                columns={columns}
                dataSource={workModelsData}
                loading={loading}
            />
            <WMDeleteConfirmation
                show={workModelToDelete !== undefined}
                hide={() => setWorkModelToDelete(undefined)}
                deleteRequest={() => deleteWorkModel(workModelToDelete)}
                title={
                    lang === "en"
                        ? `Are you sure want to delete ${workModelToDelete?.name} work model`
                        : `" هل انت متأكد انك تريد حذف نموذج عمل ${workModelToDelete?.name}"`
                }
                body={
                    lang === "en"
                        ? "Tip: You can assign all suppliers in this work model to another work model"
                        : "ملحوظة: يمكنك اعادة تعين جميع التجار في نموذج العمل هذا إلى منوذج عمل جديد"
                }
            />
            <SuccessfulRequest
                show={deleteSuccess}
                hide={() => setDeleteSuccess(false)}
                title={lang === "en" ? "Work Model" : "نموذج العمل"}
                body={
                    lang === "en"
                        ? "Work Model deleted successfully"
                        : "تم حذف نموذج العمل بنجاح"
                }
            />
        </div>
    );
}
