import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { SuccessfulRequest } from "../../../modals/confirmationModal";
import { Icon } from "@shopify/polaris";
import { UploadMajor } from "@shopify/polaris-icons";
import { Spin, Upload, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import { REFRESH_ORG } from "../../../../global-state/actions/actionTypes";

function SystemTheme() {
    const { t, i18n } = useTranslation();

    const reader = new FileReader();
    const org = useSelector((state) => state.auth.user.org);
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [primary, setPrimary] = useState("");
    const [second, setSecond] = useState("");
    const [file, setFile] = useState("");
    const dispatch = useDispatch();
    const hide = () => {
        setShowSuccess(false);
    };

    const editTheme = async () => {
        console.log({ org });
        let formdata = new FormData();
        if (file !== "") {
            formdata.append("logo", file);
        }
        formdata.append("primary_color", primary);
        formdata.append("secondary_color", second);

        setLoading(true);
        let result = await axiosInstance
            .put("/Organization/", formdata)
            .catch((err) => {
                setLoading(false);
            });
        if (result) {
            await refreshTheme();
            setShowSuccess(true);
            setLoading(false);
        }
    };

    async function refreshTheme() {
        let newOrg;
        if (org.id) {
            const orgResult = await axiosInstance.get(
                "/Organization/" + org.id
            );
            if (orgResult) newOrg = orgResult.data;
        } else {
            const orgResult = await axiosInstance.get("/Organization/");
            if (orgResult) newOrg = orgResult.data.data[0];
        }

        if (newOrg) {
            dispatch({
                type: REFRESH_ORG,
                payload: {
                    id: newOrg.id,
                    logo: `/media/${newOrg.logo.split('/media/')[1]}`,
                    primary_color: newOrg.primary_color,
                    secondary_color: newOrg.secondary_color,
                },
            });
        }
    }

    useEffect(() => {
        setPrimary(org.primary_color);
        setSecond(org.secondary_color);
    }, []);

    return (
        <div>
            <Container>
                <Row>
                    <Col sm="12">
                        <span className="title2">{t("sideMenu.settings")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("preferences")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title1">{t("systemTheme")}</span>
                    </Col>
                    <Col className="mt-3" sm="12">
                        <h2 className="pageTitle">{t("systemTheme")}</h2>
                    </Col>
                </Row>
                <Row>
                    <p className="uploadLogo">{t("uploadLogo")}</p>
                </Row>

                <Row>
                    <Col sm="12">
                        <DropzoneArea
                            acceptedFiles={["image/*"]}
                            filesLimit={1}
                            dropzoneText={t("uploadLogoText")}
                            onChange={(files) => setFile(files[0])}
                        />
                    </Col>
                </Row>
                <Row>
                    <p className="uploadLogo">{t("selectColors")}</p>
                </Row>
                <Row>
                    <Col sm="12" md="12">
                        <Form.Label htmlFor="exampleColorInput">
                            {t("primaryColor")}
                        </Form.Label>
                        <Form.Control
                            type="color"
                            id="exampleColorInput"
                            value={primary}
                            title="Primery Color "
                            // value={color}
                            onChange={(e) => setPrimary(e.target.value)}
                        />
                    </Col>
                    <Col sm="12" md="12">
                        <Form.Label htmlFor="exampleColorInput">
                            {t("secondaryColor")}
                        </Form.Label>
                        <Form.Control
                            type="color"
                            id="exampleColorInput"
                            value={second}
                            title="Primery Color "
                            // value={color}
                            onChange={(e) => setPrimary(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3" sm="12">
                        <button onClick={editTheme} className="confirm">
                            {t("save")}
                        </button>
                    </Col>
                </Row>
            </Container>
            <SuccessfulRequest
                hide={hide}
                show={showSuccess}
                body={t("successfulRequest")}
                title={t("systemTheme")}
            />
        </div>
    );
}

export default ErrorHandler(SystemTheme, axiosInstance);
