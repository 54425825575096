import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
// import { managerCol,sellerCol,courierCol } from "../../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { CreationConfirmation } from "../../modals/confirmationModal";
import CourierInfoModel from "../../modals/CourierInfoModel";
import { useHistory } from "react-router-dom";
import { getSelectedUser } from "../../../global-state/actions/getSelectedAction";
import { useTranslation } from "react-i18next";
import CourierChangePassword from "./CourierChangePassword";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";

const UserTable = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [selectionModel, setSelectionModel] = useState("");
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedUserID, setSelectedUserID] = useState("");
    const [show, setShow] = useState(false);
    const [showCourier, setShowCourierInfo] = useState(false);
    const [action, setActions] = useState("");
    const [active, setActive] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const { Option } = Select;
    const user = useSelector((state) => state.auth.user);
    const history = useHistory();
    const getUserDispatch = useDispatch();
    const { t } = useTranslation();
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [doRedirect, setDoRedirect] = useState(false);
    useEffect(() => {
        if (selectedRows.length == 1) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [selectedRows]);

    async function fetch() {
        setLoading(true);
        console.log(filters);
        const result = await axiosInstance.get(props.fetchEndpoint, {
            params: {
                page,
                search: keyword,
                ...filters,
            },
        });
        if (result) {
            console.log(result);
            setData(result.data.data);
            setCount(result.data.count);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [page, keyword, filters]);

    useEffect(() => {
        setSelectedUser(data.filter((it) => selectedRows.includes(it.id)));
        setSelectedUserID(selectedRows);
        console.log(selectedUser);
    }, [selectedRows]);

    const showModel = () => {
        if (selectedUserID.length !== 0) {
            setShow(true);
        }
    };
    const hide = () => {
        setShow(false);
    };
    const deleteAccount = async () => {
        console.log(selectedUserID);
        let result = await axiosInstance.delete(
            `${props.deleteEndpoint}${selectedUserID[0]}`
        );
        console.log(result);
        if (result) {
            if (result.data == "") {
                hide();
                fetch();
            }
        }
    };

    const showCourierInfo = (action) => {
        console.log(selectedUser);
        setActions(action);
        setShowCourierInfo(true);
    };
    const hideInfo = () => {
        setShowCourierInfo(false);
    };
    const redirect = () => {
        if (selectedUserID.length == 1) {
            getUserDispatch(getSelectedUser(selectedUser));
            history.push(
                `/user_details/${props.userType}/${selectedUserID[0]}`
            );
        }
    };
    useEffect(() => {
        if (doRedirect && selectedUserID[0] && selectedUser[0]) redirect();
    }, [doRedirect, selectedUserID, selectedUser]);
    const lang = useLanguage();

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col className="text-end" sm="12">
                        <i
                            className={`${
                                selectedRows.length === 1
                                    ? "userTableIconActive fas fa-eye userTableIcon"
                                    : "userTableIconInActive fas fa-eye userTableIcon"
                            }`}
                            onClick={
                                props.userType == "courier"
                                    ? () => showCourierInfo("show")
                                    : redirect
                            }
                        ></i>
                        <i
                            onClick={
                                props.userType == "courier"
                                    ? () => showCourierInfo("edit")
                                    : redirect
                            }
                            className={`${
                                selectedRows.length === 1
                                    ? "userTableIconActive fas fa-pen userTableIcon"
                                    : "userTableIconInActive fas fa-pen userTableIcon"
                            }`}
                        ></i>
                        {props.userType !== "courier" && (
                            <i
                                className={`${
                                    selectedRows.length > 0
                                        ? "userTableIconActive fas fa-archive userTableIcon"
                                        : "userTableIconInActive fas fa-archive userTableIcon"
                                }`}
                                onClick={showModel}
                            ></i>
                        )}
                    </Col>
                </Row>
                <div>
                    <TablePro
                        loading={loading}
                        headerExtra={
                            <div className="row-centered-horizontal gap-1">
                                {Object.keys(props.filters ?? {}).map((key) => (
                                    <Select
                                        key={key}
                                        onChange={(v) => {
                                            setFilters({
                                                ...filters,
                                                [key]: v,
                                            });
                                        }}
                                        value={
                                            props.filters[key]?.selectedValue
                                        }
                                        allowClear
                                        style={{ minWidth: "150px" }}
                                        placeholder={props.filters[key].title}
                                    >
                                        {props.filters[key].values.map((it) => (
                                            <Select.Option
                                                key={it.id}
                                                value={it.id}
                                            >
                                                {it.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            </div>
                        }
                        selection={"radio"}
                        columns={props.columns}
                        dataSource={data}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        pagination={{
                            current: page,
                            total: count,
                            onChange: (page) => setPage(page),
                        }}
                        onRowClick={(i, record) => {
                            setSelectedUser([record]);
                            setSelectedUserID([record.id]);
                            setDoRedirect(true);
                        }}
                        searchPlaceholder={props.searchText}
                        onSearch={setKeyword}
                    />
                </div>
            </Container>
            <CreationConfirmation
                sendRequest={deleteAccount}
                show={show}
                hide={hide}
                body={
                    lang == "ar"
                        ? "هل تريد تعطيل هذا الحساب"
                        : `Are you sure that you want to Archive this user ?`
                }
                title={
                    lang == "ar" ? "أرشفة الحساب" : "Archive User Confirmation"
                }
            />

            {selectedUser.length !== 0 && props.userType == "courier" ? (
                <>
                    <CourierInfoModel
                        setShow={setShowCourierInfo}
                        action={action}
                        showCourier={showCourier}
                        info={selectedUser}
                        hideInfo={hideInfo}
                        getData={fetch}
                        onOpenChangePassword={() => {
                            setShowCourierInfo(false);
                            setOpenChangePassword(true);
                        }}
                    />
                    <CourierChangePassword
                        show={openChangePassword}
                        courierId={selectedUser[0]?.id}
                        onHide={() => {
                            setOpenChangePassword(false);
                            setSelectedUser([]);
                        }}
                    />
                </>
            ) : null}
        </div>
    );
};

export default ErrorHandler(UserTable, axiosInstance);
