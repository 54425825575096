import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { useSmsTemplateColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { notifyErrors } from "../../../api/ErrorNotifier";

const AllSmsTemplates = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [queryParams, setQueryParams] = useState({
        page: 1,
        page_size: 10,
        search: "",
    });
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loadingAction, setLoadingAction] = useState(false);

    const handleActivateAction = async (id) => {
        setLoadingAction(true);
        const response = await SmsTemplateRepository.actionActivateSwitch(id);
        if (response.success) {
            getWarehouses();
        } else {
            notifyErrors(response.error);
        }
        setLoadingAction(false);
    }
    const columns = useSmsTemplateColumns(handleActivateAction, loadingAction, setSelectedTemplate);

    const getWarehouses = async () => {
        setLoading(true);
        const response = await SmsTemplateRepository.getAllTemplates(queryParams);
        if (response.success) {
            setSmsTemplates(response.data);
            setCount(response.count);
        } else {
            notifyErrors(response.error);
        }
        setLoading(false);
    }

    const handleChangeQueryParams = (param, value) => {
        setQueryParams({ ...queryParams, [param]: value, page: param === "page" ? value : 1 });
    }

    useEffect(() => {
        getWarehouses();
    }, [queryParams]);

    



    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.smsTemplates")}
                        </span>

                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.allSmsTemplates")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("sideMenu.allSmsTemplates")}
                        </h2>
                    </Col>
                </Row>

                <Row>
                    <div>
                        <div>
                            <TablePro
                                loading={loading}
                                emptyPlaceholder={
                                    <div>
                                        <h6 className="noDataHead">
                                            {t("noDataFound")}
                                        </h6>
                                        <p>
                                            {t("There are no sms templates available")}
                                        </p>
                                    </div>
                                }
                                onSearch={(value) => handleChangeQueryParams("search", value)}
                                dataSource={smsTemplates}
                                columns={columns}
                                pagination={{
                                    current: queryParams.page,
                                    total: count,
                                    onChange: (page) => handleChangeQueryParams("page", page),
                                }}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(AllSmsTemplates, axiosInstance);
