import SelectSearch from "./SelectSearch";
import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../api/axios";
import { Select } from "antd";
import useOnScreen from "./onscreen";

export default function SelectAreas(props) {
    const initialOptions = props.initialOptions ?? [];
    const [options, setOptions] = useState([...initialOptions]);
    const [page, setPage] = useState(1);
    const [isAllFetched, setAllFetched] = useState(false);
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [alreadyFetched, setAlreadyFetched] = useState(false);
    const onScroll = async (event) => {
        var target = event.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (isAllFetched) getAreas();
        }
    };

    useEffect(async () => {
        setLoading(true);
        setPage(1);

        const current =
            props.currentArea && !alreadyFetched
                ? await getCurrentArea(props.currentArea)
                : undefined;
        getAreas(current).then(() => setLoading(false));
    }, [search, props.currentArea]);

    useEffect(() => {}, [props.value]);
    const getAreas = async (current) => {
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    search,
                    page_size: 100,
                    page,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            if (current)
                setOptions((prev) => [
                    ...new Set([
                        ...prev.filter((it) => it.id === current.id),
                        current,
                    ]),
                ]);
            setOptions((prev) => [...new Set([...prev, ...result.data.data])]);
            setPage(page + 1);
            setAllFetched(result.data.next);
        }
    };

    const getCurrentArea = async (id) => {
        const res = await axiosInstance.get(`/Area/${id}`);
        setAlreadyFetched(true);
        return res.data;
    };
    return (
        <SelectSearch
            onPopupScroll={onScroll}
            name={"area"}
            loading={loading}
            options={options}
            onSearch={(v) => {
                console.log("On Search", v);
                setPage(1);
                setSearch(v);
                setOptions([]);
            }}
            {...props}
        />
    );
}
