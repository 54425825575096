import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { SuccessfulRequest } from "../modals/confirmationModal";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";
import { message } from "antd";
import { getSelectedUser } from "./../../global-state/actions/getSelectedAction";
import workModalData from "../modals/workModalData";
import useLanguage from "../../hooks/useLanguage";

const AccountInfo = (props) => {
    const selectedUserState = useSelector(
        (state) => state.selectedUser.selectedUser
    );
    const { t } = useTranslation();
    const [name, setName] = useState(selectedUserState[0].user.name);
    const [phone, setPhone] = useState(selectedUserState[0].user.phone);
    const [phone2, setPhone2] = useState(selectedUserState[0].user.phone_alt);
    const [email, setEmail] = useState(selectedUserState[0].user.email);
    const [storeName, setStore] = useState(selectedUserState[0].store_name);
    const [assignToTerminal, setAssignToTerminal] = useState(
        selectedUserState[0].assign_to_terminal
    );
    const [zones, setZones] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState({ old: {}, new: [] });
    const [selectedZone, setSelectedZone] = useState([]);
    const [next, SetNext] = useState("");
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedBranchesID, setSelectedBranchesID] = useState([]);
    const [selectedZoneID, setSelectedZoneID] = useState([]);
    const [selectedArea, setselectedArea] = useState([]);
    const [newAddresses, setNewAddresses] = useState([]);
    const [oldAddresses, setOldAddresses] = useState([]);
    const [newWorkModels, setNewWorkModels] = useState({ old: {}, new: [] });
    const [selectedWorkModel, setWorkModel] = useState(
        selectedUserState[0].work_model?.name
    );
    const [deletedAddresses, setDeletedAddresses] = useState([]);
    const [models, setModel] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const dispatch = useDispatch();
    const [show, setShow] = useState("");
    const hideModel = () => {
        setShow(false);
    };

    const getArea = async (i, type, city = null) => {
        const id =
            type === "new"
                ? newAddresses[i]?.["new_work_model"]
                : oldAddresses[i]?.["new_work_model"];
        console.log({
            b: branches.find((b) => b.id == id)?.city.map((it) => it.id),
            city,
        });

        const areasData = await axiosInstance.get(
            `/FilterAreas/?new_work_model=${
                id
                // city ? city : branches.find((b) => b.id == id)?.city?.map((it) => it.id)
            }`
        );
        if (areasData) {
            let copy = { ...areas[type] };
            copy[i] = areasData.data.data;
            console.log(areas, copy, "from_area");
            setAreas({ ...areas, [type]: copy });
            if (city) return areasData.data.data;
        }
    };
    const getNewWorkModel = async (i, type, branch = null) => {
        const id =
            type === "new"
                ? newAddresses[i].branch
                : branch === null
                ? oldAddresses[i]?.branch
                : branch;
        const workModelsData = await axiosInstance.get(
            `/NewWorkModel/?branch=${id}&page_size=1000000
            `
        );
        if (workModelsData) {
            let copy = { ...newWorkModels[type] };
            copy[i] = workModelsData.data.data;
            setNewWorkModels({ ...newWorkModels, [type]: copy });
        }
        if (branch) return workModelsData.data.data;
    };
    const updateUser = async () => {
        const result = await axiosInstance.get(
            `/FilterSeller/?id=${selectedUserState[0].id}`
        );
        dispatch(getSelectedUser(result.data.data));
    };
    const edit = async (e) => {
        e.preventDefault();
        if (["manager", "courier"].includes(props.match.params.type)) {
            let data = {
                name: name,
                phone: phone,
                phone_alt: phone2,
                email: email,
            };
            if (props.match.params.type === "courier") {
                data.assign_to_terminal = assignToTerminal;
            }
            console.log(data);
            let result = await axiosInstance
                .put(`/OrgEmployee/${props.match.params.id}`, data)
                .catch((err) => {
                    console.log(err);
                });
            if (result) {
                console.log(result);
                setShow(true);
            }
        } else if (props.match.params.type === "seller") {
            let edited = true;
            //TODO Delete Addresses

            // if (deletedAddresses.length > 0) {
            //     await axiosInstance.delete("/SellerAddress/", deletedAddresses).catch((err) => {
            //         if (err.response?.data) {
            //             err.response.data.map((error) => {
            //                 Object.keys(error).map((key) => {
            //                     message.error(error[key], 5);
            //                 });
            //             });
            //         }
            //     });
            // }
            if (oldAddresses.length > 0) {
                edited = await axiosInstance
                    .put("/SellerAddress/", oldAddresses)
                    .catch((err) => {
                        if (err.response?.data) {
                            err.response.data.map((error) => {
                                Object.keys(error).map((key) => {
                                    message.error(error[key], 5);
                                });
                            });
                        }
                    });
            }
            if (edited && newAddresses.length > 0) {
                const result = await axiosInstance
                    .post("/SellerAddress/", [
                        ...newAddresses.map((a) => {
                            a.seller = selectedUserState[0].id;
                            return a;
                        }),
                    ])
                    .catch((err) => {
                        if (err.response?.data) {
                            err.response.data.map((error) => {
                                Object.keys(error).map((key) => {
                                    message.error(error[key], 5);
                                });
                            });
                        }
                    });
                if (result?.data) {
                    setNewAddresses([]);
                    updateUser();
                    setShow(true);
                }
            } else if (edit) setShow(true);
        }
    };
    const getBranches = async (e, i) => {
        let result = await axiosInstance
            .get("/Branch/?page_count=100")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            SetNext(result.data.next);
        }
    };

    const getZones = async (i) => {
        let result = await axiosInstance
            .get(
                `/FilterZones/?branch=${selectedBranchesID[i]}&page_size=1000000`
            )
            .catch((err) => console.log(err));
        if (result) {
            setZones(result.data.data);
            SetNext(result.data.next);
        }
    };

    const getAreas = async (e, i) => {
        let result = await axiosInstance
            .get(`/FilterAreas/?Zone=${selectedZoneID}`)
            .catch((err) => console.log(err));
        if (result) {
            setAreas(result.data.data);
            SetNext(result.data.next);
        }
    };
    const handleSelectedBranch = (e) => {
        console.log(e);
        setSelectedBranch(e);
        // setSelectedBranchID(e);
    };
    const handleSelectedZone = (e) => {
        setSelectedZone(e);
        setSelectedZoneID(e);
    };
    const handleSelectedArea = (e) => {
        setselectedArea(e);
    };
    const handleSelectChange = (e, i, field, addresses, setAddresses) => {
        let addressesCopy = [...addresses];
        addressesCopy[i][field] = e.target.value;
        setAddresses(addressesCopy);
    };
    const getOldAddresses = async () => {
        let old = await Promise.all(
            selectedUserState[0].saved_address.map(async (a, i) => {
                let newA = {
                    ...a,
                    branch: a.branch.id,
                    area: String(a.area.id),
                    new_work_model: a.new_work_model.id,
                };
                let suc = await getArea(i, "old", a.branch.city);
                if (suc) {
                    let suc2 = await getNewWorkModel(i, "old", a.branch.id);
                    if (suc2) {
                        console.log("suc2");
                        return { obj: newA, areas: suc, new_work_models: suc2 };
                    }
                }
            })
        );
        console.log(old);
        setAreas({ ...areas, old: old.map((o) => o.areas) });
        setNewWorkModels({
            ...newWorkModels,
            old: old.map((o) => o.new_work_models),
        });
        setOldAddresses(old.map((o) => o.obj));
    };
    useEffect(() => {
        if (props.match.params.type == "seller") {
            getBranches();
            let copy = [];
            let branchesIDs = [];
            selectedUserState[0].saved_address.map((address, i) => {
                copy[i] = address.branch.name;
                setSelectedBranches(copy);
                console.log(copy);

                branchesIDs[i] = address.branch.id;
                setSelectedBranchesID(branchesIDs);
                // setSelectedBranch(address.area[0].zone[0].branch[0].name);
                // setSelectedBranchID(address.area[i].zone[i].branch[i].id);
                // setSelectedZone(address.area[i].zone[0].name);
                // setSelectedZoneID(address.area[i].zone[0].id);
                // setselectedArea(address.area[i].name);
            });
            getOldAddresses();
        }
    }, [selectedUserState]);
    const getForAddress = (i, field, getFunc) => {};
    const getWorkModels = async () => {
        let result = await axiosInstance
            .get("/WorkModel/")
            .catch((err) => console.log(err));
        if (result) {
            setModel(result.data.data);
            SetNext(result.data.next);
        }
    };
    const handleWorkModel = (e) => {
        setModel(e);
    };
    const addNewAddress = () => {
        setNewAddresses([
            ...newAddresses,
            { branch: "", area: "", new_work_model: "", address: "" },
        ]);
    };
    const lang = useLanguage();
    useEffect(() => {
        console.log(areas, newWorkModels, oldAddresses);
    }, [oldAddresses]);
    return (
        <div>
            <Container>
                <Row className="mb-2">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.accounts")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.allAccounts")}
                        </span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("personalInfo")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("sideMenu.allAccounts")}
                        </h2>
                    </Col>
                </Row>
                <Row className="">
                    <Col sm="12">
                        <h4 className="pageTitle">
                            {t("personalInfo")} #{selectedUserState[0].id}
                        </h4>
                    </Col>
                </Row>
                <Form>
                    {" "}
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("fullname")}</Form.Label>
                            <Form.Control
                                placeholder={t("fullname")}
                                name="fullName"
                                value={name}
                                // disabled
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                        {props.match.params.type == "seller" ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        ) : (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>
                                    {t("sideMenu.branches")}
                                </Form.Label>
                                <Form.Control
                                    placeholder={t("sideMenu.branches")}
                                    name="branch"
                                    value={selectedUserState[0].branch?.name}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("phone")}</Form.Label>

                            <Form.Control
                                placeholder={t("phone")}
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Col>
                        {props.match.params.type != "seller" ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        ) : (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("storename")}</Form.Label>

                                <Form.Control
                                    placeholder={t("storename")}
                                    name="storeName"
                                    value={storeName}
                                    onChange={(e) => setStore(e.target.value)}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm="12" md="6">
                            <Form.Label>{t("phone")}</Form.Label>
                            <Form.Control
                                placeholder={t("phone")}
                                name="Phone2"
                                value={phone2}
                                // disabled
                                onChange={(e) => setPhone2(e.target.value)}
                            />
                        </Col>
                        {props.match.params.type == "courier" && (
                            <Col
                                className="mb-3 d-flex flex-column justify-content-end"
                                sm="12"
                                md="6"
                            >
                                {/* <Form.Label>
                                    {t("Assign to terminal")}
                                </Form.Label> */}
                                <Form.Check
                                    type="checkbox"
                                    id="assign-to-terminal"
                                    label={t("Assign to terminal")}
                                    checked={assignToTerminal}
                                    onChange={(e) =>
                                        setAssignToTerminal(e.target.checked)
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                    {props.match.params.type == "seller" &&
                    oldAddresses.length > 0
                        ? oldAddresses.map((address, i) => {
                              return (
                                  <div
                                      style={{ position: "relative" }}
                                      className="addressCard mt-2"
                                  >
                                      <div
                                          role="button"
                                          onClick={() => {
                                              setDeletedAddresses((prev) => [
                                                  ...prev,
                                                  address.id,
                                              ]);

                                              let copy = [...oldAddresses];
                                              copy.splice(i, 1);
                                              setOldAddresses(copy);
                                          }}
                                          style={{
                                              position: "absolute",
                                              top: "0.75rem",
                                              left: "0.75rem",
                                              color: "#57BE6C",
                                              fontSize: "1.2em",
                                          }}
                                      >
                                          <i class="fas fa-times-circle"></i>
                                      </div>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("branch")}{" "}
                                              </Form.Label>
                                              <Form.Select
                                                  placeholder="Select Branch"
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "branch",
                                                          oldAddresses,
                                                          setOldAddresses
                                                      )
                                                  }
                                                  value={address.branch}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {" "}
                                                      --Select Branch--{" "}
                                                  </option>
                                                  {branches.map((branch) => {
                                                      return (
                                                          <option
                                                              value={branch.id}
                                                          >
                                                              {branch.name}
                                                          </option>
                                                      );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("area")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "area",
                                                          oldAddresses,
                                                          setOldAddresses
                                                      )
                                                  }
                                                  onClick={() =>
                                                      getArea(i, "old")
                                                  }
                                                  value={address.area}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {" "}
                                                      --Select Area--{" "}
                                                  </option>
                                                  {areas.old[i]?.map((area) => {
                                                      return (
                                                          <option
                                                              value={area.id}
                                                          >
                                                              {area.name}
                                                          </option>
                                                      );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("workModelName")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "new_work_model",
                                                          oldAddresses,
                                                          setOldAddresses
                                                      )
                                                  }
                                                  onClick={() =>
                                                      getNewWorkModel(i, "old")
                                                  }
                                                  value={address.new_work_model}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {" "}
                                                      --Select Work Model--{" "}
                                                  </option>

                                                  {newWorkModels.old[i]?.map(
                                                      (model) => {
                                                          return (
                                                              <option
                                                                  value={
                                                                      model.id
                                                                  }
                                                              >
                                                                  {model.name}
                                                              </option>
                                                          );
                                                      }
                                                  )}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("address")}
                                              </Form.Label>
                                              <Form.Control
                                                  placeholder="Address Description"
                                                  name="Address Description"
                                                  value={address.address}
                                                  onChange={(e) => {
                                                      let copy = [
                                                          ...oldAddresses,
                                                      ];
                                                      copy[i].address =
                                                          e.target.value;
                                                      setOldAddresses(copy);
                                                  }}
                                              />
                                          </Col>
                                      </Row>
                                  </div>
                              );
                          })
                        : null}
                    {props.match.params.type === "seller"
                        ? newAddresses.map((address, i) => {
                              return (
                                  <div
                                      style={{ position: "relative" }}
                                      className="addressCard mt-2"
                                  >
                                      <div
                                          role="button"
                                          onClick={() => {
                                              let copy = [...newAddresses];
                                              copy.splice(i, 1);
                                              setNewAddresses(copy);
                                          }}
                                          style={{
                                              position: "absolute",
                                              top: "0.75rem",
                                              left: "0.75rem",
                                              color: "#57BE6C",
                                              fontSize: "1.2em",
                                          }}
                                      >
                                          <i class="fas fa-times-circle"></i>
                                      </div>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("branch")}{" "}
                                              </Form.Label>
                                              <Form.Select
                                                  placeholder="Select Branch"
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "branch",
                                                          newAddresses,
                                                          setNewAddresses
                                                      )
                                                  }
                                                  value={address.branch}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {`${t("select")} ${t(
                                                          "branch"
                                                      )}`}
                                                  </option>
                                                  {branches.map((branch) => {
                                                      return (
                                                          <option
                                                              value={branch.id}
                                                          >
                                                              {branch.name}
                                                          </option>
                                                      );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("area")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "area",
                                                          newAddresses,
                                                          setNewAddresses
                                                      )
                                                  }
                                                  onClick={() =>
                                                      getArea(i, "new")
                                                  }
                                                  value={address.area}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {`${t("select")} ${t(
                                                          "area"
                                                      )}`}
                                                  </option>
                                                  {areas.new[i]?.map((area) => {
                                                      return (
                                                          <option
                                                              value={area.id}
                                                          >
                                                              {area.name}
                                                          </option>
                                                      );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("workModelName")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                      handleSelectChange(
                                                          e,
                                                          i,
                                                          "new_work_model",
                                                          newAddresses,
                                                          setNewAddresses
                                                      )
                                                  }
                                                  onClick={() =>
                                                      getNewWorkModel(i, "new")
                                                  }
                                                  value={address.new_work_model}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {t("select_work_model")}
                                                  </option>

                                                  {newWorkModels.new[i]?.map(
                                                      (model) => {
                                                          return (
                                                              <option
                                                                  value={
                                                                      model.id
                                                                  }
                                                              >
                                                                  {model.name}
                                                              </option>
                                                          );
                                                      }
                                                  )}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("address")}
                                              </Form.Label>
                                              <Form.Control
                                                  placeholder={t("address")}
                                                  name="Address Description"
                                                  value={address.address}
                                                  onChange={(e) => {
                                                      let copy = [
                                                          ...newAddresses,
                                                      ];
                                                      copy[i].address =
                                                          e.target.value;
                                                      setNewAddresses(copy);
                                                  }}
                                              />
                                          </Col>
                                      </Row>
                                  </div>
                              );
                          })
                        : null}
                    {props.match.params.type === "seller" ? (
                        <div
                            className="confirm mt-3 text-center"
                            role="button"
                            onClick={addNewAddress}
                        >
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addAddress")}
                        </div>
                    ) : null}
                    <button onClick={edit} className="confirm mt-3">
                        {t("saveChanges")}
                    </button>
                </Form>
            </Container>
            <SuccessfulRequest
                show={show}
                hide={hideModel}
                title={
                    lang == "ar" ? "تحديث معلومات المستخدم" : "Update User Info"
                }
                body={
                    lang == "ar"
                        ? "تم تحديث المعلومات بنجاح"
                        : "This user has been updated successfully"
                }
            />
        </div>
    );
};

export default ErrorHandler(AccountInfo, axiosInstance);
