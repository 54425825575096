import React, { useState, useEffect, useMemo } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    runsheetOrders,
    runsheetOrders_ar,
    useOrdersColumns,
} from "../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import isReAssigned, {
    getReassignedSheet,
} from "../../global-state/actions/reassignedRsAction";
import { useTranslation } from "react-i18next";
import TablePro from "../tables/TablePro";
import useLanguage from "../../hooks/useLanguage";

function RunsheetRequest({
    value,
    isReAssigned: isReAssignedState,
    handleChange,
}) {
    let history = useHistory();
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [ID, setID] = useState("");
    const [reAssignedRsOrder, setReAssignedRsOrder] = useState([]);

    const branch = useSelector((state) => state.runsheet?.branch) ?? {};
    const city = useSelector((state) => state.runsheet?.city) ?? {};
    const data = useSelector((state) => state.rsOrders?.orders) ?? {};
    const courier = useSelector((state) => state.runsheet?.courierObj);
    const object = useSelector((state) => state.runsheet);
    const reAssignedRS = useSelector(
        (state) => state.reAssignedRs.reAssignedRs
    );
    const isReassignedDispatch = useDispatch();
    const selectedReAssignedRsDispatch = useDispatch();

    const hide = () => {
        setShow(false);
        setShowConfirm(false);
        setShowError(false);
        isReassignedDispatch(isReAssigned(false));
        selectedReAssignedRsDispatch(getReassignedSheet(""));
        history.push("/dashboard");
    };
    const sendRequest = () => {
        setShow(true);
    };

    const confirm = async () => {
        if (isReAssignedState) {
            console.log(object.courier);
            let data = { courier: object.courier };
            let result = await axiosInstance
                .put(`/ReassigneCourier/${reAssignedRS.id}`, data)
                .catch((err) => {
                    if (err.response.status < 500) setError(err.response.data);
                    else setError(t("server_error"));
                    console.log(err.response);
                });
            if (result) {
                console.log(result);
                setID(result.data.id);
            }
        } else {
            // const ordersText = object.orders.map(order => order.toString())
            let data = {
                orders: object.orders ?? [],
                orders_ids: `[${(object.orders ?? []).join(",")}]`,
                courier: object.courier,
                branch: object.branch,
            };
            let result = await axiosInstance
                .post("/Runsheet/", data)
                .catch((err) => {
                    if (err.response.status < 500) setError(err.response.data);
                    else setError(t("server_error"));

                    console.log(err.response);
                });

            if (result) {
                console.log(result);
                setID(result.data?.id);
            }
        }
    };
    useEffect(() => {
        if (error !== "") {
            setShowError(true);
        }
    }, [error]);
    useEffect(() => {
        if (ID !== "") {
            setShowConfirm(true);
        }
    }, [ID]);
    useEffect(() => {
        if (isReAssignedState) {
            getSelectedRsOrders();
        }
    }, []);
    const getSelectedRsOrders = async () => {
        console.log("REQUEST");

        let result = await axiosInstance
            .get(`/Runsheet/${reAssignedRS.id}`)
            .catch((err) => console.log(err));
        if (result) {
            // console.log(result.data.orders.length);
            setReAssignedRsOrder(result.data.orders);
            //   setCount(result.data.count);
            // setSelectedBranch(result.data.data[0].branch);
        }
    };

    const discardRunsheet = () => {
        handleChange(null, 0);
    };
    const lang = useLanguage();

    return (
        <>
            <Row>
                <Col sm="6">
                    {isReAssignedState ? (
                        <p className="orderID">
                            {t("runsheetID")}#{reAssignedRS.id}
                        </p>
                    ) : null}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("couriername")}</label>
                        <Form.Control
                            defaultValue={courier.user.name}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("courierBranch")}</label>
                    <Form.Control
                        defaultValue={courier.branch?.name}
                        disabled
                        type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">
                            {t("courierVehicle")}
                        </label>
                        <Form.Control
                            defaultValue={courier.vehicle}
                            disabled
                            type="text"
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("courierPhone")}</label>
                    <Form.Control
                        defaultValue={courier.user.phone}
                        disabled
                        type="text"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button onClick={discardRunsheet} className="cancel">
                        {t("discard")}
                    </button>
                    <button onClick={sendRequest} className="confirm">
                        {t("sendRequest")}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <div>
                        <TablePro
                            dataSource={
                                isReAssignedState ? reAssignedRsOrder : data
                            }
                            columns={useOrdersColumns()}
                        />
                    </div>
                </Col>
                <CreationConfirmation
                    sendRequest={confirm}
                    show={show}
                    hide={hide}
                    body={`${t("runsheetCreateMsg1")} ${courier.user.name}`}
                    title={t("runsheetConfirm")}
                />
                <SuccessfulRequest
                    show={showConfirm}
                    hide={hide}
                    title={t("runsheetConfirm")}
                    body={
                        lang === "en"
                            ? `Runsheet with ID #${ID} has been successfully assigned to ${courier.user.name}`
                            : `تم تعيين خط سير برقم ${ID} للمندوب ${courier.user.name}`
                    }
                />
                <UnSuccessfulRequest
                    show={showError}
                    hide={hide}
                    title={t("runsheetConfirm")}
                    body={error}
                />
            </Row>
        </>
    );
}

export default ErrorHandler(RunsheetRequest, axiosInstance);
