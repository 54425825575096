import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import StatusCard from "../../../layout/StatusCard";
import { CircleRightMajor, ViewMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxBranch } from "../../../../global-state/actions/branchAction";
import { getSeller } from "../../../../global-state/actions/getSelectedAction";
import { SuccessfulRequest } from "../../../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { addRunsheetOrders } from "../../../../global-state/actions/rsOrdersAction";
import useLanguage from "../../../../hooks/useLanguage";

function SellersRecievedRequests(props) {
    const dispatch = useDispatch();
    const sellerDispatch = useDispatch();
    const dispatchOrders = useDispatch();

    const { t } = useTranslation();

    const [selected, setSelected] = useState("Sent");
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [payment, setPayment] = useState("");
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const [statesNumbers, setStatesNumbers] = useState({ 1: 0, 2: 0, 3: 0 });
    const prevSelectionModel = useRef(selectionModel);
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const hide = () => {
        setShow(false);
    };

    const hideSuccess = () => {
        setShowSuccess(false);
        selectState(currentState);
        getSummary();
    };
    const [columns, setColumns] = useState([
        {
            field: "payment_id",
            headerName: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">#{row.row?.id}</p>
            ),
        },
        {
            field: "id",
            headerName: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">#{row.row?.seller.id}</p>
            ),
        },
        {
            field: "name",
            headerName: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            headerName: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.seller.store_name}</p>
            ),
        },

        {
            field: "total_amount",
            headerName: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <strong>
                    {cur} {parseInt(row?.row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            headerName: `${t("createDate")}`,
            width: 190,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            headerName: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <Link
                    onClick={() => {
                        console.log(row.row, "ROOOOOOOOOOOOOOW");
                        dispatch(reduxBranch(row.row.seller.user.name));
                        sellerDispatch(getSeller(row.row.seller));
                        dispatchOrders(
                            addRunsheetOrders({
                                orders: row.row.orders,
                                payment: row.row,
                            })
                        );
                    }}
                    to={`/view_orders`}
                    className="sellers-wallets"
                >
                    <Icon source={CircleRightMajor} color="base" />
                </Link>
            ),
        },
    ]);

    const [column1, setColumn1] = useState([
        {
            field: "payment_id",
            headerName: `${t("paymentID")}`,
            width: 200,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">#{row.row?.id}</p>
            ),
        },
        {
            field: "id",
            headerName: `${t("sellerID")}`,
            width: 200,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">#{row.row?.seller.id}</p>
            ),
        },
        {
            field: "name",
            headerName: `${t("sellername")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.seller.user.name}</p>
            ),
        },
        {
            field: "store",
            headerName: `${t("storename")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.seller.store_name}</p>
            ),
        },

        {
            field: "total_amount",
            headerName: `${t("totalAmount")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <strong>
                    {cur} {parseInt(row?.row.amount)}
                </strong>
            ),
        },
        {
            field: "create_date",
            headerName: `${t("createDate")}`,
            width: 190,
            renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            field: "more",
            headerName: `${t("more")}`,
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => (
                <Link
                    onClick={() => {
                        setShow(true);
                        setPayment(row.row);
                    }}
                    to={`/sellers_orders/${row.row.id}`}
                    className="sellers-wallets"
                >
                    <Icon source={ViewMajor} color="base" />
                </Link>
            ),
        },
    ]);
    const lang = useLanguage();

    const [currentState, setCurrentState] = useState({
        id: "2",
        type: ` ${lang == "ar" ? "المرسلة" : "Sent"}`,
        number: statesNumbers["2"],
        styleClass: "statusCard",
        background: "#E0F3E8",
        style: { borderLeft: `solid 20px #BFFFDA` },
    });
    const cards = [
        {
            id: "2",
            type: ` ${lang == "ar" ? "المرسلة" : "Sent"}`,
            number: statesNumbers["2"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #BFFFDA` },
        },

        {
            id: "3",
            type: ` ${lang == "ar" ? "تجميع" : "Collected"}`,
            number: statesNumbers["3"],
            styleClass: "statusCard",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #148060` },
        },
    ];

    const getSummary = async () => {
        let result = await axiosInstance.get(`/PaymentSummary/?sender=${1}`);

        if (result) {
            console.log(result.data);
            setStatesNumbers(result.data);
        }
    };
    const confirmPayment = async () => {
        let result = await axiosInstance.post(
            `/AcceptPaymentRequest/${payment.id}`,
            { verdict: "True" }
        );
        if (result) {
            setShow(false);
            setShowSuccess(true);
        }
    };

    const selectState = async (
        state = {
            id: "2",
            type: "Sent",
            number: statesNumbers["2"],
            styleClass: "statusCard",
            selectedCard: "Sent",
            background: "#E0F3E8",
            style: { borderLeft: `solid 20px #BFFFDA` },
        }
    ) => {
        console.log(state);
        setCurrentState(state);
        let result;

        result = await axiosInstance.get(
            `/FilterPaymentRequest/?state=${state.id}&sender=${1}`
        );
        if (result) {
            setData(result.data.data);
            console.log(result.data);
            setCount(result.data.count);
        }
        setSelected(state.type);
    };

    useEffect(() => {
        selectState();
        getSummary();
    }, []);
    // const lang = localStorage.getItem("lang");

    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="cardsMargin">
                {cards.map((card, index) => {
                    return (
                        <Col
                            sm="12"
                            md="4"
                            onClick={() => selectState(card, cards[index + 1])}
                        >
                            <StatusCard
                                key={card.id}
                                selectedCard={selected}
                                type={card.type}
                                number={card.number}
                                styleClass={"statusCard"}
                                background={card.background}
                                style={card.style}
                            />
                        </Col>
                    );
                })}
            </Row>

            <Row>
                <Col sm="12">
                    <div className="reqTableContainer">
                        <div className="tableHeader">
                            <h6>{t("sentRequest")}</h6>
                        </div>

                        <div dir="ltr" className="requestTable">
                            <DataGrid
                                rows={data}
                                columns={
                                    currentState.id === "3" ? columns : column1
                                }
                                rowCount={count}
                                pagination
                                selectionModel={selectionModel}
                                onPageChange={async (page) => {
                                    setPage(page);

                                    let result = await axiosInstance.get(
                                        `/FilterPaymentRequest/?state=${
                                            currentState.id
                                        }&sender=${1}&page=${page + 1}`
                                    );
                                    setData(result.data.data);
                                    setCount(result.data.count);
                                    prevSelectionModel.current = selectionModel;
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("invoiceAttached")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        style={{ width: "100%" }}
                        src={payment.payment_proof}
                        alt={t("paymentProof")}
                    />
                </Modal.Body>
                {currentState.id === "4" ? null : (
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("cancel")}
                        </button>
                        <button
                            // onClick={}
                            className="confirm"
                            onClick={confirmPayment}
                        >
                            {t("collected")}
                        </button>
                    </Modal.Footer>
                )}
            </Modal>
            <SuccessfulRequest
                title={t("collectInvoice")}
                body={t("collectInvoiceSuccess")}
                show={showSuccess}
                hide={hideSuccess}
            />
        </>
    );
}

export default ErrorHandler(SellersRecievedRequests, axiosInstance);
