import React from "react";
import styles from "./TableComponent.module.css";
import { Skeleton } from "antd";

export default function TableLoading() {
    return (
        <tbody>
            {Array.from({ length: 10 }).map((_, i) => (
                <tr key={i} className={styles.loadingRow}>
                    <td colSpan="200">
                        <Skeleton.Input block active />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}
