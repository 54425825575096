import React from "react";
import styles from "./WeightStep.module.css";
import CenteredWMCard from "../../../AllWorkModels/WorkModelCard/CenteredWMCard";
import { Form, Input, Select } from "antd";
import useQuery from "../../../../../hooks/useQuery";
import SecondaryWMButton from "../../../AllWorkModels/WMButton/SecondaryWMButton";
import WMButton from "../../../AllWorkModels/WMButton";
import { useTranslation } from "react-i18next";

export default function WeightStep({}) {
    const { appendQuery } = useQuery();
    const { t } = useTranslation();
    return (
        <Form
            className={styles.container}
            onFinish={(data) => {
                appendQuery("default_weight", data["default_weight"]);
            }}
        >
            <CenteredWMCard
                title={t("work_model.default_weight")}
                actions={
                    <>
                        <SecondaryWMButton>{t("cancel")}</SecondaryWMButton>
                        <WMButton htmlType="submit">{t("confirm")}</WMButton>
                    </>
                }
            >
                <label>{t("work_model.default_weight_enter")}</label>
                <div
                    style={{ gap: "8px", width: "50%", marginTop: "8px" }}
                >
                    <Form.Item
                        name="default_weight"
                        rules={[
                            {
                                required: true,
                                message: t("requiredField"),
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            min={1}
                            placeholder={t("work_model.default_weight")}
                        />
                    </Form.Item>
                    {/*<Form.Item name="unit">*/}
                    {/*    <Input  placeholder="KG" />*/}
                    {/*</Form.Item>*/}
                </div>
            </CenteredWMCard>
        </Form>
    );
}
