import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import loginImg from "../../images/forgetPass.png";
import forgetPassSeller from "../../images/forgetPassSeller.png";
import "../../style-sheets/login.css";
import logoSeller from "../../images/logoSeller.png";
import logoAdmin from "../../images/logoAdmin.png";
import arabicLogo from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png"
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../api/axios";
import { code } from "../../global-state/actions/codeAction";

const ForetPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailerr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (
      e.target.value.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      )
    ) {
      setEmail(e.target.value);
      setEmailerr("");
    } else {
      setEmailerr("Enter valid email!");
    }
  };
  const submit = async () => {
    setLoading(true);
    let result = await axios.post(BASE_URL + "/forgot_password", {
      email: email,
    });
    if (result) {
      dispatch(code(email));

      setLoading(false);
      props.history.location.pathname == "/admin_forget_password"
        ? props.history.push("/admin_confirm")
        : props.history.push("/confirm");
    }
  };
  return (
    <div>
      <div className="p-3">
        {props.history.location.pathname == "/admin_forget_password" ?(
          <img src={localStorage.getItem("lang") === "en" ? logoAdmin : arabicLogo} className="img-fluid" />
        ) : (
          <img src={localStorage.getItem("lang") === "en" ? logoSeller : logoSellerAr} className="img-fluid" />
        )}
      </div>
      <Container className="pageContainer">
        <Row>
          <Col md="6" sm="12">
            {props.history.location.pathname == "/admin_forget_password" ? (
              <img src={loginImg} className="img-fluid" />
            ) : (
              <img src={forgetPassSeller} className="img-fluid" />
            )}
          </Col>
          <Col md="1" sm="12"></Col>
          <Col className="mt-5 p-5" md="4" sm="12">
            <h3 className="title">{t("enterEmail")}</h3>
            <p className="subTitle mt-2">
              {t("enterEmail")} <br />{" "}
              <span style={{ color: "black" }}>example@gmail.com</span>
            </p>
            <Form
              name="normal_login"
              className="login-form mt-5"
              initialValues={{
                remember: true,
              }}
              //   onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: `${t("enterEmail")}`,
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={t("email")}
                  name="email"
                  value={email}
                  onChange={handleEmail}
                />
              </Form.Item>

              <p className="error">{emailErr}</p>

              <Form.Item>
                {props.history.location.pathname == "/admin_forget_password" ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    className="login-form-button adminBtn"
                    onClick={submit}
                  >
                    {t("done")}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    className="login-form-button sellerBtn"
                    onClick={submit}
                  >
                    {t("done")}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForetPassword;
